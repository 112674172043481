import { readObject, removeObject, writeObject } from "@punks/core"
import { UserPayload } from "../state/userTypes"

export const USER_DATA_CACHE_KEY = "user:user_data_cache"

export const readCachedUser = (): UserPayload | undefined =>
  readObject(USER_DATA_CACHE_KEY)

export const saveCachedUser = (data: UserPayload) =>
  writeObject(USER_DATA_CACHE_KEY, data)

export const deleteCachedUser = () => removeObject(USER_DATA_CACHE_KEY)
