import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeOptions,
} from "@material-ui/core/styles"
import { TypographyStyleOptions } from "@material-ui/core/styles/createTypography"
import {
  AppActionColors,
  AppBackgrounds,
  AppButtonStyles,
  AppFontColors,
  AppFontVariant,
  AppPaletteColor,
  AppTheme,
  getHigherBreakpoint,
} from "@punks/core"

export const defaultColors = {
  action: {
    active: "rgba(0, 0, 0, 0.54)",
    disabled: "rgba(0, 0, 0, 0.26)",
    hover: "rgba(0, 0, 0, 0.04)",
    selected: "rgba(0, 0, 0, 0.08)",
    disabledBackground: "rgba(0, 0, 0, 0.12)",
  },
  textColors: {
    primary: "rgba(0, 0, 0, 0.87)",
    secondary: "rgba(0, 0, 0, 0.54)",
    disabled: "rgba(0, 0, 0, 0.38)",
  },
  background: {
    default: "rgba(0, 0, 0, 0)",
  },
}

const NONE_IMPORTANT = "none !important"
const DEFAULT_MOBILE_BREAKPINT = "sm"

const fontWeight = (value: string | undefined) =>
  value ? parseInt(value.trim()) : undefined

const paletteColor = (color: AppPaletteColor | undefined) =>
  color?.main
    ? {
        main: color.main,
        light: color.light,
        dark: color.dark,
      }
    : undefined

const textColors = (textColors?: AppFontColors) => ({
  primary: textColors?.primary ?? defaultColors.textColors.primary,
  secondary: textColors?.secondary ?? defaultColors.textColors.secondary,
  disabled: textColors?.disabled ?? defaultColors.textColors.disabled,
  hint: textColors?.hint,
})

const actionColors = (actionColors: AppActionColors | undefined) => ({
  active: actionColors?.active ?? defaultColors.action.active,
  disabled: actionColors?.disabled ?? defaultColors.action.disabled,
  focus: actionColors?.focus ?? defaultColors.action.selected,
  hover: actionColors?.hover ?? defaultColors.action.hover,
  selected: actionColors?.selected ?? defaultColors.action.selected,
})

const backgroundColors = (backgroundColors: AppBackgrounds | undefined) => ({
  default: backgroundColors?.default?.color ?? defaultColors.background.default,
})

const fontStyles = (font: AppFontVariant) => ({
  fontSize: font.size,
  fontWeight: fontWeight(font.weight),
  lineHeight: font.lineHeight,
  letterSpacing: font.letterSpacing,
})

const fontTypography = (
  font: AppFontVariant | undefined,
  mobileFont: AppFontVariant | undefined,
  mobileBreakpoint: number
): TypographyStyleOptions | undefined =>
  font
    ? {
        ...fontStyles(font),
        ...(mobileFont
          ? {
              [`@media (max-width:${mobileBreakpoint}px)`]: fontStyles(
                mobileFont
              ),
            }
          : undefined),
      }
    : undefined

const boxShadow = (theme: AppTheme, override?: string) =>
  override ?? theme.styles?.shadows?.disabled
    ? NONE_IMPORTANT
    : theme.styles?.shadows?.style

const buttonStyles = (
  theme: AppTheme,
  styles: AppButtonStyles | undefined,
  defaults?: AppButtonStyles
) => ({
  boxShadow: styles?.boxShadow ?? defaults?.boxShadow ?? boxShadow(theme),
  borderWidth: styles?.borderWidth ?? defaults?.borderWidth,
  fontWeight:
    fontWeight(styles?.fontWeight) ?? fontWeight(defaults?.fontWeight),
  letterSpacing: styles?.letterSpacing ?? defaults?.letterSpacing,
  paddingRight: styles?.padding?.right ?? defaults?.padding?.right,
  paddingLeft: styles?.padding?.left ?? defaults?.padding?.left,
  paddingTop: styles?.padding?.top ?? defaults?.padding?.top,
  paddingBottom: styles?.padding?.bottom ?? defaults?.padding?.bottom,
})

const DEFAULT_BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

const mobileBreakpoint = (variables: AppTheme) =>
  DEFAULT_BREAKPOINTS[
    getHigherBreakpoint(
      variables.breakpoints?.mobileBreakpoint ?? DEFAULT_MOBILE_BREAKPINT
    )
  ]

export const createBaseTheme = (
  variables: AppTheme,
  options?: ThemeOptions,
  ...args: any[]
) =>
  createMuiTheme({
    breakpoints: {
      values: DEFAULT_BREAKPOINTS,
    },
    palette: {
      primary: paletteColor(variables.palette?.primary),
      secondary: paletteColor(variables.palette?.secondary),
      info: paletteColor(variables.palette?.info),
      success: paletteColor(variables.palette?.success),
      warning: paletteColor(variables.palette?.warn),
      error: paletteColor(variables.palette?.error),
      text: textColors(variables.font?.styles?.textColors),
      action: actionColors(variables.palette?.action),
      background: backgroundColors(variables.background),
    },
    shape: {
      borderRadius: variables.styles?.borders?.borderRadius?.default,
    },
    typography: {
      fontFamily: variables.font?.styles?.defaults?.fontFamily,
      h1: fontTypography(
        variables.font?.typography?.h1,
        variables.font?.typographyMobile?.h1,
        mobileBreakpoint(variables)
      ),
      h2: fontTypography(
        variables.font?.typography?.h2,
        variables.font?.typographyMobile?.h2,
        mobileBreakpoint(variables)
      ),
      h3: fontTypography(
        variables.font?.typography?.h3,
        variables.font?.typographyMobile?.h3,
        mobileBreakpoint(variables)
      ),
      h4: fontTypography(
        variables.font?.typography?.h4,
        variables.font?.typographyMobile?.h4,
        mobileBreakpoint(variables)
      ),
      h5: fontTypography(
        variables.font?.typography?.h5,
        variables.font?.typographyMobile?.h5,
        mobileBreakpoint(variables)
      ),
      h6: fontTypography(
        variables.font?.typography?.h6,
        variables.font?.typographyMobile?.h6,
        mobileBreakpoint(variables)
      ),
      subtitle1: fontTypography(
        variables.font?.typography?.subtitle1,
        variables.font?.typographyMobile?.subtitle1,
        mobileBreakpoint(variables)
      ),
      subtitle2: fontTypography(
        variables.font?.typography?.subtitle2,
        variables.font?.typographyMobile?.subtitle2,
        mobileBreakpoint(variables)
      ),
      body1: fontTypography(
        variables.font?.typography?.body1,
        variables.font?.typographyMobile?.body1,
        mobileBreakpoint(variables)
      ),
      body2: fontTypography(
        variables.font?.typography?.body2,
        variables.font?.typographyMobile?.body2,
        mobileBreakpoint(variables)
      ),
      overline: fontTypography(
        variables.font?.typography?.overline,
        variables.font?.typographyMobile?.overline,
        mobileBreakpoint(variables)
      ),
    },
    overrides: {
      MuiAppBar: {
        root: {
          boxShadow: boxShadow(variables),
        },
      },
      MuiFab: {
        root: {
          boxShadow: boxShadow(variables),
        },
      },
      MuiButtonBase: {
        root: {
          boxShadow: boxShadow(variables),
        },
      },
      MuiOutlinedInput: {
        root: {
          "& fieldset": {
            borderWidth: "1px",
          },
        },
      },
      MuiButton: {
        root: buttonStyles(
          variables,
          undefined,
          variables.components?.button?.default
        ),
        contained: buttonStyles(
          variables,
          undefined,
          variables.components?.button?.contained
        ),
        outlined: buttonStyles(
          variables,
          undefined,
          variables.components?.button?.outlined
        ),
        outlinedPrimary: {
          borderColor: variables.palette?.primary?.main,
        },
        outlinedSecondary: {
          borderColor: variables.palette?.secondary?.main,
        },
      },
    },
    //   custom: {
    //     variables,
    //   },
    ...options,
    ...args,
  })

export const buildTheme = (
  variables: AppTheme,
  options?: ThemeOptions,
  ...args: any[]
) => {
  const theme = createBaseTheme(variables, options, ...args)
  switch (variables.font?.styles?.scaling) {
    case "responsive":
      return responsiveFontSizes(theme)
    case "fluid":
      throw Error("Not yet implemented from MUI")
    default:
      return theme
  }
}
