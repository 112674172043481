import {
  WishlistAvailabilityDocument,
  WishlistAvailabilityQuery,
  WishlistAvailabilityQueryVariables,
  WishlistDocument,
  WishlistItemAddDocument,
  WishlistItemAddMutation,
  WishlistItemAddMutationVariables,
  WishlistItemRemoveDocument,
  WishlistItemRemoveMutation,
  WishlistItemRemoveMutationVariables,
  WishlistQuery,
  WishlistQueryVariables,
} from "../backend-api"
import client from "../backend-api-client"

export const loadWishlist = (variables: WishlistQueryVariables) =>
  client.query<WishlistQuery, WishlistQueryVariables>({
    query: WishlistDocument,
    variables,
    fetchPolicy: "no-cache",
  })

export const getWishlistAvailability = (
  variables: WishlistAvailabilityQueryVariables
) =>
  client.query<WishlistAvailabilityQuery, WishlistAvailabilityQueryVariables>({
    query: WishlistAvailabilityDocument,
    variables,
    fetchPolicy: "no-cache",
  })

export const addItemToWishlist = (
  variables: WishlistItemAddMutationVariables
) =>
  client.mutate<WishlistItemAddMutation, WishlistItemAddMutationVariables>({
    mutation: WishlistItemAddDocument,
    variables,
  })

export const removeItemFromWishlist = (
  variables: WishlistItemRemoveMutationVariables
) =>
  client.mutate<
    WishlistItemRemoveMutation,
    WishlistItemRemoveMutationVariables
  >({
    mutation: WishlistItemRemoveDocument,
    variables,
  })
