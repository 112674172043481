import { capitalizeFirst } from "./strings"
import { differenceInYears } from "date-fns"

export const addDays = (date: Date, days: number) => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const getShortDayName = (date: Date): string | undefined => {
  switch (date.getDay()) {
    case 0:
      return "dom"
    case 1:
      return "lun"
    case 2:
      return "mar"
    case 3:
      return "mer"
    case 4:
      return "gio"
    case 5:
      return "ven"
    case 6:
      return "sab"
  }
}

export const formatShortDate = (date: Date) =>
  `${capitalizeFirst(
    getShortDayName(date) ?? ""
  )}, ${date
    .getDate()
    .toString()
    .padStart(2, "0")} ${date.getMonth().toString().padStart(2, "0")}`

export const toISODate = (date: Date) => {
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`
}

export const parseIsoDate = (value: string, separator = "-") => {
  const parts = value.split("T")[0].split(separator)
  return new Date(
    Date.UTC(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]))
  )
}

export const getAge = (birthday: Date) =>
  differenceInYears(new Date(), birthday)

export const toItaDate = (date: Date) =>
  capitalizeFirst(
    date.toLocaleString("it-IT", {
      weekday: "long",
      month: "2-digit",
      day: "2-digit",
    })
  )

export const toItaShortDate = (date: Date) =>
  capitalizeFirst(
    date.toLocaleString("it-IT", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
  )
