import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { UiState } from "./uiTypes"

const ui = createSlice({
  name: "ui",
  initialState: {
    basketDrawerOpen: false,
    wishlistDrawerOpen: false,
    loginDialogOpen: false,
    mobileMenuOpen: false,
    searchDialogOpen: false,
    newPoliciesPanelOpen: false,
    welcomePanelOpen: false,
    promoRouletteOpen: false,
    promoTimedVoucherOpen: false,
  } as UiState,
  reducers: {
    setBasketDrawerOpen(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        basketDrawerOpen: action.payload,
      }
    },
    setWishlistDrawerOpen(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        wishlistDrawerOpen: action.payload,
      }
    },
    setLoginDialogOpen(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        loginDialogOpen: action.payload,
      }
    },
    setSearchDialogOpen(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        searchDialogOpen: action.payload,
      }
    },
    setMobileMenuOpen(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        mobileMenuOpen: action.payload,
      }
    },
    setNewPoliciesPanelOpen(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        newPoliciesPanelOpen: action.payload,
      }
    },
    setWelcomePanelOpen(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        welcomePanelOpen: action.payload,
      }
    },
    setPromoRouletteModalOpen(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        promoRouletteOpen: action.payload,
      }
    },
    setPromoTimedVoucherOpen(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        promoTimedVoucherOpen: action.payload,
      }
    },
    setGameDialogOpen(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        gameDialogOpen: action.payload,
      }
    },
  },
})

export const {
  setBasketDrawerOpen,
  setWishlistDrawerOpen,
  setLoginDialogOpen,
  setSearchDialogOpen,
  setMobileMenuOpen,
  setNewPoliciesPanelOpen,
  setWelcomePanelOpen,
  setPromoRouletteModalOpen,
  setPromoTimedVoucherOpen,
  setGameDialogOpen,
} = ui.actions

export default ui.reducer
