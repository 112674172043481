import React from "react"
import { useSettingsInitialized } from "../../../../modules/settings/state/hooks"
import { useAppDispatch } from "../../../../state/store"
import { useLocalStorageEvents } from "../../../navigation/hooks/useLocalStorageEvents"
import { useAppUser } from "../../../user/state/userHooks"
import { BASKET_STORAGE_KEY, readBasket } from "../../services/persist"
import { loadCheckout } from "../../state/actions/load"
import { basketReset, updateBasketCompleted } from "../../state/checkoutSlice"

interface Props {
  children: any
}

export const BasketInitializer = ({ children }: Props) => {
  const dispatch = useAppDispatch()
  const initialized = useSettingsInitialized()
  const user = useAppUser()

  React.useEffect(() => {
    if (!user.initialized || !initialized) {
      return
    }
    dispatch(loadCheckout())
  }, [user.account?.email, initialized])

  const handleBasketUpdateEvent = () => {
    const basket = readBasket()
    if (basket) {
      dispatch(updateBasketCompleted(basket))
    } else {
      dispatch(basketReset())
    }
  }

  useLocalStorageEvents(BASKET_STORAGE_KEY, handleBasketUpdateEvent)

  return <>{children}</>
}
