import store from "../../state/store"
import { trimEnd } from "../../utils/strings"

let endpointOverride: string | undefined

export const setBackendEndpointOverride = (url?: string) => {
  endpointOverride = url || undefined
}

export const getGraphqlEndpoint = (): string => {
  // server side override
  if (endpointOverride) {
    return endpointOverride
  }

  // client side override
  const override = store.getState().settings.api?.graphqlEndpoint
  if (override) {
    return override
  }

  return process.env.BACKEND_GRAPHQL_ENDPOINT as string
}

export const getRestEndpoint = (): string => {
  return trimEnd(getGraphqlEndpoint()?.replace("/graphql", "") ?? "", "/")
}
