import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GameRef, GameState } from "./types"

const game = createSlice({
  name: "game",
  initialState: {} as GameState,
  reducers: {
    setGameRoundInProgress(state, action: PayloadAction<GameRef>) {
      state.gameRound = {
        gameId: action.payload.gameId,
        inmProgress: true,
      }
    },
    setGameRoundCompleted(state, action: PayloadAction<GameRef>) {
      state.gameRound = {
        gameId: action.payload.gameId,
        inmProgress: false,
      }
    },
  },
})

export const gameActions = game.actions

export default game.reducer
