import {
  TrackEventDocument,
  TrackEventMutation,
  TrackEventMutationVariables,
} from "../backend-api"
import client from "../backend-api-client"

export const trackEvent = (variables: TrackEventMutationVariables) =>
  client.mutate<TrackEventMutation, TrackEventMutationVariables>({
    mutation: TrackEventDocument,
    variables,
  })
