import { AppThunk } from "../../../state/store"
import {
  deleteCachedUser,
  readCachedUser,
  saveCachedUser,
} from "../services/userCacheService"
import { removeAuthToken } from "../../authentication/services/authService"
import {
  deleteUserMutation,
  loadCurrentUser,
  manageNewsletterMutation,
  userInformationMutation,
} from "../../../api/backend/customer/api"
import {
  toProfileResult,
  toProfileResultData,
} from "../converters/userProfileConverter"
import { CustomerUpdateInput } from "../../../api/backend/backend-api"
import {
  deleteAnonymousMarketingProfile,
  deleteAnonymousUserProfile,
  readAnonymousMarketingProfile,
  readAnonymousUserAccount,
  readAnonymousUserProfile,
  saveAnonymousMarketingProfile,
  saveAnonymousUserAccount,
  saveAnonymousUserProfile,
} from "../services/profileService"
import {
  AnonymousProfileResult,
  AuthenticatedProfileResult,
  ProfileUpdateErrorType,
  UserSessionTokens,
} from "../state/userTypes"
import {
  clearProfileData,
  deleteAccountError,
  deleteAccountStart,
  initializeLoggedProfileFromCache,
  resetDeleteOperation,
  setUserSession,
  updateAnonymousProfileData,
  updateLoggedProfileData,
  updateUserInformationError,
  updateUserInformationStart,
  updateUserInformationSuccess,
} from "../state/userSlice"
import { updateNewPoliciesPanelState } from "../../ui/state/uiActions"
import { identifyUser } from "../../tracking"

const deleteUserLocalData = () => {
  removeAuthToken()
  deleteCachedUser()
  deleteAnonymousUserProfile()
  deleteAnonymousMarketingProfile()
}

export const logoutUser = (): AppThunk => async (dispatch) => {
  deleteUserLocalData()
  dispatch(clearProfileData())
}

const updateAuthenticatedProfile = (
  profileProvider: () => Promise<AuthenticatedProfileResult>,
  callback?: () => void,
  errorCallback?: (error: any) => void
): AppThunk => async (dispatch) => {
  try {
    const cachedProfile = readCachedUser()
    if (cachedProfile) {
      dispatch(initializeLoggedProfileFromCache(cachedProfile))
    }

    const result = await profileProvider()
    if (!result.isAuthenticated || !result.user) {
      dispatch(logoutUser())
      return
    }
    saveCachedUser(result.user)
    identifyUser({
      email: result?.user?.account?.email,
      firstName: result?.user?.userProfile?.firstName,
      lastName: result?.user?.userProfile?.lastName,
    })

    dispatch(updateLoggedProfileData(result.user))

    if (
      result.user.marketingProfile.oldCustomer === true &&
      result.user.marketingProfile.newTermsAccepted !== true
    ) {
      dispatch(updateNewPoliciesPanelState(true))
    }

    if (callback) {
      callback()
    }
  } catch (e) {
    if (errorCallback) {
      errorCallback(e)
    } else {
      throw e
    }
  }
}

export const refreshUserProfile = (callback?: () => void): AppThunk => async (
  dispatch
) => {
  const user = await loadCurrentUser()
  if (!user.data) {
    throw Error("Missing result data")
  }

  const result = toProfileResult(user.data)
  if (!result.isAuthenticated || !result.user) {
    dispatch(clearProfileData())
    return
  }
  saveCachedUser(result.user)
  dispatch(updateLoggedProfileData(result.user))

  if (callback) {
    callback()
  }
}

export const deleteUser = (): AppThunk => async (dispatch) => {
  try {
    dispatch(deleteAccountStart())
    await deleteUserMutation()
    deleteUserLocalData()
    dispatch(clearProfileData())
    dispatch(resetDeleteOperation())
  } catch (e) {
    console.error("Error deleting account", e)
    dispatch(deleteAccountError())
  }
}

export const updateUserInformation = (
  inputData: CustomerUpdateInput,
  onCompleted?: () => void,
  onError?: (error: ProfileUpdateErrorType) => void
): AppThunk => async (dispatch) => {
  try {
    dispatch(updateUserInformationStart())
    const user = await userInformationMutation(inputData)

    if (user.data?.updateCustomer.errorType === "phoneInUse") {
      dispatch(updateUserInformationError("phoneInUse"))
      onError?.("phoneInUse")
      return
    }

    if (user && user.data?.updateCustomer?.customer) {
      const userData = toProfileResultData(user.data?.updateCustomer.customer)
      saveCachedUser(userData)
      dispatch(updateUserInformationSuccess(userData))
    } else {
      console.error("Error updating user", user.errors)
      dispatch(updateUserInformationError("generic"))
      onError?.("generic")
      return
    }
    onCompleted?.()
  } catch (e) {
    console.error("Error updating account", e)
    dispatch(updateUserInformationError("generic"))
    onError?.("generic")
  }
}

export const loadAuthenticatedProfile = () =>
  updateAuthenticatedProfile(async () => {
    const result = await loadCurrentUser()
    if (!result.data) {
      throw Error("Missing result data")
    }
    return toProfileResult(result.data)
  })

// export const subscribeToNewsletterAuthUser = (email: string) =>
//   updateAuthenticatedProfile(async () => {
//     await subscribeToNewsletterMutation(
//       email,
//       buildNewsletterUnsubscribeUrl(email)
//     )
//     const result = await loadCurrentUser()
//     if (!result.data) {
//       throw Error("Missing result data")
//     }
//     return toProfileResult(result.data)
//   })

// export const unsubscribeFromNewsletterAuthUser = (
//   email: string,
//   callback?: () => void,
//   errorCallback?: (error: any) => void
// ) =>
//   updateAuthenticatedProfile(
//     async () => {
//       await unsubscribeFromNewsletterMutation(email)
//       const result = await loadCurrentUser()
//       if (!result.data) {
//         throw Error("Missing result data")
//       }
//       return toProfileResult(result.data)
//     },
//     callback,
//     errorCallback
//   )

const updateAnonymousProfile = (
  profileProvider: () => Promise<AnonymousProfileResult>,
  callback?: () => void,
  errorCallback?: (error: any) => void
): AppThunk => async (dispatch) => {
  try {
    const result = await profileProvider()
    saveAnonymousUserAccount(result.account)
    saveAnonymousUserProfile(result.userProfile)
    saveAnonymousMarketingProfile(result.marketingProfile)
    dispatch(
      updateAnonymousProfileData({
        account: result.account,
        marketingProfile: result.marketingProfile,
        userProfile: result.userProfile,
        shippingAddresses: [],
      })
    )
    if (callback) {
      callback()
    }
  } catch (e) {
    if (errorCallback) {
      errorCallback(e)
    } else {
      throw e
    }
  }
}

export const loadAnonymousProfile = () =>
  updateAnonymousProfile(() =>
    Promise.resolve({
      account: readAnonymousUserAccount(),
      userProfile: readAnonymousUserProfile(),
      marketingProfile: readAnonymousMarketingProfile(),
    })
  )

export const manageNewsletterForAnonymousUser = (
  email: string,
  subscribed: boolean
) =>
  updateAnonymousProfile(async () => {
    await manageNewsletterMutation({
      email,
      subscribed,
    })
    return {
      marketingProfile: {
        ...readAnonymousMarketingProfile(),
        subscribedToNewsletter: true,
      },
      userProfile: {
        ...readAnonymousUserProfile(),
      },
      account: {
        email,
        emailConfirmed: true,
      },
    }
  })

export const initUserSession = (session: UserSessionTokens): AppThunk => async (
  dispatch
) => {
  dispatch(setUserSession(session))
}

// export const acceptNewPolicies = (
//   onCompleted?: () => void,
//   onError?: (error: ProfileUpdateErrorType) => void
// ): AppThunk => async (dispatch) => {
//   try {
//     dispatch(updateUserInformationStart())
//     const user = await customerAcceptNewPoliciesMutation()

//     if (user && user.data?.customerAcceptNewPolicies?.customer) {
//       const userData = toProfileResultData(
//         user.data?.customerAcceptNewPolicies.customer
//       )
//       saveCachedUser(userData)
//       dispatch(updateUserInformationSuccess(userData))
//     } else {
//       console.error("Error accepting new policies", user.errors)
//       dispatch(updateUserInformationError("generic"))
//       onError?.("generic")
//       return
//     }
//     onCompleted?.()
//   } catch (e) {
//     console.error("Error accepting new policies", e)
//     dispatch(updateUserInformationError("generic"))
//     onError?.("generic")
//   }
// }
