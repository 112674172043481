import { createSlice } from "@reduxjs/toolkit"
import { ResetPasswordState } from "./resetPasswordTypes"

const createInitialState = () =>
  ({
    isLoading: false,
    error: false,
    passwordResetSuccess: undefined,
  } as ResetPasswordState)

const { actions, reducer } = createSlice({
  name: "resetPassword",
  initialState: createInitialState(),
  reducers: {
    resetPasswordStart: () => ({
      ...createInitialState(),
      isLoading: true,
    }),
    resetPasswordSuccessful: () => ({
      isLoading: false,
      error: false,
      passwordResetSuccess: true,
    }),
    resetPasswordError: () => ({
      isLoading: false,
      error: true,
      passwordResetSuccess: false,
    }),
    resetPasswordReset: () => ({
      ...createInitialState(),
    }),
  },
})

export const {
  resetPasswordStart,
  resetPasswordSuccessful,
  resetPasswordError,
  resetPasswordReset,
} = actions

export default reducer
