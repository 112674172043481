import { trimStart } from "../../utils/strings"
import { buildUrl } from "../../utils/url"
import { locales } from "./locales"
import { localizePath } from "./paths"
import { buildReturnUrl } from "./utils"

// todo: normalize
export const productPagePath = (slug: string) => `/p/${slug}/`
export const brandPagePath = (slug: string) => `/brand/${slug}/`
export const brandCategoryPagePath = (slug: string) => `/b/${slug}/`

const pagePath = (document: any) =>
  document?.data?.seo?.slug ? `/${document?.data?.seo?.slug}` : "/"

const getCategoryPathSegments = (document: any): string[] => [
  ...(document.parent ? getCategoryPathSegments(document.parent) : []),
  document.data.slug.current,
]

const categoryPath = (document: any) =>
  `/${getCategoryPathSegments(document).join("/")}`

const productPath = (document: any) => `/p/${document.data.info.slug.current}/`

export const brandPageDocumentPath = (document: any) =>
  `/brand/${document.data?.seo?.slug}/`

export const brandCategoryPageDocumentPath = (document: any) =>
  brandCategoryPagePath(document.data?.seo?.slug)

export const serpPageDocumentPath = (document: any) =>
  `/c/${document.data?.seo?.slug}/`

export const serpPagePath = (slug: string) => `/c/${slug}/`

export const promoPagePath = (slug: string) => `/promo/${slug}/`

export const promoPageDocumentPath = (document: any) =>
  promoPagePath(document.data?.seo?.slug)

const pathPrefixes = new Map<string, string>([
  ["productCategory", "/c"],
  ["product", "/p"],
])

const getPagePrefix = (type: string) =>
  pathPrefixes.has(type) ? pathPrefixes.get(type) : ""

export const editorialPagePath = (document: any) =>
  document.category
    ? `/${document.category}/${document.data.seo.slug}/`
    : `/${document.data.seo.slug}/`

export const editorialPageUrl = (slug: string, category?: string) =>
  category ? `/${category}/${slug}/` : `/${slug}/`

const getNonLocalizedPath = (document: any) => {
  switch (document._type) {
    case "page":
      return pagePath(document)
    case "editorialPage":
      return trimStart(editorialPagePath(document), "/")
    case "productCategory":
      return categoryPath(document)
    case "product":
      return productPath(document)
    case "brandPage":
      return brandPageDocumentPath(document)
    case "brandCategoryPage":
      return brandCategoryPageDocumentPath(document)
    case "serp":
      return serpPageDocumentPath(document)
    case "promoPage":
      return promoPageDocumentPath(document)
  }
  console.log("cannot resolve url for", document)
  return ""
}

export const urlBuilder = (document: any) =>
  localizePath(document, getNonLocalizedPath(document), locales)

export const buildNextPath = (document: any) =>
  getNonLocalizedPath(document).substring(1).split("/")

export const buildPagePath = (document: any) => {
  try {
    return localizePath(
      document,
      `${getPagePrefix(document._type)}${getNonLocalizedPath(document)}`,
      locales
    )
  } catch (e) {
    console.error("Error building page path", document, e)
    return ""
  }
}

export const orderDetailsUrl = (orderId: string) => `/order/?o=${orderId}`

export const searchPageUrl = (query: string) =>
  buildUrl("/search/", {
    q: query,
  })

export const categoryPagePath = (segments: string[]) =>
  `/c/${segments.join("/")}/`

export const buildCategoryPath = (document: any) =>
  `/c${categoryPath(document)}/`

export const wishlistPagePath = () => "/wishlist/"

export const profilePagePath = () => "/profile/"
export const profileInfoPagePath = () => "/profile/info/"

export const basketRecoverPagePath = (basketId: string, returnUrl: string) =>
  buildUrl("/checkout/basket-recover/", {
    id: basketId,
    redirectUrl: returnUrl,
  })

export const basketPagePath = () => "/checkout/basket/"
export const recommendationsPagePath = () => "/checkout/recommendations/"
export const giftSelectPagePath = () => "/checkout/gift-select/"
export const shippingPagePath = () => "/checkout/shipping/"
export const samplesPagePath = () => "/checkout/samples/"
export const paymentPagePath = () => "/checkout/payment/"
export const verifyPaymentPagePath = () => "/checkout/verify/"
export const typPagePath = () => "/checkout/thank-you/"
export const typNoPayPagePath = (basketId: string) =>
  `/checkout/thank-you-no-pay/?c=${basketId}`

export const editOrderTypPagePath = () => "/edit-order/payment-verify/"

export interface PrefilledRegistrationData {
  email?: string
  firstName?: string
  lastName?: string
  phone?: string
}
export interface LoginPageUrlOptions {
  returnUrl?: string
  returnToCurrentUrl?: boolean
  prefilledData?: PrefilledRegistrationData
}
export interface RegistrationPageUrlOptions {
  returnUrl?: string
  returnToCurrentUrl?: boolean
}

const loginPagePath = "/account/login/"

export const loginPageUrl = (options?: LoginPageUrlOptions) => {
  const returnUrl = options ? buildReturnUrl(options) : undefined
  return returnUrl ? buildUrl(loginPagePath, { returnUrl }) : loginPagePath
}

export const registrationPagePath = "/account/register/"
export const accountDeletedPagePath = "/account/account-deleted/"
export const forgotPasswordPagePath = "/account/forgot-password/"

interface ForgotPasswordPageOptions {
  returnUrl?: string
  returnToCurrentUrl?: boolean
}

export const buildForgotPasswordPagePath = (
  options?: ForgotPasswordPageOptions
) => {
  const returnUrl = options ? buildReturnUrl(options) : undefined
  const urlParams = {
    ...(returnUrl ? { returnUrl } : {}),
  }
  return buildUrl(forgotPasswordPagePath, urlParams)
}

export const buildRegistrationPageUrl = (options?: LoginPageUrlOptions) => {
  if (options) {
    const returnUrl = buildReturnUrl(options)
    const urlParams = {
      ...(returnUrl ? { returnUrl } : {}),
      ...(options.prefilledData?.email
        ? { email: options.prefilledData?.email }
        : {}),
      ...(options.prefilledData?.firstName
        ? { firstName: options.prefilledData?.firstName }
        : {}),
      ...(options.prefilledData?.lastName
        ? { lastName: options.prefilledData?.lastName }
        : {}),
      ...(options.prefilledData?.phone
        ? { phone: options.prefilledData?.phone }
        : {}),
    }

    return buildUrl(registrationPagePath, urlParams)
  }
  return registrationPagePath
}

export const accountPendingPagePath = (email: string) =>
  `/account/account-pending/?email=${email}`
