const writeObject = <T>(key: string, value: T) =>
  window.localStorage.setItem(key, JSON.stringify(value))

const readObject = <T>(key: string): T | undefined => {
  const serializedValue = window.localStorage.getItem(key)
  return serializedValue ? (JSON.parse(serializedValue) as T) : undefined
}

const removeObject = (key: string) => {
  window.localStorage.removeItem(key)
}

// TODO: importare writeObject, ... da punks

const AUTH_TOKEN_KEY = "auth:jwt_token"

export const saveAuthToken = (token: string) =>
  writeObject(AUTH_TOKEN_KEY, token)

export const readAuthToken = () => readObject<string>(AUTH_TOKEN_KEY)

export const removeAuthToken = () => removeObject(AUTH_TOKEN_KEY)
