import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { LocalizationState } from "./localizationTypes"

const localization = createSlice({
  name: "localization",
  initialState: {
    languageId: "it_it",
  } as LocalizationState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => ({
      ...state,
      languageId: action.payload,
    }),
  },
})

export default localization.reducer
