import { useEventListener } from "./useEventListener"

export interface LocalStorageEvent<T> {
  key: string
  oldValue: T
  newValue: T
}

export const useLocalStorageEvents = <T>(
  key: string,
  handler: (event: LocalStorageEvent<T>) => void
) => {
  const handleStorageUpdate = (event: any) => {
    if (event.key === key) {
      handler(event)
    }
  }

  return useEventListener("storage", handleStorageUpdate)
}
