import React from "react"

const KlaviyoTracking = () => {
  if (!process.env.KALVIYO_PUBLIC_API_KEY) {
    return <></>
  }

  return (
    <script
      type="application/javascript"
      defer
      async
      src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.KALVIYO_PUBLIC_API_KEY}`}
    ></script>
  )
}

export default KlaviyoTracking
