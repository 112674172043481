import { mul, sub } from "../../../utils/math"
import { getSessionId } from "../internal/storage"
import {
  TrackAddPaymentInfoInput,
  TrackAddPromoCodeInput,
  TrackAddShippingInfoInput,
  TrackAddToCartInput,
  TrackAddToWishlistInput,
  TrackClickedProductInput,
  Tracker,
  TrackInitiateCheckoutInput,
  TrackPopupInput,
  TrackPurchaseInput,
  TrackRemoveFromCartInput,
  TrackSelectProductGiftInput,
  TrackViewProductInput,
  TrackReservationsInput,
} from "../types"
import {
  toAddToCartItem,
  toBasketInfo,
  toCommonProductItem,
  toPurchaseData,
  toPurchaseItem,
  toUserData,
} from "./converter"

const gtag = () => (window as any).gtag
const track = (event: string, data?: any) => {
  try {
    gtag()?.("event", event, data)
  } catch (e) {
    console.error(`Google tracker -> error tracking event ${event}`, e)
  }
}

const push = (event: string, data?: any) => {
  try {
    ;(window as any).dataLayer?.push({
      event,
      session: getSessionId(),
      data,
    })
  } catch (e) {
    console.error(`Google tracker -> error pushing event ${event}`, e)
  }
}

export const googleTracker: Tracker = {
  name: "Google",
  enabled: function (): boolean {
    return true
  },
  initialize: function (): void {},
  identifyUser: function (): void {},
  trackPageView: function (): void {},
  trackAddToCart: function (input: TrackAddToCartInput): void {
    const data = {
      currency: "EUR",
      value: mul(
        input.item.productVariant.inventory?.price ?? 0,
        input.item.quantity
      ),
      items: [toAddToCartItem(input.item)],
    }
    if (input.item.source === "basket-recommendations") {
      track("add_recommendation_to_cart", data)
      push("added_recommendation_to_cart", {
        ...data,
        basket: toBasketInfo(input.basket),
      })
    }
    track("add_to_cart", data)
    push("added_to_cart", {
      ...data,
      basket: toBasketInfo(input.basket),
    })
  },
  trackRemoveFromCart: function (input: TrackRemoveFromCartInput): void {
    const item = toPurchaseItem(input.item)
    const data = {
      currency: "EUR",
      value: mul(
        sub(parseFloat(item.price), parseFloat(item.discount)),
        item.quantity
      ),
      items: [item],
    }
    track("remove_from_cart", data)
    push("removed_from_cart", data)
  },
  trackAddToWishlist: function (input: TrackAddToWishlistInput): void {
    const data = {
      currency: "EUR",
      value: input.variant.price,
      items: [
        {
          variantId: input.variant.variantId,
          name: input.variant.name,
          brand: input.variant.brand,
          index: 0,
          price: input.variant.price,
          quantity: 1,
          google_business_vertical: "retail",
        },
      ],
    }
    track("add_to_wishlist", data)
    push("added_to_wishlist", data)
  },
  trackCompleteRegistration: function (): void {},
  trackContact: function (): void {},
  trackLead: function (): void {
    track("generate_lead", {
      // currency: "EUR",
      // value: LEAD_VALUE,
    })
  },
  trackViewProduct: function (input: TrackViewProductInput): void {
    const data = {
      currency: "EUR",
      value: input.variant.inventory?.price,
      items: [
        {
          ...toCommonProductItem(input.variant),
        },
      ],
    }
    track("view_item", data)
    push("viewed_item", data)
  },
  trackInitiateCheckout: function (input: TrackInitiateCheckoutInput): void {
    const data = {
      currency: "EUR",
      value: input.basket.recap.subtotal.price,
      coupon: input.basket.promotions[0]?.code,
      items: input.basket.items.map((x) => toPurchaseItem(x)),
    }
    track("begin_checkout", data)
    push("began_checkout", {
      ...data,
      basket: toBasketInfo(input.basket),
    })
  },
  trackAddPromoCode: function (input: TrackAddPromoCodeInput): void {
    const data = {
      promotion_id: input.promoCode,
      promotion_name: input.promoName,
      // items: input.basket.items.map((x) => toPurchaseItem(x)),
    }
    track("select_promotion", data)
    push("selected_promotion", data)
  },
  trackAddShippingInfo: function (input: TrackAddShippingInfoInput): void {
    const data = {
      currency: "EUR",
      value: input.basket.recap.subtotal.price,
      coupon: input.basket.promotions[0]?.code,
      items: input.basket.items.map((x) => toPurchaseItem(x)),
    }
    track("add_shipping_info", data)
    push("added_shipping_info", data)
  },
  trackAddPaymentInfo: function (input: TrackAddPaymentInfoInput): void {
    const data = {
      currency: "EUR",
      value: input.basket.recap.subtotal.price,
      coupon: input.basket.promotions[0]?.code,
      payment_type: input.paymentType,
      items: input.basket.items.map((x) => toPurchaseItem(x)),
    }
    track("add_payment_info", data)
    push("added_payment_info", data)
  },
  trackPurchase: function (input: TrackPurchaseInput): void {
    const purchaseData = toPurchaseData(input)
    console.log("google purchase", JSON.stringify(purchaseData))
    track("purchase", purchaseData)
    console.log("Google purchase tracked")
    push("purchase_completed", {
      ...purchaseData,
      basket: toBasketInfo(input.basket),
      user: input.basket.customer
        ? toUserData(input.basket.customer)
        : undefined,
    })
    // console.log("Google purchase event sent")
    // const conversionData = toConversionData(input)
    // console.log("google ads conversion", JSON.stringify(conversionData))
    // track("conversion", conversionData)
    console.log("Google ADS purchase event sent")
  },
  trackSearch: function (): void {
    push("view_item_list")
    push("viewed_item_list")
  },
  trackClickedProduct: function (input: TrackClickedProductInput): void {
    const data = {
      item_list_id: "search",
      item_list_name: "search",
      items: [
        {
          variantId: input.variant.info.variantId,
          name: input.variant.info.name,
          brand: input.variant.info.brandName,
          index: 0,
          price: input.variant.inventory?.price,
          quantity: 1,
          google_business_vertical: "retail",
        },
      ],
    }
    push("select_item", data)
    push("selected_item", data)
  },
  trackPopup: (input: TrackPopupInput) => {
    track("viewed_popup", input)
    // push("viewed_popup", input)
  },
  trackSelectProductGift: function (input: TrackSelectProductGiftInput): void {
    track("gift_selected", input)
    // push("gift_selected", input)
  },
  trackReservations: function (input: TrackReservationsInput): void {
    track("reservation", input)
    push("reservation", input)
  },
}
