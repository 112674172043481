import React from "react"
import { toMap } from "../../../../utils/array"
import { serializeNodes } from "./nodes"
import { NodeSerializerDefinition, Placeholders, SanityNode } from "./types"

export type NodeSerializationData = {
  placeholders?: Placeholders
}

export type NodeSerializer = (
  nodes: SanityNode[],
  data: NodeSerializationData
) => React.ReactElement

export const NodeSerializerContext = React.createContext<NodeSerializer>(() => {
  throw new Error("Missing serializer context")
})

interface Props {
  definitions: NodeSerializerDefinition[]
  children: any
}

export const SerializationProvider = ({ definitions, children }: Props) => {
  const definitionsMap = toMap(definitions, (x) => x.type)

  const serialize = (nodes: SanityNode[], data: NodeSerializationData) => {
    return serializeNodes(definitionsMap, nodes, data)
  }

  return (
    <NodeSerializerContext.Provider value={serialize}>
      {children}
    </NodeSerializerContext.Provider>
  )
}

interface RawContentSerializerProps {
  nodes?: SanityNode[]
  placeholders?: Placeholders
}

export const RawContentSerializer = ({
  nodes,
  placeholders,
}: RawContentSerializerProps) => {
  const serializer = React.useContext(NodeSerializerContext)
  return serializer(nodes ?? [], { placeholders })
}
