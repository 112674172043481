import {
  ChangeEmailDocument,
  ChangeEmailInput,
  ChangeEmailMutation,
  ChangeEmailMutationVariables,
  ChangeEmailVerifyDocument,
  ChangeEmailVerifyInput,
  ChangeEmailVerifyMutation,
  ChangeEmailVerifyMutationVariables,
  CustomerAcceptNewPoliciesDocument,
  CustomerAcceptNewPoliciesMutation,
  CustomerAcceptNewPoliciesMutationVariables,
  CustomerUpdateInput,
  DeleteCustomerDocument,
  DeleteCustomerMutation,
  DeleteCustomerMutationVariables,
  GetCustomerDocument,
  GetCustomerQuery,
  GetCustomerQueryVariables,
  NewsletterManageDocument,
  NewsletterManageInput,
  NewsletterManageMutation,
  NewsletterManageMutationVariables,
  UpdateCustomerDocument,
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables,
} from "../backend-api"
import client from "../backend-api-client"

export const loadCurrentUser = () =>
  client.query<GetCustomerQuery, GetCustomerQueryVariables>({
    query: GetCustomerDocument,
    fetchPolicy: "no-cache",
  })

export const userInformationMutation = (data: CustomerUpdateInput) =>
  client.mutate<UpdateCustomerMutation, UpdateCustomerMutationVariables>({
    mutation: UpdateCustomerDocument,
    variables: {
      input: data,
    },
  })

export const deleteUserMutation = () =>
  client.mutate<DeleteCustomerMutation, DeleteCustomerMutationVariables>({
    mutation: DeleteCustomerDocument,
  })

export const manageNewsletterMutation = (data: NewsletterManageInput) =>
  client.mutate<NewsletterManageMutation, NewsletterManageMutationVariables>({
    mutation: NewsletterManageDocument,
    variables: {
      input: data,
    },
  })

export const changeEmailMutation = (input: ChangeEmailInput) =>
  client.mutate<ChangeEmailMutation, ChangeEmailMutationVariables>({
    mutation: ChangeEmailDocument,
    variables: {
      input,
    },
  })

export const changeEmailVerifyMutation = (input: ChangeEmailVerifyInput) =>
  client.mutate<ChangeEmailVerifyMutation, ChangeEmailVerifyMutationVariables>({
    mutation: ChangeEmailVerifyDocument,
    variables: {
      input,
    },
  })

export const customerAcceptNewPoliciesMutation = () =>
  client.mutate<
    CustomerAcceptNewPoliciesMutation,
    CustomerAcceptNewPoliciesMutationVariables
  >({
    mutation: CustomerAcceptNewPoliciesDocument,
  })
