import {
  CheckoutAcceptNoLoggedPromoDocument,
  CheckoutAcceptNoLoggedPromoMutation,
  CheckoutAcceptNoLoggedPromoMutationVariables,
  CheckoutApplyBirthdayDiscountDocument,
  CheckoutApplyBirthdayDiscountMutation,
  CheckoutApplyBirthdayDiscountMutationVariables,
  CheckoutApplyGiftcardDocument,
  CheckoutApplyGiftcardMutation,
  CheckoutApplyGiftcardMutationVariables,
  CheckoutApplyPassagesDiscountDocument,
  CheckoutApplyPassagesDiscountMutation,
  CheckoutApplyPassagesDiscountMutationVariables,
  CheckoutApplyPointsDiscountDocument,
  CheckoutApplyPointsDiscountMutation,
  CheckoutApplyPointsDiscountMutationVariables,
  CheckoutApplyPromoCodeDocument,
  CheckoutApplyPromoCodeMutation,
  CheckoutApplyPromoCodeMutationVariables,
  CheckoutCreateDocument,
  CheckoutCreateMutation,
  CheckoutCreateMutationVariables,
  CheckoutDocument,
  CheckoutLockDocument,
  CheckoutLockMutation,
  CheckoutLockMutationVariables,
  CheckoutQuery,
  CheckoutQueryVariables,
  CheckoutRecoverDocument,
  CheckoutRecoverMutation,
  CheckoutRecoverMutationVariables,
  CheckoutRemoveBirthdayDiscountDocument,
  CheckoutRemoveBirthdayDiscountMutation,
  CheckoutRemoveBirthdayDiscountMutationVariables,
  CheckoutRemoveGiftcardDocument,
  CheckoutRemoveGiftcardMutation,
  CheckoutRemoveGiftcardMutationVariables,
  CheckoutRemovePassagesDiscountDocument,
  CheckoutRemovePassagesDiscountMutation,
  CheckoutRemovePassagesDiscountMutationVariables,
  CheckoutRemovePointsDiscountDocument,
  CheckoutRemovePointsDiscountMutation,
  CheckoutRemovePointsDiscountMutationVariables,
  CheckoutRemoveProductGiftDocument,
  CheckoutRemoveProductGiftMutation,
  CheckoutRemoveProductGiftMutationVariables,
  CheckoutRemovePromoCodeDocument,
  CheckoutRemovePromoCodeMutation,
  CheckoutRemovePromoCodeMutationVariables,
  CheckoutSelectProductGiftDocument,
  CheckoutSelectProductGiftMutation,
  CheckoutSelectProductGiftMutationVariables,
  CheckoutSetPaymentMethodDocument,
  CheckoutSetPaymentMethodMutation,
  CheckoutSetPaymentMethodMutationVariables,
  CheckoutSetSamplesDocument,
  CheckoutSetSamplesMutation,
  CheckoutSetSamplesMutationVariables,
  CheckoutSetShippingDocument,
  CheckoutSetShippingMutation,
  CheckoutSetShippingMutationVariables,
  CheckoutUpdateDocument,
  CheckoutUpdateMutation,
  CheckoutUpdateMutationVariables,
  CustomerCheckoutDocument,
  CustomerCheckoutQuery,
  CustomerCheckoutQueryVariables,
} from "../backend-api"
import client from "../backend-api-client"

export const getCheckout = (variables: CheckoutQueryVariables) =>
  client.query<CheckoutQuery, CheckoutQueryVariables>({
    query: CheckoutDocument,
    fetchPolicy: "no-cache",
    variables,
  })

export const getCustomerCheckout = (
  variables: CustomerCheckoutQueryVariables
) =>
  client.query<CustomerCheckoutQuery, CustomerCheckoutQueryVariables>({
    query: CustomerCheckoutDocument,
    fetchPolicy: "no-cache",
    variables,
  })

export const checkoutCreate = (variables: CheckoutCreateMutationVariables) =>
  client.mutate<CheckoutCreateMutation, CheckoutCreateMutationVariables>({
    mutation: CheckoutCreateDocument,
    variables,
  })

export const checkoutUpdate = (variables: CheckoutUpdateMutationVariables) =>
  client.mutate<CheckoutUpdateMutation, CheckoutUpdateMutationVariables>({
    mutation: CheckoutUpdateDocument,
    variables,
  })

export const checkoutLock = (variables: CheckoutLockMutationVariables) =>
  client.mutate<CheckoutLockMutation, CheckoutLockMutationVariables>({
    mutation: CheckoutLockDocument,
    variables,
  })

export const checkoutApplyPromoCode = (
  variables: CheckoutApplyPromoCodeMutationVariables
) =>
  client.mutate<
    CheckoutApplyPromoCodeMutation,
    CheckoutApplyPromoCodeMutationVariables
  >({
    mutation: CheckoutApplyPromoCodeDocument,
    variables,
  })

export const checkoutRemovePromoCode = (
  variables: CheckoutRemovePromoCodeMutationVariables
) =>
  client.mutate<
    CheckoutRemovePromoCodeMutation,
    CheckoutRemovePromoCodeMutationVariables
  >({
    mutation: CheckoutRemovePromoCodeDocument,
    variables,
  })

export const checkoutAcceptNoLoggedPromoMutation = (
  variables: CheckoutAcceptNoLoggedPromoMutationVariables
) =>
  client.mutate<
    CheckoutAcceptNoLoggedPromoMutation,
    CheckoutAcceptNoLoggedPromoMutationVariables
  >({
    mutation: CheckoutAcceptNoLoggedPromoDocument,
    variables,
  })

export const checkoutApplyBirthdayDiscount = (
  variables: CheckoutApplyBirthdayDiscountMutationVariables
) =>
  client.mutate<
    CheckoutApplyBirthdayDiscountMutation,
    CheckoutApplyBirthdayDiscountMutationVariables
  >({
    mutation: CheckoutApplyBirthdayDiscountDocument,
    variables,
  })

export const checkoutRemoveBirthdayDiscount = (
  variables: CheckoutRemoveBirthdayDiscountMutationVariables
) =>
  client.mutate<
    CheckoutRemoveBirthdayDiscountMutation,
    CheckoutRemoveBirthdayDiscountMutationVariables
  >({
    mutation: CheckoutRemoveBirthdayDiscountDocument,
    variables,
  })

export const checkoutApplyPassagesDiscount = (
  variables: CheckoutApplyPassagesDiscountMutationVariables
) =>
  client.mutate<
    CheckoutApplyPassagesDiscountMutation,
    CheckoutApplyPassagesDiscountMutationVariables
  >({
    mutation: CheckoutApplyPassagesDiscountDocument,
    variables,
  })

export const checkoutRemovePassagesDiscount = (
  variables: CheckoutRemovePassagesDiscountMutationVariables
) =>
  client.mutate<
    CheckoutRemovePassagesDiscountMutation,
    CheckoutRemovePassagesDiscountMutationVariables
  >({
    mutation: CheckoutRemovePassagesDiscountDocument,
    variables,
  })

export const checkoutSetShipping = (
  variables: CheckoutSetShippingMutationVariables
) =>
  client.mutate<
    CheckoutSetShippingMutation,
    CheckoutSetShippingMutationVariables
  >({
    mutation: CheckoutSetShippingDocument,
    variables,
  })

export const checkoutSetSamples = (
  variables: CheckoutSetSamplesMutationVariables
) =>
  client.mutate<
    CheckoutSetSamplesMutation,
    CheckoutSetSamplesMutationVariables
  >({
    mutation: CheckoutSetSamplesDocument,
    variables,
  })

export const checkoutGiftcardApply = (
  variables: CheckoutApplyGiftcardMutationVariables
) =>
  client.mutate<
    CheckoutApplyGiftcardMutation,
    CheckoutApplyGiftcardMutationVariables
  >({
    mutation: CheckoutApplyGiftcardDocument,
    variables,
  })

export const checkoutGiftcardRemove = (
  variables: CheckoutRemoveGiftcardMutationVariables
) =>
  client.mutate<
    CheckoutRemoveGiftcardMutation,
    CheckoutRemoveGiftcardMutationVariables
  >({
    mutation: CheckoutRemoveGiftcardDocument,
    variables,
  })

export const checkoutApplyPointsDiscount = (
  variables: CheckoutApplyPointsDiscountMutationVariables
) =>
  client.mutate<
    CheckoutApplyPointsDiscountMutation,
    CheckoutApplyPointsDiscountMutationVariables
  >({
    mutation: CheckoutApplyPointsDiscountDocument,
    variables,
  })

export const checkoutRemovePointsDiscount = (
  variables: CheckoutRemovePointsDiscountMutationVariables
) =>
  client.mutate<
    CheckoutRemovePointsDiscountMutation,
    CheckoutRemovePointsDiscountMutationVariables
  >({
    mutation: CheckoutRemovePointsDiscountDocument,
    variables,
  })

export const checkoutSetPaymentMethod = (
  variables: CheckoutSetPaymentMethodMutationVariables
) =>
  client.mutate<
    CheckoutSetPaymentMethodMutation,
    CheckoutSetPaymentMethodMutationVariables
  >({
    mutation: CheckoutSetPaymentMethodDocument,
    variables,
  })

export const checkoutRecover = (variables: CheckoutRecoverMutationVariables) =>
  client.mutate<CheckoutRecoverMutation, CheckoutRecoverMutationVariables>({
    mutation: CheckoutRecoverDocument,
    variables,
  })

export const checkoutSelectProductGift = (
  variables: CheckoutSelectProductGiftMutationVariables
) =>
  client.mutate<
    CheckoutSelectProductGiftMutation,
    CheckoutSelectProductGiftMutationVariables
  >({
    mutation: CheckoutSelectProductGiftDocument,
    variables,
  })

export const checkoutRemoveProductGift = (
  variables: CheckoutRemoveProductGiftMutationVariables
) =>
  client.mutate<
    CheckoutRemoveProductGiftMutation,
    CheckoutRemoveProductGiftMutationVariables
  >({
    mutation: CheckoutRemoveProductGiftDocument,
    variables,
  })
