import React from "react"
import { ApolloProvider } from "@apollo/react-hooks"
import { Provider } from "react-redux"
import store, { useAppDispatch } from "../state/store"
import client from "../api/backend/backend-api-client"
import { PunksProviders } from "./punks"
import { importFaIcons } from "./imports/faIcons"
import AuthInitializer from "../features/authentication/components/AuthInitializer"
import { WishlistInitializer } from "../features/wishlist/components/WishlistInitializer"
import { BasketInitializer } from "../features/checkout/components/basket/BasketInitializer"
import { ToastProvider } from "react-toast-notifications"
import AuthListener from "../features/authentication/components/AuthListener"
import KlaviyoTracking from "./tracking/klaviyo"
import GoogleTrackingHead from "./tracking/googleTrackingHead"
import GoogleTrackingBody from "./tracking/googleTrackingBody"
import { RootContentsProvider, RootData } from "../aggregators/rootData/context"
import Head from "next/head"
import { RawHtmlReact, RawStyle } from "../ui/utilities/Html"
import InternalTracking from "./tracking/internalTracking"
import CookiesBar from "./cookies"
import { initializeSettings } from "../modules/settings/state/actions"
import { selectEndpointOverrideUrl } from "../aggregators/rootData/selectors"
import PrivateModeGuard from "../modules/preview/components/PrivateModeGuard"

interface Params {
  children: any
}

export const WrapBody = ({ children }: Params) => {
  importFaIcons()
  return (
    <>
      <GoogleTrackingBody />
      <PunksProviders>
        <Provider store={store}>
          <ApolloProvider client={client}>
            <AuthInitializer>
              <AuthListener>
                <BasketInitializer>
                  <WishlistInitializer>
                    <ToastProvider placement="top-right">
                      {children}
                    </ToastProvider>
                  </WishlistInitializer>
                </BasketInitializer>
              </AuthListener>
            </AuthInitializer>
          </ApolloProvider>
        </Provider>
      </PunksProviders>
      <KlaviyoTracking />
    </>
  )
}

interface WrapPageHead {
  rootData: any
}

const WrapHead = ({ rootData }: WrapPageHead) => {
  return (
    <>
      <>
        {rootData?.layout?.data?.customScripts?.headBeforeCookies && (
          <RawHtmlReact
            value={rootData.layout.data.customScripts.headBeforeCookies}
          />
        )}
      </>
      <GoogleTrackingHead
        cookiesScript={
          rootData?.layout?.data?.customScripts?.cookiesHead && (
            <RawHtmlReact
              value={rootData.layout.data.customScripts.cookiesHead}
            />
          )
        }
      />
      <meta
        name="google-site-verification"
        content="St5gak4dU69jjliRQNrv7Bbx8cy3eUamuoV2A77zd-U"
      />
      {rootData?.layout?.data?.customScripts?.head && (
        <RawHtmlReact value={rootData.layout.data.customScripts.head} />
      )}
      <RawStyle value={rootData?.layout?.data?.customStyles?.styles} />
    </>
  )
}

interface WrapPageProps {
  rootData: RootData
  children: any
}

export const WrapPage = ({ rootData, children }: WrapPageProps) => {
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    dispatch(
      initializeSettings({
        api: {
          graphqlEndpoint: selectEndpointOverrideUrl(rootData.layout),
        },
      })
    )
  }, [])

  return (
    <PrivateModeGuard rootData={rootData}>
      <RootContentsProvider value={rootData}>
        <Head>
          <WrapHead rootData={rootData} />
        </Head>
        {rootData?.layout?.data?.customScripts?.bodyStart && (
          <>
            <RawHtmlReact
              value={rootData.layout.data.customScripts.bodyStart}
            />
          </>
        )}
        {children}
        {rootData?.layout?.data?.customScripts?.bodyEnd && (
          <>
            <RawHtmlReact value={rootData.layout.data.customScripts.bodyEnd} />
          </>
        )}
        <InternalTracking />
        <CookiesBar rootData={rootData} />
      </RootContentsProvider>
    </PrivateModeGuard>
  )
}
