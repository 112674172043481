import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ready, running, error, success } from "../../../types/operations"

import {
  ProfileState,
  ProfileUpdateErrorType,
  UserPayload,
  UserSessionTokens,
} from "./userTypes"

const createInitialState = () =>
  ({
    loggedOut: false,
    initialized: false,
    isAnonymous: true,
    isAuthenticated: false,
    account: undefined,
    userProfile: undefined,
    marketingProfile: undefined,
    shippingAddresses: undefined,
    update: ready(),
    delete: ready(),
    errorType: undefined,
    session: undefined,
  } as ProfileState)

const profile = createSlice({
  name: "profile",
  initialState: createInitialState(),
  reducers: {
    initializeLoggedProfileFromCache(
      state,
      action: PayloadAction<UserPayload>
    ) {
      return {
        ...state,
        initialized: true,
        isAnonymous: false,
        isAuthenticated: true,
        ...action.payload,
      }
    },
    updateLoggedProfileData(state, action: PayloadAction<UserPayload>) {
      return {
        ...state,
        initialized: true,
        isAnonymous: false,
        isAuthenticated: true,
        loggedOut: false,
        ...action.payload,
      }
    },
    deleteAccountStart: (state) => ({ ...state, delete: running() }),
    deleteAccountError: (state) => ({
      ...state,
      delete: error(),
    }),
    updateUserInformationStart: (state) => ({ ...state, update: running() }),
    updateUserInformationError: (
      state,
      action: PayloadAction<ProfileUpdateErrorType>
    ) => ({
      ...state,
      update: error(action.payload),
    }),
    updateUserInformationSuccess(state, action: PayloadAction<UserPayload>) {
      return {
        ...state,
        ...action.payload,
        update: success(),
      }
    },
    updateAnonymousProfileData(state, action: PayloadAction<UserPayload>) {
      return {
        ...state,
        initialized: true,
        isAnonymous: true,
        isAuthenticated: false,
        account: action.payload.account,
        userProfile: action.payload.userProfile,
        marketingProfile: action.payload.marketingProfile,
        subscriptions: [],
      }
    },
    clearProfileData() {
      return {
        ...createInitialState(),
        initialized: true,
        loggedOut: true,
      }
    },
    resetUpdateOperation(state) {
      return {
        ...state,
        update: ready(),
      }
    },
    resetDeleteOperation(state) {
      return {
        ...state,
        delete: ready(),
      }
    },
    setUserSession(state, action: PayloadAction<UserSessionTokens>) {
      return {
        ...state,
        session: action.payload,
      }
    },
  },
})

export const {
  initializeLoggedProfileFromCache,
  updateLoggedProfileData,
  deleteAccountError,
  deleteAccountStart,
  updateUserInformationStart,
  updateUserInformationError,
  updateUserInformationSuccess,
  updateAnonymousProfileData,
  clearProfileData,
  resetUpdateOperation,
  resetDeleteOperation,
  setUserSession,
} = profile.actions

export default profile.reducer
