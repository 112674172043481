import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { VerificationIntent, VerificationState } from "./verificationTypes"

const createInitialState = () =>
  ({
    isLoading: false,
    error: undefined,
    result: undefined,
    verificationSuccess: undefined,
  } as VerificationState)

const { actions, reducer } = createSlice({
  name: "verify",
  initialState: createInitialState(),
  reducers: {
    emailVerificationStart: () => ({
      ...createInitialState(),
      isLoading: true,
    }),
    emailVerificationSuccessful: (
      state,
      action: PayloadAction<VerificationIntent>
    ) => ({
      isLoading: false,
      error: false,
      verificationSuccess: true,
      intent: action.payload,
    }),
    emailVerificationError: () => ({
      ...createInitialState(),
      verificationSuccess: false,
      error: true,
    }),
    emailVerificationReset: () => ({
      ...createInitialState(),
    }),
  },
})

export const {
  emailVerificationStart,
  emailVerificationSuccessful,
  emailVerificationError,
  emailVerificationReset,
} = actions

export default reducer
