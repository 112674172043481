import React from "react"
import { TwRichTextRenderingProvider } from "../tailwind/richText"

interface Props {
  children: any
}

const RichTextProviders = ({ children }: Props) => {
  return <TwRichTextRenderingProvider>{children}</TwRichTextRenderingProvider>
}

export default RichTextProviders
