import {
  CustomerAccount,
  CustomerAddress,
  CustomerDataFragment,
  GetCustomerQuery,
  UserProfile as ApiUserProfile,
} from "../../../api/backend/backend-api"
import { Gender } from "../../../types/accounts"
import {
  MarketingProfile,
  UserShippingAddress,
  UserAccount,
  UserPayload,
  UserProfile,
} from "../state/userTypes"

export const parseGender = (value: string): Gender => {
  switch (value.toLowerCase()) {
    case "m":
    case "male":
      return "male"
    case "f":
    case "female":
      return "female"
    default:
      throw Error(`Invalid gender ${value}`)
  }
}

export const toUserProfile = (user: ApiUserProfile): UserProfile => ({
  firstName: user.name,
  lastName: user.surname,
  gender: user.gender ? parseGender(user.gender) : undefined,
  birthday: user.birthDay,
  phone: user.phone ?? undefined,
  taxCode: user.taxCode ?? undefined,
  state: user.state ?? undefined,
  country: user.country ?? undefined,
  city: user.city ?? undefined,
  address: user.address ?? undefined,
  zipCode: user.zipCode ?? undefined,
})

const toUserAccount = (account: CustomerAccount): UserAccount => ({
  email: account.email,
  emailVerified: account.emailVerified,
})

const toMarketingProfile = (user: CustomerDataFragment): MarketingProfile => ({
  subscribedToNewsletter: user.marketingProfile.subscribedToNewsletter ?? false,
  marketingConsent: user.marketingProfile.marketingConsent ?? false,
  profilingConsent: user.marketingProfile.profilingConsent ?? false,
  surveyConsent: user.marketingProfile.surveyConsent ?? false,
  fidelityCard: user.marketingProfile.cardId
    ? {
        number: user.marketingProfile.cardId,
        points: user.marketingProfile.cardBalance ?? 0,
      }
    : undefined,
  oldCustomer: user.marketingProfile.oldCustomer ?? undefined,
  hasOldCard: user.marketingProfile.hasOldCard ?? undefined,
  newTermsAccepted: user.marketingProfile.newTermsAccepted ?? undefined,
})

const toCustomerAddress = (address: CustomerAddress): UserShippingAddress => ({
  ...address,
  address2: address.address2 ?? undefined,
  company: address.company ?? undefined,
  phone: address.phone ?? undefined,
  notes: address.notes ?? undefined,
  type: address.type ?? undefined,
  vatNumber: address.vatNumber ?? undefined,
  phone2: address.phone2 ?? undefined,
})

export const toProfileResultData = (
  user: CustomerDataFragment
): UserPayload => ({
  account: toUserAccount(user.account),
  userProfile: toUserProfile(user.userProfile),
  marketingProfile: toMarketingProfile(user),
  shippingAddresses: user.addresses.map(toCustomerAddress),
})

export const toProfileResult = (data: GetCustomerQuery) =>
  data.getCustomer
    ? {
        isAuthenticated: true,
        user: toProfileResultData(data.getCustomer),
      }
    : {
        isAuthenticated: false,
        user: undefined,
      }
