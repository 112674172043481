import React from "react"
import { SanityRichTextProvider } from "./richText/provider"

interface Props {
  children: any
}

const SanityPunksProviders = ({ children }: Props) => {
  return (
    // <SanityProviders
    //   linkProcessorType="dynamic"
    //   clientConfig={{
    //     dataset: process.env.SANITY_DATASET ?? "",
    //     projectId: process.env.SANITY_PROJECT_ID ?? "",
    //     // token: process.env.SANITY_TOKEN,
    //   }}
    // >
    <SanityRichTextProvider>
      {/* <SiteThemeProvider> */}
      {children}
      {/* </SiteThemeProvider> */}
    </SanityRichTextProvider>
    // </SanityProviders>
  )
}

export default SanityPunksProviders
