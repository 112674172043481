import Head from "next/head"
import React from "react"
import { COVER_IMAGE } from "../data/meta"
import OrganizationJsonLD from "./OrganizationJsonLD"

export const SiteMeta = () => {
  return (
    <Head>
      <OrganizationJsonLD />

      <meta
        property="business:contact_data:street_address"
        content="Via Pietro Massia 13"
      />
      <meta property="business:contact_data:locality" content="Savigliano" />
      <meta property="business:contact_data:region" content="Torino" />
      <meta property="business:contact_data:postal_code" content="12038" />
      <meta property="business:contact_data:country_name" content="Italia" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="https://www.modusprofumerie.it" />
      <meta name="twitter:title" content="Modus Profumerie" />
      <meta name="twitter:description" content="" />
      <meta name="twitter:image" content={COVER_IMAGE} />

      {/* <DefaultTwitterMeta /> */}

      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/images/favicon/modus_logo_icon.jpg"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/images/favicon/modus_logo_icon.jpg"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/images/favicon/modus_logo_icon.jpg"
      />
      <link rel="manifest" href="/images/favicon/manifest.json" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta
        name="msapplication-TileImage"
        content="/images/favicon/ms-icon-144x144.png"
      />
      <meta name="theme-color" content="#ffffff"></meta>
    </Head>
  )
}
