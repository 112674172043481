import { combineReducers } from "@reduxjs/toolkit"

import navigationReducer from "../features/navigation/state/navigationSlice"
import checkoutReducer from "../features/checkout/state/checkoutSlice"
import localizationReducer from "../features/localization/state/localizationSlice"
import loginReducer from "../features/authentication/state/login/loginSlice"
import registerReducer from "../features/authentication/state/accountRegistration/registrationSlice"
import verifyReducer from "../features/authentication/state/accountVerification/verificationSlice"
import changeEmailReducer from "../features/authentication/state/changeEmail/changeEmailSlice"
import changeEmailVerifyReducer from "../features/authentication/state/changeEmailVerify/changeEmailVerifySlice"
import forgotPasswordReducer from "../features/authentication/state/forgotPassword/forgotPasswordSlice"
import resetPasswordReducer from "../features/authentication/state/passwordReset/resetPasswordSlice"
import userReducer from "../features/user/state/userSlice"
import wishlistReducer from "../features/wishlist/state/wishlistSlice"
import uiReducer from "../features/ui/state/uiSlice"
import popupReducer from "../features/promotions/state/popupSlice"
import sessionReducer from "../features/session/state/sessionSlice"
import settingsReducer from "../modules/settings/state/slice"
import gameReducer from "../features/games/state/slice"

const rootReducer = combineReducers({
  navigation: navigationReducer,
  checkout: checkoutReducer,
  localization: localizationReducer,
  user: userReducer,
  login: loginReducer,
  register: registerReducer,
  verifyUser: verifyReducer,
  changeEmail: changeEmailReducer,
  changeEmailVerify: changeEmailVerifyReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  wishlist: wishlistReducer,
  ui: uiReducer,
  popup: popupReducer,
  session: sessionReducer,
  settings: settingsReducer,
  games: gameReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
