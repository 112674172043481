export const brandColors = {
  facebook: "#3b5998",
  google: "#dd4b39",
  whatsapp: "#25D366",
  twitter: "#1DA1F2",
  gmail: "#c71610",
}

export const titlesGray = "#9b9b98"
export const lightBlack = "#333"
export const lightGray = "#7e7e7e"
export const lightestGray = "#d8d8d8"
export const paperBackground = "#f2f2f2"
export const cardShadows = {
  default: "rgb(100 100 111 / 20%) 0px 7px 29px 0px",
  hover: "rgb(100 100 111 / 50%) 0px 7px 29px 0px",
}
export const mediumLetterSpacing = "0.04rem"
export const breakpoints = { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 }
