export const isInt = (value: string | undefined) =>
  value?.match(/^\d+$/) ?? false

export const capitalizeFirst = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const toTitleCase = (string: string) => {
  const str = string.toLowerCase().split(" ")
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }

  return str.join(" ")
}

export const firstValue = (
  values: (string | undefined)[],
  defaultValue = ""
) => {
  for (const value of values) {
    if (value) {
      return value
    }
  }
  return defaultValue
}

export const isAllUppercase = (value: string) => value === value?.toUpperCase()

export const capitalizeSentence = (value: string) => {
  const trimmed = value.trimStart()
  return " ".repeat(value.length - trimmed.length) + toTitleCase(trimmed)
}

export const capitalizeSentences = (value: string) =>
  value
    ?.split(".")
    .map((x) => capitalizeSentence(x))
    .join(".")

export const ensureTailingSlash = (value: string) =>
  value?.endsWith("/") ? value : value + "/"

export const ensureStartSlash = (value: string) =>
  value?.startsWith("/") ? value : "/" + value

export const trimStartSlash = (value: string) =>
  value?.startsWith("/") ? value.substring(1) : value

export const trimStart = (str: string, value: string) =>
  str?.startsWith(value) ? str.substring(value.length) : str

export const trimEnd = (str: string, value: string) =>
  str?.endsWith(value) ? str.substring(0, str.length - value.length) : str

export const trim = (str: string, value: string) =>
  trimStart(trimEnd(str, value), value)
