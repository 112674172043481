import React from "react"
import { CustomColorsMap } from "../../contents/colors/types"
import { IconsMap } from "../../contents/icons/types"
import { LabelsMap } from "../../contents/labels/types"
import { WebsiteSettings } from "../../contents/options/types"
import { ProfileMenuData } from "../../contents/profile/types"
import { MenuData } from "../menu/types"
import { EditorialProductRecommendations } from "../../contents/productRecommendations/types"
import { EditorialBasketGifts } from "../../contents/basketGifts/types"

export interface RootData {
  layout: any
  labelsData: LabelsMap | undefined
  iconsData: IconsMap | undefined
  profileMenu: ProfileMenuData | undefined
  promotions: any[] | undefined
  promoBanner: any[] | undefined
  gameModals: any[] | undefined
  productRecommendations: EditorialProductRecommendations[] | undefined
  basketGifts: EditorialBasketGifts[] | undefined
  websiteSettings: WebsiteSettings | undefined
  colors: CustomColorsMap | undefined
  menuData: MenuData | undefined
}

export const RootContentsContext = React.createContext<RootData>({
  layout: undefined,
  labelsData: undefined,
  profileMenu: undefined,
  promotions: undefined,
  promoBanner: undefined,
  iconsData: undefined,
  websiteSettings: undefined,
  colors: undefined,
  menuData: undefined,
  productRecommendations: undefined,
  gameModals: undefined,
  basketGifts: undefined,
})

export const RootContentsProvider = RootContentsContext.Provider
