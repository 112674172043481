import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import {
  UserData,
  RegistrationState,
  RegistrationError,
} from "./registrationTypes"

const createInitialState = () =>
  ({
    isLoading: false,
    emailSent: false,
    error: undefined,
    user: undefined,
    invitationCode: undefined,
  } as RegistrationState)

const { actions, reducer } = createSlice({
  name: "register",
  initialState: createInitialState(),
  reducers: {
    setInvitationCode(state, action: PayloadAction<string>) {
      return {
        ...state,
        invitationCode: action.payload,
      }
    },
    userRegisterStart: () => ({
      ...createInitialState(),
      isLoading: true,
      emailSent: false,
    }),
    userRegisterSuccessful: (state, action: PayloadAction<UserData>) => ({
      ...state,
      emailSent: true,
      error: undefined,
      isLoading: false,
      user: action.payload,
    }),
    userRegisterFailure: (state, action: PayloadAction<RegistrationError>) => ({
      ...state,
      isLoading: false,
      emailSent: false,
      error: action.payload,
    }),
  },
})

export const {
  setInvitationCode,
  userRegisterStart,
  userRegisterSuccessful,
  userRegisterFailure,
} = actions

export default reducer
