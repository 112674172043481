import { processPath } from "./paths"
import { LocaleSettings } from "./types"

export const locales: LocaleSettings[] = [
  {
    id: "it_it",
    path: "",
  },
  {
    id: "en_us",
    path: "en",
  },
]

export const extractPageInfo = (path: string[]) => processPath(path, locales)

export const languagePaths = ["it", "en"]

const localesMapping: { [key: string]: string } = {
  it: "it_it",
  en: "it_it",
}

export const getLocaleId = (locale: string) => localesMapping[locale]

interface FetchPathsInput {
  fetchDocuments: (languageId: string) => Promise<any[]>
  mapProps: (doc: any) => any
}

export const fetchPaths = async (input: FetchPathsInput) => {
  const paths = []
  for (const lang of languagePaths) {
    const documents = await input.fetchDocuments(getLocaleId(lang))
    for (const doc of documents) {
      paths.push({
        params: input.mapProps(doc),
        locale: lang,
      })
    }
  }
  return paths
}
