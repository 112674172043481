import React from "react"
import { newUuid } from "../../utils"
import { getQueryParam } from "../../utils/url"
import { GtmBody } from "./gtm"

const GoogleTrackingBody = () => {
  React.useEffect(() => {
    const urmSource = getQueryParam(window.location, "utm_source")
    if (urmSource === "direct") {
      ;(window as any).dataLayer?.push({
        event: "init_direct_navigation",
        session: newUuid(),
      })
      return
    }
    ;(window as any).dataLayer?.push({
      event: "init_navigation",
      session: newUuid(),
    })
  }, [])
  return (
    <>
      {/* {process.env.GOOGLE_TAG_MANAGER_ALL_ID && (
        <GtmBody tagManagerId={process.env.GOOGLE_TAG_MANAGER_ALL_ID} />
      )} */}
      {process.env.GOOGLE_TAG_MANAGER_ID && (
        <GtmBody tagManagerId={process.env.GOOGLE_TAG_MANAGER_ID} />
      )}
    </>
  )
}

export default GoogleTrackingBody
