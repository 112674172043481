import React from "react"
import { GaHead } from "./ga"
import { GtmConsentScript, GtmHead } from "./gtm"

interface Props {
  cookiesScript: React.ReactNode
}

const GoogleTrackingHead = ({ cookiesScript }: Props) => {
  return (
    <>
      {process.env.GOOGLE_ANALYTICS_ID && (
        <GaHead analyticsId={process.env.GOOGLE_ANALYTICS_ID} />
      )}
      <GtmConsentScript />
      {cookiesScript}
      {process.env.GOOGLE_TAG_MANAGER_ID && (
        <GtmHead tagManagerId={process.env.GOOGLE_TAG_MANAGER_ID} />
      )}
    </>
  )
}

export default GoogleTrackingHead
