import { SanityNode } from "../types"
import { NodesProcessor } from "./types"

export const renderNodes = (
  nodes: SanityNode[],
  processors: NodesProcessor[]
) => {
  if (processors.length === 0) {
    throw new Error("Processors cannot be empty")
  }

  return processors[0](nodes, processors.slice(1))
}
