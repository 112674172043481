export interface ReturnUrlOptions {
  returnUrl?: string
  returnToCurrentUrl?: boolean
}

export const buildReturnUrl = (options: ReturnUrlOptions) => {
  if (options.returnUrl) {
    return options.returnUrl
  }
  if (options.returnToCurrentUrl) {
    return window.location.href
  }

  return undefined
}
