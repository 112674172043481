import React from "react"
import { ThemeProvider, CssBaseline, ThemeOptions } from "@material-ui/core"
import { AppTheme, useAppTheme, useDefaultAppTheme } from "@punks/core"
import { buildTheme } from "./builder"

interface Props {
  children: React.ReactNode
  withBaseline: boolean
  themeId?: string
  overrides?: ThemeOptions
}

const getTheme = (
  defaultTheme: AppTheme,
  customTheme: AppTheme | undefined
) => {
  if (customTheme) {
    return buildTheme(customTheme)
  }

  if (defaultTheme) {
    return buildTheme(defaultTheme)
  }

  return undefined
}

const MUIThemeProvider = ({
  children,
  withBaseline,
  themeId,
  overrides,
}: Props) => {
  const defaultTheme = useDefaultAppTheme()
  const customTheme = useAppTheme(themeId ?? "")
  const theme = getTheme(defaultTheme, customTheme)

  if (!theme) {
    return <>{children}</>
  }

  const themeWithOverrides = overrides ? Object.assign(theme, overrides) : theme

  return (
    <ThemeProvider theme={themeWithOverrides}>
      {withBaseline === true ? <CssBaseline /> : undefined}
      {children}
    </ThemeProvider>
  )
}

MUIThemeProvider.defaultProps = {
  withBaseline: false,
}

export { MUIThemeProvider }
