import { useEffect, useState } from "react"
import { RootData } from "../../../aggregators/rootData/context"
import { readPrivateModeCookie, writePrivateModeCookie } from "../cookies"
import { addDays } from "date-fns"

const tokenValidityDays = 30

export const usePrivateMode = (rootData: RootData) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>()
  const [hasEnteredToken, setHasEnteredToken] = useState<boolean>(false)

  if (!rootData?.websiteSettings?.internal.privateModeEnabled) {
    return {
      privateModeEnabled: false,
      isAuthenticated: false,
      hasEnteredToken: false,
      enterPreviewToken: () => {},
    }
  }

  const checkAuthenticationToken = () => {
    const tokenCookie = readPrivateModeCookie()
    setIsAuthenticated(
      tokenCookie === rootData?.websiteSettings?.internal.privateModePassword
    )
  }

  useEffect(() => {
    checkAuthenticationToken()
  }, [])

  const enterPreviewToken = (value: string) => {
    setHasEnteredToken(true)
    writePrivateModeCookie(value, addDays(new Date(), tokenValidityDays))
    checkAuthenticationToken()
  }

  return {
    privateModeEnabled: true,
    isAuthenticated,
    hasEnteredToken,
    enterPreviewToken,
  }
}
