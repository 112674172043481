import React from "react"
import { useAppDispatch } from "../../../../state/store"
import { useLocalStorageEvents } from "../../../navigation/hooks/useLocalStorageEvents"
import { logoutUser } from "../../../user/actions"
import {
  readCachedUser,
  USER_DATA_CACHE_KEY,
} from "../../../user/services/userCacheService"
import { updateLoggedProfileData } from "../../../user/state/userSlice"
import { UserPayload } from "../../../user/state/userTypes"

interface Props {
  children: any
}

const AuthListener = ({ children }: Props) => {
  const dispatch = useAppDispatch()

  const handleUserUpdated = (user: UserPayload) => {
    dispatch(updateLoggedProfileData(user))
  }

  const handleLogoutUser = () => {
    dispatch(logoutUser())
  }

  const handleUserUpdateEvent = () => {
    const user = readCachedUser()
    if (user) {
      handleUserUpdated(user)
    } else {
      handleLogoutUser()
    }
  }

  useLocalStorageEvents(USER_DATA_CACHE_KEY, handleUserUpdateEvent)
  return <>{children}</>
}

export default AuthListener
