import { TextRenderer } from "@punks/core"
import React from "react"
import { defaultRenderer } from "./defaultRenderer"

export interface TextRenderersContext {
  default: TextRenderer
}

export const TextRenderers = React.createContext<TextRenderersContext>({
  default: defaultRenderer,
})

export const useTextRenderers = () => React.useContext(TextRenderers)
export const useDefaultTextRenderer = () => useTextRenderers().default
