import { ensureStartSlash } from "../../utils/strings"
import { LocaleSettings, PageInfo } from "./types"

const getLocaleByPath = (locales: LocaleSettings[], prefix: string) =>
  locales.filter((x) => x.path.toLowerCase() === prefix.toLowerCase())[0] ??
  undefined

const getDefaultLocale = (locales: LocaleSettings[]) =>
  locales.filter((x) => x.path === "")[0]

const getLocaleById = (locales: LocaleSettings[], id: string) =>
  locales.filter((x) => x.id.toLowerCase() === id.toLowerCase())[0] ?? undefined

export const localizePath = (
  document: any,
  path: string,
  locales: LocaleSettings[]
) => {
  const locale = document.__i18n_lang
    ? getLocaleById(locales, document.__i18n_lang)
    : getDefaultLocale(locales)

  if (!locale) {
    throw new Error(`Cannot localize document ${JSON.stringify(document)}`)
  }

  return locale.path !== "" ? `/${locale.path}${path}` : ensureStartSlash(path)
}

export const processPath = (
  path: string[],
  locales: LocaleSettings[]
): PageInfo => {
  if (path.length === 0) {
    return {
      locale: getDefaultLocale(locales).id,
      slug: "",
    }
  }

  const locale = getLocaleByPath(locales, path[0])
  if (locale) {
    return {
      locale: locale.id,
      slug: path.slice(1).join("/"),
    }
  }

  return {
    locale: getDefaultLocale(locales).id,
    slug: path.join("/"),
  }
}
