import { loadWishlist } from "../../../../api/backend/wishlist/api"
import { AppThunk } from "../../../../state/store"
import { readWishlistId } from "../../services/persist"
import { toWishlist } from "../converters"
import {
  updateWishlistCompleted,
  updateWishlistError,
  updateWishlistStarted,
} from "../wishlistSlice"

export const loadUserWishlist = (): AppThunk => async (dispatch, getState) => {
  const wishlistId = getState().wishlist.wishlist?.id ?? readWishlistId()
  if (!wishlistId) {
    dispatch(updateWishlistCompleted(undefined))
    return
  }

  try {
    dispatch(updateWishlistStarted())
    const wishlist = await loadWishlist({
      id: wishlistId,
    })

    dispatch(
      updateWishlistCompleted(
        wishlist.data.wishlist ? toWishlist(wishlist.data.wishlist) : undefined
      )
    )
  } catch (err) {
    dispatch(updateWishlistError(err.toString()))
    console.error(`Error loading wishlist -> ${wishlistId}`, err)
  }
}
