import React from "react"
import { RawContentSerializer, SerializationProvider } from "./serializer"
import { Placeholders, SanityRichText, TextProps } from "./types"
import { markDefinitions, serializerDefinitions } from "./definitions"
import { MarksSerializationProvider } from "./marks"
import { RichTextContext } from "./context"
import { useDefaultTextRenderer } from "../../core/fields/RichTextField/renderer"
import Typography from "../../../../ui/atoms/Typography"

interface RawContentProps {
  value?: SanityRichText
  placeholders?: Placeholders
  typographyProps?: TextProps
  className?: string
  style?: React.CSSProperties
  rootComponent?: string
}

const defaultLinkResolver = (pageId: string) => {
  try {
    return ""
  } catch (e) {
    console.error("Error resolving document url", pageId)
    console.error(e)
    return undefined
  }
}

interface CompProps {
  tag: keyof JSX.IntrinsicElements
}

const HtmlTag: React.FunctionComponent<
  CompProps & React.HTMLAttributes<HTMLOrSVGElement>
> = ({ tag: Wrapper = "div", children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>
}

export const RawContent = ({
  value,
  typographyProps,
  placeholders,
  className,
  style,
  rootComponent,
}: RawContentProps) => {
  const linkResolver = defaultLinkResolver
  const textRenderer = useDefaultTextRenderer()

  if (!value) {
    return <></>
  }

  return (
    <RichTextContext.Provider
      value={{
        linkResolver,
        textRenderer,
        placeholders,
        typographyProps,
      }}
    >
      <SerializationProvider definitions={serializerDefinitions()}>
        <MarksSerializationProvider definitions={markDefinitions()}>
          <HtmlTag
            tag={rootComponent ?? ("div" as any)}
            className={className}
            style={style}
          >
            {typeof value === "string" || React.isValidElement(value) ? (
              <Typography {...(typographyProps as any)}>{value}</Typography>
            ) : (
              <RawContentSerializer nodes={value} placeholders={placeholders} />
            )}
          </HtmlTag>
        </MarksSerializationProvider>
      </SerializationProvider>
    </RichTextContext.Provider>
  )
}
