import imageUrlBuilder from "@sanity/image-url"
import { buildSanityClient } from "./client"

export interface SanityImageBuildOptions {
  width?: number
  height?: number
  blur?: number
}

const builder = imageUrlBuilder(buildSanityClient())

export const urlFor = (source: any) => {
  return source ? builder.image(source) : undefined
}

export const imageUrl = (source: any, options?: SanityImageBuildOptions) => {
  let img = urlFor(source)
  if (!img) {
    return undefined
  }

  if (options?.width) {
    img = img.width(options.width)
  }
  if (options?.height) {
    img = img.height(options.height)
  }
  if (options?.blur) {
    img = img.height(options.blur)
  }
  return img?.url() ?? undefined
}
