import { RootData } from "../../../../aggregators/rootData/context"
import { usePrivateMode } from "../../hooks/usePrivateMode"
import React from "react"
import PrivateModeLoginPage from "../PrivateModeLoginPage"

export interface PrivateModeGuardProps {
  children: any
  rootData: RootData
}

const PrivateModeGuard = ({ children, rootData }: PrivateModeGuardProps) => {
  const {
    enterPreviewToken,
    hasEnteredToken,
    isAuthenticated,
    privateModeEnabled,
  } = usePrivateMode(rootData)

  if (privateModeEnabled && !isAuthenticated) {
    return (
      <PrivateModeLoginPage
        onTokenSubmit={enterPreviewToken}
        loginError={hasEnteredToken && !isAuthenticated}
      />
    )
  }

  return <>{children}</>
}

export default PrivateModeGuard
