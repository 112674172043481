import { AppTheme } from "@punks/core"
import { mediumLetterSpacing } from "./variables"

export const dark = {
  main: "#3c3c3b",
}

export const paletteContrast = {
  black: "#fff",
  disabled: "#d6d6d6",
  error: "#fff",
  info: "#fff",
  neutral: "#f2f2f2",
  primary: "rgba(0, 0, 0, 0.87)",
  secondary: "#fff",
  success: "rgba(0, 0, 0, 0.87)",
  transparent: "rgba(0,0,0,0)",
  warning: "rgba(0, 0, 0, 0.87)",
  white: "rgba(0, 0, 0, 0.87)",
}

export const defaultAppTheme: AppTheme = {
  name: "default",
  default: true,
  background: {},
  breakpoints: {
    mobileBreakpoint: "sm",
  },
  font: {
    weights: {
      regular: 400,
      bold: 500,
      boldest: 700,
    },
    styles: {
      defaults: {
        fontFamily: "CustomManrope",
      },
      textColors: {
        primary: "#000",
      },
    },
    typography: {
      body1: {
        size: "0.875rem", // 14px
        letterSpacing: mediumLetterSpacing,
      },
      body2: {
        size: "0.75rem", // 12px
        letterSpacing: mediumLetterSpacing,
      },
      h1: {
        size: "4.375rem", // 70px
        lineHeight: "5.375rem", // 86px
        letterSpacing: mediumLetterSpacing,
      },
      h2: {
        size: "3.75rem", // 60px
        lineHeight: "4.75rem", // 76px
        letterSpacing: mediumLetterSpacing,
      },
      h3: {
        size: "2.875rem", // 46px
        lineHeight: "4.375rem", // 70px
        letterSpacing: mediumLetterSpacing,
      },
      h4: {
        size: "1.625rem", // 26px
        lineHeight: "2.25rem", // 36px
        letterSpacing: mediumLetterSpacing,
      },
      h5: {
        size: "1.4375rem", // 23px
        lineHeight: "1.875rem", // 30px
        letterSpacing: mediumLetterSpacing,
      },
      h6: {
        size: "1.25rem", // 20px
        lineHeight: "1.75rem", // 28px
        letterSpacing: mediumLetterSpacing,
      },
      overline: {
        size: "1rem", // 16px
        lineHeight: "1.375rem", // 22px
        letterSpacing: mediumLetterSpacing,
      },
      subtitle1: {
        size: "1.125rem", // 18px
        lineHeight: "1.75rem", // 28px
        letterSpacing: mediumLetterSpacing,
      },
      subtitle2: {
        size: "1rem", // 16px
        lineHeight: "1.25rem", // 20px
        letterSpacing: mediumLetterSpacing,
      },
    },
    typographyMobile: {
      h1: {
        size: "2.75rem", // 44px
        lineHeight: "3.5rem", // 56px
        letterSpacing: mediumLetterSpacing,
      },
      h2: {
        size: "1.75rem", // 28px
        lineHeight: "2.375rem", // 38px
        letterSpacing: mediumLetterSpacing,
      },
      h3: {
        size: "1.4375rem", // 23px
        lineHeight: "2rem", // 32px
        letterSpacing: mediumLetterSpacing,
      },
      h4: {
        size: "1.25rem", // 20px
        lineHeight: "1.875rem", // 30px
        letterSpacing: mediumLetterSpacing,
      },
      h5: {},
      h6: {},
      body1: {},
      body2: {},
      overline: {},
      subtitle1: {},
      subtitle2: {},
    },
  },
  styles: {
    borders: {
      borderRadius: {
        default: 0,
      },
    },
    shadows: {
      disabled: true,
    },
  },
  palette: {
    action: {
      selected: "rgba(0, 0, 0, 0.08)",
      disabled: "#f2f2f2",
      active: "#d6d6d6",
    },
    primary: {
      light: "#fddf99",
      main: "#dbbc71",
      dark: "#dbbc71",
    },
    secondary: {
      light: "#666665",
      main: "#3c3c3b",
      dark: "#161615",
    },
    error: {
      light: "#e85653",
      main: "#b0202a",
      dark: "#790000",
    },
    info: {
      main: "#2196f3",
      light: "rgb(77, 171, 245)",
      dark: "rgb(23, 105, 170)",
    },
    success: {
      main: "#f4f2eb",
      light: "rgb(246, 244, 239)",
      dark: "rgb(170, 169, 164)",
    },
    warn: {
      main: "#ff9800",
      light: "rgb(255, 172, 51)",
      dark: "rgb(255, 172, 51)",
    },
  },
  components: {
    button: {
      contained: {},
      default: {},
      outlined: {},
    },
  },
}
