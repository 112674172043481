import React from "react"
import ReactHtmlParser from "html-react-parser"

interface RawHtmlProps {
  value: string
}

export const RawHtml = ({ value }: RawHtmlProps) => {
  return value ? <div dangerouslySetInnerHTML={{ __html: value }} /> : <></>
}

interface RawScriptProps {
  value: string
}

export const RawScript = ({ value }: RawScriptProps) => {
  return value ? <script dangerouslySetInnerHTML={{ __html: value }} /> : <></>
}

interface RawStyleProps {
  value: string
}

export const RawStyle = ({ value }: RawStyleProps) => {
  return value ? <style dangerouslySetInnerHTML={{ __html: value }} /> : <></>
}

interface RawHtmlReactProps {
  value: string
}

const parseHtml = (value: string) => {
  try {
    // const htmlToReactParser = new Parser()
    // const reactElement = htmlToReactParser.parse(value)
    // return ReactDOMServer.renderToStaticMarkup(reactElement)
    return ReactHtmlParser(value)
  } catch (e) {
    console.error("Error parsing html", value)
    return <></>
  }
}

export const RawHtmlReact = ({ value }: RawHtmlReactProps) => {
  return <>{parseHtml(value)}</>
}

// interface HtmlCommentProps {
//   text: string
// }

// export const HtmlComment = ({ text }: HtmlCommentProps) => {
//   // return parseHtml(`<!-- ${text} -->`)
//   const html = "<script> Example HTML string s </script> <script>bbb</script>"
//   return <>{parseHtml(html)}</>
// }
