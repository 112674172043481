import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type CrmContactEntity = {
  __typename?: 'CrmContactEntity';
  contactId: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  marketingConsent?: Maybe<Scalars['Boolean']>;
  profilingConsent?: Maybe<Scalars['Boolean']>;
  surveyConsent?: Maybe<Scalars['Boolean']>;
  newsletterConsent?: Maybe<Scalars['Boolean']>;
  fidelityCardId?: Maybe<Scalars['String']>;
  fidelityCardNumber?: Maybe<Scalars['String']>;
  fidelityCardPoints?: Maybe<Scalars['Float']>;
  crmCreatedOn?: Maybe<Scalars['String']>;
  crmModifiedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};


export type PromoLineItemFilterType = {
  __typename?: 'PromoLineItemFilterType';
  linkedPriceCodes?: Maybe<Array<Scalars['String']>>;
};

export type PromoProductsFilterType = {
  __typename?: 'PromoProductsFilterType';
  productId?: Maybe<Array<Scalars['String']>>;
  eanCode?: Maybe<Array<Scalars['String']>>;
  itmCode?: Maybe<Array<Scalars['String']>>;
  brandCode?: Maybe<Array<Scalars['String']>>;
  categoryCode?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['String']>>;
  excludedProductId?: Maybe<Array<Scalars['String']>>;
  excludedEanCode?: Maybe<Array<Scalars['String']>>;
  excludedItmCode?: Maybe<Array<Scalars['String']>>;
  excludedBrandCode?: Maybe<Array<Scalars['String']>>;
  excludedCategoryCode?: Maybe<Array<Scalars['String']>>;
  excludedTags?: Maybe<Array<Scalars['String']>>;
};

export type PromoOrderFilterType = {
  __typename?: 'PromoOrderFilterType';
  minSubtotal?: Maybe<Scalars['Float']>;
  minArticles?: Maybe<Scalars['Int']>;
  firstOrderOnly?: Maybe<Scalars['Boolean']>;
};

export type PromoOptionsType = {
  __typename?: 'PromoOptionsType';
  cumulative: Scalars['Boolean'];
  type?: Maybe<Scalars['Float']>;
  exclusive?: Maybe<Scalars['Boolean']>;
  mixable?: Maybe<Scalars['Boolean']>;
};

export type PromoDatesFilterType = {
  __typename?: 'PromoDatesFilterType';
  validFrom?: Maybe<Scalars['DateTime']>;
  validTo?: Maybe<Scalars['DateTime']>;
};

export type PromoCustomerFilterType = {
  __typename?: 'PromoCustomerFilterType';
  type?: Maybe<CustomerType>;
};

export enum CustomerType {
  Authenticated = 'Authenticated',
  WithFidelity = 'WithFidelity'
}

export type PromoConditionType = {
  __typename?: 'PromoConditionType';
  datesFilter?: Maybe<PromoDatesFilterType>;
  productsFilter?: Maybe<PromoProductsFilterType>;
  lineItemsFilter?: Maybe<PromoLineItemFilterType>;
  customerFilter?: Maybe<PromoCustomerFilterType>;
  orderFilter?: Maybe<PromoOrderFilterType>;
};

export type PromoProductAddToBasketTriggerParamsType = {
  __typename?: 'PromoProductAddToBasketTriggerParamsType';
  products: PromoProductsFilterType;
};

export type PromoMinimumPriceTriggerParamsType = {
  __typename?: 'PromoMinimumPriceTriggerParamsType';
  minAmount: Scalars['Float'];
};

export type PromoVoucherCodeDurationType = {
  __typename?: 'PromoVoucherCodeDurationType';
  hours: Scalars['Int'];
  days: Scalars['Int'];
};

export type PromoVoucherCodeTriggerParamsType = {
  __typename?: 'PromoVoucherCodeTriggerParamsType';
  duration: PromoVoucherCodeDurationType;
  voucherPrefix: Scalars['String'];
};

export type BasketItemPromoCodeTriggerParamsType = {
  __typename?: 'BasketItemPromoCodeTriggerParamsType';
  maxProductsPerBasket?: Maybe<Scalars['Float']>;
};

export type PromoEffectType = {
  __typename?: 'PromoEffectType';
  type: PromoType;
  payload: Scalars['JSON'];
};

export enum PromoType {
  Fixed = 'Fixed',
  Dynamic = 'Dynamic',
  Bxgy = 'Bxgy',
  Bundle = 'Bundle',
  IncrementalDiscount = 'IncrementalDiscount',
  FreeShipping = 'FreeShipping',
  FreeProduct = 'FreeProduct',
  FreeGadget = 'FreeGadget',
  ProductDiscount = 'ProductDiscount',
  None = 'None'
}


export type DiscountAmountType = {
  __typename?: 'DiscountAmountType';
  minOrderPrice?: Maybe<Scalars['Float']>;
  value: Scalars['Float'];
  type: DiscountType;
};

export enum DiscountType {
  Absolute = 'Absolute',
  Percent = 'Percent'
}

export type FixedPromoPayloadType = {
  __typename?: 'FixedPromoPayloadType';
  amount: DiscountAmountType;
};

export type BxgyPromoPayloadType = {
  __typename?: 'BxgyPromoPayloadType';
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type FreeGiftPromoPayloadType = {
  __typename?: 'FreeGiftPromoPayloadType';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type FreeProductPayloadType = {
  __typename?: 'FreeProductPayloadType';
  eanCodes: Array<Scalars['String']>;
};

export type IncrementalDiscountPayloadType = {
  __typename?: 'IncrementalDiscountPayloadType';
  amounts: Array<DiscountAmountType>;
};

export type ProductDiscountPayloadType = {
  __typename?: 'ProductDiscountPayloadType';
  eanCodes: Array<Scalars['String']>;
  maxItems?: Maybe<Scalars['Int']>;
  amount: DiscountAmountType;
};

export type DynamicDiscountPayloadType = {
  __typename?: 'DynamicDiscountPayloadType';
  items: Array<DynamicDiscountItemType>;
};

export type DynamicDiscountItemType = {
  __typename?: 'DynamicDiscountItemType';
  id: Scalars['String'];
  productsFilter?: Maybe<PromoProductsFilterType>;
  amount: DiscountAmountType;
};

export type DynamicPromoCondition = {
  __typename?: 'DynamicPromoCondition';
  conditions: Array<PromoConditionType>;
};

export type PromoDefinition = {
  __typename?: 'PromoDefinition';
  id: Scalars['String'];
  promoCode: Scalars['String'];
  weight: Scalars['Float'];
  disabled: Scalars['Boolean'];
  options: PromoOptionsType;
  conditionType: PromotionConditionType;
  condition: Scalars['JSON'];
  effect: PromoEffectType;
  triggerType: PromoTriggerType;
  triggerParams?: Maybe<Scalars['JSON']>;
  productPromo?: Maybe<Scalars['Boolean']>;
  promoLogoUrl?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export enum PromotionConditionType {
  Default = 'Default',
  Dynamic = 'Dynamic'
}

export enum PromoTriggerType {
  Automatic = 'Automatic',
  FirstOrder = 'FirstOrder',
  InviteFriend = 'InviteFriend',
  PromoCode = 'PromoCode',
  VoucherCode = 'VoucherCode',
  ExitPopup = 'ExitPopup',
  ProductAddToBasket = 'ProductAddToBasket',
  NewsletterSubscription = 'NewsletterSubscription',
  MinimumPrice = 'MinimumPrice',
  Birthday = 'Birthday',
  Passages = 'Passages',
  Points = 'Points',
  BackOffice = 'BackOffice',
  BasketItemPromoCode = 'BasketItemPromoCode'
}

export type FeatureSwitchEntity = {
  __typename?: 'FeatureSwitchEntity';
  id: Scalars['String'];
  enabled: Scalars['Boolean'];
  betaUsers: Array<Scalars['String']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type ProductCustomInfoEntity = {
  __typename?: 'ProductCustomInfoEntity';
  productId: Scalars['String'];
  weight?: Maybe<Scalars['String']>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type OpenHour = {
  __typename?: 'OpenHour';
  from: Scalars['String'];
  to: Scalars['String'];
};

export type StoreOpenHours = {
  __typename?: 'StoreOpenHours';
  monday: Array<OpenHour>;
  tuesday: Array<OpenHour>;
  wednesday: Array<OpenHour>;
  thursday: Array<OpenHour>;
  friday: Array<OpenHour>;
  saturday: Array<OpenHour>;
  sunday: Array<OpenHour>;
};

export type StoreAddress = {
  __typename?: 'StoreAddress';
  street: Scalars['String'];
  cap: Scalars['String'];
  city: Scalars['String'];
  province: Scalars['String'];
};

export type StoreContacts = {
  __typename?: 'StoreContacts';
  phone?: Maybe<Scalars['String']>;
};

export type StoreBusinessHour = {
  __typename?: 'StoreBusinessHour';
  day: Scalars['String'];
  hours: Scalars['String'];
};

export type StoreService = {
  __typename?: 'StoreService';
  name: Scalars['String'];
};

export type StoreCalendar = {
  __typename?: 'StoreCalendar';
  url: Scalars['String'];
};

export type StoreDocumentV2 = {
  __typename?: 'StoreDocumentV2';
  id: Scalars['String'];
  company: Scalars['String'];
  name: Scalars['String'];
  address: StoreAddress;
  coordinates?: Maybe<Coordinates>;
  businessHours?: Maybe<Array<StoreBusinessHour>>;
  contacts: StoreContacts;
  services?: Maybe<Array<StoreService>>;
  serviceReservationCalendar?: Maybe<StoreCalendar>;
  storeVisitCalendar?: Maybe<StoreCalendar>;
};

export type DynamicPricePayload = {
  __typename?: 'DynamicPricePayload';
  maxDiscountPercent: Scalars['Float'];
  fallbackDiscountPercent: Scalars['Float'];
  unavailableCompetitorStockPercentMarkup: Scalars['Float'];
  roundingStrategy: RoundPriceStrategy;
};

export enum RoundPriceStrategy {
  NoDecimals = 'NoDecimals',
  FirstDecimal = 'FirstDecimal',
  AllDecimals = 'AllDecimals'
}

export type PriceRule = {
  __typename?: 'PriceRule';
  id: Scalars['String'];
  priceCode: Scalars['String'];
  promoCode?: Maybe<Scalars['String']>;
  weight: Scalars['Int'];
  disabled?: Maybe<Scalars['Boolean']>;
  crossedPrice?: Maybe<Scalars['Boolean']>;
  ruleType: PriceRuleType;
  discountType?: Maybe<DiscountType>;
  discountAmount?: Maybe<Scalars['Float']>;
  fixedPrice?: Maybe<Scalars['Float']>;
  fixedCompareAtPrice?: Maybe<Scalars['Float']>;
  validFrom?: Maybe<Scalars['DateTime']>;
  validTo?: Maybe<Scalars['DateTime']>;
  productIds?: Maybe<Array<Scalars['String']>>;
  excludedProductIds?: Maybe<Array<Scalars['String']>>;
  eanCodes?: Maybe<Array<Scalars['String']>>;
  excludedEanCodes?: Maybe<Array<Scalars['String']>>;
  itmCodes?: Maybe<Array<Scalars['String']>>;
  excludedItmCodes?: Maybe<Array<Scalars['String']>>;
  brandCodes?: Maybe<Array<Scalars['String']>>;
  excludedBrandCodes?: Maybe<Array<Scalars['String']>>;
  productCategories?: Maybe<Array<Scalars['String']>>;
  excludedProductCategories?: Maybe<Array<Scalars['String']>>;
  productTags?: Maybe<Array<Scalars['String']>>;
  excludedProductTags?: Maybe<Array<Scalars['String']>>;
  discountRulePayload?: Maybe<Scalars['JSON']>;
  dynamicPricePayload?: Maybe<Scalars['JSON']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export enum PriceRuleType {
  Discount = 'Discount',
  FixedPrice = 'FixedPrice',
  DynamicPrice = 'DynamicPrice'
}

export type CrmRequestsEntity = {
  __typename?: 'CrmRequestsEntity';
  id: Scalars['String'];
  cmrId?: Maybe<Scalars['String']>;
  ecommerceId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  method?: Maybe<Scalars['String']>;
  requestHeaders?: Maybe<Scalars['String']>;
  request?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Float']>;
  error?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  updatedDate: Scalars['DateTime'];
};

export type JobStatusEntity = {
  __typename?: 'JobStatusEntity';
  id: Scalars['String'];
  jobUid: Scalars['String'];
  runUid: Scalars['String'];
  status: Scalars['String'];
  runType: Scalars['String'];
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  result?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  updatedDate: Scalars['DateTime'];
};

export type ConfigParamEntry = {
  __typename?: 'ConfigParamEntry';
  id: Scalars['String'];
  value?: Maybe<Scalars['JSON']>;
  createdDate: Scalars['DateTime'];
  updatedDate: Scalars['DateTime'];
};

export type ProductsFeedDefinition = {
  __typename?: 'ProductsFeedDefinition';
  id: Scalars['String'];
  disabled?: Maybe<Scalars['Boolean']>;
  alias: Scalars['String'];
  path: Scalars['String'];
  user: Scalars['String'];
  password: Scalars['String'];
  filters?: Maybe<Scalars['JSON']>;
  fields?: Maybe<Scalars['JSON']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type StructuredText = {
  __typename?: 'StructuredText';
  text: Scalars['String'];
  html: Scalars['String'];
};

export type CatalogProductInfo = {
  __typename?: 'CatalogProductInfo';
  productId: Scalars['String'];
  productSlug: Scalars['String'];
  available: Scalars['Boolean'];
  archived: Scalars['Boolean'];
  weight?: Maybe<Scalars['String']>;
};

export type CatalogProductCategory = {
  __typename?: 'CatalogProductCategory';
  code: Scalars['String'];
  name: Scalars['String'];
  level: Scalars['Int'];
};

export type CatalogProductCategoryPath = {
  __typename?: 'CatalogProductCategoryPath';
  path: Array<CatalogProductCategory>;
};

export type CatalogProductTag = {
  __typename?: 'CatalogProductTag';
  key: Scalars['String'];
  code: Scalars['String'];
  value: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
};

export type CatalogProductContents = {
  __typename?: 'CatalogProductContents';
  name: Scalars['String'];
  productImages?: Maybe<Array<CatalogProductImage>>;
  campaignImages?: Maybe<Array<CatalogProductImage>>;
};

export type CatalogProductVariantShopifyData = {
  __typename?: 'CatalogProductVariantShopifyData';
  shopifyId?: Maybe<Scalars['String']>;
  shopifyStorefrontId?: Maybe<Scalars['String']>;
};

export type CatalogProductVariantInfo = {
  __typename?: 'CatalogProductVariantInfo';
  variantId: Scalars['String'];
  default: Scalars['Boolean'];
  available: Scalars['Boolean'];
  archived: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  brandCode?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
};

export type CatalogProductTexture = {
  __typename?: 'CatalogProductTexture';
  url: Scalars['String'];
  asset: Scalars['String'];
};

export type CatalogColorDefinition = {
  __typename?: 'CatalogColorDefinition';
  hex?: Maybe<Scalars['String']>;
};

export type CatalogProductColor = {
  __typename?: 'CatalogProductColor';
  name: Scalars['String'];
  value?: Maybe<CatalogColorDefinition>;
  texture?: Maybe<CatalogProductTexture>;
};

export type CatalogProductSize = {
  __typename?: 'CatalogProductSize';
  value: Scalars['String'];
};

export type CatalogProductVariantDetails = {
  __typename?: 'CatalogProductVariantDetails';
  quantity?: Maybe<CatalogProductSize>;
  color?: Maybe<CatalogProductColor>;
};

export type CatalogProductVideo = {
  __typename?: 'CatalogProductVideo';
  url: Scalars['String'];
};

export type CatalogProductVariantContents = {
  __typename?: 'CatalogProductVariantContents';
  sanityId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  shortDescription?: Maybe<StructuredText>;
  longDescription?: Maybe<StructuredText>;
  highlights?: Maybe<StructuredText>;
  instructions?: Maybe<StructuredText>;
  ingredients?: Maybe<StructuredText>;
  composition?: Maybe<StructuredText>;
  testResults?: Maybe<StructuredText>;
  fragrance?: Maybe<StructuredText>;
  fragranceFamily?: Maybe<StructuredText>;
  technology?: Maybe<StructuredText>;
  plus?: Maybe<StructuredText>;
  productImages?: Maybe<Array<CatalogProductImage>>;
  campaignImages?: Maybe<Array<CatalogProductImage>>;
  videos?: Maybe<Array<CatalogProductVideo>>;
};

export type CatalogProductLine = {
  __typename?: 'CatalogProductLine';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CatalogProductBrand = {
  __typename?: 'CatalogProductBrand';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CatalogProductMetadata = {
  __typename?: 'CatalogProductMetadata';
  brand?: Maybe<CatalogProductBrand>;
  productLines?: Maybe<Array<CatalogProductLine>>;
  tags?: Maybe<Array<CatalogProductTag>>;
  categories?: Maybe<Array<CatalogProductCategory>>;
  categoryPaths?: Maybe<Array<CatalogProductCategoryPath>>;
  relatedProducts?: Maybe<Array<Scalars['String']>>;
};

export type CatalogProductVariantMetadata = {
  __typename?: 'CatalogProductVariantMetadata';
  brand?: Maybe<CatalogProductBrand>;
  productLines?: Maybe<Array<CatalogProductLine>>;
  tags?: Maybe<Array<CatalogProductTag>>;
  categories?: Maybe<Array<CatalogProductCategory>>;
  allCategories?: Maybe<Array<CatalogProductCategory>>;
  categoryPaths?: Maybe<Array<CatalogProductCategoryPath>>;
  relatedProducts?: Maybe<Array<Scalars['String']>>;
  complementaryProducts?: Maybe<Array<Scalars['String']>>;
};

export type CatalogInventoryInfo = {
  __typename?: 'CatalogInventoryInfo';
  position: Scalars['Float'];
  price: Scalars['Float'];
  compareAtPrice?: Maybe<Scalars['Float']>;
  discountPercent?: Maybe<Scalars['Float']>;
  maxSellQuantity: Scalars['Float'];
  available?: Maybe<Scalars['Boolean']>;
  priceRuleCode?: Maybe<Scalars['String']>;
  priceRuleCodes?: Maybe<Array<Scalars['String']>>;
  priceRuleIds?: Maybe<Array<Scalars['String']>>;
  sku?: Maybe<Scalars['String']>;
  bestSeller?: Maybe<Scalars['Boolean']>;
};

export type CatalogProductVariant = {
  __typename?: 'CatalogProductVariant';
  info: CatalogProductVariantInfo;
  shopify?: Maybe<CatalogProductVariantShopifyData>;
  details: CatalogProductVariantDetails;
  contents: CatalogProductVariantContents;
  metadata: CatalogProductVariantMetadata;
  inventory: CatalogInventoryInfo;
};

export type ImageSize = {
  __typename?: 'ImageSize';
  width: Scalars['Int'];
  height: Scalars['Int'];
  aspectRatio: Scalars['Float'];
};

export type CatalogProductImage = {
  __typename?: 'CatalogProductImage';
  url: Scalars['String'];
  asset: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  size?: Maybe<ImageSize>;
};

export type CatalogProduct = {
  __typename?: 'CatalogProduct';
  info: CatalogProductInfo;
  contents: CatalogProductContents;
  metadata: CatalogProductMetadata;
};

export type CatalogProductPromo = {
  __typename?: 'CatalogProductPromo';
  code: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
};

export type CatalogProductExtraData = {
  __typename?: 'CatalogProductExtraData';
  promotions: Array<CatalogProductPromo>;
  noPromo: Scalars['Boolean'];
};

export type CatalogProductDocument = {
  __typename?: 'CatalogProductDocument';
  documentId: Scalars['String'];
  languageId: Scalars['String'];
  product: CatalogProduct;
  variants: Array<CatalogProductVariant>;
  defaultVariant: CatalogProductVariant;
  extraData?: Maybe<CatalogProductExtraData>;
};

export type VoucherCode = {
  __typename?: 'VoucherCode';
  id?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  amount: Scalars['Float'];
  cumulative: Scalars['Boolean'];
  cumulativeType: Scalars['Float'];
  exclusive: Scalars['Boolean'];
  condition: PromoConditionType;
  amountType: DiscountType;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  basketId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  usedDate?: Maybe<Scalars['DateTime']>;
  usedOnOrderId?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type CrmDisabledProfile = {
  __typename?: 'CrmDisabledProfile';
  id: Scalars['String'];
  email: Scalars['String'];
  createdOn: Scalars['DateTime'];
};

export type BrandProductOptions = {
  __typename?: 'BrandProductOptions';
  hidden: Scalars['Boolean'];
  isGift: Scalars['Boolean'];
};

export type CatalogItemMetadata = {
  __typename?: 'CatalogItemMetadata';
  creationDate: Scalars['String'];
  updateDate: Scalars['String'];
};

export type QuantityType = {
  __typename?: 'QuantityType';
  value: Scalars['String'];
};

export type ColorType = {
  __typename?: 'ColorType';
  hex?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CatalogBrandProductItemContents = {
  __typename?: 'CatalogBrandProductItemContents';
  languageId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  highlights?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  ingredients?: Maybe<Scalars['String']>;
  fragrance?: Maybe<Scalars['String']>;
  fragranceFamily?: Maybe<Scalars['String']>;
  testResults?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  technology?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
};

export type CatalogBrandProductVariantDocument = {
  __typename?: 'CatalogBrandProductVariantDocument';
  productId: Scalars['String'];
  productLine?: Maybe<Scalars['String']>;
  productLineId?: Maybe<Scalars['String']>;
  eanCode: Scalars['String'];
  brandName: Scalars['String'];
  brandCode: Scalars['String'];
  contents: Array<CatalogBrandProductItemContents>;
  tags?: Maybe<Array<Scalars['String']>>;
  quantity?: Maybe<QuantityType>;
  color?: Maybe<ColorType>;
  category?: Maybe<Scalars['String']>;
  secondaryCategories?: Maybe<Array<Scalars['String']>>;
  relatedEanCodes?: Maybe<Array<Scalars['String']>>;
  complementaryEanCodes?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  internalNotes?: Maybe<Scalars['String']>;
  _metadata: CatalogItemMetadata;
};

export type CatalogBrandProductStatus = {
  __typename?: 'CatalogBrandProductStatus';
  published: Scalars['Boolean'];
  publishedOn?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  disabled: Scalars['Boolean'];
  archived?: Maybe<Scalars['Boolean']>;
};

export type CatalogBrandProductItem = {
  __typename?: 'CatalogBrandProductItem';
  productId: Scalars['String'];
  brandCode: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  missingCategory?: Maybe<Scalars['Boolean']>;
  missingTags?: Maybe<Scalars['Boolean']>;
  secondaryCategories?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  _metadata: CatalogItemMetadata;
};

export type CatalogBrandProductDocument = {
  __typename?: 'CatalogBrandProductDocument';
  product: CatalogBrandProductItem;
  variants: Array<CatalogBrandProductVariantDocument>;
  status: CatalogBrandProductStatus;
};

export type ProductToolSearchResultPaging = {
  __typename?: 'ProductToolSearchResultPaging';
  totResults: Scalars['Int'];
  size: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  isLastPage: Scalars['Boolean'];
};

export type ProductToolSearchResultCategory = {
  __typename?: 'ProductToolSearchResultCategory';
  code: Scalars['String'];
  name: Scalars['String'];
  occurrences: Scalars['Int'];
};

export type ProductToolSearchResultBrand = {
  __typename?: 'ProductToolSearchResultBrand';
  name: Scalars['String'];
  code: Scalars['String'];
  occurrences: Scalars['Int'];
};

export type ProductToolSearchResultsAggregations = {
  __typename?: 'ProductToolSearchResultsAggregations';
  productCategories: Array<ProductToolSearchResultCategory>;
  brands: Array<ProductToolSearchResultBrand>;
};

export type ProductToolSearchResults = {
  __typename?: 'ProductToolSearchResults';
  results: Array<CatalogBrandProductDocument>;
  paging?: Maybe<ProductToolSearchResultPaging>;
  aggregations?: Maybe<ProductToolSearchResultsAggregations>;
};

export type ProductUpdateContents = {
  __typename?: 'ProductUpdateContents';
  shortDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  inci?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  highlights?: Maybe<Scalars['String']>;
  olfactoryNotes?: Maybe<Scalars['String']>;
  olfactoryFamily?: Maybe<Scalars['String']>;
  tests?: Maybe<Scalars['String']>;
  technology?: Maybe<Scalars['String']>;
};

export type ProductUpdateRecord = {
  __typename?: 'ProductUpdateRecord';
  ean: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  colorName?: Maybe<Scalars['String']>;
  colorHex?: Maybe<Scalars['String']>;
  colorUrl?: Maybe<Scalars['String']>;
  contents: ProductUpdateContents;
  relatedEanCodes?: Maybe<Array<Scalars['String']>>;
  complementaryEanCodes?: Maybe<Array<Scalars['String']>>;
  notes?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
  imageUrls?: Maybe<Array<Scalars['String']>>;
  videoUrls?: Maybe<Array<Scalars['String']>>;
};

export type ProductsUpdateData = {
  __typename?: 'ProductsUpdateData';
  records: Array<ProductUpdateRecord>;
};

export type NewProductIdResult = {
  __typename?: 'NewProductIdResult';
  productId: Scalars['String'];
};

export type ToolColorType = {
  __typename?: 'ToolColorType';
  hex?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ToolQuantityType = {
  __typename?: 'ToolQuantityType';
  value?: Maybe<Scalars['String']>;
};

export type BrandProductItemContentsType = {
  __typename?: 'BrandProductItemContentsType';
  languageId: Scalars['String'];
  name: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  highlights?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  ingredients?: Maybe<Scalars['String']>;
  fragrance?: Maybe<Scalars['String']>;
  fragranceFamily?: Maybe<Scalars['String']>;
  testResults?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  technology?: Maybe<Scalars['String']>;
  images: Array<Scalars['String']>;
  videos?: Maybe<Array<Scalars['String']>>;
};

export type BrandProductVariantType = {
  __typename?: 'BrandProductVariantType';
  productId: Scalars['String'];
  line?: Maybe<Scalars['String']>;
  productLine?: Maybe<Scalars['String']>;
  productLineId?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
  eanCode: Scalars['String'];
  brandName: Scalars['String'];
  brandCode: Scalars['String'];
  contents: Array<BrandProductItemContentsType>;
  tags?: Maybe<Array<Scalars['String']>>;
  quantity?: Maybe<ToolQuantityType>;
  color?: Maybe<ToolColorType>;
  category?: Maybe<Scalars['String']>;
  secondaryCategories?: Maybe<Array<Scalars['String']>>;
  relatedEanCodes?: Maybe<Array<Scalars['String']>>;
  complementaryEanCodes?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  price?: Maybe<Scalars['Float']>;
};

export type ToolProductVariantType = {
  __typename?: 'ToolProductVariantType';
  eanCode: Scalars['String'];
  productId: Scalars['String'];
  variant: BrandProductVariantType;
  versionId: Scalars['String'];
  variantIndex: Scalars['Int'];
  createdDate: Scalars['DateTime'];
  updatedDate: Scalars['DateTime'];
  disabled: Scalars['Boolean'];
  internalNotes?: Maybe<Scalars['String']>;
};

export type ToolProductVariantVersionType = {
  __typename?: 'ToolProductVariantVersionType';
  id: Scalars['String'];
  eanCode: Scalars['String'];
  version: Scalars['Int'];
  variant: BrandProductVariantType;
  createdDate: Scalars['DateTime'];
  updatedDate: Scalars['DateTime'];
  createdBy: Scalars['String'];
  published: Scalars['Boolean'];
  publishedOn?: Maybe<Scalars['DateTime']>;
  internalNotes?: Maybe<Scalars['String']>;
};

export type ToolProductItemType = {
  __typename?: 'ToolProductItemType';
  productId: Scalars['String'];
  productLine?: Maybe<Scalars['String']>;
  productLineId?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  category?: Maybe<Scalars['String']>;
  secondaryCategories?: Maybe<Array<Scalars['String']>>;
  relatedEanCodes?: Maybe<Array<Scalars['String']>>;
  complementaryEanCodes?: Maybe<Array<Scalars['String']>>;
  contents: Array<BrandProductItemContentsType>;
  options?: Maybe<BrandProductOptions>;
};

export type ToolProductVersionType = {
  __typename?: 'ToolProductVersionType';
  id: Scalars['String'];
  productId: Scalars['String'];
  brandCode: Scalars['String'];
  version: Scalars['Int'];
  createdDate: Scalars['DateTime'];
  updatedDate: Scalars['DateTime'];
  createdBy: Scalars['String'];
  published: Scalars['Boolean'];
  publishedOn?: Maybe<Scalars['DateTime']>;
  product: ToolProductItemType;
  internalNotes?: Maybe<Scalars['String']>;
};

export type ToolProductType = {
  __typename?: 'ToolProductType';
  productId: Scalars['String'];
  brandCode: Scalars['String'];
  versionId: Scalars['String'];
  internalNotes?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  updatedDate: Scalars['DateTime'];
  publishable: Scalars['Boolean'];
  published: Scalars['Boolean'];
  publishedOn?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  disabled: Scalars['Boolean'];
  archived?: Maybe<Scalars['Boolean']>;
};

export type MoveVariantToNewProductResult = {
  __typename?: 'MoveVariantToNewProductResult';
  eanCode: Scalars['String'];
  oldProductId: Scalars['String'];
  newProductId: Scalars['String'];
};

export type ProductsDownloadResult = {
  __typename?: 'ProductsDownloadResult';
  downloadUrl: Scalars['String'];
};

export type ProductsUpdatePreviewResult = {
  __typename?: 'ProductsUpdatePreviewResult';
  data: ProductsUpdateData;
  uploadHash: Scalars['String'];
};

export type ProductCustomDataInfo = {
  __typename?: 'ProductCustomDataInfo';
  name: Scalars['String'];
  brand: Scalars['String'];
};

export type ProductCustomItemMapping = {
  __typename?: 'ProductCustomItemMapping';
  meta?: Maybe<ProductCustomDataInfo>;
  data: ProductCustomInfoEntity;
};

export type ProductCustomInfoItems = {
  __typename?: 'ProductCustomInfoItems';
  items: Array<ProductCustomItemMapping>;
};

export type OperationResult = {
  __typename?: 'OperationResult';
  success: Scalars['Boolean'];
};

export type CustomerUnSubscription = {
  __typename?: 'CustomerUnSubscription';
  id: Scalars['String'];
  websiteName: Scalars['String'];
  medium: Scalars['String'];
  address: Scalars['String'];
  createdOn?: Maybe<Scalars['DateTime']>;
};

export type GetNarUserInfoData = {
  __typename?: 'GetNarUserInfoData';
  rawData: Scalars['String'];
};

export type CustomerAddress = {
  __typename?: 'CustomerAddress';
  id: Scalars['String'];
  shippingDefault: Scalars['Boolean'];
  invoiceDefault: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  country: Scalars['String'];
  countryCode: Scalars['String'];
  province: Scalars['String'];
  provinceCode: Scalars['String'];
  city: Scalars['String'];
  address: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  phone2?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CustomerAccount = {
  __typename?: 'CustomerAccount';
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
};

export type UserProfile = {
  __typename?: 'UserProfile';
  name: Scalars['String'];
  surname: Scalars['String'];
  birthDay: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type MarketingProfile = {
  __typename?: 'MarketingProfile';
  subscribedToNewsletter?: Maybe<Scalars['Boolean']>;
  marketingConsent?: Maybe<Scalars['Boolean']>;
  profilingConsent?: Maybe<Scalars['Boolean']>;
  surveyConsent?: Maybe<Scalars['Boolean']>;
  cardId?: Maybe<Scalars['String']>;
  cardBalance?: Maybe<Scalars['Float']>;
  oldCustomer?: Maybe<Scalars['Boolean']>;
  hasOldCard?: Maybe<Scalars['Boolean']>;
  newTermsAccepted?: Maybe<Scalars['Boolean']>;
};

export type Customer = {
  __typename?: 'Customer';
  account: CustomerAccount;
  userProfile: UserProfile;
  marketingProfile: MarketingProfile;
  addresses: Array<CustomerAddress>;
};

export type CustomerUpdateResult = {
  __typename?: 'CustomerUpdateResult';
  success: Scalars['Boolean'];
  errorType?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
};

export type SmsUnregisterResult = {
  __typename?: 'SmsUnregisterResult';
  success: Scalars['Boolean'];
  error?: Maybe<SmsUnregisterError>;
};

export enum SmsUnregisterError {
  NotFound = 'NotFound',
  GenericError = 'GenericError'
}

export type NewsletterManageResult = {
  __typename?: 'NewsletterManageResult';
  success: Scalars['Boolean'];
  alreadySubscribed?: Maybe<Scalars['Boolean']>;
  alreadyUnsubscribed?: Maybe<Scalars['Boolean']>;
  voucherCreated?: Maybe<Scalars['Boolean']>;
};

export type CustomerNewPoliciesAcceptResult = {
  __typename?: 'CustomerNewPoliciesAcceptResult';
  success: Scalars['Boolean'];
  customer?: Maybe<Customer>;
};

export type ConvertOldCustomerResult = {
  __typename?: 'ConvertOldCustomerResult';
  success: Scalars['Boolean'];
  customer?: Maybe<Customer>;
};

export type TimedVoucher = {
  __typename?: 'TimedVoucher';
  code: Scalars['String'];
  expirationDate?: Maybe<Scalars['String']>;
  valid: Scalars['Boolean'];
};

export type WishlistItem = {
  __typename?: 'WishlistItem';
  variantId: Scalars['String'];
};

export type Wishlist = {
  __typename?: 'Wishlist';
  id: Scalars['String'];
  items: Array<WishlistItem>;
};

export type WishlistAvailabilityItem = {
  __typename?: 'WishlistAvailabilityItem';
  variantId: Scalars['String'];
  item: CatalogProductDocument;
};

export type WishlistAvailability = {
  __typename?: 'WishlistAvailability';
  id: Scalars['String'];
  languageId: Scalars['String'];
  items: Array<WishlistAvailabilityItem>;
};

export type EmailValidationResult = {
  __typename?: 'EmailValidationResult';
  isValid: Scalars['Boolean'];
  errorType?: Maybe<ValidationErrorType>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum ValidationErrorType {
  InvalidFormat = 'InvalidFormat',
  InvalidDomain = 'InvalidDomain'
}

export type AddressCreateResult = {
  __typename?: 'AddressCreateResult';
  success: Scalars['Boolean'];
  address: CustomerAddress;
};

export type AddressDeleteResult = {
  __typename?: 'AddressDeleteResult';
  success: Scalars['Boolean'];
};

export type AddressUpdateResult = {
  __typename?: 'AddressUpdateResult';
  success: Scalars['Boolean'];
  address: CustomerAddress;
};

export type CustomerEnableCardResult = {
  __typename?: 'CustomerEnableCardResult';
  success: Scalars['Boolean'];
  cardId: Scalars['String'];
};

export type CustomerDeleteResult = {
  __typename?: 'CustomerDeleteResult';
  success: Scalars['Boolean'];
  errorType?: Maybe<CustomerDeletionError>;
};

export enum CustomerDeletionError {
  UserNotFound = 'USER_NOT_FOUND',
  Other = 'OTHER'
}

export type UserType = {
  __typename?: 'UserType';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roles?: Maybe<Array<Scalars['String']>>;
};

export type ChangeEmailResult = {
  __typename?: 'ChangeEmailResult';
  success: Scalars['Boolean'];
  errorType?: Maybe<ChangeEmailError>;
};

export enum ChangeEmailError {
  EmailInUse = 'EMAIL_IN_USE',
  Other = 'OTHER'
}

export type ChangeEmailVerifyResult = {
  __typename?: 'ChangeEmailVerifyResult';
  success: Scalars['Boolean'];
  accessToken: Scalars['String'];
};

export type ChangePasswordResult = {
  __typename?: 'ChangePasswordResult';
  success: Scalars['Boolean'];
  accessToken: Scalars['String'];
};

export type LoginResult = {
  __typename?: 'LoginResult';
  success: Scalars['Boolean'];
  errorType?: Maybe<LoginError>;
  accessToken?: Maybe<Scalars['String']>;
};

export enum LoginError {
  PendingEmailVerification = 'PENDING_EMAIL_VERIFICATION',
  UserNotFound = 'USER_NOT_FOUND',
  WrongPassword = 'WRONG_PASSWORD',
  Other = 'OTHER',
  OldUser = 'OLD_USER'
}

export type RegistrationResult = {
  __typename?: 'RegistrationResult';
  success: Scalars['Boolean'];
  errorType?: Maybe<RegistrationError>;
  accessToken?: Maybe<Scalars['String']>;
};

export enum RegistrationError {
  UserAlreadyExists = 'USER_ALREADY_EXISTS',
  Other = 'OTHER'
}

export type ResetPasswordResult = {
  __typename?: 'ResetPasswordResult';
  success: Scalars['Boolean'];
  errorType?: Maybe<ResetPasswordError>;
  accessToken?: Maybe<Scalars['String']>;
};

export enum ResetPasswordError {
  InvalidToken = 'INVALID_TOKEN',
  Other = 'OTHER'
}

export type SendResetPasswordResult = {
  __typename?: 'SendResetPasswordResult';
  success: Scalars['Boolean'];
  errorType?: Maybe<SendResetPasswordError>;
};

export enum SendResetPasswordError {
  UserNotFound = 'USER_NOT_FOUND',
  Other = 'OTHER'
}

export type VerificationResult = {
  __typename?: 'VerificationResult';
  success: Scalars['Boolean'];
  accessToken?: Maybe<Scalars['String']>;
  errorType?: Maybe<VerificationError>;
};

export enum VerificationError {
  InvalidToken = 'INVALID_TOKEN',
  UserAlreadyVerified = 'USER_ALREADY_VERIFIED',
  Other = 'OTHER'
}

export type CheckOldUserExistsResult = {
  __typename?: 'CheckOldUserExistsResult';
  success: Scalars['Boolean'];
  enum: CheckOldUserExistsEnum;
};

export enum CheckOldUserExistsEnum {
  ExistsCrmOnly = 'EXISTS_CRM_ONLY',
  ExistsBoth = 'EXISTS_BOTH',
  NewUser = 'NEW_USER',
  PhoneInUseByLocalUser = 'PHONE_IN_USE_BY_LOCAL_USER',
  PhoneInUseByCrmUser = 'PHONE_IN_USE_BY_CRM_USER',
  Underage = 'UNDERAGE'
}

export type CheckStoreUserData = {
  __typename?: 'CheckStoreUserData';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
};

export type CheckStoreUserResult = {
  __typename?: 'CheckStoreUserResult';
  status: CheckStoreUserEnum;
  contact?: Maybe<CheckStoreUserData>;
};

export enum CheckStoreUserEnum {
  ExistsCrmOnly = 'EXISTS_CRM_ONLY',
  ExistsBoth = 'EXISTS_BOTH',
  NotFound = 'NOT_FOUND'
}

export type RegisterStoreUserResult = {
  __typename?: 'RegisterStoreUserResult';
  success: Scalars['Boolean'];
  accessToken?: Maybe<Scalars['String']>;
};

export type AvailabilitySearchResultPaging = {
  __typename?: 'AvailabilitySearchResultPaging';
  totResults: Scalars['Int'];
  size: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  isLastPage: Scalars['Boolean'];
};

export type AvailabilitySearchResultTag = {
  __typename?: 'AvailabilitySearchResultTag';
  key: Scalars['String'];
  code: Scalars['String'];
  value: Scalars['String'];
  name: Scalars['String'];
  occurrences: Scalars['Int'];
  active: Scalars['Boolean'];
};

export type AvailabilitySearchResultCategory = {
  __typename?: 'AvailabilitySearchResultCategory';
  code: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  occurrences: Scalars['Int'];
  active: Scalars['Boolean'];
  children: Array<AvailabilitySearchResultCategory>;
  externalCategory: Scalars['Boolean'];
};

export type AvailabilitySearchResultTagCategory = {
  __typename?: 'AvailabilitySearchResultTagCategory';
  key: Scalars['String'];
  categoryName: Scalars['String'];
  weight: Scalars['Float'];
  tags: Array<AvailabilitySearchResultTag>;
  active: Scalars['Boolean'];
};

export type AvailabilitySearchResultDiscountPercentItem = {
  __typename?: 'AvailabilitySearchResultDiscountPercentItem';
  value: Scalars['Float'];
  occurrences: Scalars['Int'];
};

export type AvailabilitySearchResultDiscountPercent = {
  __typename?: 'AvailabilitySearchResultDiscountPercent';
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  values: Array<AvailabilitySearchResultDiscountPercentItem>;
};

export type AvailabilitySearchResultsPrices = {
  __typename?: 'AvailabilitySearchResultsPrices';
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

export type AvailabilitySearchResultBrand = {
  __typename?: 'AvailabilitySearchResultBrand';
  name: Scalars['String'];
  code: Scalars['String'];
  occurrences: Scalars['Int'];
  active: Scalars['Boolean'];
};

export type AvailabilitySearchResultsAggregations = {
  __typename?: 'AvailabilitySearchResultsAggregations';
  productTags: Array<AvailabilitySearchResultTagCategory>;
  productCategories: Array<AvailabilitySearchResultCategory>;
  prices: AvailabilitySearchResultsPrices;
  discountPercent: AvailabilitySearchResultDiscountPercent;
  brands: Array<AvailabilitySearchResultBrand>;
};

export type CatalogPromoContents = {
  __typename?: 'CatalogPromoContents';
  conditions?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['JSON']>;
  logoUrl?: Maybe<Scalars['String']>;
};

export type CatalogPromo = {
  __typename?: 'CatalogPromo';
  code: Scalars['String'];
  name: Scalars['String'];
  contents: CatalogPromoContents;
};

export type PromoCode = {
  __typename?: 'PromoCode';
  id: Scalars['String'];
  code: Scalars['String'];
  trigger: PromoTriggerType;
  amount: Scalars['Float'];
};

export type CheckoutLineItemCatalogData = {
  __typename?: 'CheckoutLineItemCatalogData';
  document: CatalogProductDocument;
  variant: CatalogProductVariant;
};

export type CheckoutPromo = {
  __typename?: 'CheckoutPromo';
  code: Scalars['String'];
  name: Scalars['String'];
  item?: Maybe<CatalogPromo>;
};

export type CheckoutLineItem = {
  __typename?: 'CheckoutLineItem';
  id: Scalars['String'];
  quantity: Scalars['Int'];
  originalUnitPrice: Scalars['Float'];
  originalUnitCompareAtPrice?: Maybe<Scalars['Float']>;
  originalTotal: Scalars['Float'];
  originalTotalCompareAtPrice?: Maybe<Scalars['Float']>;
  discountedUnitPrice?: Maybe<Scalars['Float']>;
  discountedTotal?: Maybe<Scalars['Float']>;
  discountPercent?: Maybe<Scalars['Float']>;
  extraDiscountPercent?: Maybe<Scalars['Float']>;
  assignedGiftCardCode?: Maybe<Scalars['String']>;
  isGift: Scalars['Boolean'];
  promotions: Array<CheckoutPromo>;
  product?: Maybe<CheckoutLineItemCatalogData>;
  source?: Maybe<Scalars['String']>;
  promoCodes?: Maybe<Array<Scalars['String']>>;
};

export type CheckoutCustomerFidelityCard = {
  __typename?: 'CheckoutCustomerFidelityCard';
  cardNumber: Scalars['String'];
  cardPoints: Scalars['Int'];
};

export type CheckoutCustomer = {
  __typename?: 'CheckoutCustomer';
  email?: Maybe<Scalars['String']>;
  acceptsMarketing?: Maybe<Scalars['Boolean']>;
  fidelityCard?: Maybe<CheckoutCustomerFidelityCard>;
};

export type CheckoutAppliedGiftcard = {
  __typename?: 'CheckoutAppliedGiftcard';
  code: Scalars['String'];
  totalAmount: Scalars['Float'];
  usedAmount: Scalars['Float'];
};

export type CheckoutPrice = {
  __typename?: 'CheckoutPrice';
  currierPaymentPrice?: Maybe<Scalars['Float']>;
  shippingPrice?: Maybe<Scalars['Float']>;
  shippingDiscount?: Maybe<Scalars['Float']>;
  defaultFreeShippingThreshold: Scalars['Float'];
  defaultShippingPrice: Scalars['Float'];
  hasFreeShippingPromo: Scalars['Boolean'];
  freeShippingPromoCode?: Maybe<Scalars['String']>;
  subtotal: Scalars['Float'];
  discountTotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  totalToPay?: Maybe<Scalars['Float']>;
  totalToPayLater?: Maybe<Scalars['Float']>;
  currency: Scalars['String'];
  appliedGiftcards?: Maybe<Array<CheckoutAppliedGiftcard>>;
};

export type CheckoutShippingAddress = {
  __typename?: 'CheckoutShippingAddress';
  customerAddressId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phone2?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
};

export type CheckoutShipping = {
  __typename?: 'CheckoutShipping';
  preference: ShippingPreference;
  shippingAddress?: Maybe<CheckoutShippingAddress>;
  billingAddress?: Maybe<CheckoutShippingAddress>;
  store?: Maybe<StoreDocumentV2>;
};

export enum ShippingPreference {
  Courier = 'Courier',
  ClickAndCollect = 'ClickAndCollect'
}

export type CheckoutSample = {
  __typename?: 'CheckoutSample';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CheckoutSamples = {
  __typename?: 'CheckoutSamples';
  samples: Array<CheckoutSample>;
  notes: Scalars['String'];
};

export type CheckoutOrder = {
  __typename?: 'CheckoutOrder';
  id?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['String']>;
};

export type CheckoutSettings = {
  __typename?: 'CheckoutSettings';
  clickAndCollectEnabled: Scalars['Boolean'];
  giftcardEnabled: Scalars['Boolean'];
  giftBoxEnabled: Scalars['Boolean'];
  enabledPaymentMethods: Array<Scalars['String']>;
};

export type CustomerPointsDiscount = {
  __typename?: 'CustomerPointsDiscount';
  points: Scalars['Int'];
  discount: Scalars['Float'];
};

export type CustomerAvailableDiscounts = {
  __typename?: 'CustomerAvailableDiscounts';
  birthdayDiscount: Scalars['Boolean'];
  passagesDiscount: Scalars['Boolean'];
  pointDiscounts: Array<CustomerPointsDiscount>;
  promoCode: Scalars['Boolean'];
};

export type CheckoutBasketDiscount = {
  __typename?: 'CheckoutBasketDiscount';
  totalDiscount?: Maybe<Scalars['Float']>;
  trigger?: Maybe<PromoTriggerType>;
  id: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  item?: Maybe<CatalogPromo>;
};

export type CheckoutLock = {
  __typename?: 'CheckoutLock';
  locked: Scalars['Boolean'];
  lockTime?: Maybe<Scalars['String']>;
  lockExpiration?: Maybe<Scalars['String']>;
};

export type CheckoutPromoState = {
  __typename?: 'CheckoutPromoState';
  hasLoggedPromoInBasket: Scalars['Boolean'];
  acceptedNoLoggedPromo: Scalars['Boolean'];
  authenticatedBasket: Scalars['Boolean'];
};

export type CheckoutSelectedPaymentMethod = {
  __typename?: 'CheckoutSelectedPaymentMethod';
  providerId: Scalars['String'];
  captureType: PaymentMethodCaptureType;
};

export enum PaymentMethodCaptureType {
  PayLater = 'PayLater',
  PayNow = 'PayNow'
}

export type CheckoutFeeProductItems = {
  __typename?: 'CheckoutFeeProductItems';
  eanCodes: Array<Scalars['String']>;
};

export type CheckoutAvailableFreeProductPromo = {
  __typename?: 'CheckoutAvailableFreeProductPromo';
  code: Scalars['String'];
  promoId: Scalars['String'];
  products: CheckoutFeeProductItems;
};

export type CheckoutFreeProductPromoThresholds = {
  __typename?: 'CheckoutFreeProductPromoThresholds';
  promoCode: Scalars['String'];
  promoId: Scalars['String'];
  minOrderSubtotal?: Maybe<Scalars['Float']>;
  amountLeft?: Maybe<Scalars['Float']>;
  products: CheckoutFeeProductItems;
};

export type CheckoutProductPromoDiscountAmount = {
  __typename?: 'CheckoutProductPromoDiscountAmount';
  value: Scalars['Float'];
  type: DiscountType;
};

export type CheckoutAvailableProductPromoDiscount = {
  __typename?: 'CheckoutAvailableProductPromoDiscount';
  eanCodes: Array<Scalars['String']>;
  maxItems?: Maybe<Scalars['Int']>;
  amount: CheckoutProductPromoDiscountAmount;
};

export type CheckoutExtraPromotions = {
  __typename?: 'CheckoutExtraPromotions';
  freeProductPromoThresholds: Array<CheckoutFreeProductPromoThresholds>;
  availableFreeProducts: Array<CheckoutAvailableFreeProductPromo>;
  availableProductPromoDiscounts: Array<CheckoutAvailableProductPromoDiscount>;
};

export type Checkout = {
  __typename?: 'Checkout';
  id: Scalars['String'];
  alias: Scalars['String'];
  status: CheckoutStatus;
  selectedPayment?: Maybe<CheckoutSelectedPaymentMethod>;
  customer: CheckoutCustomer;
  lineItems: Array<CheckoutLineItem>;
  unavailableLineItems?: Maybe<Array<CheckoutLineItemCatalogData>>;
  giftLineItems?: Maybe<Array<CheckoutLineItem>>;
  price: CheckoutPrice;
  basketDiscounts: Array<CheckoutBasketDiscount>;
  promotions: Array<PromoCode>;
  promotionsExtra?: Maybe<CheckoutExtraPromotions>;
  promoState: CheckoutPromoState;
  shipping?: Maybe<CheckoutShipping>;
  samples?: Maybe<CheckoutSamples>;
  order?: Maybe<CheckoutOrder>;
  settings?: Maybe<CheckoutSettings>;
  customerDiscounts?: Maybe<CustomerAvailableDiscounts>;
  lock?: Maybe<CheckoutLock>;
};

export enum CheckoutStatus {
  Draft = 'Draft',
  Completed = 'Completed'
}

export type CheckoutCreateResult = {
  __typename?: 'CheckoutCreateResult';
  success: Scalars['Boolean'];
  data?: Maybe<Checkout>;
};

export type CheckoutUpdateResult = {
  __typename?: 'CheckoutUpdateResult';
  success: Scalars['Boolean'];
  data?: Maybe<Checkout>;
};

export type CheckoutPromoCodeApplyResult = {
  __typename?: 'CheckoutPromoCodeApplyResult';
  success: Scalars['Boolean'];
  data?: Maybe<Checkout>;
  error?: Maybe<CheckoutPromoCodeApplyError>;
};

export enum CheckoutPromoCodeApplyError {
  CodeDoesNotExits = 'CodeDoesNotExits',
  PromoNotApplicable = 'PromoNotApplicable',
  VoucherAlreadyUsed = 'VoucherAlreadyUsed',
  VoucherExpired = 'VoucherExpired',
  VoucherNotApplicable = 'VoucherNotApplicable'
}

export type CheckoutCompleteWithNoPaymentResult = {
  __typename?: 'CheckoutCompleteWithNoPaymentResult';
  success: Scalars['Boolean'];
  data?: Maybe<Checkout>;
};

export type CheckoutGiftcardApplyResult = {
  __typename?: 'CheckoutGiftcardApplyResult';
  success: Scalars['Boolean'];
  data?: Maybe<Checkout>;
  error?: Maybe<GiftcardApplyError>;
};

export enum GiftcardApplyError {
  GenericError = 'GenericError',
  GiftcardNotFound = 'GiftcardNotFound',
  EmptyGiftcard = 'EmptyGiftcard',
  GiftcardLocked = 'GiftcardLocked'
}

export type RawBasket = {
  __typename?: 'RawBasket';
  data: Scalars['JSON'];
};

export type FixBasketEmailResult = {
  __typename?: 'FixBasketEmailResult';
  orderId: Scalars['String'];
};

export type CheckoutBasketItemPromoApplyPreviewDiscount = {
  __typename?: 'CheckoutBasketItemPromoApplyPreviewDiscount';
  originalCompareAtPrice?: Maybe<Scalars['Float']>;
  originalDiscountPercent?: Maybe<Scalars['Float']>;
  originalDiscountAmount: Scalars['Float'];
  newCompareAtPrice: Scalars['Float'];
  newDiscountPercent: Scalars['Float'];
  newDiscountAmount: Scalars['Float'];
  totalDiscountAmount: Scalars['Float'];
  price: Scalars['Float'];
};

export type CheckoutBasketItemProductPromoApplyPreviewResultVariant = {
  __typename?: 'CheckoutBasketItemProductPromoApplyPreviewResultVariant';
  productId: Scalars['String'];
  eanCode: Scalars['String'];
};

export type CheckoutBasketItemProductPromoApplyPreviewPromoResult = {
  __typename?: 'CheckoutBasketItemProductPromoApplyPreviewPromoResult';
  promoCode: Scalars['String'];
};

export type CheckoutBasketItemProductPromoApplyPreviewResult = {
  __typename?: 'CheckoutBasketItemProductPromoApplyPreviewResult';
  applicable: Scalars['Boolean'];
  promo?: Maybe<CheckoutBasketItemProductPromoApplyPreviewPromoResult>;
  variant: CheckoutBasketItemProductPromoApplyPreviewResultVariant;
  discount?: Maybe<CheckoutBasketItemPromoApplyPreviewDiscount>;
};

export type CheckoutBasketItemPromoApplyPreviewResult = {
  __typename?: 'CheckoutBasketItemPromoApplyPreviewResult';
  results: Array<CheckoutBasketItemProductPromoApplyPreviewResult>;
};

export type ClickAndCollectStore = {
  __typename?: 'ClickAndCollectStore';
  storeId: Scalars['String'];
};

export type InitializePaymentSessionOutputType = {
  __typename?: 'InitializePaymentSessionOutputType';
  providerId: Scalars['String'];
  paymentPayload: Scalars['String'];
  paymentSessionId: Scalars['String'];
  paymentAmount: Scalars['Float'];
  paymentSuccessUrl: Scalars['String'];
  paymentCancelUrl: Scalars['String'];
  transactionId: Scalars['String'];
};

export type PaymentVerifyResult = {
  __typename?: 'PaymentVerifyResult';
  success: Scalars['Boolean'];
  data: Checkout;
};

export type PaymentCheckResult = {
  __typename?: 'PaymentCheckResult';
  fulfilled: Scalars['Boolean'];
};

export type Country = {
  __typename?: 'Country';
  name: Scalars['String'];
  code: Scalars['String'];
  hasProvince: Scalars['Boolean'];
};

export type Province = {
  __typename?: 'Province';
  name: Scalars['String'];
  code: Scalars['String'];
};

export type Countries = {
  __typename?: 'Countries';
  default: Country;
  values: Array<Country>;
};

export type BrandItem = {
  __typename?: 'BrandItem';
  code: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type CategoryItem = {
  __typename?: 'CategoryItem';
  code: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type FullTextSearchResults = {
  __typename?: 'FullTextSearchResults';
  products: Array<CatalogProductDocument>;
  brands: Array<BrandItem>;
  categories: Array<CategoryItem>;
};

export type OrderShippingAddress = {
  __typename?: 'OrderShippingAddress';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
};

export type OrderInfo = {
  __typename?: 'OrderInfo';
  id: Scalars['String'];
  uid: Scalars['String'];
  alias?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  status: Scalars['String'];
  email: Scalars['String'];
  createdAt: Scalars['String'];
  totalPrice: Scalars['Float'];
  shippingAddress?: Maybe<OrderShippingAddress>;
  deliveredDate?: Maybe<Scalars['String']>;
  shippedDate?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  raw?: Maybe<Scalars['JSON']>;
};

export type OrderResult = {
  __typename?: 'OrderResult';
  orders: Array<OrderInfo>;
  hasNextPage: Scalars['Boolean'];
  cursor?: Maybe<Scalars['String']>;
};

export type OrderGiftcard = {
  __typename?: 'OrderGiftcard';
  amountUsed: Scalars['Float'];
  code: Scalars['String'];
};

export type OrderPrice = {
  __typename?: 'OrderPrice';
  shippingPrice?: Maybe<Scalars['Float']>;
  subtotal: Scalars['Float'];
  discountTotal?: Maybe<Scalars['Float']>;
  total: Scalars['Float'];
  totalPaid: Scalars['Float'];
  currency: Scalars['String'];
  giftcards: Array<OrderGiftcard>;
};

export type OrderCustomer = {
  __typename?: 'OrderCustomer';
  email?: Maybe<Scalars['String']>;
  acceptsMarketing?: Maybe<Scalars['Boolean']>;
};

export type OrderLineItemCatalogData = {
  __typename?: 'OrderLineItemCatalogData';
  document: CatalogProductDocument;
  variant: CatalogProductVariant;
};

export type OrderLineItem = {
  __typename?: 'OrderLineItem';
  id: Scalars['String'];
  quantity: Scalars['Int'];
  originalUnitPrice?: Maybe<Scalars['Float']>;
  originalUnitCompareAtPrice?: Maybe<Scalars['Float']>;
  originalTotal: Scalars['Float'];
  originalTotalCompareAtPrice?: Maybe<Scalars['Float']>;
  discountedUnitPrice?: Maybe<Scalars['Float']>;
  discountedTotal: Scalars['Float'];
  assignedGiftCardCode?: Maybe<Scalars['String']>;
  isGift: Scalars['Boolean'];
  product?: Maybe<OrderLineItemCatalogData>;
};

export type OrderShipping = {
  __typename?: 'OrderShipping';
  preference: ShippingPreference;
  shippingAddress?: Maybe<OrderShippingAddress>;
  billingAddress?: Maybe<OrderShippingAddress>;
  store?: Maybe<StoreDocumentV2>;
};

export type OrderTracking = {
  __typename?: 'OrderTracking';
  url: Scalars['String'];
};

export type EditOrderDetails = {
  __typename?: 'EditOrderDetails';
  pendingPaymentUrl?: Maybe<Scalars['String']>;
  pendingPaymentAmount?: Maybe<Scalars['Float']>;
};

export type OrderDetails = {
  __typename?: 'OrderDetails';
  id: Scalars['String'];
  uid: Scalars['String'];
  type: Scalars['String'];
  alias?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  status: Scalars['String'];
  deliveredDate?: Maybe<Scalars['String']>;
  shippedDate?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  shipping?: Maybe<OrderShipping>;
  customer: OrderCustomer;
  lineItems: Array<OrderLineItem>;
  giftLineItems?: Maybe<Array<OrderLineItem>>;
  price: OrderPrice;
  basketDiscounts: Array<CheckoutBasketDiscount>;
  promotions?: Maybe<Array<PromoCode>>;
  tracking?: Maybe<OrderTracking>;
  editOrder?: Maybe<EditOrderDetails>;
};

export type OrderPaymentDetails = {
  __typename?: 'OrderPaymentDetails';
  provider: Scalars['String'];
  transactionId: Scalars['String'];
  sessionId: Scalars['String'];
};

export type AdminOrderItem = {
  __typename?: 'AdminOrderItem';
  details: OrderDetails;
  payment?: Maybe<OrderPaymentDetails>;
  rawBasket?: Maybe<Scalars['JSON']>;
  rawOrder?: Maybe<Scalars['JSON']>;
};

export type GetGiftcardBalanceResult = {
  __typename?: 'GetGiftcardBalanceResult';
  giftcardNumber: Scalars['String'];
  balance: Scalars['Float'];
};

export type EditOrderPaymentSessionInit = {
  __typename?: 'EditOrderPaymentSessionInit';
  providerId: Scalars['String'];
  paymentPayload: Scalars['String'];
  paymentSessionId: Scalars['String'];
  paymentAmount: Scalars['Float'];
  paymentSuccessUrl: Scalars['String'];
  paymentCancelUrl: Scalars['String'];
  transactionId: Scalars['String'];
};

export type EditOrderPaymentVerifyResult = {
  __typename?: 'EditOrderPaymentVerifyResult';
  success: Scalars['Boolean'];
};

export type ToolMediaUploadResult = {
  __typename?: 'ToolMediaUploadResult';
  assetUrl: Scalars['String'];
};

export type CatalogTreeMetaItemType = {
  __typename?: 'CatalogTreeMetaItemType';
  key: Scalars['String'];
  categoryLabel?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  children?: Maybe<Array<CatalogTreeMetaItemType>>;
};

export type CatalogMetaItemType = {
  __typename?: 'CatalogMetaItemType';
  key: Scalars['String'];
  categoryLabel?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  parent?: Maybe<CatalogMetaItemType>;
  disabled?: Maybe<Scalars['Boolean']>;
};

export type ToolMetaData = {
  __typename?: 'ToolMetaData';
  tag: Array<CatalogMetaItemType>;
  tagCategories: Array<CatalogMetaItemType>;
  categories: Array<CatalogMetaItemType>;
  brands: Array<CatalogMetaItemType>;
};

export type ExportToolProductsResult = {
  __typename?: 'ExportToolProductsResult';
  url: Scalars['String'];
};

export type PublishQueueItem = {
  __typename?: 'PublishQueueItem';
  id: Scalars['String'];
  productId: Scalars['String'];
  status: Scalars['String'];
  createdDate: Scalars['DateTime'];
};

export type ImportVariantItem = {
  __typename?: 'ImportVariantItem';
  itemIndex: Scalars['Int'];
  eanCode: Scalars['String'];
  imageCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  brandName: Scalars['String'];
  brandCode: Scalars['String'];
  lineName?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  colorName?: Maybe<Scalars['String']>;
  colorHex?: Maybe<Scalars['String']>;
  colorUrl?: Maybe<Scalars['String']>;
  gender?: Maybe<VariantGender>;
  shortDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  inci?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  olfactoryNotes?: Maybe<Scalars['String']>;
  highlights?: Maybe<Scalars['String']>;
  testResults?: Maybe<Scalars['String']>;
  technology?: Maybe<Scalars['String']>;
  imageUrls?: Maybe<Array<Scalars['String']>>;
  videoUrls?: Maybe<Array<Scalars['String']>>;
  notes?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  relatedEan?: Maybe<Array<Scalars['String']>>;
  complementaryEan?: Maybe<Array<Scalars['String']>>;
  categoryCodes?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
};

export enum VariantGender {
  Male = 'Male',
  Female = 'Female',
  Unisex = 'Unisex'
}

export type ImportProductItem = {
  __typename?: 'ImportProductItem';
  variants: Array<ImportVariantItem>;
  options: BrandProductOptions;
  productId: Scalars['String'];
};

export type ProductsXlsParseError = {
  __typename?: 'ProductsXlsParseError';
  type: VariantValidationErrorType;
  fieldName?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
  itemIndex?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['String']>;
};

export enum VariantValidationErrorType {
  ProductAlreadyExists = 'ProductAlreadyExists',
  VariantAlreadyExists = 'VariantAlreadyExists',
  MissingField = 'MissingField',
  BrandNotFound = 'BrandNotFound',
  InvalidDateFormat = 'InvalidDateFormat'
}

export type ProductsCreateXlsParseData = {
  __typename?: 'ProductsCreateXlsParseData';
  uploadHash: Scalars['String'];
  items: Array<ImportProductItem>;
  unmappedColumns: Array<Scalars['String']>;
};

export type ProductsCreateXlsParseResult = {
  __typename?: 'ProductsCreateXlsParseResult';
  data?: Maybe<ProductsCreateXlsParseData>;
  success: Scalars['Boolean'];
  errors?: Maybe<Array<ProductsXlsParseError>>;
};

export type ProductCreateFieldNames = {
  __typename?: 'ProductCreateFieldNames';
  columns: Array<Scalars['String']>;
};

export type ProductMediaRef = {
  __typename?: 'ProductMediaRef';
  id: Scalars['String'];
  filename: Scalars['String'];
  uploadHash: Scalars['String'];
  type: Scalars['String'];
};

export type ProductMediaUploadResult = {
  __typename?: 'ProductMediaUploadResult';
  ref: ProductMediaRef;
  url: Scalars['String'];
  type: VariantMediaType;
};

export enum VariantMediaType {
  Image = 'Image',
  Video = 'Video',
  File = 'File'
}

export type ParkodDataExport = {
  __typename?: 'ParkodDataExport';
  url: Scalars['String'];
};

export type CardFetchResult = {
  __typename?: 'CardFetchResult';
  data?: Maybe<Scalars['JSON']>;
};

export type ContactFetchResult = {
  __typename?: 'ContactFetchResult';
  data?: Maybe<Scalars['JSON']>;
};

export type CalendarTime = {
  __typename?: 'CalendarTime';
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export type CalendarDate = {
  __typename?: 'CalendarDate';
  year: Scalars['Int'];
  month: Scalars['Int'];
  day: Scalars['Int'];
};

export type CalendarDaySlot = {
  __typename?: 'CalendarDaySlot';
  from: CalendarTime;
  to: CalendarTime;
  maxQuantity?: Maybe<Scalars['Int']>;
};

export type CalendarDaySchedule = {
  __typename?: 'CalendarDaySchedule';
  slots: Array<CalendarDaySlot>;
};

export type CalendarSchedule = {
  __typename?: 'CalendarSchedule';
  monday: CalendarDaySchedule;
  tuesday: CalendarDaySchedule;
  wednesday: CalendarDaySchedule;
  thursday: CalendarDaySchedule;
  friday: CalendarDaySchedule;
  saturday: CalendarDaySchedule;
  sunday: CalendarDaySchedule;
};

export type EventExtraQuestion = {
  __typename?: 'EventExtraQuestion';
  key: Scalars['String'];
  label: Scalars['String'];
  type: Scalars['String'];
  required: Scalars['Boolean'];
  options?: Maybe<Array<Scalars['String']>>;
};

export type CalendarPreSelection = {
  __typename?: 'CalendarPreSelection';
  fixed?: Maybe<Scalars['Boolean']>;
  preselectedDate?: Maybe<Scalars['String']>;
};

export type CalendarDefinitionOptions = {
  __typename?: 'CalendarDefinitionOptions';
  preSelection?: Maybe<CalendarPreSelection>;
};

export type CalendarDefinition = {
  __typename?: 'CalendarDefinition';
  id: Scalars['String'];
  alias: Scalars['String'];
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
  schedule: Scalars['JSON'];
  storeId?: Maybe<Scalars['String']>;
  notificationEmail: Scalars['String'];
  locationName?: Maybe<Scalars['String']>;
  eventName: Scalars['String'];
  eventDescription: Scalars['String'];
  maxVisibilityDays: Scalars['Int'];
  defaultEventDurationMin: Scalars['Int'];
  offsetBeforeEvent: Scalars['Int'];
  offsetAfterEvent: Scalars['Int'];
  eventStartTimeIncrements: Scalars['Int'];
  minReservationDaysOffset: Scalars['Int'];
  maxReservationsPerDay: Scalars['Int'];
  extraQuestions?: Maybe<Scalars['JSON']>;
  options?: Maybe<CalendarDefinitionOptions>;
  blockPublicEmails: Scalars['Boolean'];
  phoneRequired: Scalars['Boolean'];
  internalNotificationAlias?: Maybe<Scalars['String']>;
  customerNotificationAlias?: Maybe<Scalars['String']>;
  internalNotificationTemplate: Scalars['String'];
  internalNotificationSubject: Scalars['String'];
  customerNotificationTemplate: Scalars['String'];
  customerNotificationSubject: Scalars['String'];
  internalCancellationNotificationTemplate: Scalars['String'];
  customerCancellationNotificationTemplate: Scalars['String'];
  createdOn?: Maybe<Scalars['DateTime']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type CalendarReservation = {
  __typename?: 'CalendarReservation';
  id: Scalars['String'];
  calendarId: Scalars['String'];
  guestName: Scalars['String'];
  guestEmail: Scalars['String'];
  guestNotes?: Maybe<Scalars['String']>;
  extraFields?: Maybe<Scalars['JSON']>;
  date: Scalars['String'];
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
  endHour: Scalars['Int'];
  endMinute: Scalars['Int'];
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
};

export type CalendarReservationCancellation = {
  __typename?: 'CalendarReservationCancellation';
  id: Scalars['String'];
  calendarId: Scalars['String'];
  guestName: Scalars['String'];
  guestEmail: Scalars['String'];
  guestNotes?: Maybe<Scalars['String']>;
  extraFields?: Maybe<Scalars['JSON']>;
  date: Scalars['String'];
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
  endHour: Scalars['Int'];
  endMinute: Scalars['Int'];
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
};

export type EventRegistration = {
  __typename?: 'EventRegistration';
  calendarId: Scalars['String'];
  date: CalendarDate;
  from: CalendarTime;
  to: CalendarTime;
  data: Scalars['JSON'];
};

export type EventRegistrationResult = {
  __typename?: 'EventRegistrationResult';
  success: Scalars['Boolean'];
  error?: Maybe<EventRegistrationErrorType>;
  event?: Maybe<EventRegistration>;
};

export enum EventRegistrationErrorType {
  GenericError = 'GenericError',
  InvalidWorkEmailDomain = 'InvalidWorkEmailDomain',
  BlockedEmailDomain = 'BlockedEmailDomain',
  AlreadyRegistered = 'AlreadyRegistered'
}

export type EventCancellationResult = {
  __typename?: 'EventCancellationResult';
  success: Scalars['Boolean'];
  error?: Maybe<EventCancellationErrorType>;
};

export enum EventCancellationErrorType {
  GenericError = 'GenericError',
  EventNotFound = 'EventNotFound'
}

export type CalendarHolidayRange = {
  __typename?: 'CalendarHolidayRange';
  from: Scalars['String'];
  to: Scalars['String'];
};

export type CalendarsExport = {
  __typename?: 'CalendarsExport';
  url: Scalars['String'];
};

export type SlotTime = {
  __typename?: 'SlotTime';
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export type CalendarEventAvailabilitySlot = {
  __typename?: 'CalendarEventAvailabilitySlot';
  from: SlotTime;
  to: SlotTime;
  maxQuantity: Scalars['Int'];
};

export type CalendarEventAvailabilityDay = {
  __typename?: 'CalendarEventAvailabilityDay';
  year: Scalars['Int'];
  month: Scalars['Int'];
  day: Scalars['Int'];
  date: Scalars['String'];
  slots: Array<CalendarEventAvailabilitySlot>;
};

export type CalendarEventAvailabilityPage = {
  __typename?: 'CalendarEventAvailabilityPage';
  year: Scalars['Int'];
  month: Scalars['Int'];
  days: Array<CalendarEventAvailabilityDay>;
};

export type CalendarEventAvailability = {
  __typename?: 'CalendarEventAvailability';
  pages: Array<CalendarEventAvailabilityPage>;
};

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  definition: CalendarDefinition;
  availability: CalendarEventAvailability;
};

export type AdminPaymentInitResult = {
  __typename?: 'AdminPaymentInitResult';
  data?: Maybe<Scalars['JSON']>;
};

export type AdminPaymentVerifyResult = {
  __typename?: 'AdminPaymentVerifyResult';
  completed: Scalars['Boolean'];
  data?: Maybe<Scalars['JSON']>;
};

export type AdminPaymentGetResult = {
  __typename?: 'AdminPaymentGetResult';
  completed: Scalars['Boolean'];
  data?: Maybe<Scalars['JSON']>;
};

export type AdminPaymentRefundResult = {
  __typename?: 'AdminPaymentRefundResult';
  data?: Maybe<Scalars['JSON']>;
  refoundSucceeded: Scalars['Boolean'];
  paymentId: Scalars['String'];
  refoundId: Scalars['String'];
};

export type PromoLineItemFilterInput = {
  linkedPriceCodes?: Maybe<Array<Scalars['String']>>;
};

export type PromoProductsFilterInput = {
  productId?: Maybe<Array<Scalars['String']>>;
  eanCode?: Maybe<Array<Scalars['String']>>;
  itmCode?: Maybe<Array<Scalars['String']>>;
  brandCode?: Maybe<Array<Scalars['String']>>;
  categoryCode?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['String']>>;
  excludedProductId?: Maybe<Array<Scalars['String']>>;
  excludedEanCode?: Maybe<Array<Scalars['String']>>;
  excludedItmCode?: Maybe<Array<Scalars['String']>>;
  excludedBrandCode?: Maybe<Array<Scalars['String']>>;
  excludedCategoryCode?: Maybe<Array<Scalars['String']>>;
  excludedTags?: Maybe<Array<Scalars['String']>>;
};

export type PromoOrderFilterInput = {
  minSubtotal?: Maybe<Scalars['Float']>;
  minArticles?: Maybe<Scalars['Int']>;
  firstOrderOnly?: Maybe<Scalars['Boolean']>;
};

export type PromoOptionsInput = {
  cumulative: Scalars['Boolean'];
  type?: Maybe<Scalars['Float']>;
  exclusive?: Maybe<Scalars['Boolean']>;
  mixable?: Maybe<Scalars['Boolean']>;
};

export type PromoDatesFilterInput = {
  validFrom?: Maybe<Scalars['DateTime']>;
  validTo?: Maybe<Scalars['DateTime']>;
};

export type PromoCustomerFilterInput = {
  type?: Maybe<CustomerType>;
};

export type PromoConditionInput = {
  datesFilter?: Maybe<PromoDatesFilterInput>;
  productsFilter?: Maybe<PromoProductsFilterInput>;
  lineItemsFilter?: Maybe<PromoLineItemFilterInput>;
  customerFilter?: Maybe<PromoCustomerFilterInput>;
  orderFilter?: Maybe<PromoOrderFilterInput>;
};

export type PromoProductAddToBasketTriggerParamsInput = {
  products: PromoProductsFilterInput;
};

export type PromoMinimumPriceTriggerParamsInput = {
  minAmount: Scalars['Float'];
};

export type PromoVoucherCodeDurationInput = {
  hours: Scalars['Int'];
  days: Scalars['Int'];
};

export type PromoVoucherCodeTriggerParamsInput = {
  duration: PromoVoucherCodeDurationInput;
  voucherPrefix: Scalars['String'];
};

export type BasketItemPromoCodeTriggerParamsInput = {
  maxProductsPerBasket?: Maybe<Scalars['Float']>;
};

export type PromoEffectInput = {
  type: PromoType;
  payload: Scalars['JSON'];
};

export type DiscountAmountInput = {
  minOrderPrice?: Maybe<Scalars['Float']>;
  value: Scalars['Float'];
  type: DiscountType;
};

export type FixedPromoPayloadInput = {
  amount: DiscountAmountInput;
};

export type BxgyPromoPayloadInput = {
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type FreeGiftPromoPayloadInput = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type FreeProductPayloadInput = {
  eanCodes: Array<Scalars['String']>;
};

export type IncrementalDiscountPayloadInput = {
  amounts: Array<DiscountAmountInput>;
};

export type ProductDiscountPayloadInput = {
  eanCodes: Array<Scalars['String']>;
  maxItems?: Maybe<Scalars['Int']>;
  amount: DiscountAmountInput;
};

export type DynamicDiscountPayloadInput = {
  items: Array<DynamicDiscountItemInput>;
};

export type DynamicDiscountItemInput = {
  id: Scalars['String'];
  productsFilter?: Maybe<PromoProductsFilterInput>;
  amount: DiscountAmountInput;
};

export type DynamicPromoConditionInput = {
  conditions: Array<PromoConditionInput>;
};

export type PromoDefinitionInput = {
  id: Scalars['String'];
  promoCode: Scalars['String'];
  weight: Scalars['Float'];
  disabled: Scalars['Boolean'];
  options: PromoOptionsInput;
  conditionType: PromotionConditionType;
  condition: Scalars['JSON'];
  effect: PromoEffectInput;
  triggerType: PromoTriggerType;
  triggerParams?: Maybe<Scalars['JSON']>;
  productPromo?: Maybe<Scalars['Boolean']>;
  promoLogoUrl?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type ProductCustomInfoEntityInput = {
  productId: Scalars['String'];
  weight?: Maybe<Scalars['String']>;
};

export type DynamicPricePayloadInput = {
  maxDiscountPercent: Scalars['Float'];
  fallbackDiscountPercent: Scalars['Float'];
  unavailableCompetitorStockPercentMarkup: Scalars['Float'];
  roundingStrategy: RoundPriceStrategy;
};

export type PriceRuleInput = {
  id: Scalars['String'];
  priceCode: Scalars['String'];
  promoCode?: Maybe<Scalars['String']>;
  weight: Scalars['Int'];
  disabled?: Maybe<Scalars['Boolean']>;
  crossedPrice?: Maybe<Scalars['Boolean']>;
  ruleType: PriceRuleType;
  discountType?: Maybe<DiscountType>;
  discountAmount?: Maybe<Scalars['Float']>;
  fixedPrice?: Maybe<Scalars['Float']>;
  fixedCompareAtPrice?: Maybe<Scalars['Float']>;
  validFrom?: Maybe<Scalars['DateTime']>;
  validTo?: Maybe<Scalars['DateTime']>;
  productIds?: Maybe<Array<Scalars['String']>>;
  excludedProductIds?: Maybe<Array<Scalars['String']>>;
  eanCodes?: Maybe<Array<Scalars['String']>>;
  excludedEanCodes?: Maybe<Array<Scalars['String']>>;
  itmCodes?: Maybe<Array<Scalars['String']>>;
  excludedItmCodes?: Maybe<Array<Scalars['String']>>;
  brandCodes?: Maybe<Array<Scalars['String']>>;
  excludedBrandCodes?: Maybe<Array<Scalars['String']>>;
  productCategories?: Maybe<Array<Scalars['String']>>;
  excludedProductCategories?: Maybe<Array<Scalars['String']>>;
  productTags?: Maybe<Array<Scalars['String']>>;
  excludedProductTags?: Maybe<Array<Scalars['String']>>;
  discountRulePayload?: Maybe<Scalars['JSON']>;
  dynamicPricePayload?: Maybe<Scalars['JSON']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type ProductsFeedDefinitionInput = {
  id: Scalars['String'];
  disabled?: Maybe<Scalars['Boolean']>;
  alias: Scalars['String'];
  path: Scalars['String'];
  user: Scalars['String'];
  password: Scalars['String'];
  filters?: Maybe<Scalars['JSON']>;
  fields?: Maybe<Scalars['JSON']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type VoucherCodeInput = {
  id?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  amount: Scalars['Float'];
  cumulative: Scalars['Boolean'];
  cumulativeType: Scalars['Float'];
  exclusive: Scalars['Boolean'];
  condition: PromoConditionInput;
  amountType: DiscountType;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  basketId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  usedDate?: Maybe<Scalars['DateTime']>;
  usedOnOrderId?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type BrandProductOptionsInput = {
  hidden: Scalars['Boolean'];
  isGift: Scalars['Boolean'];
};

export type ProductUpdateContentsInput = {
  shortDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  inci?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  highlights?: Maybe<Scalars['String']>;
  olfactoryNotes?: Maybe<Scalars['String']>;
  olfactoryFamily?: Maybe<Scalars['String']>;
  tests?: Maybe<Scalars['String']>;
  technology?: Maybe<Scalars['String']>;
};

export type ProductUpdateRecordInput = {
  ean: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  colorName?: Maybe<Scalars['String']>;
  colorHex?: Maybe<Scalars['String']>;
  colorUrl?: Maybe<Scalars['String']>;
  contents: ProductUpdateContentsInput;
  relatedEanCodes?: Maybe<Array<Scalars['String']>>;
  complementaryEanCodes?: Maybe<Array<Scalars['String']>>;
  notes?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
  imageUrls?: Maybe<Array<Scalars['String']>>;
  videoUrls?: Maybe<Array<Scalars['String']>>;
};

export type ImportVariantItemInput = {
  itemIndex: Scalars['Int'];
  eanCode: Scalars['String'];
  imageCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  brandName: Scalars['String'];
  brandCode: Scalars['String'];
  lineName?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  colorName?: Maybe<Scalars['String']>;
  colorHex?: Maybe<Scalars['String']>;
  colorUrl?: Maybe<Scalars['String']>;
  gender?: Maybe<VariantGender>;
  shortDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  inci?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  olfactoryNotes?: Maybe<Scalars['String']>;
  highlights?: Maybe<Scalars['String']>;
  testResults?: Maybe<Scalars['String']>;
  technology?: Maybe<Scalars['String']>;
  imageUrls?: Maybe<Array<Scalars['String']>>;
  videoUrls?: Maybe<Array<Scalars['String']>>;
  notes?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  relatedEan?: Maybe<Array<Scalars['String']>>;
  complementaryEan?: Maybe<Array<Scalars['String']>>;
  categoryCodes?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
};

export type ImportProductItemInput = {
  variants: Array<ImportVariantItemInput>;
  options: BrandProductOptionsInput;
  productId: Scalars['String'];
};

export type ProductMediaRefInput = {
  id: Scalars['String'];
  filename: Scalars['String'];
  uploadHash: Scalars['String'];
  type: Scalars['String'];
};

export type CalendarTimeInput = {
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export type CalendarDateInput = {
  year: Scalars['Int'];
  month: Scalars['Int'];
  day: Scalars['Int'];
};

export type CalendarPreSelectionInput = {
  fixed?: Maybe<Scalars['Boolean']>;
  preselectedDate?: Maybe<Scalars['String']>;
};

export type CalendarDefinitionOptionsInput = {
  preSelection?: Maybe<CalendarPreSelectionInput>;
};

export type CalendarDefinitionInput = {
  id: Scalars['String'];
  alias: Scalars['String'];
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
  schedule: Scalars['JSON'];
  storeId?: Maybe<Scalars['String']>;
  notificationEmail: Scalars['String'];
  locationName?: Maybe<Scalars['String']>;
  eventName: Scalars['String'];
  eventDescription: Scalars['String'];
  maxVisibilityDays: Scalars['Int'];
  defaultEventDurationMin: Scalars['Int'];
  offsetBeforeEvent: Scalars['Int'];
  offsetAfterEvent: Scalars['Int'];
  eventStartTimeIncrements: Scalars['Int'];
  minReservationDaysOffset: Scalars['Int'];
  maxReservationsPerDay: Scalars['Int'];
  extraQuestions?: Maybe<Scalars['JSON']>;
  options?: Maybe<CalendarDefinitionOptionsInput>;
  blockPublicEmails: Scalars['Boolean'];
  phoneRequired: Scalars['Boolean'];
  internalNotificationAlias?: Maybe<Scalars['String']>;
  customerNotificationAlias?: Maybe<Scalars['String']>;
  internalNotificationTemplate: Scalars['String'];
  internalNotificationSubject: Scalars['String'];
  customerNotificationTemplate: Scalars['String'];
  customerNotificationSubject: Scalars['String'];
  internalCancellationNotificationTemplate: Scalars['String'];
  customerCancellationNotificationTemplate: Scalars['String'];
  createdOn?: Maybe<Scalars['DateTime']>;
  updatedOn?: Maybe<Scalars['DateTime']>;
};

export type EventRegistrationInput = {
  calendarId: Scalars['String'];
  date: CalendarDateInput;
  from: CalendarTimeInput;
  to: CalendarTimeInput;
  data: Scalars['JSON'];
};

export type Query = {
  __typename?: 'Query';
  backofficeUsersGet: Array<UserType>;
  getUser: UserType;
  checkout?: Maybe<Checkout>;
  customerCheckout?: Maybe<Checkout>;
  checkoutGetAvailableCollectStores: Array<ClickAndCollectStore>;
  searchBasket: Array<RawBasket>;
  countries: Countries;
  provinces: Array<Province>;
  fullTextSearch: FullTextSearchResults;
  jobInstances: Array<JobStatusEntity>;
  storesV2: Array<StoreDocumentV2>;
  getCommerceProduct: CatalogProductDocument;
  getCommerceProductByVariantId: CatalogProductDocument;
  getCommerceProducts: Array<CatalogProductDocument>;
  getCommerceVariant: CatalogProductVariant;
  getCommerceVariants: Array<CatalogProductVariant>;
  priceRules: Array<PriceRule>;
  priceRule: PriceRule;
  dynamicPricePayload: DynamicPricePayload;
  voucherSearch: Array<VoucherCode>;
  voucherGet: VoucherCode;
  promotions: Array<PromoDefinition>;
  promotion: PromoDefinition;
  promoPromoCondition: PromoConditionType;
  promoDynamicPromoCondition: DynamicPromoCondition;
  promoVoucherCodeTriggerParams: PromoVoucherCodeTriggerParamsType;
  basketItemPromoCodeTriggerParams: BasketItemPromoCodeTriggerParamsType;
  promoMinimumPriceTriggerParams: PromoMinimumPriceTriggerParamsType;
  promoProductAddToBasketTriggerParams: PromoProductAddToBasketTriggerParamsType;
  fixedPromoPayload: FixedPromoPayloadType;
  bxgyPromoPayload: BxgyPromoPayloadType;
  freeGiftPromoPayload: FreeGiftPromoPayloadType;
  freeProductPayload: FreeProductPayloadType;
  incrementalDiscountPayload: IncrementalDiscountPayloadType;
  dynamicDiscountPayload: DynamicDiscountPayloadType;
  productDiscountPayload: ProductDiscountPayloadType;
  productsFeed: Array<ProductsFeedDefinition>;
  productFeed: ProductsFeedDefinition;
  getGiftcardBalance: GetGiftcardBalanceResult;
  getOrder?: Maybe<AdminOrderItem>;
  getOrders: OrderResult;
  orders: OrderResult;
  order?: Maybe<OrderDetails>;
  getCmsTags: Array<CatalogMetaItemType>;
  getCmsTagCategories: Array<CatalogMetaItemType>;
  getCmsProductCategories: Array<CatalogMetaItemType>;
  getToolMetaItems: ToolMetaData;
  searchCatalogProducts: ProductToolSearchResults;
  getProductVariants: Array<ToolProductVariantType>;
  getProductVariant?: Maybe<ToolProductVariantType>;
  getLatestVariantVersion: ToolProductVariantVersionType;
  getVariantVersion: ToolProductVariantVersionType;
  getLatestProductVersion: ToolProductVersionType;
  getProductVersion: ToolProductVariantVersionType;
  getProduct: ToolProductType;
  getPublishQueue: Array<PublishQueueItem>;
  massiveProductCreateFieldNames: ProductCreateFieldNames;
  masterProductCustomInfo: ProductCustomInfoItems;
  adminGetCustomer?: Maybe<Customer>;
  getCustomer?: Maybe<Customer>;
  customerUnSubscriptions: Array<CustomerUnSubscription>;
  crmDisabledProfiles: Array<CrmDisabledProfile>;
  getNavUserInfo: GetNarUserInfoData;
  wishlist?: Maybe<Wishlist>;
  wishlistAvailability: WishlistAvailability;
  timedVoucher?: Maybe<TimedVoucher>;
  fetchCrmCard: CardFetchResult;
  fetchCrmContact: ContactFetchResult;
  searchCrmContact: Array<CrmContactEntity>;
  searchCrmLogs: Array<CrmRequestsEntity>;
  getFeatureSwitches: Array<FeatureSwitchEntity>;
  getSqlParams: Array<ConfigParamEntry>;
  getCalendarStoreHolidays: Array<CalendarHolidayRange>;
  getCalendarGlobalHolidays: Array<CalendarHolidayRange>;
  calendarDefinition?: Maybe<CalendarDefinition>;
  calendarDefinitions: Array<CalendarDefinition>;
  calendarReservations: Array<CalendarReservation>;
  calendarCancellations: Array<CalendarReservationCancellation>;
  extraQuestion: EventExtraQuestion;
  calendarSchedule: CalendarSchedule;
  calendarEvent?: Maybe<CalendarEvent>;
};


export type QueryCheckoutArgs = {
  email?: Maybe<Scalars['String']>;
  draftOnly?: Maybe<Scalars['Boolean']>;
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryCustomerCheckoutArgs = {
  languageId: Scalars['String'];
};


export type QueryCheckoutGetAvailableCollectStoresArgs = {
  checkoutId: Scalars['String'];
};


export type QuerySearchBasketArgs = {
  query: Scalars['String'];
};


export type QueryCountriesArgs = {
  languageId: Scalars['String'];
};


export type QueryProvincesArgs = {
  countryCode: Scalars['String'];
};


export type QueryFullTextSearchArgs = {
  options: FullTextSearchOptions;
  query: Scalars['String'];
};


export type QueryStoresV2Args = {
  input: GetStoresInput;
};


export type QueryGetCommerceProductArgs = {
  input: GetCommerceProductInputType;
};


export type QueryGetCommerceProductByVariantIdArgs = {
  input: GetCommerceProductByVariantIdInputType;
};


export type QueryGetCommerceProductsArgs = {
  input: GetCommerceProductsInputType;
};


export type QueryGetCommerceVariantArgs = {
  input: GetCommerceVariantInputType;
};


export type QueryGetCommerceVariantsArgs = {
  input: GetCommerceVariantsInputType;
};


export type QueryPriceRuleArgs = {
  id: Scalars['String'];
};


export type QueryVoucherSearchArgs = {
  input: SearchVoucherParamsInput;
};


export type QueryVoucherGetArgs = {
  code: Scalars['String'];
};


export type QueryPromotionArgs = {
  id: Scalars['String'];
};


export type QueryProductFeedArgs = {
  id: Scalars['String'];
};


export type QueryGetGiftcardBalanceArgs = {
  input: GetGiftcardBalanceInput;
};


export type QueryGetOrderArgs = {
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryGetOrdersArgs = {
  input: GetOrdersInput;
};


export type QueryOrdersArgs = {
  cursor?: Maybe<Scalars['String']>;
  tot: Scalars['Int'];
  languageId: Scalars['String'];
};


export type QueryOrderArgs = {
  orderId: Scalars['String'];
  languageId: Scalars['String'];
};


export type QuerySearchCatalogProductsArgs = {
  options: ProductToolSearchOptions;
  filters: ProductToolSearchFilters;
};


export type QueryGetProductVariantsArgs = {
  input: GetProductVariantsInputType;
};


export type QueryGetProductVariantArgs = {
  input: GetProductVariantInputType;
};


export type QueryGetLatestVariantVersionArgs = {
  input: GetLatestVariantVersionInputType;
};


export type QueryGetVariantVersionArgs = {
  input: GetVariantVersionInputType;
};


export type QueryGetLatestProductVersionArgs = {
  input: GetLatestProductVersionInputType;
};


export type QueryGetProductVersionArgs = {
  input: GetProductVersionInputType;
};


export type QueryGetProductArgs = {
  input: GetProductInputType;
};


export type QueryAdminGetCustomerArgs = {
  input: AdminGetUserInput;
};


export type QueryGetNavUserInfoArgs = {
  input: GetNavUserInfoInput;
};


export type QueryWishlistArgs = {
  id: Scalars['String'];
};


export type QueryWishlistAvailabilityArgs = {
  input: WishlistAvailabilityInput;
};


export type QueryTimedVoucherArgs = {
  code: Scalars['String'];
};


export type QueryFetchCrmCardArgs = {
  id: Scalars['String'];
};


export type QueryFetchCrmContactArgs = {
  filter: Scalars['String'];
};


export type QuerySearchCrmContactArgs = {
  term: Scalars['String'];
};


export type QuerySearchCrmLogsArgs = {
  filters: CrmLogsSearchFilter;
};


export type QueryGetCalendarStoreHolidaysArgs = {
  storeId: Scalars['String'];
};


export type QueryCalendarDefinitionArgs = {
  id: Scalars['String'];
};


export type QueryCalendarReservationsArgs = {
  calendarId: Scalars['String'];
};


export type QueryCalendarCancellationsArgs = {
  calendarId: Scalars['String'];
};


export type QueryCalendarEventArgs = {
  alias: Scalars['String'];
};

export type FullTextSearchOptions = {
  languageId: Scalars['String'];
  maxProducts: Scalars['Int'];
  maxCategories: Scalars['Int'];
  maxBrands: Scalars['Int'];
};

export type GetStoresInput = {
  includeHidden?: Maybe<Scalars['Boolean']>;
};

export type GetCommerceProductInputType = {
  languageId: Scalars['String'];
  productId: Scalars['String'];
};

export type GetCommerceProductByVariantIdInputType = {
  languageId: Scalars['String'];
  variantId: Scalars['String'];
};

export type GetCommerceProductsInputType = {
  languageId: Scalars['String'];
  productId: Array<Scalars['String']>;
  options: GetCommerceProductsOptionsInputType;
};

export type GetCommerceProductsOptionsInputType = {
  excludeUnavailable: Scalars['Boolean'];
};

export type GetCommerceVariantInputType = {
  variantId: Scalars['String'];
};

export type GetCommerceVariantsInputType = {
  variantIds: Array<Scalars['String']>;
};

export type SearchVoucherParamsInput = {
  term?: Maybe<Scalars['String']>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type GetGiftcardBalanceInput = {
  giftcardNumber: Scalars['String'];
};

export type GetOrdersInput = {
  sorting?: Maybe<OrdersSorting>;
  tot: Scalars['Float'];
  email?: Maybe<Scalars['String']>;
  fullText?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
};

export enum OrdersSorting {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type ProductToolSearchOptions = {
  paging?: Maybe<ProductToolSearchPaging>;
  order?: Maybe<ProductToolSearchSorting>;
};

export type ProductToolSearchPaging = {
  tot?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
};

export type ProductToolSearchSorting = {
  by: Scalars['String'];
  direction: Scalars['String'];
};

export type ProductToolSearchFilters = {
  productIds?: Maybe<Array<Scalars['String']>>;
  productVariantIds?: Maybe<Array<Scalars['String']>>;
  brandCodes?: Maybe<Array<Scalars['String']>>;
  categories?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type GetProductVariantsInputType = {
  productId: Scalars['String'];
};

export type GetProductVariantInputType = {
  eanCode: Scalars['String'];
};

export type GetLatestVariantVersionInputType = {
  eanCode: Scalars['String'];
};

export type GetVariantVersionInputType = {
  eanCode: Scalars['String'];
  version: Scalars['Int'];
};

export type GetLatestProductVersionInputType = {
  productId: Scalars['String'];
};

export type GetProductVersionInputType = {
  productId: Scalars['String'];
  version: Scalars['Int'];
};

export type GetProductInputType = {
  productId: Scalars['String'];
};

export type AdminGetUserInput = {
  email: Scalars['String'];
};

export type GetNavUserInfoInput = {
  cardNumber: Scalars['String'];
};

export type WishlistAvailabilityInput = {
  wishlistId: Scalars['String'];
  languageId: Scalars['String'];
};

export type CrmLogsSearchFilter = {
  minDate?: Maybe<Scalars['String']>;
  maxDate?: Maybe<Scalars['String']>;
  order: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  login: LoginResult;
  resetPassword: ResetPasswordResult;
  sendResetPassword: SendResetPasswordResult;
  verify: VerificationResult;
  register: RegistrationResult;
  changeEmailVerify: ChangeEmailVerifyResult;
  checkOldUserExists: CheckOldUserExistsResult;
  checkStoreUserStatus: CheckStoreUserResult;
  completeStoreUserRegistration: RegisterStoreUserResult;
  backofficeUserRoleSet: OperationResult;
  backofficeUserRolesRemove: OperationResult;
  changePassword: ChangePasswordResult;
  changeEmail: ChangeEmailResult;
  checkoutBasketItemPromoApplyPreview: CheckoutBasketItemPromoApplyPreviewResult;
  giftcardCheckoutCreate: CheckoutCreateResult;
  checkoutCreate: CheckoutCreateResult;
  checkoutUpdate: CheckoutUpdateResult;
  checkoutLock: CheckoutUpdateResult;
  checkoutApplyPromoCode: CheckoutPromoCodeApplyResult;
  checkoutRemovePromoCode: CheckoutUpdateResult;
  checkoutApplyGiftcard: CheckoutGiftcardApplyResult;
  checkoutRemoveGiftcard: CheckoutUpdateResult;
  checkoutSelectProductGift: CheckoutUpdateResult;
  checkoutRemoveProductGift: CheckoutUpdateResult;
  checkoutApplyBirthdayDiscount: CheckoutUpdateResult;
  checkoutRemoveBirthdayDiscount: CheckoutUpdateResult;
  checkoutApplyPassagesDiscount: CheckoutUpdateResult;
  checkoutRemovePassagesDiscount: CheckoutUpdateResult;
  checkoutApplyPointsDiscount: CheckoutUpdateResult;
  checkoutRemovePointsDiscount: CheckoutUpdateResult;
  checkoutSetShipping: CheckoutUpdateResult;
  checkoutSetSamples: CheckoutUpdateResult;
  checkoutSetBillingData: CheckoutUpdateResult;
  checkoutSetPaymentMethod: CheckoutUpdateResult;
  checkoutAcceptNoLoggedPromo: CheckoutUpdateResult;
  checkoutCompleteWithoutPayment: CheckoutCompleteWithNoPaymentResult;
  checkoutRecover: CheckoutUpdateResult;
  paymentInitialize: InitializePaymentSessionOutputType;
  paymentVerify: PaymentVerifyResult;
  paymentCheck: PaymentCheckResult;
  fixBasketEmail: FixBasketEmailResult;
  resendOrderInternalNotification: OperationResult;
  runOrdersCheck: OperationResult;
  runBasketPaymentSessionsCheck: OperationResult;
  runCheckoutUnlockJob: OperationResult;
  runExpiredBasketsJob: OperationResult;
  resendPurchaseVoucherCode: OperationResult;
  rawUpdateBasket: RawBasket;
  simulateCustomerNavDiscounts: CustomerAvailableDiscounts;
  runMaintenanceJobs: OperationResult;
  importStores: OperationResult;
  importZipCodes: OperationResult;
  jobUnlock: OperationResult;
  runProductUpdateSimulation: OperationResult;
  importCompetitorPrices: OperationResult;
  productInventoriesRefresh: OperationResult;
  syncAlgoliaProducts: OperationResult;
  rebuildVariantMappings: OperationResult;
  archiveProducts: OperationResult;
  checkShopifyProducts: OperationResult;
  importProducts: OperationResult;
  refreshProductVariants: OperationResult;
  importMeta: OperationResult;
  indexMeta: OperationResult;
  importProductCosts: OperationResult;
  processMinderestFeed: OperationResult;
  processNavCatalogData: OperationResult;
  processNavBarcodesData: OperationResult;
  processNavStockData: OperationResult;
  syncNavCatalogEvents: OperationResult;
  syncNavStockEvents: OperationResult;
  reindexProducts: OperationResult;
  reindexBrandProducts: OperationResult;
  reindexProduct: OperationResult;
  deleteElasticProduct: OperationResult;
  enablePriceRule: PriceRule;
  disablePriceRule: PriceRule;
  createPriceRule: PriceRule;
  updatePriceRule: PriceRule;
  deletePriceRule: OperationResult;
  voucherCreate: VoucherCode;
  voucherUpdate: VoucherCode;
  enablePromo: PromoDefinition;
  disablePromo: PromoDefinition;
  createPromo: PromoDefinition;
  updatePromo: PromoDefinition;
  deletePromo: OperationResult;
  productFeedCreate: ProductsFeedDefinition;
  productFeedUpdate: ProductsFeedDefinition;
  productFeedDelete: OperationResult;
  productFeedEnable: ProductsFeedDefinition;
  productFeedDisable: ProductsFeedDefinition;
  createReturn: OperationResult;
  orderEmailChange: OperationResult;
  shopifyOrderMigrate: OperationResult;
  shopifyOrdersMigrateBatch: OperationResult;
  createOrdersReport: OperationResult;
  refillGiftcard: OperationResult;
  refundOrder: AdminOrderItem;
  editOrder: AdminOrderItem;
  orderTagsModify: OperationResult;
  orderCancelFromNav: OperationResult;
  orderForceDelivered: OperationResult;
  orderForceFulfilled: OperationResult;
  archiveOrder: OperationResult;
  resyncQaplaOrder: OperationResult;
  syncUnfulfilledOrder: OperationResult;
  syncOrderShipping: OperationResult;
  syncUnfulfilledOrders: OperationResult;
  syncNavOrders: OperationResult;
  syncArchivedOrders: OperationResult;
  processReturnFile: OperationResult;
  editOrderPaymentInitialize: EditOrderPaymentSessionInit;
  editOrderPaymentVerify: EditOrderPaymentVerifyResult;
  mappingsRebuild: OperationResult;
  backupCatalogData: OperationResult;
  toggleTagStatus: OperationResult;
  toggleProductCategoryStatus: OperationResult;
  createCmsTag: OperationResult;
  createCmsTagCategory: OperationResult;
  createCmsProductCategory: OperationResult;
  uploadToolAsset: ToolMediaUploadResult;
  publishAllProducts: OperationResult;
  publishProduct: OperationResult;
  loadBrandProducts: OperationResult;
  deleteBrandProducts: OperationResult;
  exportCatalogProducts: ExportToolProductsResult;
  refreshBrandProductItem: OperationResult;
  refreshCatalogBrandProducts: OperationResult;
  refreshAllCatalogProducts: OperationResult;
  upsertProductItem: OperationResult;
  upsertVariantItem: OperationResult;
  updateProductBrandName: OperationResult;
  copyTextsOnAllVariants: OperationResult;
  normalizeVariantNames: OperationResult;
  clearPublishQueue: OperationResult;
  enableProduct: OperationResult;
  disableProduct: OperationResult;
  enableProductVariant: OperationResult;
  disableProductVariant: OperationResult;
  moveVariant: OperationResult;
  moveVariantToNewProduct: MoveVariantToNewProductResult;
  swapVariantsOrder: OperationResult;
  changeVariantsOrder: OperationResult;
  exportProductCategories: ExportToolProductsResult;
  exportTagCategories: ExportToolProductsResult;
  exportTags: ExportToolProductsResult;
  exportProductsForNav: ExportToolProductsResult;
  exportToolFullReport: ExportToolProductsResult;
  importSupplierProducts: OperationResult;
  importNavCategories: OperationResult;
  importNavCategoryMappings: OperationResult;
  runShopifySanitize: OperationResult;
  getAvailableProductId: NewProductIdResult;
  changeProductBrand: OperationResult;
  massiveProductsCreateXlsParse: ProductsCreateXlsParseResult;
  massiveProductsMediaUpload: ProductMediaUploadResult;
  massiveProductsCreate: OperationResult;
  massiveProductsDeleteBatch: OperationResult;
  massiveProductsUpdateXlsPreview: ProductsUpdatePreviewResult;
  massiveProductsUpdateXls: OperationResult;
  massiveProductsDownload: ProductsDownloadResult;
  masterProductArchive: OperationResult;
  masterProductRestore: OperationResult;
  upsertMasterProductCustomInfo: OperationResult;
  deleteMasterProductCustomInfo: OperationResult;
  masterVariantChangeEan: OperationResult;
  parkodBrandMappingsUpload: OperationResult;
  parkodAxisMappingsUpload: OperationResult;
  parkodCategoryMappingsUpload: OperationResult;
  parkodCategoryMappingsDownload: ParkodDataExport;
  parkodBrandMappingsDownload: ParkodDataExport;
  parkodAxisMappingsDownload: ParkodDataExport;
  parkodDataGenerate: ParkodDataExport;
  updateCustomer: CustomerUpdateResult;
  deleteCustomer: CustomerDeleteResult;
  customerAddressCreate: AddressCreateResult;
  customerAddressUpdate: AddressUpdateResult;
  customerAddressDelete: AddressDeleteResult;
  customerEnableCard: CustomerEnableCardResult;
  customerAcceptNewPolicies: CustomerNewPoliciesAcceptResult;
  convertOldCustomer: ConvertOldCustomerResult;
  crmDisabledProfilesAdd: OperationResult;
  crmDisabledProfilesRemove: OperationResult;
  emailAddressValidate: EmailValidationResult;
  customListManage: OperationResult;
  userFeedbackCreate: OperationResult;
  newsletterManage: NewsletterManageResult;
  unsubscribeSms: SmsUnregisterResult;
  userConsentsUnsubscribe: OperationResult;
  wishlistItemAdd: Wishlist;
  wishlistItemRemove: Wishlist;
  subscribeOutOfStockProduct: OperationResult;
  timedVoucherActivate: TimedVoucher;
  importCrmContacts: OperationResult;
  unusedVouchersSync: OperationResult;
  shopifyCrmSync: OperationResult;
  syncKlaviyoProfile: OperationResult;
  syncShopifyProfile: OperationResult;
  exportCrmContacts: OperationResult;
  cleanCrmLogs: OperationResult;
  setFeatureSwitchStatus: OperationResult;
  updateSqlParam: OperationResult;
  calendarsExport: CalendarsExport;
  appointmentsFullSyncRun: OperationResult;
  createCalendarStoreHolidays: Array<CalendarHolidayRange>;
  deleteCalendarStoreHolidays: Array<CalendarHolidayRange>;
  createGlobalStoreHolidays: Array<CalendarHolidayRange>;
  deleteGlobalStoreHolidays: Array<CalendarHolidayRange>;
  updateCalendar: CalendarDefinition;
  createCalendar: CalendarDefinition;
  toggleCalendar: CalendarDefinition;
  submitCalendarEvent: EventRegistrationResult;
  cancelCalendarEvent: EventCancellationResult;
  runProductsReportJob: OperationResult;
  runProductsReportExport: OperationResult;
  runCrmCardsReportJob: OperationResult;
  trackEvent: OperationResult;
  adminPaymentInit: AdminPaymentInitResult;
  adminPaymentGet: AdminPaymentGetResult;
  adminPaymentVerify: AdminPaymentVerifyResult;
  adminPaymentRefund: AdminPaymentRefundResult;
  runSyncAlgoliaOrderTransactions: OperationResult;
  executePerformancesSyncTask: OperationResult;
  serverCacheClear: OperationResult;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationSendResetPasswordArgs = {
  input: SendResetPasswordInput;
};


export type MutationVerifyArgs = {
  input: VerificationInput;
};


export type MutationRegisterArgs = {
  input: RegistrationInput;
};


export type MutationChangeEmailVerifyArgs = {
  input: ChangeEmailVerifyInput;
};


export type MutationCheckOldUserExistsArgs = {
  input: CheckOldUserExistsInput;
};


export type MutationCheckStoreUserStatusArgs = {
  input: CheckStoreUserInput;
};


export type MutationCompleteStoreUserRegistrationArgs = {
  input: RegisterStoreUserInput;
};


export type MutationBackofficeUserRoleSetArgs = {
  input: BackofficeUserRoleSetInput;
};


export type MutationBackofficeUserRolesRemoveArgs = {
  input: BackofficeUserRolesRemoveInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationChangeEmailArgs = {
  input: ChangeEmailInput;
};


export type MutationCheckoutBasketItemPromoApplyPreviewArgs = {
  input: CheckoutBasketItemPromoApplyPreview;
};


export type MutationGiftcardCheckoutCreateArgs = {
  input: GiftcardCheckoutInput;
  languageId: Scalars['String'];
};


export type MutationCheckoutCreateArgs = {
  input: CheckoutInput;
  languageId: Scalars['String'];
};


export type MutationCheckoutUpdateArgs = {
  input: CheckoutInput;
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutLockArgs = {
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutApplyPromoCodeArgs = {
  email?: Maybe<Scalars['String']>;
  promoCode: Scalars['String'];
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutRemovePromoCodeArgs = {
  promoCode: Scalars['String'];
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutApplyGiftcardArgs = {
  giftcard: CheckoutGiftcardAddInput;
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutRemoveGiftcardArgs = {
  giftcard: CheckoutGiftcardRemoveInput;
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutSelectProductGiftArgs = {
  eanCode: Scalars['String'];
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutRemoveProductGiftArgs = {
  eanCode: Scalars['String'];
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutApplyBirthdayDiscountArgs = {
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutRemoveBirthdayDiscountArgs = {
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutApplyPassagesDiscountArgs = {
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutRemovePassagesDiscountArgs = {
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutApplyPointsDiscountArgs = {
  points: Scalars['Int'];
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutRemovePointsDiscountArgs = {
  points: Scalars['Int'];
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutSetShippingArgs = {
  contact?: Maybe<CheckoutContactInput>;
  samples?: Maybe<CheckoutSamplesInput>;
  shipping: CheckoutShippingInput;
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutSetSamplesArgs = {
  samples: CheckoutSamplesInput;
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutSetBillingDataArgs = {
  billing: CheckoutBillingInput;
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutSetPaymentMethodArgs = {
  input: CheckoutPaymentMethodSetInput;
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutAcceptNoLoggedPromoArgs = {
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutCompleteWithoutPaymentArgs = {
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCheckoutRecoverArgs = {
  input: CheckoutRecoverInput;
  languageId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationPaymentInitializeArgs = {
  input: InitializePaymentSessionInputType;
};


export type MutationPaymentVerifyArgs = {
  input: PaymentVerifyInput;
};


export type MutationPaymentCheckArgs = {
  input: PaymentCheckInput;
};


export type MutationFixBasketEmailArgs = {
  input: FixBasketEmailInput;
};


export type MutationResendOrderInternalNotificationArgs = {
  orderId: Scalars['String'];
};


export type MutationResendPurchaseVoucherCodeArgs = {
  voucherCode: Scalars['String'];
};


export type MutationRawUpdateBasketArgs = {
  input: UpdateBasketInput;
  id: Scalars['String'];
};


export type MutationSimulateCustomerNavDiscountsArgs = {
  input: CustomerNavSimulationsInput;
};


export type MutationImportStoresArgs = {
  input: ImportStoresInput;
};


export type MutationImportZipCodesArgs = {
  input: ImportZipCodesInput;
};


export type MutationJobUnlockArgs = {
  jobUid: Scalars['String'];
};


export type MutationProductInventoriesRefreshArgs = {
  reprocessAll: Scalars['Boolean'];
};


export type MutationSyncAlgoliaProductsArgs = {
  fullImport: Scalars['Boolean'];
};


export type MutationArchiveProductsArgs = {
  input: ArchiveProductInputType;
};


export type MutationImportProductsArgs = {
  input: ImportProductsInputType;
};


export type MutationRefreshProductVariantsArgs = {
  input: RefreshProductsInputType;
};


export type MutationImportProductCostsArgs = {
  input: ImportProductCostsInputType;
};


export type MutationProcessNavCatalogDataArgs = {
  options: ProcessNavCatalogOptions;
  feedUrl: Scalars['String'];
};


export type MutationProcessNavBarcodesDataArgs = {
  feedUrl: Scalars['String'];
};


export type MutationProcessNavStockDataArgs = {
  options: ProcessNavStockOptions;
  feedUrl: Scalars['String'];
};


export type MutationReindexBrandProductsArgs = {
  brandCode: Scalars['String'];
};


export type MutationReindexProductArgs = {
  productId: Scalars['String'];
};


export type MutationDeleteElasticProductArgs = {
  productId: Scalars['String'];
};


export type MutationEnablePriceRuleArgs = {
  id: Scalars['String'];
};


export type MutationDisablePriceRuleArgs = {
  id: Scalars['String'];
};


export type MutationCreatePriceRuleArgs = {
  input: PriceRuleInput;
};


export type MutationUpdatePriceRuleArgs = {
  input: PriceRuleInput;
};


export type MutationDeletePriceRuleArgs = {
  id: Scalars['String'];
};


export type MutationVoucherCreateArgs = {
  input: VoucherCodeInput;
};


export type MutationVoucherUpdateArgs = {
  input: VoucherCodeInput;
};


export type MutationEnablePromoArgs = {
  id: Scalars['String'];
};


export type MutationDisablePromoArgs = {
  id: Scalars['String'];
};


export type MutationCreatePromoArgs = {
  input: PromoDefinitionInput;
};


export type MutationUpdatePromoArgs = {
  input: PromoDefinitionInput;
};


export type MutationDeletePromoArgs = {
  id: Scalars['String'];
};


export type MutationProductFeedCreateArgs = {
  data: ProductsFeedDefinitionInput;
};


export type MutationProductFeedUpdateArgs = {
  data: ProductsFeedDefinitionInput;
  id: Scalars['String'];
};


export type MutationProductFeedDeleteArgs = {
  id: Scalars['String'];
};


export type MutationProductFeedEnableArgs = {
  id: Scalars['String'];
};


export type MutationProductFeedDisableArgs = {
  id: Scalars['String'];
};


export type MutationCreateReturnArgs = {
  input: ReturnInputType;
};


export type MutationOrderEmailChangeArgs = {
  input: OrderEmailChangeInput;
};


export type MutationShopifyOrderMigrateArgs = {
  input: ShopifyOrderMigrationInput;
};


export type MutationShopifyOrdersMigrateBatchArgs = {
  input: MigrateShopifyOrderJobInput;
};


export type MutationCreateOrdersReportArgs = {
  maxDays: Scalars['Int'];
};


export type MutationRefillGiftcardArgs = {
  input: RefillGiftcardInput;
};


export type MutationRefundOrderArgs = {
  languageId: Scalars['String'];
  input: RefundOrderInput;
};


export type MutationEditOrderArgs = {
  languageId: Scalars['String'];
  input: EditOrderInput;
};


export type MutationOrderTagsModifyArgs = {
  input: OrderModifyTagsInput;
};


export type MutationOrderCancelFromNavArgs = {
  orderId: Scalars['String'];
};


export type MutationOrderForceDeliveredArgs = {
  orderId: Scalars['String'];
};


export type MutationOrderForceFulfilledArgs = {
  trackingUrl: Scalars['String'];
  orderId: Scalars['String'];
};


export type MutationArchiveOrderArgs = {
  orderId: Scalars['String'];
};


export type MutationResyncQaplaOrderArgs = {
  orderId: Scalars['String'];
};


export type MutationSyncUnfulfilledOrderArgs = {
  orderId: Scalars['String'];
};


export type MutationSyncOrderShippingArgs = {
  orderId: Scalars['String'];
};


export type MutationProcessReturnFileArgs = {
  file: Scalars['String'];
};


export type MutationEditOrderPaymentInitializeArgs = {
  input: EditOrderPaymentSessionInitInput;
};


export type MutationEditOrderPaymentVerifyArgs = {
  input: EditOrderPaymentVerifyInput;
};


export type MutationToggleTagStatusArgs = {
  input: ToggleTagStatusInput;
};


export type MutationToggleProductCategoryStatusArgs = {
  input: ToggleProductCategoryStatusInput;
};


export type MutationCreateCmsTagArgs = {
  input: CreateCmsTagInput;
};


export type MutationCreateCmsTagCategoryArgs = {
  input: CreateCmsTagCategoryInput;
};


export type MutationCreateCmsProductCategoryArgs = {
  input: CreateCmsProductCategoryInput;
};


export type MutationUploadToolAssetArgs = {
  file: Scalars['Upload'];
};


export type MutationPublishProductArgs = {
  productId: Scalars['String'];
};


export type MutationLoadBrandProductsArgs = {
  input: ImportBrandProductsInputType;
};


export type MutationDeleteBrandProductsArgs = {
  input: DeleteBrandProductsInputType;
};


export type MutationExportCatalogProductsArgs = {
  input: ExportToolProductsInput;
};


export type MutationRefreshBrandProductItemArgs = {
  productId: Scalars['String'];
};


export type MutationRefreshCatalogBrandProductsArgs = {
  brandCode: Scalars['String'];
};


export type MutationUpsertProductItemArgs = {
  mode: Scalars['String'];
  product: CatalogBrandProductItemInput;
};


export type MutationUpsertVariantItemArgs = {
  mode: Scalars['String'];
  variant: CatalogBrandProductVariantInput;
};


export type MutationUpdateProductBrandNameArgs = {
  input: UpdateProductBrandNameInput;
};


export type MutationCopyTextsOnAllVariantsArgs = {
  input: CopyTextsOnAllVariantsInput;
};


export type MutationNormalizeVariantNamesArgs = {
  input: NormalizeVariantNamesInput;
};


export type MutationEnableProductArgs = {
  productId: Scalars['String'];
};


export type MutationDisableProductArgs = {
  productId: Scalars['String'];
};


export type MutationEnableProductVariantArgs = {
  eanCode: Scalars['String'];
};


export type MutationDisableProductVariantArgs = {
  eanCode: Scalars['String'];
};


export type MutationMoveVariantArgs = {
  input: MoveVariantInput;
};


export type MutationMoveVariantToNewProductArgs = {
  input: MoveVariantToNewProductInput;
};


export type MutationSwapVariantsOrderArgs = {
  input: SwapVariantsInputType;
};


export type MutationChangeVariantsOrderArgs = {
  input: VariantOrderInput;
};


export type MutationExportProductCategoriesArgs = {
  format: ReportFormat;
};


export type MutationExportProductsForNavArgs = {
  input: ExportNavProductsInput;
};


export type MutationImportSupplierProductsArgs = {
  feedUrl: Scalars['String'];
};


export type MutationImportNavCategoriesArgs = {
  feedUrl: Scalars['String'];
};


export type MutationImportNavCategoryMappingsArgs = {
  feedUrl: Scalars['String'];
};


export type MutationRunShopifySanitizeArgs = {
  startFromProductId?: Maybe<Scalars['String']>;
};


export type MutationGetAvailableProductIdArgs = {
  brandCode: Scalars['String'];
};


export type MutationChangeProductBrandArgs = {
  input: ChangeBrandProductInputType;
};


export type MutationMassiveProductsCreateXlsParseArgs = {
  file: Scalars['Upload'];
};


export type MutationMassiveProductsMediaUploadArgs = {
  media: Scalars['Upload'];
  ref: ProductMediaRefInput;
};


export type MutationMassiveProductsCreateArgs = {
  input: MassiveProductsCreateInput;
};


export type MutationMassiveProductsDeleteBatchArgs = {
  input: MassiveProductsDeleteInput;
};


export type MutationMassiveProductsUpdateXlsPreviewArgs = {
  file: Scalars['Upload'];
};


export type MutationMassiveProductsUpdateXlsArgs = {
  input: ProductsUpdateInput;
  file: Scalars['Upload'];
};


export type MutationMassiveProductsDownloadArgs = {
  input: ProductsDownloadInput;
};


export type MutationMasterProductArchiveArgs = {
  input: MasterProductArchiveInput;
};


export type MutationMasterProductRestoreArgs = {
  input: MasterProductRestoreInput;
};


export type MutationUpsertMasterProductCustomInfoArgs = {
  input: ProductCustomInfoEntityInput;
};


export type MutationDeleteMasterProductCustomInfoArgs = {
  productId: Scalars['String'];
};


export type MutationMasterVariantChangeEanArgs = {
  input: MasterVariantChangeEanCodeInput;
};


export type MutationParkodBrandMappingsUploadArgs = {
  file: Scalars['Upload'];
};


export type MutationParkodAxisMappingsUploadArgs = {
  file: Scalars['Upload'];
};


export type MutationParkodCategoryMappingsUploadArgs = {
  file: Scalars['Upload'];
};


export type MutationParkodDataGenerateArgs = {
  input: ParkodMapDataInput;
  file: Scalars['Upload'];
};


export type MutationUpdateCustomerArgs = {
  input: CustomerUpdateInput;
};


export type MutationCustomerAddressCreateArgs = {
  input: CustomerAddressInput;
};


export type MutationCustomerAddressUpdateArgs = {
  input: CustomerAddressInput;
  id: Scalars['String'];
};


export type MutationCustomerAddressDeleteArgs = {
  id: Scalars['String'];
};


export type MutationConvertOldCustomerArgs = {
  input: ConvertOldCustomerInput;
};


export type MutationCrmDisabledProfilesAddArgs = {
  email: Scalars['String'];
};


export type MutationCrmDisabledProfilesRemoveArgs = {
  id: Scalars['String'];
};


export type MutationEmailAddressValidateArgs = {
  address: Scalars['String'];
};


export type MutationCustomListManageArgs = {
  input: CustomListManageInput;
};


export type MutationUserFeedbackCreateArgs = {
  input: UserFeedbackInput;
};


export type MutationNewsletterManageArgs = {
  input: NewsletterManageInput;
};


export type MutationUnsubscribeSmsArgs = {
  input: SmsUnsubscribeInput;
};


export type MutationUserConsentsUnsubscribeArgs = {
  input: UserConsentsUnsubscribeInput;
};


export type MutationWishlistItemAddArgs = {
  input: AddWishlistItemInput;
};


export type MutationWishlistItemRemoveArgs = {
  input: RemoveWishlistItemInput;
};


export type MutationSubscribeOutOfStockProductArgs = {
  input: OutOfStockNotificationInput;
};


export type MutationTimedVoucherActivateArgs = {
  input: ActivateTimedVoucherInput;
};


export type MutationImportCrmContactsArgs = {
  input: CrmImportJobsInput;
};


export type MutationSyncKlaviyoProfileArgs = {
  input: SyncKlaviyoProfileInput;
};


export type MutationSyncShopifyProfileArgs = {
  input: SyncShopifyProfileInput;
};


export type MutationExportCrmContactsArgs = {
  input: ExportCrmContactsInput;
};


export type MutationSetFeatureSwitchStatusArgs = {
  input: SetSwitchStatusInput;
};


export type MutationUpdateSqlParamArgs = {
  input: UpdateSqlParamInput;
};


export type MutationCreateCalendarStoreHolidaysArgs = {
  input: CreateCalendarHolidaysInput;
};


export type MutationDeleteCalendarStoreHolidaysArgs = {
  input: DeleteCalendarHolidaysInput;
};


export type MutationCreateGlobalStoreHolidaysArgs = {
  input: CalendarHolidayRangeInput;
};


export type MutationDeleteGlobalStoreHolidaysArgs = {
  input: CalendarHolidayRangeInput;
};


export type MutationUpdateCalendarArgs = {
  input: CalendarDefinitionInput;
};


export type MutationCreateCalendarArgs = {
  input: CalendarDefinitionInput;
};


export type MutationToggleCalendarArgs = {
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationSubmitCalendarEventArgs = {
  input: EventRegistrationInput;
};


export type MutationCancelCalendarEventArgs = {
  input: EventCancellationInput;
};


export type MutationRunProductsReportExportArgs = {
  input: ReportExtractionInput;
};


export type MutationTrackEventArgs = {
  input: TrackEventInput;
};


export type MutationAdminPaymentInitArgs = {
  input: AdminPaymentInitInput;
};


export type MutationAdminPaymentGetArgs = {
  input: AdminPaymentGetInput;
};


export type MutationAdminPaymentVerifyArgs = {
  input: AdminPaymentVerifyInput;
};


export type MutationAdminPaymentRefundArgs = {
  input: AdminPaymentRefundInput;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  passwordResetBaseUrl?: Maybe<Scalars['String']>;
  returnUrl?: Maybe<Scalars['String']>;
};

export type ResetPasswordInput = {
  newPassword: Scalars['String'];
  tokenJwt: Scalars['String'];
};

export type SendResetPasswordInput = {
  email: Scalars['String'];
  passwordResetBaseUrl: Scalars['String'];
  redirectUrl?: Maybe<Scalars['String']>;
};

export type VerificationInput = {
  token: Scalars['String'];
};

export type RegistrationInput = {
  password: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  surname: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  createFidelityCard?: Maybe<Scalars['Boolean']>;
  birthDay: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  emailValidationBaseUrl: Scalars['String'];
  marketingAccepted?: Maybe<Scalars['Boolean']>;
  profilingAccepted?: Maybe<Scalars['Boolean']>;
  surveyAccepted?: Maybe<Scalars['Boolean']>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type ChangeEmailVerifyInput = {
  token: Scalars['String'];
};

export type CheckOldUserExistsInput = {
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  passwordResetBaseUrl: Scalars['String'];
  returnUrl?: Maybe<Scalars['String']>;
};

export type CheckStoreUserInput = {
  contactId: Scalars['String'];
};

export type RegisterStoreUserInput = {
  contactId: Scalars['String'];
  password: Scalars['String'];
};

export type BackofficeUserRoleSetInput = {
  email: Scalars['String'];
  roleName: Scalars['String'];
};

export type BackofficeUserRolesRemoveInput = {
  email: Scalars['String'];
};

export type ChangePasswordInput = {
  newPassword: Scalars['String'];
};

export type ChangeEmailInput = {
  newEmail: Scalars['String'];
  emailVerifyUrl: Scalars['String'];
  redirectUrl?: Maybe<Scalars['String']>;
};

export type CheckoutBasketItemPromoApplyPreview = {
  productId: Scalars['String'];
  promoCode: Scalars['String'];
};

export type GiftcardCheckoutInput = {
  amount: Scalars['Int'];
  email: Scalars['String'];
};

export type CheckoutInput = {
  lineItems: Array<CheckoutLineItemInput>;
  payload?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['String']>;
};

export type CheckoutLineItemInput = {
  productId: Scalars['String'];
  variantId: Scalars['String'];
  quantity: Scalars['Int'];
  isGift: Scalars['Boolean'];
  source?: Maybe<Scalars['String']>;
  promoCodes?: Maybe<Array<Scalars['String']>>;
  priceDiscountCodes?: Maybe<Array<Scalars['String']>>;
};

export type CheckoutGiftcardAddInput = {
  code: Scalars['String'];
};

export type CheckoutGiftcardRemoveInput = {
  code: Scalars['String'];
};

export type CheckoutContactInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  acceptsMarketing: Scalars['Boolean'];
};

export type CheckoutSamplesInput = {
  samples: Array<CheckoutSampleInput>;
  notes: Scalars['String'];
};

export type CheckoutSampleInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CheckoutShippingInput = {
  preference: ShippingPreference;
  shippingAddress?: Maybe<CheckoutShippingAddressInput>;
  storeId?: Maybe<Scalars['String']>;
};

export type CheckoutShippingAddressInput = {
  customerAddressId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phone2?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  pec?: Maybe<Scalars['String']>;
};

export type CheckoutBillingInput = {
  billingAddress?: Maybe<CheckoutShippingAddressInput>;
  invoice: CheckoutInvoiceInput;
  samples?: Maybe<CheckoutSamplesInput>;
  consents: CheckoutOrderConsents;
};

export type CheckoutInvoiceInput = {
  requestInvoice: Scalars['Boolean'];
  vatNumber?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  sdiCode?: Maybe<Scalars['String']>;
  pec?: Maybe<Scalars['String']>;
};

export type CheckoutOrderConsents = {
  marketingConsent?: Maybe<Scalars['Boolean']>;
  profilingConsent?: Maybe<Scalars['Boolean']>;
  newsletterConsent?: Maybe<Scalars['Boolean']>;
  createCard?: Maybe<Scalars['Boolean']>;
};

export type CheckoutPaymentMethodSetInput = {
  providerId: Scalars['String'];
};

export type CheckoutRecoverInput = {
  promoCode?: Maybe<Scalars['String']>;
};

export type InitializePaymentSessionInputType = {
  providerId: Scalars['String'];
  checkoutId: Scalars['String'];
  paymentSuccessBaseUrl: Scalars['String'];
  paymentCancelBaseUrl: Scalars['String'];
  paymentVerifyBaseUrl: Scalars['String'];
};

export type PaymentVerifyInput = {
  languageId: Scalars['String'];
  checkoutId: Scalars['String'];
  paymentSessionId: Scalars['String'];
};

export type PaymentCheckInput = {
  languageId: Scalars['String'];
  checkoutId: Scalars['String'];
  paymentSessionId: Scalars['String'];
};

export type FixBasketEmailInput = {
  basketId: Scalars['String'];
  email: Scalars['String'];
};

export type UpdateBasketInput = {
  data: Scalars['JSON'];
};

export type CustomerNavSimulationsInput = {
  cardNumber: Scalars['String'];
  basketPrice: Scalars['Float'];
};

export type ImportStoresInput = {
  feedUrl: Scalars['String'];
};

export type ImportZipCodesInput = {
  feedUrl: Scalars['String'];
};

export type ArchiveProductInputType = {
  productIds: Array<Scalars['String']>;
  deleteFromSanity?: Maybe<Scalars['Boolean']>;
  archiveOnShopify?: Maybe<Scalars['Boolean']>;
  deleteFromCatalog?: Maybe<Scalars['Boolean']>;
};

export type ImportProductsInputType = {
  feedUrl: Scalars['String'];
  overwriteCmsData?: Maybe<Scalars['Boolean']>;
  newProductsOnly?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Int']>;
  tot?: Maybe<Scalars['Int']>;
  productIds?: Maybe<Array<Scalars['String']>>;
};

export type RefreshProductsInputType = {
  eanCodes: Array<Scalars['String']>;
};

export type ImportProductCostsInputType = {
  feedUrl: Scalars['String'];
};

export type ProcessNavCatalogOptions = {
  refreshProduct?: Maybe<Scalars['Boolean']>;
};

export type ProcessNavStockOptions = {
  refreshProduct?: Maybe<Scalars['Boolean']>;
};

export type ReturnInputType = {
  orderId: Scalars['String'];
  returnAddress: ReturnAddressInputType;
  notes?: Maybe<Scalars['String']>;
};

export type ReturnAddressInputType = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  countryCode: Scalars['String'];
  city: Scalars['String'];
  address: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
  phone: Scalars['String'];
};

export type OrderEmailChangeInput = {
  orderId: Scalars['String'];
  email: Scalars['String'];
  sendNotification: Scalars['Boolean'];
};

export type ShopifyOrderMigrationInput = {
  shopifyOrderId: Scalars['String'];
};

export type MigrateShopifyOrderJobInput = {
  max?: Maybe<Scalars['Int']>;
  throttleMs?: Maybe<Scalars['Int']>;
};

export type RefillGiftcardInput = {
  giftcardNumber: Scalars['String'];
  refillAmount: Scalars['Float'];
  orderId?: Maybe<Scalars['String']>;
};

export type RefundOrderInput = {
  orderId: Scalars['String'];
  fullAmount: Scalars['Boolean'];
  customAmount?: Maybe<Scalars['Float']>;
};

export type EditOrderInput = {
  editRequestId: Scalars['String'];
  id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  shipping?: Maybe<EditOrderShippingInfo>;
  lineItems?: Maybe<Array<EditOrderLineItem>>;
  price: EditOrderPrice;
  processRefound: Scalars['Boolean'];
};

export type EditOrderShippingInfo = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

export type EditOrderLineItem = {
  id?: Maybe<Scalars['String']>;
  isNew: Scalars['Boolean'];
  isGift: Scalars['Boolean'];
  quantity: Scalars['Int'];
  discountedTotal: Scalars['Float'];
  originalTotal: Scalars['Float'];
  discountedUnitPrice: Scalars['Float'];
  originalUnitPrice: Scalars['Float'];
  eanCode: Scalars['String'];
};

export type EditOrderPrice = {
  manualDiscount: Scalars['Float'];
  originalTotal: Scalars['Float'];
  newTotal: Scalars['Float'];
  difference: Scalars['Float'];
};

export type OrderModifyTagsInput = {
  orderId: Scalars['String'];
  tags: Array<Scalars['String']>;
};

export type EditOrderPaymentSessionInitInput = {
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  cancelUrl: Scalars['String'];
  paymentCompletedBaseUrl: Scalars['String'];
};

export type EditOrderPaymentVerifyInput = {
  orderId: Scalars['String'];
  paymentSessionId: Scalars['String'];
};

export type ToggleTagStatusInput = {
  key: Scalars['String'];
  code: Scalars['String'];
  disabled: Scalars['Boolean'];
};

export type ToggleProductCategoryStatusInput = {
  code: Scalars['String'];
  disabled: Scalars['Boolean'];
};

export type CreateCmsTagInput = {
  languageId: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
  name: Scalars['String'];
};

export type CreateCmsTagCategoryInput = {
  languageId: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CreateCmsProductCategoryInput = {
  languageId: Scalars['String'];
  parentCategoryCode?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  name: Scalars['String'];
};


export type ImportBrandProductsInputType = {
  feedUrl: Scalars['String'];
  mode: Scalars['String'];
  filter: Scalars['String'];
};

export type DeleteBrandProductsInputType = {
  brandCode: Scalars['String'];
};

export type ExportToolProductsInput = {
  brandCodes?: Maybe<Array<Scalars['String']>>;
  created?: Maybe<EditDatesRange>;
  modified?: Maybe<EditDatesRange>;
  productsOnly?: Maybe<Scalars['Boolean']>;
};

export type EditDatesRange = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type CatalogBrandProductItemInput = {
  productId: Scalars['String'];
  brandCode: Scalars['String'];
  contents: Array<CatalogBrandProductItemContentsInput>;
  tags?: Maybe<Array<Scalars['String']>>;
  category?: Maybe<Scalars['String']>;
  secondaryCategories?: Maybe<Array<Scalars['String']>>;
  internalNotes?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  options: BrandProductOptionsInput;
};

export type CatalogBrandProductItemContentsInput = {
  languageId: Scalars['String'];
  name: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  highlights?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  ingredients?: Maybe<Scalars['String']>;
  fragrance?: Maybe<Scalars['String']>;
  fragranceFamily?: Maybe<Scalars['String']>;
  testResults?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  technology?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
  videos?: Maybe<Array<Scalars['String']>>;
};

export type CatalogBrandProductVariantInput = {
  productId: Scalars['String'];
  line?: Maybe<Scalars['String']>;
  productLine?: Maybe<Scalars['String']>;
  productLineId?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
  eanCode: Scalars['String'];
  brandName: Scalars['String'];
  brandCode: Scalars['String'];
  contents: Array<CatalogBrandProductItemContentsInput>;
  tags?: Maybe<Array<Scalars['String']>>;
  quantity?: Maybe<QuantityInputType>;
  color?: Maybe<ColorInputType>;
  category?: Maybe<Scalars['String']>;
  secondaryCategories?: Maybe<Array<Scalars['String']>>;
  relatedEanCodes?: Maybe<Array<Scalars['String']>>;
  complementaryEanCodes?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  internalNotes?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  variantIndex?: Maybe<Scalars['Int']>;
};

export type QuantityInputType = {
  value?: Maybe<Scalars['String']>;
};

export type ColorInputType = {
  hex?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateProductBrandNameInput = {
  productId: Scalars['String'];
  brandName: Scalars['String'];
};

export type CopyTextsOnAllVariantsInput = {
  eanCode: Scalars['String'];
  productId: Scalars['String'];
};

export type NormalizeVariantNamesInput = {
  productId: Scalars['String'];
  eanCodes: Array<Scalars['String']>;
  name: Scalars['String'];
  extractColor: Scalars['Boolean'];
};

export type MoveVariantInput = {
  eanCode: Scalars['String'];
  targetProductId: Scalars['String'];
};

export type MoveVariantToNewProductInput = {
  eanCode: Scalars['String'];
  brandCode: Scalars['String'];
};

export type SwapVariantsInputType = {
  eanCode1: Scalars['String'];
  eanCode2: Scalars['String'];
};

export type VariantOrderInput = {
  items: Array<VariantOrderInputItem>;
};

export type VariantOrderInputItem = {
  eanCode: Scalars['String'];
  index: Scalars['Float'];
};

export enum ReportFormat {
  Json = 'Json',
  Csv = 'Csv'
}

export type ExportNavProductsInput = {
  type: Scalars['String'];
};

export type ChangeBrandProductInputType = {
  oldProductId: Scalars['String'];
  newProductId: Scalars['String'];
  newBrandCode: Scalars['String'];
  newBrandName: Scalars['String'];
};

export type MassiveProductsCreateInput = {
  importHash: Scalars['String'];
  items: Array<ImportProductItemInput>;
};

export type MassiveProductsDeleteInput = {
  batchId: Scalars['String'];
};

export type ProductsUpdateInput = {
  records: Array<ProductUpdateRecordInput>;
};

export type ProductsDownloadInput = {
  productIds: Array<Scalars['String']>;
};

export type MasterProductArchiveInput = {
  productId: Scalars['String'];
};

export type MasterProductRestoreInput = {
  productId: Scalars['String'];
};

export type MasterVariantChangeEanCodeInput = {
  productId: Scalars['String'];
  currentEanCode: Scalars['String'];
  newEanCode: Scalars['String'];
};

export type ParkodMapDataInput = {
  filters: ParkodMapDataFilters;
};

export type ParkodMapDataFilters = {
  states: Array<Scalars['String']>;
  type: ParkodFilterType;
};

export enum ParkodFilterType {
  All = 'All',
  NonCoded = 'NonCoded'
}

export type CustomerUpdateInput = {
  userProfile?: Maybe<UserProfileUpdateInput>;
  marketingProfile?: Maybe<MarketingProfileUpdateInput>;
};

export type UserProfileUpdateInput = {
  name: Scalars['String'];
  surname: Scalars['String'];
  birthDay: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type MarketingProfileUpdateInput = {
  profilingConsent?: Maybe<Scalars['Boolean']>;
  marketingConsent?: Maybe<Scalars['Boolean']>;
  surveyConsent?: Maybe<Scalars['Boolean']>;
  newsletterConsent?: Maybe<Scalars['Boolean']>;
};

export type CustomerAddressInput = {
  shippingDefault: Scalars['Boolean'];
  invoiceDefault: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  country: Scalars['String'];
  countryCode: Scalars['String'];
  city: Scalars['String'];
  address: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  phone2?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ConvertOldCustomerInput = {
  marketing: MarketingProfileUpdateInput;
  createNewCard: Scalars['Boolean'];
};

export type CustomListManageInput = {
  websiteName: Scalars['String'];
  medium: Scalars['String'];
  address: Scalars['String'];
  subscribed: Scalars['Boolean'];
};

export type UserFeedbackInput = {
  user?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  reference: Scalars['String'];
  value: Scalars['String'];
};

export type NewsletterManageInput = {
  email: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  subscribed: Scalars['Boolean'];
};

export type SmsUnsubscribeInput = {
  phone: Scalars['String'];
};

export type UserConsentsUnsubscribeInput = {
  email: Scalars['String'];
  revokeNewsletter: Scalars['Boolean'];
  revokeMarketing: Scalars['Boolean'];
};

export type AddWishlistItemInput = {
  wishlistId?: Maybe<Scalars['String']>;
  variantId: Scalars['String'];
};

export type RemoveWishlistItemInput = {
  wishlistId: Scalars['String'];
  variantId: Scalars['String'];
};

export type OutOfStockNotificationInput = {
  productId: Scalars['String'];
  variantId: Scalars['String'];
  email: Scalars['String'];
  subscribeNewsletter?: Maybe<Scalars['Boolean']>;
};

export type ActivateTimedVoucherInput = {
  promoCode: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};

export type CrmImportJobsInput = {
  syncContacts: Scalars['Boolean'];
  syncTransactions: Scalars['Boolean'];
  syncCards: Scalars['Boolean'];
  aggregateTransactions: Scalars['Boolean'];
  deltaImport: Scalars['Boolean'];
};

export type SyncKlaviyoProfileInput = {
  email: Scalars['String'];
};

export type SyncShopifyProfileInput = {
  email?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
};

export type ExportCrmContactsInput = {
  filters: SearchContactsFilter;
  email?: Maybe<Array<Scalars['String']>>;
};

export type SearchContactsFilter = {
  birthDateFrom?: Maybe<Scalars['String']>;
  birthDateTo?: Maybe<Scalars['String']>;
  hasEmail?: Maybe<Scalars['Boolean']>;
  hasPhone?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Scalars['String']>;
  marketingConsent?: Maybe<Scalars['Boolean']>;
  profilingConsent?: Maybe<Scalars['Boolean']>;
  surveyConsent?: Maybe<Scalars['Boolean']>;
  newsletterConsent?: Maybe<Scalars['Boolean']>;
  hasFidelityCard?: Maybe<Scalars['Boolean']>;
};

export type SetSwitchStatusInput = {
  id: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type UpdateSqlParamInput = {
  id: Scalars['String'];
  value?: Maybe<Scalars['JSON']>;
};

export type CreateCalendarHolidaysInput = {
  storeId: Scalars['String'];
  range: CalendarHolidayRangeInput;
};

export type CalendarHolidayRangeInput = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export type DeleteCalendarHolidaysInput = {
  storeId: Scalars['String'];
  range: CalendarHolidayRangeInput;
};

export type EventCancellationInput = {
  eventId: Scalars['String'];
};

export type ReportExtractionInput = {
  filters: ReportExtractionFilters;
  target: ReportExportTarget;
  refreshData: Scalars['Boolean'];
};

export type ReportExtractionFilters = {
  itemCodes?: Maybe<Array<Scalars['String']>>;
};

export type ReportExportTarget = {
  email: Array<Scalars['String']>;
};

export type TrackEventInput = {
  sessionId: Scalars['String'];
  userId: Scalars['String'];
  event: TrackEventType;
  payload: Scalars['JSON'];
};

export enum TrackEventType {
  ClickedProduct = 'ClickedProduct',
  ViewedProduct = 'ViewedProduct',
  PurchasedProduct = 'PurchasedProduct',
  OrderCompleted = 'OrderCompleted',
  OrderSynched = 'OrderSynched',
  ViewedCategory = 'ViewedCategory',
  ViewedBrand = 'ViewedBrand',
  ClickedFilter = 'ClickedFilter',
  UserRegistered = 'UserRegistered',
  NewsletterSubscribed = 'NewsletterSubscribed',
  NewsletterUnsubscribed = 'NewsletterUnsubscribed',
  FidelityCardActivated = 'FidelityCardActivated',
  VoucherCreated = 'VoucherCreated',
  VoucherPending = 'VoucherPending',
  WishlistItemAdded = 'WishlistItemAdded',
  WishlistItemRemoved = 'WishlistItemRemoved'
}

export type AdminPaymentInitInput = {
  gatewayId: Scalars['String'];
  data: AdminPaymentInitInputData;
};

export type AdminPaymentInitInputData = {
  orderTotalAmount: Scalars['Float'];
  customerEmail: Scalars['String'];
};

export type AdminPaymentGetInput = {
  gatewayId: Scalars['String'];
  paymentId: Scalars['String'];
};

export type AdminPaymentVerifyInput = {
  gatewayId: Scalars['String'];
  paymentId: Scalars['String'];
};

export type AdminPaymentRefundInput = {
  gatewayId: Scalars['String'];
  paymentId: Scalars['String'];
  orderId: Scalars['String'];
  customAmount?: Maybe<Scalars['Float']>;
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResult' }
    & Pick<LoginResult, 'success' | 'errorType' | 'accessToken'>
  ) }
);

export type RegisterMutationVariables = Exact<{
  input: RegistrationInput;
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & { register: (
    { __typename?: 'RegistrationResult' }
    & Pick<RegistrationResult, 'success' | 'errorType' | 'accessToken'>
  ) }
);

export type VerifyMutationVariables = Exact<{
  input: VerificationInput;
}>;


export type VerifyMutation = (
  { __typename?: 'Mutation' }
  & { verify: (
    { __typename?: 'VerificationResult' }
    & Pick<VerificationResult, 'success' | 'errorType' | 'accessToken'>
  ) }
);

export type CheckOldUserExistsMutationVariables = Exact<{
  input: CheckOldUserExistsInput;
}>;


export type CheckOldUserExistsMutation = (
  { __typename?: 'Mutation' }
  & { checkOldUserExists: (
    { __typename?: 'CheckOldUserExistsResult' }
    & Pick<CheckOldUserExistsResult, 'success' | 'enum'>
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'ResetPasswordResult' }
    & Pick<ResetPasswordResult, 'success' | 'errorType' | 'accessToken'>
  ) }
);

export type SendResetPasswordMutationVariables = Exact<{
  input: SendResetPasswordInput;
}>;


export type SendResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { sendResetPassword: (
    { __typename?: 'SendResetPasswordResult' }
    & Pick<SendResetPasswordResult, 'success' | 'errorType'>
  ) }
);

export type CalendarEventDataFragment = (
  { __typename?: 'CalendarEvent' }
  & { definition: (
    { __typename?: 'CalendarDefinition' }
    & Pick<CalendarDefinition, 'id' | 'alias' | 'enabled' | 'locationName' | 'eventName' | 'eventDescription' | 'defaultEventDurationMin' | 'blockPublicEmails' | 'phoneRequired' | 'extraQuestions'>
    & { options?: Maybe<(
      { __typename?: 'CalendarDefinitionOptions' }
      & { preSelection?: Maybe<(
        { __typename?: 'CalendarPreSelection' }
        & Pick<CalendarPreSelection, 'fixed' | 'preselectedDate'>
      )> }
    )> }
  ), availability: (
    { __typename?: 'CalendarEventAvailability' }
    & { pages: Array<(
      { __typename?: 'CalendarEventAvailabilityPage' }
      & Pick<CalendarEventAvailabilityPage, 'year' | 'month'>
      & { days: Array<(
        { __typename?: 'CalendarEventAvailabilityDay' }
        & Pick<CalendarEventAvailabilityDay, 'year' | 'month' | 'day' | 'date'>
        & { slots: Array<(
          { __typename?: 'CalendarEventAvailabilitySlot' }
          & Pick<CalendarEventAvailabilitySlot, 'maxQuantity'>
          & { from: (
            { __typename?: 'SlotTime' }
            & Pick<SlotTime, 'hour' | 'minute'>
          ), to: (
            { __typename?: 'SlotTime' }
            & Pick<SlotTime, 'hour' | 'minute'>
          ) }
        )> }
      )> }
    )> }
  ) }
);

export type CancelCalendarEventMutationVariables = Exact<{
  input: EventCancellationInput;
}>;


export type CancelCalendarEventMutation = (
  { __typename?: 'Mutation' }
  & { cancelCalendarEvent: (
    { __typename?: 'EventCancellationResult' }
    & Pick<EventCancellationResult, 'success' | 'error'>
  ) }
);

export type SubmitCalendarEventMutationVariables = Exact<{
  input: EventRegistrationInput;
}>;


export type SubmitCalendarEventMutation = (
  { __typename?: 'Mutation' }
  & { submitCalendarEvent: (
    { __typename?: 'EventRegistrationResult' }
    & Pick<EventRegistrationResult, 'success' | 'error'>
    & { event?: Maybe<(
      { __typename?: 'EventRegistration' }
      & Pick<EventRegistration, 'calendarId' | 'data'>
      & { date: (
        { __typename?: 'CalendarDate' }
        & Pick<CalendarDate, 'year' | 'month' | 'day'>
      ), from: (
        { __typename?: 'CalendarTime' }
        & Pick<CalendarTime, 'hour' | 'minute'>
      ), to: (
        { __typename?: 'CalendarTime' }
        & Pick<CalendarTime, 'hour' | 'minute'>
      ) }
    )> }
  ) }
);

export type CalendarEventQueryVariables = Exact<{
  alias: Scalars['String'];
}>;


export type CalendarEventQuery = (
  { __typename?: 'Query' }
  & { calendarEvent?: Maybe<(
    { __typename?: 'CalendarEvent' }
    & CalendarEventDataFragment
  )> }
);

export type CheckoutDataFragment = (
  { __typename?: 'Checkout' }
  & Pick<Checkout, 'id' | 'status'>
  & { selectedPayment?: Maybe<(
    { __typename?: 'CheckoutSelectedPaymentMethod' }
    & Pick<CheckoutSelectedPaymentMethod, 'providerId' | 'captureType'>
  )>, price: (
    { __typename?: 'CheckoutPrice' }
    & Pick<CheckoutPrice, 'currierPaymentPrice' | 'shippingPrice' | 'shippingDiscount' | 'defaultFreeShippingThreshold' | 'defaultShippingPrice' | 'hasFreeShippingPromo' | 'subtotal' | 'discountTotal' | 'total' | 'totalToPay' | 'totalToPayLater' | 'currency'>
    & { appliedGiftcards?: Maybe<Array<(
      { __typename?: 'CheckoutAppliedGiftcard' }
      & Pick<CheckoutAppliedGiftcard, 'code' | 'totalAmount' | 'usedAmount'>
    )>> }
  ), promotions: Array<(
    { __typename?: 'PromoCode' }
    & Pick<PromoCode, 'id' | 'code' | 'trigger' | 'amount'>
  )>, basketDiscounts: Array<(
    { __typename?: 'CheckoutBasketDiscount' }
    & Pick<CheckoutBasketDiscount, 'totalDiscount' | 'trigger' | 'id' | 'code' | 'name'>
    & { item?: Maybe<(
      { __typename?: 'CatalogPromo' }
      & Pick<CatalogPromo, 'code' | 'name'>
      & { contents: (
        { __typename?: 'CatalogPromoContents' }
        & Pick<CatalogPromoContents, 'conditions' | 'description' | 'logoUrl'>
      ) }
    )> }
  )>, promotionsExtra?: Maybe<(
    { __typename?: 'CheckoutExtraPromotions' }
    & { freeProductPromoThresholds: Array<(
      { __typename?: 'CheckoutFreeProductPromoThresholds' }
      & Pick<CheckoutFreeProductPromoThresholds, 'promoCode' | 'promoId' | 'minOrderSubtotal' | 'amountLeft'>
      & { products: (
        { __typename?: 'CheckoutFeeProductItems' }
        & Pick<CheckoutFeeProductItems, 'eanCodes'>
      ) }
    )>, availableFreeProducts: Array<(
      { __typename?: 'CheckoutAvailableFreeProductPromo' }
      & Pick<CheckoutAvailableFreeProductPromo, 'code' | 'promoId'>
      & { products: (
        { __typename?: 'CheckoutFeeProductItems' }
        & Pick<CheckoutFeeProductItems, 'eanCodes'>
      ) }
    )>, availableProductPromoDiscounts: Array<(
      { __typename?: 'CheckoutAvailableProductPromoDiscount' }
      & Pick<CheckoutAvailableProductPromoDiscount, 'eanCodes' | 'maxItems'>
      & { amount: (
        { __typename?: 'CheckoutProductPromoDiscountAmount' }
        & Pick<CheckoutProductPromoDiscountAmount, 'value' | 'type'>
      ) }
    )> }
  )>, unavailableLineItems?: Maybe<Array<(
    { __typename?: 'CheckoutLineItemCatalogData' }
    & { variant: (
      { __typename?: 'CatalogProductVariant' }
      & CatalogProductVariantSearchDataFragment
    ) }
  )>>, giftLineItems?: Maybe<Array<(
    { __typename?: 'CheckoutLineItem' }
    & Pick<CheckoutLineItem, 'id' | 'quantity' | 'source'>
    & { product?: Maybe<(
      { __typename?: 'CheckoutLineItemCatalogData' }
      & { document: (
        { __typename?: 'CatalogProductDocument' }
        & { product: (
          { __typename?: 'CatalogProduct' }
          & CatalogProductSearchDataFragment
        ), variants: Array<(
          { __typename?: 'CatalogProductVariant' }
          & CatalogProductVariantSearchDataFragment
        )>, defaultVariant: (
          { __typename?: 'CatalogProductVariant' }
          & DefaultCatalogProductVariantSearchDataFragment
        ), extraData?: Maybe<(
          { __typename?: 'CatalogProductExtraData' }
          & CatalogProductExtraDataInfoFragment
        )> }
      ), variant: (
        { __typename?: 'CatalogProductVariant' }
        & CatalogProductVariantSearchDataFragment
      ) }
    )> }
  )>>, lineItems: Array<(
    { __typename?: 'CheckoutLineItem' }
    & Pick<CheckoutLineItem, 'id' | 'quantity' | 'isGift' | 'originalUnitPrice' | 'originalTotal' | 'discountedUnitPrice' | 'discountedTotal' | 'originalUnitCompareAtPrice' | 'originalTotalCompareAtPrice' | 'discountPercent' | 'extraDiscountPercent' | 'assignedGiftCardCode' | 'source' | 'promoCodes'>
    & { promotions: Array<(
      { __typename?: 'CheckoutPromo' }
      & Pick<CheckoutPromo, 'code' | 'name'>
      & { item?: Maybe<(
        { __typename?: 'CatalogPromo' }
        & Pick<CatalogPromo, 'code' | 'name'>
        & { contents: (
          { __typename?: 'CatalogPromoContents' }
          & Pick<CatalogPromoContents, 'conditions' | 'description' | 'logoUrl'>
        ) }
      )> }
    )>, product?: Maybe<(
      { __typename?: 'CheckoutLineItemCatalogData' }
      & { document: (
        { __typename?: 'CatalogProductDocument' }
        & { product: (
          { __typename?: 'CatalogProduct' }
          & CatalogProductSearchDataFragment
        ), variants: Array<(
          { __typename?: 'CatalogProductVariant' }
          & CatalogProductVariantSearchDataFragment
        )>, defaultVariant: (
          { __typename?: 'CatalogProductVariant' }
          & DefaultCatalogProductVariantSearchDataFragment
        ), extraData?: Maybe<(
          { __typename?: 'CatalogProductExtraData' }
          & CatalogProductExtraDataInfoFragment
        )> }
      ), variant: (
        { __typename?: 'CatalogProductVariant' }
        & CatalogProductVariantSearchDataFragment
      ) }
    )> }
  )>, promoState: (
    { __typename?: 'CheckoutPromoState' }
    & Pick<CheckoutPromoState, 'hasLoggedPromoInBasket' | 'acceptedNoLoggedPromo' | 'authenticatedBasket'>
  ), samples?: Maybe<(
    { __typename?: 'CheckoutSamples' }
    & Pick<CheckoutSamples, 'notes'>
    & { samples: Array<(
      { __typename?: 'CheckoutSample' }
      & Pick<CheckoutSample, 'id' | 'name'>
    )> }
  )>, shipping?: Maybe<(
    { __typename?: 'CheckoutShipping' }
    & Pick<CheckoutShipping, 'preference'>
    & { store?: Maybe<(
      { __typename?: 'StoreDocumentV2' }
      & Pick<StoreDocumentV2, 'id' | 'name' | 'company'>
      & { address: (
        { __typename?: 'StoreAddress' }
        & Pick<StoreAddress, 'street' | 'cap' | 'city' | 'province'>
      ) }
    )>, shippingAddress?: Maybe<(
      { __typename?: 'CheckoutShippingAddress' }
      & Pick<CheckoutShippingAddress, 'customerAddressId' | 'firstName' | 'lastName' | 'countryCode' | 'provinceCode' | 'city' | 'address' | 'address2' | 'notes' | 'company' | 'vatNumber' | 'zipCode' | 'phone'>
    )>, billingAddress?: Maybe<(
      { __typename?: 'CheckoutShippingAddress' }
      & Pick<CheckoutShippingAddress, 'customerAddressId' | 'firstName' | 'lastName' | 'countryCode' | 'provinceCode' | 'city' | 'address' | 'address2' | 'notes' | 'company' | 'vatNumber' | 'zipCode' | 'phone'>
    )> }
  )>, customer: (
    { __typename?: 'CheckoutCustomer' }
    & Pick<CheckoutCustomer, 'email' | 'acceptsMarketing'>
  ), order?: Maybe<(
    { __typename?: 'CheckoutOrder' }
    & Pick<CheckoutOrder, 'id' | 'uid' | 'orderDate'>
  )>, settings?: Maybe<(
    { __typename?: 'CheckoutSettings' }
    & Pick<CheckoutSettings, 'clickAndCollectEnabled' | 'giftcardEnabled' | 'giftBoxEnabled' | 'enabledPaymentMethods'>
  )>, customerDiscounts?: Maybe<(
    { __typename?: 'CustomerAvailableDiscounts' }
    & Pick<CustomerAvailableDiscounts, 'birthdayDiscount' | 'passagesDiscount' | 'promoCode'>
    & { pointDiscounts: Array<(
      { __typename?: 'CustomerPointsDiscount' }
      & Pick<CustomerPointsDiscount, 'points' | 'discount'>
    )> }
  )>, lock?: Maybe<(
    { __typename?: 'CheckoutLock' }
    & Pick<CheckoutLock, 'locked' | 'lockTime' | 'lockExpiration'>
  )> }
);

export type GiftcardCheckoutCreateMutationVariables = Exact<{
  input: GiftcardCheckoutInput;
  languageId: Scalars['String'];
}>;


export type GiftcardCheckoutCreateMutation = (
  { __typename?: 'Mutation' }
  & { giftcardCheckoutCreate: (
    { __typename?: 'CheckoutCreateResult' }
    & Pick<CheckoutCreateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutCreateMutationVariables = Exact<{
  input: CheckoutInput;
  languageId: Scalars['String'];
}>;


export type CheckoutCreateMutation = (
  { __typename?: 'Mutation' }
  & { checkoutCreate: (
    { __typename?: 'CheckoutCreateResult' }
    & Pick<CheckoutCreateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutUpdateMutationVariables = Exact<{
  id: Scalars['String'];
  input: CheckoutInput;
  languageId: Scalars['String'];
}>;


export type CheckoutUpdateMutation = (
  { __typename?: 'Mutation' }
  & { checkoutUpdate: (
    { __typename?: 'CheckoutUpdateResult' }
    & Pick<CheckoutUpdateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutLockMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
}>;


export type CheckoutLockMutation = (
  { __typename?: 'Mutation' }
  & { checkoutLock: (
    { __typename?: 'CheckoutUpdateResult' }
    & Pick<CheckoutUpdateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutApplyPromoCodeMutationVariables = Exact<{
  id: Scalars['String'];
  promoCode: Scalars['String'];
  languageId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
}>;


export type CheckoutApplyPromoCodeMutation = (
  { __typename?: 'Mutation' }
  & { checkoutApplyPromoCode: (
    { __typename?: 'CheckoutPromoCodeApplyResult' }
    & Pick<CheckoutPromoCodeApplyResult, 'success' | 'error'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutRemovePromoCodeMutationVariables = Exact<{
  id: Scalars['String'];
  promoCode: Scalars['String'];
  languageId: Scalars['String'];
}>;


export type CheckoutRemovePromoCodeMutation = (
  { __typename?: 'Mutation' }
  & { checkoutRemovePromoCode: (
    { __typename?: 'CheckoutUpdateResult' }
    & Pick<CheckoutUpdateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutSetShippingMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
  shipping: CheckoutShippingInput;
  samples?: Maybe<CheckoutSamplesInput>;
  contact?: Maybe<CheckoutContactInput>;
}>;


export type CheckoutSetShippingMutation = (
  { __typename?: 'Mutation' }
  & { checkoutSetShipping: (
    { __typename?: 'CheckoutUpdateResult' }
    & Pick<CheckoutUpdateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutSetSamplesMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
  samples: CheckoutSamplesInput;
}>;


export type CheckoutSetSamplesMutation = (
  { __typename?: 'Mutation' }
  & { checkoutSetSamples: (
    { __typename?: 'CheckoutUpdateResult' }
    & Pick<CheckoutUpdateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutApplyBirthdayDiscountMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
}>;


export type CheckoutApplyBirthdayDiscountMutation = (
  { __typename?: 'Mutation' }
  & { checkoutApplyBirthdayDiscount: (
    { __typename?: 'CheckoutUpdateResult' }
    & Pick<CheckoutUpdateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutRemoveBirthdayDiscountMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
}>;


export type CheckoutRemoveBirthdayDiscountMutation = (
  { __typename?: 'Mutation' }
  & { checkoutRemoveBirthdayDiscount: (
    { __typename?: 'CheckoutUpdateResult' }
    & Pick<CheckoutUpdateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutApplyPassagesDiscountMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
}>;


export type CheckoutApplyPassagesDiscountMutation = (
  { __typename?: 'Mutation' }
  & { checkoutApplyPassagesDiscount: (
    { __typename?: 'CheckoutUpdateResult' }
    & Pick<CheckoutUpdateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutRemovePassagesDiscountMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
}>;


export type CheckoutRemovePassagesDiscountMutation = (
  { __typename?: 'Mutation' }
  & { checkoutRemovePassagesDiscount: (
    { __typename?: 'CheckoutUpdateResult' }
    & Pick<CheckoutUpdateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutAcceptNoLoggedPromoMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
}>;


export type CheckoutAcceptNoLoggedPromoMutation = (
  { __typename?: 'Mutation' }
  & { checkoutAcceptNoLoggedPromo: (
    { __typename?: 'CheckoutUpdateResult' }
    & Pick<CheckoutUpdateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutSetPaymentMethodMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
  input: CheckoutPaymentMethodSetInput;
}>;


export type CheckoutSetPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { checkoutSetPaymentMethod: (
    { __typename?: 'CheckoutUpdateResult' }
    & Pick<CheckoutUpdateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutSetBillingDataMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
  billing: CheckoutBillingInput;
}>;


export type CheckoutSetBillingDataMutation = (
  { __typename?: 'Mutation' }
  & { checkoutSetBillingData: (
    { __typename?: 'CheckoutUpdateResult' }
    & Pick<CheckoutUpdateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutApplyGiftcardMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
  giftcard: CheckoutGiftcardAddInput;
}>;


export type CheckoutApplyGiftcardMutation = (
  { __typename?: 'Mutation' }
  & { checkoutApplyGiftcard: (
    { __typename?: 'CheckoutGiftcardApplyResult' }
    & Pick<CheckoutGiftcardApplyResult, 'success' | 'error'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutRemoveGiftcardMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
  giftcard: CheckoutGiftcardRemoveInput;
}>;


export type CheckoutRemoveGiftcardMutation = (
  { __typename?: 'Mutation' }
  & { checkoutRemoveGiftcard: (
    { __typename?: 'CheckoutUpdateResult' }
    & Pick<CheckoutUpdateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutSelectProductGiftMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
  eanCode: Scalars['String'];
}>;


export type CheckoutSelectProductGiftMutation = (
  { __typename?: 'Mutation' }
  & { checkoutSelectProductGift: (
    { __typename?: 'CheckoutUpdateResult' }
    & Pick<CheckoutUpdateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutRemoveProductGiftMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
  eanCode: Scalars['String'];
}>;


export type CheckoutRemoveProductGiftMutation = (
  { __typename?: 'Mutation' }
  & { checkoutRemoveProductGift: (
    { __typename?: 'CheckoutUpdateResult' }
    & Pick<CheckoutUpdateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutApplyPointsDiscountMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
  points: Scalars['Int'];
}>;


export type CheckoutApplyPointsDiscountMutation = (
  { __typename?: 'Mutation' }
  & { checkoutApplyPointsDiscount: (
    { __typename?: 'CheckoutUpdateResult' }
    & Pick<CheckoutUpdateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutRemovePointsDiscountMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
  points: Scalars['Int'];
}>;


export type CheckoutRemovePointsDiscountMutation = (
  { __typename?: 'Mutation' }
  & { checkoutRemovePointsDiscount: (
    { __typename?: 'CheckoutUpdateResult' }
    & Pick<CheckoutUpdateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutCompleteWithoutPaymentMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
}>;


export type CheckoutCompleteWithoutPaymentMutation = (
  { __typename?: 'Mutation' }
  & { checkoutCompleteWithoutPayment: (
    { __typename?: 'CheckoutCompleteWithNoPaymentResult' }
    & Pick<CheckoutCompleteWithNoPaymentResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutRecoverMutationVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
  input: CheckoutRecoverInput;
}>;


export type CheckoutRecoverMutation = (
  { __typename?: 'Mutation' }
  & { checkoutRecover: (
    { __typename?: 'CheckoutUpdateResult' }
    & Pick<CheckoutUpdateResult, 'success'>
    & { data?: Maybe<(
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    )> }
  ) }
);

export type CheckoutBasketItemPromoApplyPreviewMutationVariables = Exact<{
  input: CheckoutBasketItemPromoApplyPreview;
}>;


export type CheckoutBasketItemPromoApplyPreviewMutation = (
  { __typename?: 'Mutation' }
  & { checkoutBasketItemPromoApplyPreview: (
    { __typename?: 'CheckoutBasketItemPromoApplyPreviewResult' }
    & { results: Array<(
      { __typename?: 'CheckoutBasketItemProductPromoApplyPreviewResult' }
      & Pick<CheckoutBasketItemProductPromoApplyPreviewResult, 'applicable'>
      & { variant: (
        { __typename?: 'CheckoutBasketItemProductPromoApplyPreviewResultVariant' }
        & Pick<CheckoutBasketItemProductPromoApplyPreviewResultVariant, 'productId' | 'eanCode'>
      ), promo?: Maybe<(
        { __typename?: 'CheckoutBasketItemProductPromoApplyPreviewPromoResult' }
        & Pick<CheckoutBasketItemProductPromoApplyPreviewPromoResult, 'promoCode'>
      )>, discount?: Maybe<(
        { __typename?: 'CheckoutBasketItemPromoApplyPreviewDiscount' }
        & Pick<CheckoutBasketItemPromoApplyPreviewDiscount, 'originalCompareAtPrice' | 'originalDiscountPercent' | 'originalDiscountAmount' | 'newCompareAtPrice' | 'newDiscountPercent' | 'newDiscountAmount' | 'totalDiscountAmount' | 'price'>
      )> }
    )> }
  ) }
);

export type CheckoutQueryVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
  draftOnly?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
}>;


export type CheckoutQuery = (
  { __typename?: 'Query' }
  & { checkout?: Maybe<(
    { __typename?: 'Checkout' }
    & CheckoutDataFragment
  )> }
);

export type CustomerCheckoutQueryVariables = Exact<{
  languageId: Scalars['String'];
}>;


export type CustomerCheckoutQuery = (
  { __typename?: 'Query' }
  & { customerCheckout?: Maybe<(
    { __typename?: 'Checkout' }
    & CheckoutDataFragment
  )> }
);

export type CheckoutGetAvailableCollectStoresQueryVariables = Exact<{
  checkoutId: Scalars['String'];
}>;


export type CheckoutGetAvailableCollectStoresQuery = (
  { __typename?: 'Query' }
  & { checkoutGetAvailableCollectStores: Array<(
    { __typename?: 'ClickAndCollectStore' }
    & Pick<ClickAndCollectStore, 'storeId'>
  )> }
);

export type CountriesQueryVariables = Exact<{
  languageId: Scalars['String'];
}>;


export type CountriesQuery = (
  { __typename?: 'Query' }
  & { countries: (
    { __typename?: 'Countries' }
    & { default: (
      { __typename?: 'Country' }
      & Pick<Country, 'name' | 'code' | 'hasProvince'>
    ), values: Array<(
      { __typename?: 'Country' }
      & Pick<Country, 'name' | 'code' | 'hasProvince'>
    )> }
  ) }
);

export type ProvincesQueryVariables = Exact<{
  countryCode: Scalars['String'];
}>;


export type ProvincesQuery = (
  { __typename?: 'Query' }
  & { provinces: Array<(
    { __typename?: 'Province' }
    & Pick<Province, 'name' | 'code'>
  )> }
);

export type CustomerDataFragment = (
  { __typename?: 'Customer' }
  & { account: (
    { __typename?: 'CustomerAccount' }
    & Pick<CustomerAccount, 'email' | 'emailVerified'>
  ), userProfile: (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'name' | 'surname' | 'birthDay' | 'gender' | 'taxCode' | 'phone' | 'country' | 'state' | 'city' | 'address' | 'zipCode'>
  ), marketingProfile: (
    { __typename?: 'MarketingProfile' }
    & Pick<MarketingProfile, 'subscribedToNewsletter' | 'marketingConsent' | 'profilingConsent' | 'surveyConsent' | 'cardId' | 'cardBalance' | 'oldCustomer' | 'hasOldCard' | 'newTermsAccepted'>
  ), addresses: Array<(
    { __typename?: 'CustomerAddress' }
    & Pick<CustomerAddress, 'id' | 'shippingDefault' | 'invoiceDefault' | 'firstName' | 'lastName' | 'country' | 'countryCode' | 'province' | 'provinceCode' | 'city' | 'address' | 'address2' | 'zipCode' | 'phone' | 'phone2' | 'notes' | 'company' | 'vatNumber' | 'type'>
  )> }
);

export type EmailAddressValidateMutationVariables = Exact<{
  address: Scalars['String'];
}>;


export type EmailAddressValidateMutation = (
  { __typename?: 'Mutation' }
  & { emailAddressValidate: (
    { __typename?: 'EmailValidationResult' }
    & Pick<EmailValidationResult, 'isValid' | 'errorType' | 'errorMessage'>
  ) }
);

export type UpdateCustomerMutationVariables = Exact<{
  input: CustomerUpdateInput;
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomer: (
    { __typename?: 'CustomerUpdateResult' }
    & Pick<CustomerUpdateResult, 'success' | 'errorType'>
    & { customer?: Maybe<(
      { __typename?: 'Customer' }
      & CustomerDataFragment
    )> }
  ) }
);

export type DeleteCustomerMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteCustomerMutation = (
  { __typename?: 'Mutation' }
  & { deleteCustomer: (
    { __typename?: 'CustomerDeleteResult' }
    & Pick<CustomerDeleteResult, 'success' | 'errorType'>
  ) }
);

export type NewsletterManageMutationVariables = Exact<{
  input: NewsletterManageInput;
}>;


export type NewsletterManageMutation = (
  { __typename?: 'Mutation' }
  & { newsletterManage: (
    { __typename?: 'NewsletterManageResult' }
    & Pick<NewsletterManageResult, 'success' | 'alreadySubscribed' | 'alreadyUnsubscribed' | 'voucherCreated'>
  ) }
);

export type CustomerAddressCreateMutationVariables = Exact<{
  input: CustomerAddressInput;
}>;


export type CustomerAddressCreateMutation = (
  { __typename?: 'Mutation' }
  & { customerAddressCreate: (
    { __typename?: 'AddressCreateResult' }
    & Pick<AddressCreateResult, 'success'>
  ) }
);

export type CustomerAddressDeleteMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type CustomerAddressDeleteMutation = (
  { __typename?: 'Mutation' }
  & { customerAddressDelete: (
    { __typename?: 'AddressDeleteResult' }
    & Pick<AddressDeleteResult, 'success'>
  ) }
);

export type CustomerAddressUpdateMutationVariables = Exact<{
  id: Scalars['String'];
  input: CustomerAddressInput;
}>;


export type CustomerAddressUpdateMutation = (
  { __typename?: 'Mutation' }
  & { customerAddressUpdate: (
    { __typename?: 'AddressUpdateResult' }
    & Pick<AddressUpdateResult, 'success'>
  ) }
);

export type ChangeEmailMutationVariables = Exact<{
  input: ChangeEmailInput;
}>;


export type ChangeEmailMutation = (
  { __typename?: 'Mutation' }
  & { changeEmail: (
    { __typename?: 'ChangeEmailResult' }
    & Pick<ChangeEmailResult, 'success' | 'errorType'>
  ) }
);

export type ChangeEmailVerifyMutationVariables = Exact<{
  input: ChangeEmailVerifyInput;
}>;


export type ChangeEmailVerifyMutation = (
  { __typename?: 'Mutation' }
  & { changeEmailVerify: (
    { __typename?: 'ChangeEmailVerifyResult' }
    & Pick<ChangeEmailVerifyResult, 'success' | 'accessToken'>
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword: (
    { __typename?: 'ChangePasswordResult' }
    & Pick<ChangePasswordResult, 'success' | 'accessToken'>
  ) }
);

export type CustomerEnableCardMutationVariables = Exact<{ [key: string]: never; }>;


export type CustomerEnableCardMutation = (
  { __typename?: 'Mutation' }
  & { customerEnableCard: (
    { __typename?: 'CustomerEnableCardResult' }
    & Pick<CustomerEnableCardResult, 'success' | 'cardId'>
  ) }
);

export type CustomerAcceptNewPoliciesMutationVariables = Exact<{ [key: string]: never; }>;


export type CustomerAcceptNewPoliciesMutation = (
  { __typename?: 'Mutation' }
  & { customerAcceptNewPolicies: (
    { __typename?: 'CustomerNewPoliciesAcceptResult' }
    & Pick<CustomerNewPoliciesAcceptResult, 'success'>
    & { customer?: Maybe<(
      { __typename?: 'Customer' }
      & CustomerDataFragment
    )> }
  ) }
);

export type UserFeedbackCreateMutationVariables = Exact<{
  input: UserFeedbackInput;
}>;


export type UserFeedbackCreateMutation = (
  { __typename?: 'Mutation' }
  & { userFeedbackCreate: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type ConvertOldCustomerMutationVariables = Exact<{
  input: ConvertOldCustomerInput;
}>;


export type ConvertOldCustomerMutation = (
  { __typename?: 'Mutation' }
  & { convertOldCustomer: (
    { __typename?: 'ConvertOldCustomerResult' }
    & Pick<ConvertOldCustomerResult, 'success'>
    & { customer?: Maybe<(
      { __typename?: 'Customer' }
      & CustomerDataFragment
    )> }
  ) }
);

export type CheckStoreUserStatusMutationVariables = Exact<{
  input: CheckStoreUserInput;
}>;


export type CheckStoreUserStatusMutation = (
  { __typename?: 'Mutation' }
  & { checkStoreUserStatus: (
    { __typename?: 'CheckStoreUserResult' }
    & Pick<CheckStoreUserResult, 'status'>
    & { contact?: Maybe<(
      { __typename?: 'CheckStoreUserData' }
      & Pick<CheckStoreUserData, 'email' | 'firstName' | 'lastName'>
    )> }
  ) }
);

export type CompleteStoreUserRegistrationMutationVariables = Exact<{
  input: RegisterStoreUserInput;
}>;


export type CompleteStoreUserRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { completeStoreUserRegistration: (
    { __typename?: 'RegisterStoreUserResult' }
    & Pick<RegisterStoreUserResult, 'success' | 'accessToken'>
  ) }
);

export type UserConsentsUnsubscribeMutationVariables = Exact<{
  input: UserConsentsUnsubscribeInput;
}>;


export type UserConsentsUnsubscribeMutation = (
  { __typename?: 'Mutation' }
  & { userConsentsUnsubscribe: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type UnsubscribeSmsMutationVariables = Exact<{
  input: SmsUnsubscribeInput;
}>;


export type UnsubscribeSmsMutation = (
  { __typename?: 'Mutation' }
  & { unsubscribeSms: (
    { __typename?: 'SmsUnregisterResult' }
    & Pick<SmsUnregisterResult, 'success' | 'error'>
  ) }
);

export type GetCustomerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerQuery = (
  { __typename?: 'Query' }
  & { getCustomer?: Maybe<(
    { __typename?: 'Customer' }
    & CustomerDataFragment
  )> }
);

export type SubscribeOutOfStockProductMutationVariables = Exact<{
  input: OutOfStockNotificationInput;
}>;


export type SubscribeOutOfStockProductMutation = (
  { __typename?: 'Mutation' }
  & { subscribeOutOfStockProduct: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type OrderDetailsDataFragment = (
  { __typename?: 'OrderDetails' }
  & Pick<OrderDetails, 'id' | 'uid' | 'type' | 'alias' | 'status' | 'createdAt' | 'deliveredDate' | 'shippedDate'>
  & { editOrder?: Maybe<(
    { __typename?: 'EditOrderDetails' }
    & Pick<EditOrderDetails, 'pendingPaymentUrl' | 'pendingPaymentAmount'>
  )>, price: (
    { __typename?: 'OrderPrice' }
    & Pick<OrderPrice, 'shippingPrice' | 'subtotal' | 'discountTotal' | 'total' | 'totalPaid' | 'currency'>
    & { giftcards: Array<(
      { __typename?: 'OrderGiftcard' }
      & Pick<OrderGiftcard, 'code' | 'amountUsed'>
    )> }
  ), basketDiscounts: Array<(
    { __typename?: 'CheckoutBasketDiscount' }
    & Pick<CheckoutBasketDiscount, 'totalDiscount' | 'trigger' | 'code' | 'name'>
    & { item?: Maybe<(
      { __typename?: 'CatalogPromo' }
      & Pick<CatalogPromo, 'code' | 'name'>
      & { contents: (
        { __typename?: 'CatalogPromoContents' }
        & Pick<CatalogPromoContents, 'conditions' | 'description' | 'logoUrl'>
      ) }
    )> }
  )>, tracking?: Maybe<(
    { __typename?: 'OrderTracking' }
    & Pick<OrderTracking, 'url'>
  )>, shipping?: Maybe<(
    { __typename?: 'OrderShipping' }
    & Pick<OrderShipping, 'preference'>
    & { shippingAddress?: Maybe<(
      { __typename?: 'OrderShippingAddress' }
      & Pick<OrderShippingAddress, 'firstName' | 'lastName' | 'country' | 'countryCode' | 'provinceCode' | 'city' | 'address' | 'address2' | 'zipCode' | 'phone'>
    )>, billingAddress?: Maybe<(
      { __typename?: 'OrderShippingAddress' }
      & Pick<OrderShippingAddress, 'firstName' | 'lastName' | 'country' | 'countryCode' | 'provinceCode' | 'city' | 'address' | 'address2' | 'zipCode' | 'phone'>
    )>, store?: Maybe<(
      { __typename?: 'StoreDocumentV2' }
      & Pick<StoreDocumentV2, 'id' | 'name' | 'company'>
      & { address: (
        { __typename?: 'StoreAddress' }
        & Pick<StoreAddress, 'street' | 'cap' | 'city' | 'province'>
      ), coordinates?: Maybe<(
        { __typename?: 'Coordinates' }
        & Pick<Coordinates, 'latitude' | 'longitude'>
      )>, contacts: (
        { __typename?: 'StoreContacts' }
        & Pick<StoreContacts, 'phone'>
      ) }
    )> }
  )>, customer: (
    { __typename?: 'OrderCustomer' }
    & Pick<OrderCustomer, 'email' | 'acceptsMarketing'>
  ), giftLineItems?: Maybe<Array<(
    { __typename?: 'OrderLineItem' }
    & Pick<OrderLineItem, 'id' | 'quantity' | 'originalUnitPrice' | 'originalTotal' | 'discountedUnitPrice' | 'discountedTotal' | 'originalTotalCompareAtPrice' | 'originalUnitCompareAtPrice' | 'isGift'>
    & { product?: Maybe<(
      { __typename?: 'OrderLineItemCatalogData' }
      & { document: (
        { __typename?: 'CatalogProductDocument' }
        & { product: (
          { __typename?: 'CatalogProduct' }
          & CatalogProductSearchDataFragment
        ), variants: Array<(
          { __typename?: 'CatalogProductVariant' }
          & CatalogProductVariantSearchDataFragment
        )>, defaultVariant: (
          { __typename?: 'CatalogProductVariant' }
          & DefaultCatalogProductVariantSearchDataFragment
        ) }
      ), variant: (
        { __typename?: 'CatalogProductVariant' }
        & CatalogProductVariantSearchDataFragment
      ) }
    )> }
  )>>, lineItems: Array<(
    { __typename?: 'OrderLineItem' }
    & Pick<OrderLineItem, 'id' | 'quantity' | 'originalUnitPrice' | 'originalTotal' | 'discountedUnitPrice' | 'discountedTotal' | 'originalTotalCompareAtPrice' | 'originalUnitCompareAtPrice' | 'assignedGiftCardCode' | 'isGift'>
    & { product?: Maybe<(
      { __typename?: 'OrderLineItemCatalogData' }
      & { document: (
        { __typename?: 'CatalogProductDocument' }
        & { product: (
          { __typename?: 'CatalogProduct' }
          & CatalogProductSearchDataFragment
        ), variants: Array<(
          { __typename?: 'CatalogProductVariant' }
          & CatalogProductVariantSearchDataFragment
        )>, defaultVariant: (
          { __typename?: 'CatalogProductVariant' }
          & DefaultCatalogProductVariantSearchDataFragment
        ) }
      ), variant: (
        { __typename?: 'CatalogProductVariant' }
        & CatalogProductVariantSearchDataFragment
      ) }
    )> }
  )> }
);

export type EditOrderPaymentVerifyMutationVariables = Exact<{
  input: EditOrderPaymentVerifyInput;
}>;


export type EditOrderPaymentVerifyMutation = (
  { __typename?: 'Mutation' }
  & { editOrderPaymentVerify: (
    { __typename?: 'EditOrderPaymentVerifyResult' }
    & Pick<EditOrderPaymentVerifyResult, 'success'>
  ) }
);

export type EditOrderPaymentInitializeMutationVariables = Exact<{
  input: EditOrderPaymentSessionInitInput;
}>;


export type EditOrderPaymentInitializeMutation = (
  { __typename?: 'Mutation' }
  & { editOrderPaymentInitialize: (
    { __typename?: 'EditOrderPaymentSessionInit' }
    & Pick<EditOrderPaymentSessionInit, 'providerId' | 'paymentPayload' | 'paymentSessionId' | 'paymentAmount' | 'paymentSuccessUrl' | 'paymentCancelUrl' | 'transactionId'>
  ) }
);

export type OrdersQueryVariables = Exact<{
  tot: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  languageId: Scalars['String'];
}>;


export type OrdersQuery = (
  { __typename?: 'Query' }
  & { orders: (
    { __typename?: 'OrderResult' }
    & Pick<OrderResult, 'hasNextPage' | 'cursor'>
    & { orders: Array<(
      { __typename?: 'OrderInfo' }
      & Pick<OrderInfo, 'id' | 'uid' | 'type' | 'status' | 'createdAt' | 'totalPrice'>
      & { shippingAddress?: Maybe<(
        { __typename?: 'OrderShippingAddress' }
        & Pick<OrderShippingAddress, 'firstName' | 'lastName' | 'country' | 'countryCode' | 'provinceCode' | 'city' | 'address' | 'address2' | 'zipCode' | 'phone'>
      )> }
    )> }
  ) }
);

export type OrderQueryVariables = Exact<{
  id: Scalars['String'];
  languageId: Scalars['String'];
}>;


export type OrderQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'OrderDetails' }
    & OrderDetailsDataFragment
  )> }
);

export type PaymentInitializeMutationVariables = Exact<{
  input: InitializePaymentSessionInputType;
}>;


export type PaymentInitializeMutation = (
  { __typename?: 'Mutation' }
  & { paymentInitialize: (
    { __typename?: 'InitializePaymentSessionOutputType' }
    & Pick<InitializePaymentSessionOutputType, 'providerId' | 'paymentPayload' | 'transactionId' | 'paymentAmount'>
  ) }
);

export type PaymentVerifyMutationVariables = Exact<{
  input: PaymentVerifyInput;
}>;


export type PaymentVerifyMutation = (
  { __typename?: 'Mutation' }
  & { paymentVerify: (
    { __typename?: 'PaymentVerifyResult' }
    & Pick<PaymentVerifyResult, 'success'>
    & { data: (
      { __typename?: 'Checkout' }
      & CheckoutDataFragment
    ) }
  ) }
);

export type PaymentCheckMutationVariables = Exact<{
  input: PaymentCheckInput;
}>;


export type PaymentCheckMutation = (
  { __typename?: 'Mutation' }
  & { paymentCheck: (
    { __typename?: 'PaymentCheckResult' }
    & Pick<PaymentCheckResult, 'fulfilled'>
  ) }
);

export type DefaultCatalogProductVariantSearchDataFragment = (
  { __typename?: 'CatalogProductVariant' }
  & { info: (
    { __typename?: 'CatalogProductVariantInfo' }
    & Pick<CatalogProductVariantInfo, 'variantId'>
  ) }
);

export type CatalogProductSearchDataFragment = (
  { __typename?: 'CatalogProduct' }
  & { info: (
    { __typename?: 'CatalogProductInfo' }
    & Pick<CatalogProductInfo, 'productId' | 'productSlug'>
  ), contents: (
    { __typename?: 'CatalogProductContents' }
    & Pick<CatalogProductContents, 'name'>
    & { productImages?: Maybe<Array<(
      { __typename?: 'CatalogProductImage' }
      & Pick<CatalogProductImage, 'url' | 'asset' | 'title'>
      & { size?: Maybe<(
        { __typename?: 'ImageSize' }
        & Pick<ImageSize, 'width' | 'height' | 'aspectRatio'>
      )> }
    )>>, campaignImages?: Maybe<Array<(
      { __typename?: 'CatalogProductImage' }
      & Pick<CatalogProductImage, 'url' | 'asset' | 'title'>
      & { size?: Maybe<(
        { __typename?: 'ImageSize' }
        & Pick<ImageSize, 'width' | 'height' | 'aspectRatio'>
      )> }
    )>> }
  ), metadata: (
    { __typename?: 'CatalogProductMetadata' }
    & { tags?: Maybe<Array<(
      { __typename?: 'CatalogProductTag' }
      & Pick<CatalogProductTag, 'key' | 'code' | 'value' | 'name' | 'categoryName'>
    )>>, categoryPaths?: Maybe<Array<(
      { __typename?: 'CatalogProductCategoryPath' }
      & { path: Array<(
        { __typename?: 'CatalogProductCategory' }
        & Pick<CatalogProductCategory, 'code' | 'name' | 'level'>
      )> }
    )>>, brand?: Maybe<(
      { __typename?: 'CatalogProductBrand' }
      & Pick<CatalogProductBrand, 'name' | 'code'>
    )> }
  ) }
);

export type CatalogProductVariantSearchDataFragment = (
  { __typename?: 'CatalogProductVariant' }
  & { info: (
    { __typename?: 'CatalogProductVariantInfo' }
    & Pick<CatalogProductVariantInfo, 'variantId' | 'default' | 'slug' | 'validFrom' | 'validTo'>
  ), shopify?: Maybe<(
    { __typename?: 'CatalogProductVariantShopifyData' }
    & Pick<CatalogProductVariantShopifyData, 'shopifyId' | 'shopifyStorefrontId'>
  )>, details: (
    { __typename?: 'CatalogProductVariantDetails' }
    & { quantity?: Maybe<(
      { __typename?: 'CatalogProductSize' }
      & Pick<CatalogProductSize, 'value'>
    )>, color?: Maybe<(
      { __typename?: 'CatalogProductColor' }
      & Pick<CatalogProductColor, 'name'>
      & { value?: Maybe<(
        { __typename?: 'CatalogColorDefinition' }
        & Pick<CatalogColorDefinition, 'hex'>
      )>, texture?: Maybe<(
        { __typename?: 'CatalogProductTexture' }
        & Pick<CatalogProductTexture, 'asset'>
      )> }
    )> }
  ), contents: (
    { __typename?: 'CatalogProductVariantContents' }
    & Pick<CatalogProductVariantContents, 'name'>
    & { shortDescription?: Maybe<(
      { __typename?: 'StructuredText' }
      & Pick<StructuredText, 'html'>
    )>, longDescription?: Maybe<(
      { __typename?: 'StructuredText' }
      & Pick<StructuredText, 'html'>
    )>, highlights?: Maybe<(
      { __typename?: 'StructuredText' }
      & Pick<StructuredText, 'html'>
    )>, instructions?: Maybe<(
      { __typename?: 'StructuredText' }
      & Pick<StructuredText, 'html'>
    )>, composition?: Maybe<(
      { __typename?: 'StructuredText' }
      & Pick<StructuredText, 'html'>
    )>, fragrance?: Maybe<(
      { __typename?: 'StructuredText' }
      & Pick<StructuredText, 'html'>
    )>, fragranceFamily?: Maybe<(
      { __typename?: 'StructuredText' }
      & Pick<StructuredText, 'html'>
    )>, ingredients?: Maybe<(
      { __typename?: 'StructuredText' }
      & Pick<StructuredText, 'html'>
    )>, productImages?: Maybe<Array<(
      { __typename?: 'CatalogProductImage' }
      & Pick<CatalogProductImage, 'asset' | 'title'>
      & { size?: Maybe<(
        { __typename?: 'ImageSize' }
        & Pick<ImageSize, 'width' | 'height' | 'aspectRatio'>
      )> }
    )>> }
  ), metadata: (
    { __typename?: 'CatalogProductVariantMetadata' }
    & { tags?: Maybe<Array<(
      { __typename?: 'CatalogProductTag' }
      & Pick<CatalogProductTag, 'key' | 'code' | 'value' | 'name' | 'categoryName'>
    )>>, categoryPaths?: Maybe<Array<(
      { __typename?: 'CatalogProductCategoryPath' }
      & { path: Array<(
        { __typename?: 'CatalogProductCategory' }
        & Pick<CatalogProductCategory, 'code' | 'name' | 'level'>
      )> }
    )>>, brand?: Maybe<(
      { __typename?: 'CatalogProductBrand' }
      & Pick<CatalogProductBrand, 'name' | 'code'>
    )> }
  ), inventory: (
    { __typename?: 'CatalogInventoryInfo' }
    & Pick<CatalogInventoryInfo, 'price' | 'compareAtPrice' | 'discountPercent' | 'maxSellQuantity' | 'bestSeller' | 'sku'>
  ) }
);

export type CatalogProductExtraDataInfoFragment = (
  { __typename?: 'CatalogProductExtraData' }
  & Pick<CatalogProductExtraData, 'noPromo'>
  & { promotions: Array<(
    { __typename?: 'CatalogProductPromo' }
    & Pick<CatalogProductPromo, 'code' | 'logoUrl'>
  )> }
);

export type GetProductInventoryDataQueryVariables = Exact<{
  input: GetCommerceProductInputType;
}>;


export type GetProductInventoryDataQuery = (
  { __typename?: 'Query' }
  & { getCommerceProduct: (
    { __typename?: 'CatalogProductDocument' }
    & { product: (
      { __typename?: 'CatalogProduct' }
      & { info: (
        { __typename?: 'CatalogProductInfo' }
        & Pick<CatalogProductInfo, 'productId' | 'productSlug' | 'available'>
      ) }
    ), variants: Array<(
      { __typename?: 'CatalogProductVariant' }
      & { info: (
        { __typename?: 'CatalogProductVariantInfo' }
        & Pick<CatalogProductVariantInfo, 'variantId' | 'default' | 'available' | 'archived'>
      ), inventory: (
        { __typename?: 'CatalogInventoryInfo' }
        & Pick<CatalogInventoryInfo, 'price' | 'compareAtPrice' | 'discountPercent' | 'maxSellQuantity'>
      ) }
    )>, defaultVariant: (
      { __typename?: 'CatalogProductVariant' }
      & DefaultCatalogProductVariantSearchDataFragment
    ), extraData?: Maybe<(
      { __typename?: 'CatalogProductExtraData' }
      & CatalogProductExtraDataInfoFragment
    )> }
  ) }
);

export type GetCommerceProductQueryVariables = Exact<{
  input: GetCommerceProductInputType;
}>;


export type GetCommerceProductQuery = (
  { __typename?: 'Query' }
  & { getCommerceProduct: (
    { __typename?: 'CatalogProductDocument' }
    & { product: (
      { __typename?: 'CatalogProduct' }
      & CatalogProductSearchDataFragment
    ), variants: Array<(
      { __typename?: 'CatalogProductVariant' }
      & CatalogProductVariantSearchDataFragment
    )>, defaultVariant: (
      { __typename?: 'CatalogProductVariant' }
      & DefaultCatalogProductVariantSearchDataFragment
    ), extraData?: Maybe<(
      { __typename?: 'CatalogProductExtraData' }
      & CatalogProductExtraDataInfoFragment
    )> }
  ) }
);

export type GetCommerceProductByVariantIdQueryVariables = Exact<{
  input: GetCommerceProductByVariantIdInputType;
}>;


export type GetCommerceProductByVariantIdQuery = (
  { __typename?: 'Query' }
  & { getCommerceProductByVariantId: (
    { __typename?: 'CatalogProductDocument' }
    & { product: (
      { __typename?: 'CatalogProduct' }
      & CatalogProductSearchDataFragment
    ), variants: Array<(
      { __typename?: 'CatalogProductVariant' }
      & CatalogProductVariantSearchDataFragment
    )>, defaultVariant: (
      { __typename?: 'CatalogProductVariant' }
      & DefaultCatalogProductVariantSearchDataFragment
    ), extraData?: Maybe<(
      { __typename?: 'CatalogProductExtraData' }
      & CatalogProductExtraDataInfoFragment
    )> }
  ) }
);

export type GetCommerceProductsQueryVariables = Exact<{
  input: GetCommerceProductsInputType;
}>;


export type GetCommerceProductsQuery = (
  { __typename?: 'Query' }
  & { getCommerceProducts: Array<(
    { __typename?: 'CatalogProductDocument' }
    & { product: (
      { __typename?: 'CatalogProduct' }
      & CatalogProductSearchDataFragment
    ), variants: Array<(
      { __typename?: 'CatalogProductVariant' }
      & CatalogProductVariantSearchDataFragment
    )>, defaultVariant: (
      { __typename?: 'CatalogProductVariant' }
      & DefaultCatalogProductVariantSearchDataFragment
    ), extraData?: Maybe<(
      { __typename?: 'CatalogProductExtraData' }
      & CatalogProductExtraDataInfoFragment
    )> }
  )> }
);

export type GetCommerceVariantQueryVariables = Exact<{
  input: GetCommerceVariantInputType;
}>;


export type GetCommerceVariantQuery = (
  { __typename?: 'Query' }
  & { getCommerceVariant: (
    { __typename?: 'CatalogProductVariant' }
    & CatalogProductVariantSearchDataFragment
  ) }
);

export type GetCommerceVariantsQueryVariables = Exact<{
  input: GetCommerceVariantsInputType;
}>;


export type GetCommerceVariantsQuery = (
  { __typename?: 'Query' }
  & { getCommerceVariants: Array<(
    { __typename?: 'CatalogProductVariant' }
    & CatalogProductVariantSearchDataFragment
  )> }
);

export type TimedVoucherActivateMutationVariables = Exact<{
  input: ActivateTimedVoucherInput;
}>;


export type TimedVoucherActivateMutation = (
  { __typename?: 'Mutation' }
  & { timedVoucherActivate: (
    { __typename?: 'TimedVoucher' }
    & Pick<TimedVoucher, 'code' | 'expirationDate' | 'valid'>
  ) }
);

export type TimedVoucherQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type TimedVoucherQuery = (
  { __typename?: 'Query' }
  & { timedVoucher?: Maybe<(
    { __typename?: 'TimedVoucher' }
    & Pick<TimedVoucher, 'code' | 'expirationDate' | 'valid'>
  )> }
);

export type CreateReturnMutationVariables = Exact<{
  input: ReturnInputType;
}>;


export type CreateReturnMutation = (
  { __typename?: 'Mutation' }
  & { createReturn: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type FullTextSearchQueryVariables = Exact<{
  query: Scalars['String'];
  options: FullTextSearchOptions;
}>;


export type FullTextSearchQuery = (
  { __typename?: 'Query' }
  & { fullTextSearch: (
    { __typename?: 'FullTextSearchResults' }
    & { products: Array<(
      { __typename?: 'CatalogProductDocument' }
      & { product: (
        { __typename?: 'CatalogProduct' }
        & CatalogProductSearchDataFragment
      ), variants: Array<(
        { __typename?: 'CatalogProductVariant' }
        & CatalogProductVariantSearchDataFragment
      )>, defaultVariant: (
        { __typename?: 'CatalogProductVariant' }
        & DefaultCatalogProductVariantSearchDataFragment
      ), extraData?: Maybe<(
        { __typename?: 'CatalogProductExtraData' }
        & CatalogProductExtraDataInfoFragment
      )> }
    )>, brands: Array<(
      { __typename?: 'BrandItem' }
      & Pick<BrandItem, 'code' | 'name' | 'slug'>
    )>, categories: Array<(
      { __typename?: 'CategoryItem' }
      & Pick<CategoryItem, 'code' | 'name' | 'slug'>
    )> }
  ) }
);

export type StoresV2QueryVariables = Exact<{
  input: GetStoresInput;
}>;


export type StoresV2Query = (
  { __typename?: 'Query' }
  & { storesV2: Array<(
    { __typename?: 'StoreDocumentV2' }
    & Pick<StoreDocumentV2, 'id' | 'company' | 'name'>
    & { address: (
      { __typename?: 'StoreAddress' }
      & Pick<StoreAddress, 'street' | 'cap' | 'city' | 'province'>
    ), coordinates?: Maybe<(
      { __typename?: 'Coordinates' }
      & Pick<Coordinates, 'latitude' | 'longitude'>
    )>, contacts: (
      { __typename?: 'StoreContacts' }
      & Pick<StoreContacts, 'phone'>
    ), businessHours?: Maybe<Array<(
      { __typename?: 'StoreBusinessHour' }
      & Pick<StoreBusinessHour, 'day' | 'hours'>
    )>>, services?: Maybe<Array<(
      { __typename?: 'StoreService' }
      & Pick<StoreService, 'name'>
    )>>, serviceReservationCalendar?: Maybe<(
      { __typename?: 'StoreCalendar' }
      & Pick<StoreCalendar, 'url'>
    )>, storeVisitCalendar?: Maybe<(
      { __typename?: 'StoreCalendar' }
      & Pick<StoreCalendar, 'url'>
    )> }
  )> }
);

export type TrackEventMutationVariables = Exact<{
  input: TrackEventInput;
}>;


export type TrackEventMutation = (
  { __typename?: 'Mutation' }
  & { trackEvent: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type WishlistDataFragment = (
  { __typename?: 'Wishlist' }
  & Pick<Wishlist, 'id'>
  & { items: Array<(
    { __typename?: 'WishlistItem' }
    & Pick<WishlistItem, 'variantId'>
  )> }
);

export type WishlistItemAddMutationVariables = Exact<{
  input: AddWishlistItemInput;
}>;


export type WishlistItemAddMutation = (
  { __typename?: 'Mutation' }
  & { wishlistItemAdd: (
    { __typename?: 'Wishlist' }
    & WishlistDataFragment
  ) }
);

export type WishlistItemRemoveMutationVariables = Exact<{
  input: RemoveWishlistItemInput;
}>;


export type WishlistItemRemoveMutation = (
  { __typename?: 'Mutation' }
  & { wishlistItemRemove: (
    { __typename?: 'Wishlist' }
    & WishlistDataFragment
  ) }
);

export type WishlistQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type WishlistQuery = (
  { __typename?: 'Query' }
  & { wishlist?: Maybe<(
    { __typename?: 'Wishlist' }
    & WishlistDataFragment
  )> }
);

export type WishlistAvailabilityQueryVariables = Exact<{
  input: WishlistAvailabilityInput;
}>;


export type WishlistAvailabilityQuery = (
  { __typename?: 'Query' }
  & { wishlistAvailability: (
    { __typename?: 'WishlistAvailability' }
    & Pick<WishlistAvailability, 'id' | 'languageId'>
    & { items: Array<(
      { __typename?: 'WishlistAvailabilityItem' }
      & Pick<WishlistAvailabilityItem, 'variantId'>
      & { item: (
        { __typename?: 'CatalogProductDocument' }
        & { product: (
          { __typename?: 'CatalogProduct' }
          & CatalogProductSearchDataFragment
        ), variants: Array<(
          { __typename?: 'CatalogProductVariant' }
          & CatalogProductVariantSearchDataFragment
        )>, defaultVariant: (
          { __typename?: 'CatalogProductVariant' }
          & DefaultCatalogProductVariantSearchDataFragment
        ), extraData?: Maybe<(
          { __typename?: 'CatalogProductExtraData' }
          & CatalogProductExtraDataInfoFragment
        )> }
      ) }
    )> }
  ) }
);

export const CalendarEventDataFragmentDoc = gql`
    fragment CalendarEventData on CalendarEvent {
  definition {
    id
    alias
    enabled
    locationName
    eventName
    eventDescription
    defaultEventDurationMin
    blockPublicEmails
    phoneRequired
    extraQuestions
    options {
      preSelection {
        fixed
        preselectedDate
      }
    }
  }
  availability {
    pages {
      year
      month
      days {
        year
        month
        day
        date
        slots {
          from {
            hour
            minute
          }
          to {
            hour
            minute
          }
          maxQuantity
        }
      }
    }
  }
}
    `;
export const CatalogProductVariantSearchDataFragmentDoc = gql`
    fragment CatalogProductVariantSearchData on CatalogProductVariant {
  info {
    variantId
    default
    slug
    validFrom
    validTo
  }
  shopify {
    shopifyId
    shopifyStorefrontId
  }
  details {
    quantity {
      value
    }
    color {
      name
      value {
        hex
      }
      texture {
        asset
      }
    }
  }
  contents {
    name
    shortDescription {
      html
    }
    longDescription {
      html
    }
    highlights {
      html
    }
    instructions {
      html
    }
    composition {
      html
    }
    fragrance {
      html
    }
    fragranceFamily {
      html
    }
    ingredients {
      html
    }
    productImages {
      asset
      title
      size {
        width
        height
        aspectRatio
      }
    }
  }
  metadata {
    tags {
      key
      code
      value
      name
      categoryName
    }
    categoryPaths {
      path {
        code
        name
        level
      }
    }
    brand {
      name
      code
    }
  }
  inventory {
    price
    compareAtPrice
    discountPercent
    maxSellQuantity
    bestSeller
    sku
  }
}
    `;
export const CatalogProductSearchDataFragmentDoc = gql`
    fragment CatalogProductSearchData on CatalogProduct {
  info {
    productId
    productSlug
  }
  contents {
    name
    productImages {
      url
      asset
      title
      size {
        width
        height
        aspectRatio
      }
    }
    campaignImages {
      url
      asset
      title
      size {
        width
        height
        aspectRatio
      }
    }
  }
  metadata {
    tags {
      key
      code
      value
      name
      categoryName
    }
    categoryPaths {
      path {
        code
        name
        level
      }
    }
    brand {
      name
      code
    }
  }
}
    `;
export const DefaultCatalogProductVariantSearchDataFragmentDoc = gql`
    fragment DefaultCatalogProductVariantSearchData on CatalogProductVariant {
  info {
    variantId
  }
}
    `;
export const CatalogProductExtraDataInfoFragmentDoc = gql`
    fragment CatalogProductExtraDataInfo on CatalogProductExtraData {
  promotions {
    code
    logoUrl
  }
  noPromo
}
    `;
export const CheckoutDataFragmentDoc = gql`
    fragment CheckoutData on Checkout {
  id
  status
  selectedPayment {
    providerId
    captureType
  }
  price {
    currierPaymentPrice
    shippingPrice
    shippingDiscount
    defaultFreeShippingThreshold
    defaultShippingPrice
    hasFreeShippingPromo
    subtotal
    discountTotal
    total
    totalToPay
    totalToPayLater
    appliedGiftcards {
      code
      totalAmount
      usedAmount
    }
    currency
  }
  promotions {
    id
    code
    trigger
    amount
  }
  basketDiscounts {
    totalDiscount
    trigger
    id
    code
    name
    item {
      code
      name
      contents {
        conditions
        description
        logoUrl
      }
    }
  }
  promotionsExtra {
    freeProductPromoThresholds {
      promoCode
      promoId
      minOrderSubtotal
      amountLeft
      products {
        eanCodes
      }
    }
    availableFreeProducts {
      code
      promoId
      products {
        eanCodes
      }
    }
    availableProductPromoDiscounts {
      eanCodes
      maxItems
      amount {
        value
        type
      }
    }
  }
  unavailableLineItems {
    variant {
      ...CatalogProductVariantSearchData
    }
  }
  giftLineItems {
    id
    quantity
    source
    product {
      document {
        product {
          ...CatalogProductSearchData
        }
        variants {
          ...CatalogProductVariantSearchData
        }
        defaultVariant {
          ...DefaultCatalogProductVariantSearchData
        }
        extraData {
          ...CatalogProductExtraDataInfo
        }
      }
      variant {
        ...CatalogProductVariantSearchData
      }
    }
  }
  lineItems {
    id
    quantity
    isGift
    originalUnitPrice
    originalTotal
    discountedUnitPrice
    discountedTotal
    originalUnitCompareAtPrice
    originalTotalCompareAtPrice
    discountPercent
    extraDiscountPercent
    assignedGiftCardCode
    source
    promoCodes
    promotions {
      code
      name
      item {
        code
        name
        contents {
          conditions
          description
          logoUrl
        }
      }
    }
    product {
      document {
        product {
          ...CatalogProductSearchData
        }
        variants {
          ...CatalogProductVariantSearchData
        }
        defaultVariant {
          ...DefaultCatalogProductVariantSearchData
        }
        extraData {
          ...CatalogProductExtraDataInfo
        }
      }
      variant {
        ...CatalogProductVariantSearchData
      }
    }
  }
  promoState {
    hasLoggedPromoInBasket
    acceptedNoLoggedPromo
    authenticatedBasket
  }
  samples {
    samples {
      id
      name
    }
    notes
  }
  shipping {
    preference
    store {
      id
      name
      company
      address {
        street
        cap
        city
        province
      }
    }
    shippingAddress {
      customerAddressId
      firstName
      lastName
      countryCode
      provinceCode
      city
      address
      address2
      notes
      company
      vatNumber
      zipCode
      phone
    }
    billingAddress {
      customerAddressId
      firstName
      lastName
      countryCode
      provinceCode
      city
      address
      address2
      notes
      company
      vatNumber
      zipCode
      phone
    }
  }
  customer {
    email
    acceptsMarketing
  }
  order {
    id
    uid
    orderDate
  }
  settings {
    clickAndCollectEnabled
    giftcardEnabled
    giftBoxEnabled
    enabledPaymentMethods
  }
  customerDiscounts {
    birthdayDiscount
    passagesDiscount
    pointDiscounts {
      points
      discount
    }
    promoCode
  }
  lock {
    locked
    lockTime
    lockExpiration
  }
}
    ${CatalogProductVariantSearchDataFragmentDoc}
${CatalogProductSearchDataFragmentDoc}
${DefaultCatalogProductVariantSearchDataFragmentDoc}
${CatalogProductExtraDataInfoFragmentDoc}`;
export const CustomerDataFragmentDoc = gql`
    fragment CustomerData on Customer {
  account {
    email
    emailVerified
  }
  userProfile {
    name
    surname
    birthDay
    gender
    taxCode
    phone
    country
    state
    city
    address
    zipCode
  }
  marketingProfile {
    subscribedToNewsletter
    marketingConsent
    profilingConsent
    surveyConsent
    cardId
    cardBalance
    oldCustomer
    hasOldCard
    newTermsAccepted
  }
  addresses {
    id
    shippingDefault
    invoiceDefault
    firstName
    lastName
    country
    countryCode
    province
    provinceCode
    city
    address
    address2
    zipCode
    phone
    phone2
    notes
    company
    vatNumber
    type
  }
}
    `;
export const OrderDetailsDataFragmentDoc = gql`
    fragment OrderDetailsData on OrderDetails {
  id
  uid
  type
  alias
  status
  createdAt
  deliveredDate
  shippedDate
  editOrder {
    pendingPaymentUrl
    pendingPaymentAmount
  }
  price {
    shippingPrice
    subtotal
    discountTotal
    total
    totalPaid
    giftcards {
      code
      amountUsed
    }
    currency
  }
  basketDiscounts {
    totalDiscount
    trigger
    code
    name
    item {
      code
      name
      contents {
        conditions
        description
        logoUrl
      }
    }
  }
  tracking {
    url
  }
  shipping {
    shippingAddress {
      firstName
      lastName
      country
      countryCode
      provinceCode
      city
      address
      address2
      zipCode
      phone
    }
    billingAddress {
      firstName
      lastName
      country
      countryCode
      provinceCode
      city
      address
      address2
      zipCode
      phone
    }
    store {
      id
      name
      company
      address {
        street
        cap
        city
        province
      }
      coordinates {
        latitude
        longitude
      }
      contacts {
        phone
      }
    }
    preference
  }
  customer {
    email
    acceptsMarketing
  }
  giftLineItems {
    id
    quantity
    originalUnitPrice
    originalTotal
    discountedUnitPrice
    discountedTotal
    originalTotalCompareAtPrice
    originalUnitCompareAtPrice
    isGift
    product {
      document {
        product {
          ...CatalogProductSearchData
        }
        variants {
          ...CatalogProductVariantSearchData
        }
        defaultVariant {
          ...DefaultCatalogProductVariantSearchData
        }
      }
      variant {
        ...CatalogProductVariantSearchData
      }
    }
  }
  lineItems {
    id
    quantity
    originalUnitPrice
    originalTotal
    discountedUnitPrice
    discountedTotal
    originalTotalCompareAtPrice
    originalUnitCompareAtPrice
    assignedGiftCardCode
    isGift
    product {
      document {
        product {
          ...CatalogProductSearchData
        }
        variants {
          ...CatalogProductVariantSearchData
        }
        defaultVariant {
          ...DefaultCatalogProductVariantSearchData
        }
      }
      variant {
        ...CatalogProductVariantSearchData
      }
    }
  }
}
    ${CatalogProductSearchDataFragmentDoc}
${CatalogProductVariantSearchDataFragmentDoc}
${DefaultCatalogProductVariantSearchDataFragmentDoc}`;
export const WishlistDataFragmentDoc = gql`
    fragment WishlistData on Wishlist {
  id
  items {
    variantId
  }
}
    `;
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    success
    errorType
    accessToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegisterDocument = gql`
    mutation register($input: RegistrationInput!) {
  register(input: $input) {
    success
    errorType
    accessToken
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, baseOptions);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const VerifyDocument = gql`
    mutation verify($input: VerificationInput!) {
  verify(input: $input) {
    success
    errorType
    accessToken
  }
}
    `;
export type VerifyMutationFn = Apollo.MutationFunction<VerifyMutation, VerifyMutationVariables>;

/**
 * __useVerifyMutation__
 *
 * To run a mutation, you first call `useVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMutation, { data, loading, error }] = useVerifyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyMutation(baseOptions?: Apollo.MutationHookOptions<VerifyMutation, VerifyMutationVariables>) {
        return Apollo.useMutation<VerifyMutation, VerifyMutationVariables>(VerifyDocument, baseOptions);
      }
export type VerifyMutationHookResult = ReturnType<typeof useVerifyMutation>;
export type VerifyMutationResult = Apollo.MutationResult<VerifyMutation>;
export type VerifyMutationOptions = Apollo.BaseMutationOptions<VerifyMutation, VerifyMutationVariables>;
export const CheckOldUserExistsDocument = gql`
    mutation checkOldUserExists($input: CheckOldUserExistsInput!) {
  checkOldUserExists(input: $input) {
    success
    enum
  }
}
    `;
export type CheckOldUserExistsMutationFn = Apollo.MutationFunction<CheckOldUserExistsMutation, CheckOldUserExistsMutationVariables>;

/**
 * __useCheckOldUserExistsMutation__
 *
 * To run a mutation, you first call `useCheckOldUserExistsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckOldUserExistsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkOldUserExistsMutation, { data, loading, error }] = useCheckOldUserExistsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckOldUserExistsMutation(baseOptions?: Apollo.MutationHookOptions<CheckOldUserExistsMutation, CheckOldUserExistsMutationVariables>) {
        return Apollo.useMutation<CheckOldUserExistsMutation, CheckOldUserExistsMutationVariables>(CheckOldUserExistsDocument, baseOptions);
      }
export type CheckOldUserExistsMutationHookResult = ReturnType<typeof useCheckOldUserExistsMutation>;
export type CheckOldUserExistsMutationResult = Apollo.MutationResult<CheckOldUserExistsMutation>;
export type CheckOldUserExistsMutationOptions = Apollo.BaseMutationOptions<CheckOldUserExistsMutation, CheckOldUserExistsMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    success
    errorType
    accessToken
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SendResetPasswordDocument = gql`
    mutation sendResetPassword($input: SendResetPasswordInput!) {
  sendResetPassword(input: $input) {
    success
    errorType
  }
}
    `;
export type SendResetPasswordMutationFn = Apollo.MutationFunction<SendResetPasswordMutation, SendResetPasswordMutationVariables>;

/**
 * __useSendResetPasswordMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordMutation, { data, loading, error }] = useSendResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SendResetPasswordMutation, SendResetPasswordMutationVariables>) {
        return Apollo.useMutation<SendResetPasswordMutation, SendResetPasswordMutationVariables>(SendResetPasswordDocument, baseOptions);
      }
export type SendResetPasswordMutationHookResult = ReturnType<typeof useSendResetPasswordMutation>;
export type SendResetPasswordMutationResult = Apollo.MutationResult<SendResetPasswordMutation>;
export type SendResetPasswordMutationOptions = Apollo.BaseMutationOptions<SendResetPasswordMutation, SendResetPasswordMutationVariables>;
export const CancelCalendarEventDocument = gql`
    mutation cancelCalendarEvent($input: EventCancellationInput!) {
  cancelCalendarEvent(input: $input) {
    success
    error
  }
}
    `;
export type CancelCalendarEventMutationFn = Apollo.MutationFunction<CancelCalendarEventMutation, CancelCalendarEventMutationVariables>;

/**
 * __useCancelCalendarEventMutation__
 *
 * To run a mutation, you first call `useCancelCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCalendarEventMutation, { data, loading, error }] = useCancelCalendarEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCalendarEventMutation(baseOptions?: Apollo.MutationHookOptions<CancelCalendarEventMutation, CancelCalendarEventMutationVariables>) {
        return Apollo.useMutation<CancelCalendarEventMutation, CancelCalendarEventMutationVariables>(CancelCalendarEventDocument, baseOptions);
      }
export type CancelCalendarEventMutationHookResult = ReturnType<typeof useCancelCalendarEventMutation>;
export type CancelCalendarEventMutationResult = Apollo.MutationResult<CancelCalendarEventMutation>;
export type CancelCalendarEventMutationOptions = Apollo.BaseMutationOptions<CancelCalendarEventMutation, CancelCalendarEventMutationVariables>;
export const SubmitCalendarEventDocument = gql`
    mutation submitCalendarEvent($input: EventRegistrationInput!) {
  submitCalendarEvent(input: $input) {
    success
    error
    event {
      calendarId
      date {
        year
        month
        day
      }
      from {
        hour
        minute
      }
      to {
        hour
        minute
      }
      data
    }
  }
}
    `;
export type SubmitCalendarEventMutationFn = Apollo.MutationFunction<SubmitCalendarEventMutation, SubmitCalendarEventMutationVariables>;

/**
 * __useSubmitCalendarEventMutation__
 *
 * To run a mutation, you first call `useSubmitCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitCalendarEventMutation, { data, loading, error }] = useSubmitCalendarEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitCalendarEventMutation(baseOptions?: Apollo.MutationHookOptions<SubmitCalendarEventMutation, SubmitCalendarEventMutationVariables>) {
        return Apollo.useMutation<SubmitCalendarEventMutation, SubmitCalendarEventMutationVariables>(SubmitCalendarEventDocument, baseOptions);
      }
export type SubmitCalendarEventMutationHookResult = ReturnType<typeof useSubmitCalendarEventMutation>;
export type SubmitCalendarEventMutationResult = Apollo.MutationResult<SubmitCalendarEventMutation>;
export type SubmitCalendarEventMutationOptions = Apollo.BaseMutationOptions<SubmitCalendarEventMutation, SubmitCalendarEventMutationVariables>;
export const CalendarEventDocument = gql`
    query calendarEvent($alias: String!) {
  calendarEvent(alias: $alias) {
    ...CalendarEventData
  }
}
    ${CalendarEventDataFragmentDoc}`;

/**
 * __useCalendarEventQuery__
 *
 * To run a query within a React component, call `useCalendarEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventQuery({
 *   variables: {
 *      alias: // value for 'alias'
 *   },
 * });
 */
export function useCalendarEventQuery(baseOptions: Apollo.QueryHookOptions<CalendarEventQuery, CalendarEventQueryVariables>) {
        return Apollo.useQuery<CalendarEventQuery, CalendarEventQueryVariables>(CalendarEventDocument, baseOptions);
      }
export function useCalendarEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarEventQuery, CalendarEventQueryVariables>) {
          return Apollo.useLazyQuery<CalendarEventQuery, CalendarEventQueryVariables>(CalendarEventDocument, baseOptions);
        }
export type CalendarEventQueryHookResult = ReturnType<typeof useCalendarEventQuery>;
export type CalendarEventLazyQueryHookResult = ReturnType<typeof useCalendarEventLazyQuery>;
export type CalendarEventQueryResult = Apollo.QueryResult<CalendarEventQuery, CalendarEventQueryVariables>;
export const GiftcardCheckoutCreateDocument = gql`
    mutation giftcardCheckoutCreate($input: GiftcardCheckoutInput!, $languageId: String!) {
  giftcardCheckoutCreate(input: $input, languageId: $languageId) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type GiftcardCheckoutCreateMutationFn = Apollo.MutationFunction<GiftcardCheckoutCreateMutation, GiftcardCheckoutCreateMutationVariables>;

/**
 * __useGiftcardCheckoutCreateMutation__
 *
 * To run a mutation, you first call `useGiftcardCheckoutCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiftcardCheckoutCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giftcardCheckoutCreateMutation, { data, loading, error }] = useGiftcardCheckoutCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useGiftcardCheckoutCreateMutation(baseOptions?: Apollo.MutationHookOptions<GiftcardCheckoutCreateMutation, GiftcardCheckoutCreateMutationVariables>) {
        return Apollo.useMutation<GiftcardCheckoutCreateMutation, GiftcardCheckoutCreateMutationVariables>(GiftcardCheckoutCreateDocument, baseOptions);
      }
export type GiftcardCheckoutCreateMutationHookResult = ReturnType<typeof useGiftcardCheckoutCreateMutation>;
export type GiftcardCheckoutCreateMutationResult = Apollo.MutationResult<GiftcardCheckoutCreateMutation>;
export type GiftcardCheckoutCreateMutationOptions = Apollo.BaseMutationOptions<GiftcardCheckoutCreateMutation, GiftcardCheckoutCreateMutationVariables>;
export const CheckoutCreateDocument = gql`
    mutation checkoutCreate($input: CheckoutInput!, $languageId: String!) {
  checkoutCreate(input: $input, languageId: $languageId) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutCreateMutationFn = Apollo.MutationFunction<CheckoutCreateMutation, CheckoutCreateMutationVariables>;

/**
 * __useCheckoutCreateMutation__
 *
 * To run a mutation, you first call `useCheckoutCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutCreateMutation, { data, loading, error }] = useCheckoutCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useCheckoutCreateMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutCreateMutation, CheckoutCreateMutationVariables>) {
        return Apollo.useMutation<CheckoutCreateMutation, CheckoutCreateMutationVariables>(CheckoutCreateDocument, baseOptions);
      }
export type CheckoutCreateMutationHookResult = ReturnType<typeof useCheckoutCreateMutation>;
export type CheckoutCreateMutationResult = Apollo.MutationResult<CheckoutCreateMutation>;
export type CheckoutCreateMutationOptions = Apollo.BaseMutationOptions<CheckoutCreateMutation, CheckoutCreateMutationVariables>;
export const CheckoutUpdateDocument = gql`
    mutation checkoutUpdate($id: String!, $input: CheckoutInput!, $languageId: String!) {
  checkoutUpdate(id: $id, input: $input, languageId: $languageId) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutUpdateMutationFn = Apollo.MutationFunction<CheckoutUpdateMutation, CheckoutUpdateMutationVariables>;

/**
 * __useCheckoutUpdateMutation__
 *
 * To run a mutation, you first call `useCheckoutUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutUpdateMutation, { data, loading, error }] = useCheckoutUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useCheckoutUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutUpdateMutation, CheckoutUpdateMutationVariables>) {
        return Apollo.useMutation<CheckoutUpdateMutation, CheckoutUpdateMutationVariables>(CheckoutUpdateDocument, baseOptions);
      }
export type CheckoutUpdateMutationHookResult = ReturnType<typeof useCheckoutUpdateMutation>;
export type CheckoutUpdateMutationResult = Apollo.MutationResult<CheckoutUpdateMutation>;
export type CheckoutUpdateMutationOptions = Apollo.BaseMutationOptions<CheckoutUpdateMutation, CheckoutUpdateMutationVariables>;
export const CheckoutLockDocument = gql`
    mutation checkoutLock($id: String!, $languageId: String!) {
  checkoutLock(id: $id, languageId: $languageId) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutLockMutationFn = Apollo.MutationFunction<CheckoutLockMutation, CheckoutLockMutationVariables>;

/**
 * __useCheckoutLockMutation__
 *
 * To run a mutation, you first call `useCheckoutLockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutLockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutLockMutation, { data, loading, error }] = useCheckoutLockMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useCheckoutLockMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutLockMutation, CheckoutLockMutationVariables>) {
        return Apollo.useMutation<CheckoutLockMutation, CheckoutLockMutationVariables>(CheckoutLockDocument, baseOptions);
      }
export type CheckoutLockMutationHookResult = ReturnType<typeof useCheckoutLockMutation>;
export type CheckoutLockMutationResult = Apollo.MutationResult<CheckoutLockMutation>;
export type CheckoutLockMutationOptions = Apollo.BaseMutationOptions<CheckoutLockMutation, CheckoutLockMutationVariables>;
export const CheckoutApplyPromoCodeDocument = gql`
    mutation checkoutApplyPromoCode($id: String!, $promoCode: String!, $languageId: String!, $email: String) {
  checkoutApplyPromoCode(
    id: $id
    promoCode: $promoCode
    languageId: $languageId
    email: $email
  ) {
    success
    error
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutApplyPromoCodeMutationFn = Apollo.MutationFunction<CheckoutApplyPromoCodeMutation, CheckoutApplyPromoCodeMutationVariables>;

/**
 * __useCheckoutApplyPromoCodeMutation__
 *
 * To run a mutation, you first call `useCheckoutApplyPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutApplyPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutApplyPromoCodeMutation, { data, loading, error }] = useCheckoutApplyPromoCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      promoCode: // value for 'promoCode'
 *      languageId: // value for 'languageId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckoutApplyPromoCodeMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutApplyPromoCodeMutation, CheckoutApplyPromoCodeMutationVariables>) {
        return Apollo.useMutation<CheckoutApplyPromoCodeMutation, CheckoutApplyPromoCodeMutationVariables>(CheckoutApplyPromoCodeDocument, baseOptions);
      }
export type CheckoutApplyPromoCodeMutationHookResult = ReturnType<typeof useCheckoutApplyPromoCodeMutation>;
export type CheckoutApplyPromoCodeMutationResult = Apollo.MutationResult<CheckoutApplyPromoCodeMutation>;
export type CheckoutApplyPromoCodeMutationOptions = Apollo.BaseMutationOptions<CheckoutApplyPromoCodeMutation, CheckoutApplyPromoCodeMutationVariables>;
export const CheckoutRemovePromoCodeDocument = gql`
    mutation checkoutRemovePromoCode($id: String!, $promoCode: String!, $languageId: String!) {
  checkoutRemovePromoCode(id: $id, promoCode: $promoCode, languageId: $languageId) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutRemovePromoCodeMutationFn = Apollo.MutationFunction<CheckoutRemovePromoCodeMutation, CheckoutRemovePromoCodeMutationVariables>;

/**
 * __useCheckoutRemovePromoCodeMutation__
 *
 * To run a mutation, you first call `useCheckoutRemovePromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutRemovePromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutRemovePromoCodeMutation, { data, loading, error }] = useCheckoutRemovePromoCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      promoCode: // value for 'promoCode'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useCheckoutRemovePromoCodeMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutRemovePromoCodeMutation, CheckoutRemovePromoCodeMutationVariables>) {
        return Apollo.useMutation<CheckoutRemovePromoCodeMutation, CheckoutRemovePromoCodeMutationVariables>(CheckoutRemovePromoCodeDocument, baseOptions);
      }
export type CheckoutRemovePromoCodeMutationHookResult = ReturnType<typeof useCheckoutRemovePromoCodeMutation>;
export type CheckoutRemovePromoCodeMutationResult = Apollo.MutationResult<CheckoutRemovePromoCodeMutation>;
export type CheckoutRemovePromoCodeMutationOptions = Apollo.BaseMutationOptions<CheckoutRemovePromoCodeMutation, CheckoutRemovePromoCodeMutationVariables>;
export const CheckoutSetShippingDocument = gql`
    mutation checkoutSetShipping($id: String!, $languageId: String!, $shipping: CheckoutShippingInput!, $samples: CheckoutSamplesInput, $contact: CheckoutContactInput) {
  checkoutSetShipping(
    shipping: $shipping
    samples: $samples
    languageId: $languageId
    id: $id
    contact: $contact
  ) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutSetShippingMutationFn = Apollo.MutationFunction<CheckoutSetShippingMutation, CheckoutSetShippingMutationVariables>;

/**
 * __useCheckoutSetShippingMutation__
 *
 * To run a mutation, you first call `useCheckoutSetShippingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutSetShippingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutSetShippingMutation, { data, loading, error }] = useCheckoutSetShippingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *      shipping: // value for 'shipping'
 *      samples: // value for 'samples'
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useCheckoutSetShippingMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutSetShippingMutation, CheckoutSetShippingMutationVariables>) {
        return Apollo.useMutation<CheckoutSetShippingMutation, CheckoutSetShippingMutationVariables>(CheckoutSetShippingDocument, baseOptions);
      }
export type CheckoutSetShippingMutationHookResult = ReturnType<typeof useCheckoutSetShippingMutation>;
export type CheckoutSetShippingMutationResult = Apollo.MutationResult<CheckoutSetShippingMutation>;
export type CheckoutSetShippingMutationOptions = Apollo.BaseMutationOptions<CheckoutSetShippingMutation, CheckoutSetShippingMutationVariables>;
export const CheckoutSetSamplesDocument = gql`
    mutation checkoutSetSamples($id: String!, $languageId: String!, $samples: CheckoutSamplesInput!) {
  checkoutSetSamples(samples: $samples, languageId: $languageId, id: $id) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutSetSamplesMutationFn = Apollo.MutationFunction<CheckoutSetSamplesMutation, CheckoutSetSamplesMutationVariables>;

/**
 * __useCheckoutSetSamplesMutation__
 *
 * To run a mutation, you first call `useCheckoutSetSamplesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutSetSamplesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutSetSamplesMutation, { data, loading, error }] = useCheckoutSetSamplesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *      samples: // value for 'samples'
 *   },
 * });
 */
export function useCheckoutSetSamplesMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutSetSamplesMutation, CheckoutSetSamplesMutationVariables>) {
        return Apollo.useMutation<CheckoutSetSamplesMutation, CheckoutSetSamplesMutationVariables>(CheckoutSetSamplesDocument, baseOptions);
      }
export type CheckoutSetSamplesMutationHookResult = ReturnType<typeof useCheckoutSetSamplesMutation>;
export type CheckoutSetSamplesMutationResult = Apollo.MutationResult<CheckoutSetSamplesMutation>;
export type CheckoutSetSamplesMutationOptions = Apollo.BaseMutationOptions<CheckoutSetSamplesMutation, CheckoutSetSamplesMutationVariables>;
export const CheckoutApplyBirthdayDiscountDocument = gql`
    mutation checkoutApplyBirthdayDiscount($id: String!, $languageId: String!) {
  checkoutApplyBirthdayDiscount(languageId: $languageId, id: $id) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutApplyBirthdayDiscountMutationFn = Apollo.MutationFunction<CheckoutApplyBirthdayDiscountMutation, CheckoutApplyBirthdayDiscountMutationVariables>;

/**
 * __useCheckoutApplyBirthdayDiscountMutation__
 *
 * To run a mutation, you first call `useCheckoutApplyBirthdayDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutApplyBirthdayDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutApplyBirthdayDiscountMutation, { data, loading, error }] = useCheckoutApplyBirthdayDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useCheckoutApplyBirthdayDiscountMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutApplyBirthdayDiscountMutation, CheckoutApplyBirthdayDiscountMutationVariables>) {
        return Apollo.useMutation<CheckoutApplyBirthdayDiscountMutation, CheckoutApplyBirthdayDiscountMutationVariables>(CheckoutApplyBirthdayDiscountDocument, baseOptions);
      }
export type CheckoutApplyBirthdayDiscountMutationHookResult = ReturnType<typeof useCheckoutApplyBirthdayDiscountMutation>;
export type CheckoutApplyBirthdayDiscountMutationResult = Apollo.MutationResult<CheckoutApplyBirthdayDiscountMutation>;
export type CheckoutApplyBirthdayDiscountMutationOptions = Apollo.BaseMutationOptions<CheckoutApplyBirthdayDiscountMutation, CheckoutApplyBirthdayDiscountMutationVariables>;
export const CheckoutRemoveBirthdayDiscountDocument = gql`
    mutation checkoutRemoveBirthdayDiscount($id: String!, $languageId: String!) {
  checkoutRemoveBirthdayDiscount(languageId: $languageId, id: $id) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutRemoveBirthdayDiscountMutationFn = Apollo.MutationFunction<CheckoutRemoveBirthdayDiscountMutation, CheckoutRemoveBirthdayDiscountMutationVariables>;

/**
 * __useCheckoutRemoveBirthdayDiscountMutation__
 *
 * To run a mutation, you first call `useCheckoutRemoveBirthdayDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutRemoveBirthdayDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutRemoveBirthdayDiscountMutation, { data, loading, error }] = useCheckoutRemoveBirthdayDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useCheckoutRemoveBirthdayDiscountMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutRemoveBirthdayDiscountMutation, CheckoutRemoveBirthdayDiscountMutationVariables>) {
        return Apollo.useMutation<CheckoutRemoveBirthdayDiscountMutation, CheckoutRemoveBirthdayDiscountMutationVariables>(CheckoutRemoveBirthdayDiscountDocument, baseOptions);
      }
export type CheckoutRemoveBirthdayDiscountMutationHookResult = ReturnType<typeof useCheckoutRemoveBirthdayDiscountMutation>;
export type CheckoutRemoveBirthdayDiscountMutationResult = Apollo.MutationResult<CheckoutRemoveBirthdayDiscountMutation>;
export type CheckoutRemoveBirthdayDiscountMutationOptions = Apollo.BaseMutationOptions<CheckoutRemoveBirthdayDiscountMutation, CheckoutRemoveBirthdayDiscountMutationVariables>;
export const CheckoutApplyPassagesDiscountDocument = gql`
    mutation checkoutApplyPassagesDiscount($id: String!, $languageId: String!) {
  checkoutApplyPassagesDiscount(languageId: $languageId, id: $id) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutApplyPassagesDiscountMutationFn = Apollo.MutationFunction<CheckoutApplyPassagesDiscountMutation, CheckoutApplyPassagesDiscountMutationVariables>;

/**
 * __useCheckoutApplyPassagesDiscountMutation__
 *
 * To run a mutation, you first call `useCheckoutApplyPassagesDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutApplyPassagesDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutApplyPassagesDiscountMutation, { data, loading, error }] = useCheckoutApplyPassagesDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useCheckoutApplyPassagesDiscountMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutApplyPassagesDiscountMutation, CheckoutApplyPassagesDiscountMutationVariables>) {
        return Apollo.useMutation<CheckoutApplyPassagesDiscountMutation, CheckoutApplyPassagesDiscountMutationVariables>(CheckoutApplyPassagesDiscountDocument, baseOptions);
      }
export type CheckoutApplyPassagesDiscountMutationHookResult = ReturnType<typeof useCheckoutApplyPassagesDiscountMutation>;
export type CheckoutApplyPassagesDiscountMutationResult = Apollo.MutationResult<CheckoutApplyPassagesDiscountMutation>;
export type CheckoutApplyPassagesDiscountMutationOptions = Apollo.BaseMutationOptions<CheckoutApplyPassagesDiscountMutation, CheckoutApplyPassagesDiscountMutationVariables>;
export const CheckoutRemovePassagesDiscountDocument = gql`
    mutation checkoutRemovePassagesDiscount($id: String!, $languageId: String!) {
  checkoutRemovePassagesDiscount(languageId: $languageId, id: $id) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutRemovePassagesDiscountMutationFn = Apollo.MutationFunction<CheckoutRemovePassagesDiscountMutation, CheckoutRemovePassagesDiscountMutationVariables>;

/**
 * __useCheckoutRemovePassagesDiscountMutation__
 *
 * To run a mutation, you first call `useCheckoutRemovePassagesDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutRemovePassagesDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutRemovePassagesDiscountMutation, { data, loading, error }] = useCheckoutRemovePassagesDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useCheckoutRemovePassagesDiscountMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutRemovePassagesDiscountMutation, CheckoutRemovePassagesDiscountMutationVariables>) {
        return Apollo.useMutation<CheckoutRemovePassagesDiscountMutation, CheckoutRemovePassagesDiscountMutationVariables>(CheckoutRemovePassagesDiscountDocument, baseOptions);
      }
export type CheckoutRemovePassagesDiscountMutationHookResult = ReturnType<typeof useCheckoutRemovePassagesDiscountMutation>;
export type CheckoutRemovePassagesDiscountMutationResult = Apollo.MutationResult<CheckoutRemovePassagesDiscountMutation>;
export type CheckoutRemovePassagesDiscountMutationOptions = Apollo.BaseMutationOptions<CheckoutRemovePassagesDiscountMutation, CheckoutRemovePassagesDiscountMutationVariables>;
export const CheckoutAcceptNoLoggedPromoDocument = gql`
    mutation checkoutAcceptNoLoggedPromo($id: String!, $languageId: String!) {
  checkoutAcceptNoLoggedPromo(languageId: $languageId, id: $id) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutAcceptNoLoggedPromoMutationFn = Apollo.MutationFunction<CheckoutAcceptNoLoggedPromoMutation, CheckoutAcceptNoLoggedPromoMutationVariables>;

/**
 * __useCheckoutAcceptNoLoggedPromoMutation__
 *
 * To run a mutation, you first call `useCheckoutAcceptNoLoggedPromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutAcceptNoLoggedPromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutAcceptNoLoggedPromoMutation, { data, loading, error }] = useCheckoutAcceptNoLoggedPromoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useCheckoutAcceptNoLoggedPromoMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutAcceptNoLoggedPromoMutation, CheckoutAcceptNoLoggedPromoMutationVariables>) {
        return Apollo.useMutation<CheckoutAcceptNoLoggedPromoMutation, CheckoutAcceptNoLoggedPromoMutationVariables>(CheckoutAcceptNoLoggedPromoDocument, baseOptions);
      }
export type CheckoutAcceptNoLoggedPromoMutationHookResult = ReturnType<typeof useCheckoutAcceptNoLoggedPromoMutation>;
export type CheckoutAcceptNoLoggedPromoMutationResult = Apollo.MutationResult<CheckoutAcceptNoLoggedPromoMutation>;
export type CheckoutAcceptNoLoggedPromoMutationOptions = Apollo.BaseMutationOptions<CheckoutAcceptNoLoggedPromoMutation, CheckoutAcceptNoLoggedPromoMutationVariables>;
export const CheckoutSetPaymentMethodDocument = gql`
    mutation checkoutSetPaymentMethod($id: String!, $languageId: String!, $input: CheckoutPaymentMethodSetInput!) {
  checkoutSetPaymentMethod(languageId: $languageId, id: $id, input: $input) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutSetPaymentMethodMutationFn = Apollo.MutationFunction<CheckoutSetPaymentMethodMutation, CheckoutSetPaymentMethodMutationVariables>;

/**
 * __useCheckoutSetPaymentMethodMutation__
 *
 * To run a mutation, you first call `useCheckoutSetPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutSetPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutSetPaymentMethodMutation, { data, loading, error }] = useCheckoutSetPaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckoutSetPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutSetPaymentMethodMutation, CheckoutSetPaymentMethodMutationVariables>) {
        return Apollo.useMutation<CheckoutSetPaymentMethodMutation, CheckoutSetPaymentMethodMutationVariables>(CheckoutSetPaymentMethodDocument, baseOptions);
      }
export type CheckoutSetPaymentMethodMutationHookResult = ReturnType<typeof useCheckoutSetPaymentMethodMutation>;
export type CheckoutSetPaymentMethodMutationResult = Apollo.MutationResult<CheckoutSetPaymentMethodMutation>;
export type CheckoutSetPaymentMethodMutationOptions = Apollo.BaseMutationOptions<CheckoutSetPaymentMethodMutation, CheckoutSetPaymentMethodMutationVariables>;
export const CheckoutSetBillingDataDocument = gql`
    mutation checkoutSetBillingData($id: String!, $languageId: String!, $billing: CheckoutBillingInput!) {
  checkoutSetBillingData(languageId: $languageId, id: $id, billing: $billing) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutSetBillingDataMutationFn = Apollo.MutationFunction<CheckoutSetBillingDataMutation, CheckoutSetBillingDataMutationVariables>;

/**
 * __useCheckoutSetBillingDataMutation__
 *
 * To run a mutation, you first call `useCheckoutSetBillingDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutSetBillingDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutSetBillingDataMutation, { data, loading, error }] = useCheckoutSetBillingDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *      billing: // value for 'billing'
 *   },
 * });
 */
export function useCheckoutSetBillingDataMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutSetBillingDataMutation, CheckoutSetBillingDataMutationVariables>) {
        return Apollo.useMutation<CheckoutSetBillingDataMutation, CheckoutSetBillingDataMutationVariables>(CheckoutSetBillingDataDocument, baseOptions);
      }
export type CheckoutSetBillingDataMutationHookResult = ReturnType<typeof useCheckoutSetBillingDataMutation>;
export type CheckoutSetBillingDataMutationResult = Apollo.MutationResult<CheckoutSetBillingDataMutation>;
export type CheckoutSetBillingDataMutationOptions = Apollo.BaseMutationOptions<CheckoutSetBillingDataMutation, CheckoutSetBillingDataMutationVariables>;
export const CheckoutApplyGiftcardDocument = gql`
    mutation checkoutApplyGiftcard($id: String!, $languageId: String!, $giftcard: CheckoutGiftcardAddInput!) {
  checkoutApplyGiftcard(languageId: $languageId, id: $id, giftcard: $giftcard) {
    success
    error
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutApplyGiftcardMutationFn = Apollo.MutationFunction<CheckoutApplyGiftcardMutation, CheckoutApplyGiftcardMutationVariables>;

/**
 * __useCheckoutApplyGiftcardMutation__
 *
 * To run a mutation, you first call `useCheckoutApplyGiftcardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutApplyGiftcardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutApplyGiftcardMutation, { data, loading, error }] = useCheckoutApplyGiftcardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *      giftcard: // value for 'giftcard'
 *   },
 * });
 */
export function useCheckoutApplyGiftcardMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutApplyGiftcardMutation, CheckoutApplyGiftcardMutationVariables>) {
        return Apollo.useMutation<CheckoutApplyGiftcardMutation, CheckoutApplyGiftcardMutationVariables>(CheckoutApplyGiftcardDocument, baseOptions);
      }
export type CheckoutApplyGiftcardMutationHookResult = ReturnType<typeof useCheckoutApplyGiftcardMutation>;
export type CheckoutApplyGiftcardMutationResult = Apollo.MutationResult<CheckoutApplyGiftcardMutation>;
export type CheckoutApplyGiftcardMutationOptions = Apollo.BaseMutationOptions<CheckoutApplyGiftcardMutation, CheckoutApplyGiftcardMutationVariables>;
export const CheckoutRemoveGiftcardDocument = gql`
    mutation checkoutRemoveGiftcard($id: String!, $languageId: String!, $giftcard: CheckoutGiftcardRemoveInput!) {
  checkoutRemoveGiftcard(languageId: $languageId, id: $id, giftcard: $giftcard) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutRemoveGiftcardMutationFn = Apollo.MutationFunction<CheckoutRemoveGiftcardMutation, CheckoutRemoveGiftcardMutationVariables>;

/**
 * __useCheckoutRemoveGiftcardMutation__
 *
 * To run a mutation, you first call `useCheckoutRemoveGiftcardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutRemoveGiftcardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutRemoveGiftcardMutation, { data, loading, error }] = useCheckoutRemoveGiftcardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *      giftcard: // value for 'giftcard'
 *   },
 * });
 */
export function useCheckoutRemoveGiftcardMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutRemoveGiftcardMutation, CheckoutRemoveGiftcardMutationVariables>) {
        return Apollo.useMutation<CheckoutRemoveGiftcardMutation, CheckoutRemoveGiftcardMutationVariables>(CheckoutRemoveGiftcardDocument, baseOptions);
      }
export type CheckoutRemoveGiftcardMutationHookResult = ReturnType<typeof useCheckoutRemoveGiftcardMutation>;
export type CheckoutRemoveGiftcardMutationResult = Apollo.MutationResult<CheckoutRemoveGiftcardMutation>;
export type CheckoutRemoveGiftcardMutationOptions = Apollo.BaseMutationOptions<CheckoutRemoveGiftcardMutation, CheckoutRemoveGiftcardMutationVariables>;
export const CheckoutSelectProductGiftDocument = gql`
    mutation checkoutSelectProductGift($id: String!, $languageId: String!, $eanCode: String!) {
  checkoutSelectProductGift(languageId: $languageId, id: $id, eanCode: $eanCode) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutSelectProductGiftMutationFn = Apollo.MutationFunction<CheckoutSelectProductGiftMutation, CheckoutSelectProductGiftMutationVariables>;

/**
 * __useCheckoutSelectProductGiftMutation__
 *
 * To run a mutation, you first call `useCheckoutSelectProductGiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutSelectProductGiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutSelectProductGiftMutation, { data, loading, error }] = useCheckoutSelectProductGiftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *      eanCode: // value for 'eanCode'
 *   },
 * });
 */
export function useCheckoutSelectProductGiftMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutSelectProductGiftMutation, CheckoutSelectProductGiftMutationVariables>) {
        return Apollo.useMutation<CheckoutSelectProductGiftMutation, CheckoutSelectProductGiftMutationVariables>(CheckoutSelectProductGiftDocument, baseOptions);
      }
export type CheckoutSelectProductGiftMutationHookResult = ReturnType<typeof useCheckoutSelectProductGiftMutation>;
export type CheckoutSelectProductGiftMutationResult = Apollo.MutationResult<CheckoutSelectProductGiftMutation>;
export type CheckoutSelectProductGiftMutationOptions = Apollo.BaseMutationOptions<CheckoutSelectProductGiftMutation, CheckoutSelectProductGiftMutationVariables>;
export const CheckoutRemoveProductGiftDocument = gql`
    mutation checkoutRemoveProductGift($id: String!, $languageId: String!, $eanCode: String!) {
  checkoutRemoveProductGift(languageId: $languageId, id: $id, eanCode: $eanCode) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutRemoveProductGiftMutationFn = Apollo.MutationFunction<CheckoutRemoveProductGiftMutation, CheckoutRemoveProductGiftMutationVariables>;

/**
 * __useCheckoutRemoveProductGiftMutation__
 *
 * To run a mutation, you first call `useCheckoutRemoveProductGiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutRemoveProductGiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutRemoveProductGiftMutation, { data, loading, error }] = useCheckoutRemoveProductGiftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *      eanCode: // value for 'eanCode'
 *   },
 * });
 */
export function useCheckoutRemoveProductGiftMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutRemoveProductGiftMutation, CheckoutRemoveProductGiftMutationVariables>) {
        return Apollo.useMutation<CheckoutRemoveProductGiftMutation, CheckoutRemoveProductGiftMutationVariables>(CheckoutRemoveProductGiftDocument, baseOptions);
      }
export type CheckoutRemoveProductGiftMutationHookResult = ReturnType<typeof useCheckoutRemoveProductGiftMutation>;
export type CheckoutRemoveProductGiftMutationResult = Apollo.MutationResult<CheckoutRemoveProductGiftMutation>;
export type CheckoutRemoveProductGiftMutationOptions = Apollo.BaseMutationOptions<CheckoutRemoveProductGiftMutation, CheckoutRemoveProductGiftMutationVariables>;
export const CheckoutApplyPointsDiscountDocument = gql`
    mutation checkoutApplyPointsDiscount($id: String!, $languageId: String!, $points: Int!) {
  checkoutApplyPointsDiscount(languageId: $languageId, id: $id, points: $points) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutApplyPointsDiscountMutationFn = Apollo.MutationFunction<CheckoutApplyPointsDiscountMutation, CheckoutApplyPointsDiscountMutationVariables>;

/**
 * __useCheckoutApplyPointsDiscountMutation__
 *
 * To run a mutation, you first call `useCheckoutApplyPointsDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutApplyPointsDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutApplyPointsDiscountMutation, { data, loading, error }] = useCheckoutApplyPointsDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *      points: // value for 'points'
 *   },
 * });
 */
export function useCheckoutApplyPointsDiscountMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutApplyPointsDiscountMutation, CheckoutApplyPointsDiscountMutationVariables>) {
        return Apollo.useMutation<CheckoutApplyPointsDiscountMutation, CheckoutApplyPointsDiscountMutationVariables>(CheckoutApplyPointsDiscountDocument, baseOptions);
      }
export type CheckoutApplyPointsDiscountMutationHookResult = ReturnType<typeof useCheckoutApplyPointsDiscountMutation>;
export type CheckoutApplyPointsDiscountMutationResult = Apollo.MutationResult<CheckoutApplyPointsDiscountMutation>;
export type CheckoutApplyPointsDiscountMutationOptions = Apollo.BaseMutationOptions<CheckoutApplyPointsDiscountMutation, CheckoutApplyPointsDiscountMutationVariables>;
export const CheckoutRemovePointsDiscountDocument = gql`
    mutation checkoutRemovePointsDiscount($id: String!, $languageId: String!, $points: Int!) {
  checkoutRemovePointsDiscount(languageId: $languageId, id: $id, points: $points) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutRemovePointsDiscountMutationFn = Apollo.MutationFunction<CheckoutRemovePointsDiscountMutation, CheckoutRemovePointsDiscountMutationVariables>;

/**
 * __useCheckoutRemovePointsDiscountMutation__
 *
 * To run a mutation, you first call `useCheckoutRemovePointsDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutRemovePointsDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutRemovePointsDiscountMutation, { data, loading, error }] = useCheckoutRemovePointsDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *      points: // value for 'points'
 *   },
 * });
 */
export function useCheckoutRemovePointsDiscountMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutRemovePointsDiscountMutation, CheckoutRemovePointsDiscountMutationVariables>) {
        return Apollo.useMutation<CheckoutRemovePointsDiscountMutation, CheckoutRemovePointsDiscountMutationVariables>(CheckoutRemovePointsDiscountDocument, baseOptions);
      }
export type CheckoutRemovePointsDiscountMutationHookResult = ReturnType<typeof useCheckoutRemovePointsDiscountMutation>;
export type CheckoutRemovePointsDiscountMutationResult = Apollo.MutationResult<CheckoutRemovePointsDiscountMutation>;
export type CheckoutRemovePointsDiscountMutationOptions = Apollo.BaseMutationOptions<CheckoutRemovePointsDiscountMutation, CheckoutRemovePointsDiscountMutationVariables>;
export const CheckoutCompleteWithoutPaymentDocument = gql`
    mutation checkoutCompleteWithoutPayment($id: String!, $languageId: String!) {
  checkoutCompleteWithoutPayment(languageId: $languageId, id: $id) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutCompleteWithoutPaymentMutationFn = Apollo.MutationFunction<CheckoutCompleteWithoutPaymentMutation, CheckoutCompleteWithoutPaymentMutationVariables>;

/**
 * __useCheckoutCompleteWithoutPaymentMutation__
 *
 * To run a mutation, you first call `useCheckoutCompleteWithoutPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutCompleteWithoutPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutCompleteWithoutPaymentMutation, { data, loading, error }] = useCheckoutCompleteWithoutPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useCheckoutCompleteWithoutPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutCompleteWithoutPaymentMutation, CheckoutCompleteWithoutPaymentMutationVariables>) {
        return Apollo.useMutation<CheckoutCompleteWithoutPaymentMutation, CheckoutCompleteWithoutPaymentMutationVariables>(CheckoutCompleteWithoutPaymentDocument, baseOptions);
      }
export type CheckoutCompleteWithoutPaymentMutationHookResult = ReturnType<typeof useCheckoutCompleteWithoutPaymentMutation>;
export type CheckoutCompleteWithoutPaymentMutationResult = Apollo.MutationResult<CheckoutCompleteWithoutPaymentMutation>;
export type CheckoutCompleteWithoutPaymentMutationOptions = Apollo.BaseMutationOptions<CheckoutCompleteWithoutPaymentMutation, CheckoutCompleteWithoutPaymentMutationVariables>;
export const CheckoutRecoverDocument = gql`
    mutation checkoutRecover($id: String!, $languageId: String!, $input: CheckoutRecoverInput!) {
  checkoutRecover(languageId: $languageId, id: $id, input: $input) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type CheckoutRecoverMutationFn = Apollo.MutationFunction<CheckoutRecoverMutation, CheckoutRecoverMutationVariables>;

/**
 * __useCheckoutRecoverMutation__
 *
 * To run a mutation, you first call `useCheckoutRecoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutRecoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutRecoverMutation, { data, loading, error }] = useCheckoutRecoverMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckoutRecoverMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutRecoverMutation, CheckoutRecoverMutationVariables>) {
        return Apollo.useMutation<CheckoutRecoverMutation, CheckoutRecoverMutationVariables>(CheckoutRecoverDocument, baseOptions);
      }
export type CheckoutRecoverMutationHookResult = ReturnType<typeof useCheckoutRecoverMutation>;
export type CheckoutRecoverMutationResult = Apollo.MutationResult<CheckoutRecoverMutation>;
export type CheckoutRecoverMutationOptions = Apollo.BaseMutationOptions<CheckoutRecoverMutation, CheckoutRecoverMutationVariables>;
export const CheckoutBasketItemPromoApplyPreviewDocument = gql`
    mutation checkoutBasketItemPromoApplyPreview($input: CheckoutBasketItemPromoApplyPreview!) {
  checkoutBasketItemPromoApplyPreview(input: $input) {
    results {
      applicable
      variant {
        productId
        eanCode
      }
      promo {
        promoCode
      }
      discount {
        originalCompareAtPrice
        originalDiscountPercent
        originalDiscountAmount
        newCompareAtPrice
        newDiscountPercent
        newDiscountAmount
        totalDiscountAmount
        price
      }
    }
  }
}
    `;
export type CheckoutBasketItemPromoApplyPreviewMutationFn = Apollo.MutationFunction<CheckoutBasketItemPromoApplyPreviewMutation, CheckoutBasketItemPromoApplyPreviewMutationVariables>;

/**
 * __useCheckoutBasketItemPromoApplyPreviewMutation__
 *
 * To run a mutation, you first call `useCheckoutBasketItemPromoApplyPreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutBasketItemPromoApplyPreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutBasketItemPromoApplyPreviewMutation, { data, loading, error }] = useCheckoutBasketItemPromoApplyPreviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckoutBasketItemPromoApplyPreviewMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutBasketItemPromoApplyPreviewMutation, CheckoutBasketItemPromoApplyPreviewMutationVariables>) {
        return Apollo.useMutation<CheckoutBasketItemPromoApplyPreviewMutation, CheckoutBasketItemPromoApplyPreviewMutationVariables>(CheckoutBasketItemPromoApplyPreviewDocument, baseOptions);
      }
export type CheckoutBasketItemPromoApplyPreviewMutationHookResult = ReturnType<typeof useCheckoutBasketItemPromoApplyPreviewMutation>;
export type CheckoutBasketItemPromoApplyPreviewMutationResult = Apollo.MutationResult<CheckoutBasketItemPromoApplyPreviewMutation>;
export type CheckoutBasketItemPromoApplyPreviewMutationOptions = Apollo.BaseMutationOptions<CheckoutBasketItemPromoApplyPreviewMutation, CheckoutBasketItemPromoApplyPreviewMutationVariables>;
export const CheckoutDocument = gql`
    query checkout($id: String!, $languageId: String!, $draftOnly: Boolean, $email: String) {
  checkout(id: $id, languageId: $languageId, draftOnly: $draftOnly, email: $email) {
    ...CheckoutData
  }
}
    ${CheckoutDataFragmentDoc}`;

/**
 * __useCheckoutQuery__
 *
 * To run a query within a React component, call `useCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutQuery({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *      draftOnly: // value for 'draftOnly'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckoutQuery(baseOptions: Apollo.QueryHookOptions<CheckoutQuery, CheckoutQueryVariables>) {
        return Apollo.useQuery<CheckoutQuery, CheckoutQueryVariables>(CheckoutDocument, baseOptions);
      }
export function useCheckoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckoutQuery, CheckoutQueryVariables>) {
          return Apollo.useLazyQuery<CheckoutQuery, CheckoutQueryVariables>(CheckoutDocument, baseOptions);
        }
export type CheckoutQueryHookResult = ReturnType<typeof useCheckoutQuery>;
export type CheckoutLazyQueryHookResult = ReturnType<typeof useCheckoutLazyQuery>;
export type CheckoutQueryResult = Apollo.QueryResult<CheckoutQuery, CheckoutQueryVariables>;
export const CustomerCheckoutDocument = gql`
    query customerCheckout($languageId: String!) {
  customerCheckout(languageId: $languageId) {
    ...CheckoutData
  }
}
    ${CheckoutDataFragmentDoc}`;

/**
 * __useCustomerCheckoutQuery__
 *
 * To run a query within a React component, call `useCustomerCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerCheckoutQuery({
 *   variables: {
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useCustomerCheckoutQuery(baseOptions: Apollo.QueryHookOptions<CustomerCheckoutQuery, CustomerCheckoutQueryVariables>) {
        return Apollo.useQuery<CustomerCheckoutQuery, CustomerCheckoutQueryVariables>(CustomerCheckoutDocument, baseOptions);
      }
export function useCustomerCheckoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerCheckoutQuery, CustomerCheckoutQueryVariables>) {
          return Apollo.useLazyQuery<CustomerCheckoutQuery, CustomerCheckoutQueryVariables>(CustomerCheckoutDocument, baseOptions);
        }
export type CustomerCheckoutQueryHookResult = ReturnType<typeof useCustomerCheckoutQuery>;
export type CustomerCheckoutLazyQueryHookResult = ReturnType<typeof useCustomerCheckoutLazyQuery>;
export type CustomerCheckoutQueryResult = Apollo.QueryResult<CustomerCheckoutQuery, CustomerCheckoutQueryVariables>;
export const CheckoutGetAvailableCollectStoresDocument = gql`
    query checkoutGetAvailableCollectStores($checkoutId: String!) {
  checkoutGetAvailableCollectStores(checkoutId: $checkoutId) {
    storeId
  }
}
    `;

/**
 * __useCheckoutGetAvailableCollectStoresQuery__
 *
 * To run a query within a React component, call `useCheckoutGetAvailableCollectStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutGetAvailableCollectStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutGetAvailableCollectStoresQuery({
 *   variables: {
 *      checkoutId: // value for 'checkoutId'
 *   },
 * });
 */
export function useCheckoutGetAvailableCollectStoresQuery(baseOptions: Apollo.QueryHookOptions<CheckoutGetAvailableCollectStoresQuery, CheckoutGetAvailableCollectStoresQueryVariables>) {
        return Apollo.useQuery<CheckoutGetAvailableCollectStoresQuery, CheckoutGetAvailableCollectStoresQueryVariables>(CheckoutGetAvailableCollectStoresDocument, baseOptions);
      }
export function useCheckoutGetAvailableCollectStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckoutGetAvailableCollectStoresQuery, CheckoutGetAvailableCollectStoresQueryVariables>) {
          return Apollo.useLazyQuery<CheckoutGetAvailableCollectStoresQuery, CheckoutGetAvailableCollectStoresQueryVariables>(CheckoutGetAvailableCollectStoresDocument, baseOptions);
        }
export type CheckoutGetAvailableCollectStoresQueryHookResult = ReturnType<typeof useCheckoutGetAvailableCollectStoresQuery>;
export type CheckoutGetAvailableCollectStoresLazyQueryHookResult = ReturnType<typeof useCheckoutGetAvailableCollectStoresLazyQuery>;
export type CheckoutGetAvailableCollectStoresQueryResult = Apollo.QueryResult<CheckoutGetAvailableCollectStoresQuery, CheckoutGetAvailableCollectStoresQueryVariables>;
export const CountriesDocument = gql`
    query countries($languageId: String!) {
  countries(languageId: $languageId) {
    default {
      name
      code
      hasProvince
    }
    values {
      name
      code
      hasProvince
    }
  }
}
    `;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useCountriesQuery(baseOptions: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
        return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, baseOptions);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, baseOptions);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const ProvincesDocument = gql`
    query provinces($countryCode: String!) {
  provinces(countryCode: $countryCode) {
    name
    code
  }
}
    `;

/**
 * __useProvincesQuery__
 *
 * To run a query within a React component, call `useProvincesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvincesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvincesQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useProvincesQuery(baseOptions: Apollo.QueryHookOptions<ProvincesQuery, ProvincesQueryVariables>) {
        return Apollo.useQuery<ProvincesQuery, ProvincesQueryVariables>(ProvincesDocument, baseOptions);
      }
export function useProvincesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProvincesQuery, ProvincesQueryVariables>) {
          return Apollo.useLazyQuery<ProvincesQuery, ProvincesQueryVariables>(ProvincesDocument, baseOptions);
        }
export type ProvincesQueryHookResult = ReturnType<typeof useProvincesQuery>;
export type ProvincesLazyQueryHookResult = ReturnType<typeof useProvincesLazyQuery>;
export type ProvincesQueryResult = Apollo.QueryResult<ProvincesQuery, ProvincesQueryVariables>;
export const EmailAddressValidateDocument = gql`
    mutation emailAddressValidate($address: String!) {
  emailAddressValidate(address: $address) {
    isValid
    errorType
    errorMessage
  }
}
    `;
export type EmailAddressValidateMutationFn = Apollo.MutationFunction<EmailAddressValidateMutation, EmailAddressValidateMutationVariables>;

/**
 * __useEmailAddressValidateMutation__
 *
 * To run a mutation, you first call `useEmailAddressValidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailAddressValidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailAddressValidateMutation, { data, loading, error }] = useEmailAddressValidateMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useEmailAddressValidateMutation(baseOptions?: Apollo.MutationHookOptions<EmailAddressValidateMutation, EmailAddressValidateMutationVariables>) {
        return Apollo.useMutation<EmailAddressValidateMutation, EmailAddressValidateMutationVariables>(EmailAddressValidateDocument, baseOptions);
      }
export type EmailAddressValidateMutationHookResult = ReturnType<typeof useEmailAddressValidateMutation>;
export type EmailAddressValidateMutationResult = Apollo.MutationResult<EmailAddressValidateMutation>;
export type EmailAddressValidateMutationOptions = Apollo.BaseMutationOptions<EmailAddressValidateMutation, EmailAddressValidateMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($input: CustomerUpdateInput!) {
  updateCustomer(input: $input) {
    success
    errorType
    customer {
      ...CustomerData
    }
  }
}
    ${CustomerDataFragmentDoc}`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, baseOptions);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const DeleteCustomerDocument = gql`
    mutation deleteCustomer {
  deleteCustomer {
    success
    errorType
  }
}
    `;
export type DeleteCustomerMutationFn = Apollo.MutationFunction<DeleteCustomerMutation, DeleteCustomerMutationVariables>;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteCustomerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>) {
        return Apollo.useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(DeleteCustomerDocument, baseOptions);
      }
export type DeleteCustomerMutationHookResult = ReturnType<typeof useDeleteCustomerMutation>;
export type DeleteCustomerMutationResult = Apollo.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>;
export const NewsletterManageDocument = gql`
    mutation newsletterManage($input: NewsletterManageInput!) {
  newsletterManage(input: $input) {
    success
    alreadySubscribed
    alreadyUnsubscribed
    voucherCreated
  }
}
    `;
export type NewsletterManageMutationFn = Apollo.MutationFunction<NewsletterManageMutation, NewsletterManageMutationVariables>;

/**
 * __useNewsletterManageMutation__
 *
 * To run a mutation, you first call `useNewsletterManageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewsletterManageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newsletterManageMutation, { data, loading, error }] = useNewsletterManageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewsletterManageMutation(baseOptions?: Apollo.MutationHookOptions<NewsletterManageMutation, NewsletterManageMutationVariables>) {
        return Apollo.useMutation<NewsletterManageMutation, NewsletterManageMutationVariables>(NewsletterManageDocument, baseOptions);
      }
export type NewsletterManageMutationHookResult = ReturnType<typeof useNewsletterManageMutation>;
export type NewsletterManageMutationResult = Apollo.MutationResult<NewsletterManageMutation>;
export type NewsletterManageMutationOptions = Apollo.BaseMutationOptions<NewsletterManageMutation, NewsletterManageMutationVariables>;
export const CustomerAddressCreateDocument = gql`
    mutation customerAddressCreate($input: CustomerAddressInput!) {
  customerAddressCreate(input: $input) {
    success
  }
}
    `;
export type CustomerAddressCreateMutationFn = Apollo.MutationFunction<CustomerAddressCreateMutation, CustomerAddressCreateMutationVariables>;

/**
 * __useCustomerAddressCreateMutation__
 *
 * To run a mutation, you first call `useCustomerAddressCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAddressCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAddressCreateMutation, { data, loading, error }] = useCustomerAddressCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerAddressCreateMutation(baseOptions?: Apollo.MutationHookOptions<CustomerAddressCreateMutation, CustomerAddressCreateMutationVariables>) {
        return Apollo.useMutation<CustomerAddressCreateMutation, CustomerAddressCreateMutationVariables>(CustomerAddressCreateDocument, baseOptions);
      }
export type CustomerAddressCreateMutationHookResult = ReturnType<typeof useCustomerAddressCreateMutation>;
export type CustomerAddressCreateMutationResult = Apollo.MutationResult<CustomerAddressCreateMutation>;
export type CustomerAddressCreateMutationOptions = Apollo.BaseMutationOptions<CustomerAddressCreateMutation, CustomerAddressCreateMutationVariables>;
export const CustomerAddressDeleteDocument = gql`
    mutation customerAddressDelete($id: String!) {
  customerAddressDelete(id: $id) {
    success
  }
}
    `;
export type CustomerAddressDeleteMutationFn = Apollo.MutationFunction<CustomerAddressDeleteMutation, CustomerAddressDeleteMutationVariables>;

/**
 * __useCustomerAddressDeleteMutation__
 *
 * To run a mutation, you first call `useCustomerAddressDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAddressDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAddressDeleteMutation, { data, loading, error }] = useCustomerAddressDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerAddressDeleteMutation(baseOptions?: Apollo.MutationHookOptions<CustomerAddressDeleteMutation, CustomerAddressDeleteMutationVariables>) {
        return Apollo.useMutation<CustomerAddressDeleteMutation, CustomerAddressDeleteMutationVariables>(CustomerAddressDeleteDocument, baseOptions);
      }
export type CustomerAddressDeleteMutationHookResult = ReturnType<typeof useCustomerAddressDeleteMutation>;
export type CustomerAddressDeleteMutationResult = Apollo.MutationResult<CustomerAddressDeleteMutation>;
export type CustomerAddressDeleteMutationOptions = Apollo.BaseMutationOptions<CustomerAddressDeleteMutation, CustomerAddressDeleteMutationVariables>;
export const CustomerAddressUpdateDocument = gql`
    mutation customerAddressUpdate($id: String!, $input: CustomerAddressInput!) {
  customerAddressUpdate(id: $id, input: $input) {
    success
  }
}
    `;
export type CustomerAddressUpdateMutationFn = Apollo.MutationFunction<CustomerAddressUpdateMutation, CustomerAddressUpdateMutationVariables>;

/**
 * __useCustomerAddressUpdateMutation__
 *
 * To run a mutation, you first call `useCustomerAddressUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAddressUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAddressUpdateMutation, { data, loading, error }] = useCustomerAddressUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerAddressUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CustomerAddressUpdateMutation, CustomerAddressUpdateMutationVariables>) {
        return Apollo.useMutation<CustomerAddressUpdateMutation, CustomerAddressUpdateMutationVariables>(CustomerAddressUpdateDocument, baseOptions);
      }
export type CustomerAddressUpdateMutationHookResult = ReturnType<typeof useCustomerAddressUpdateMutation>;
export type CustomerAddressUpdateMutationResult = Apollo.MutationResult<CustomerAddressUpdateMutation>;
export type CustomerAddressUpdateMutationOptions = Apollo.BaseMutationOptions<CustomerAddressUpdateMutation, CustomerAddressUpdateMutationVariables>;
export const ChangeEmailDocument = gql`
    mutation changeEmail($input: ChangeEmailInput!) {
  changeEmail(input: $input) {
    success
    errorType
  }
}
    `;
export type ChangeEmailMutationFn = Apollo.MutationFunction<ChangeEmailMutation, ChangeEmailMutationVariables>;

/**
 * __useChangeEmailMutation__
 *
 * To run a mutation, you first call `useChangeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmailMutation, { data, loading, error }] = useChangeEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeEmailMutation(baseOptions?: Apollo.MutationHookOptions<ChangeEmailMutation, ChangeEmailMutationVariables>) {
        return Apollo.useMutation<ChangeEmailMutation, ChangeEmailMutationVariables>(ChangeEmailDocument, baseOptions);
      }
export type ChangeEmailMutationHookResult = ReturnType<typeof useChangeEmailMutation>;
export type ChangeEmailMutationResult = Apollo.MutationResult<ChangeEmailMutation>;
export type ChangeEmailMutationOptions = Apollo.BaseMutationOptions<ChangeEmailMutation, ChangeEmailMutationVariables>;
export const ChangeEmailVerifyDocument = gql`
    mutation changeEmailVerify($input: ChangeEmailVerifyInput!) {
  changeEmailVerify(input: $input) {
    success
    accessToken
  }
}
    `;
export type ChangeEmailVerifyMutationFn = Apollo.MutationFunction<ChangeEmailVerifyMutation, ChangeEmailVerifyMutationVariables>;

/**
 * __useChangeEmailVerifyMutation__
 *
 * To run a mutation, you first call `useChangeEmailVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmailVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmailVerifyMutation, { data, loading, error }] = useChangeEmailVerifyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeEmailVerifyMutation(baseOptions?: Apollo.MutationHookOptions<ChangeEmailVerifyMutation, ChangeEmailVerifyMutationVariables>) {
        return Apollo.useMutation<ChangeEmailVerifyMutation, ChangeEmailVerifyMutationVariables>(ChangeEmailVerifyDocument, baseOptions);
      }
export type ChangeEmailVerifyMutationHookResult = ReturnType<typeof useChangeEmailVerifyMutation>;
export type ChangeEmailVerifyMutationResult = Apollo.MutationResult<ChangeEmailVerifyMutation>;
export type ChangeEmailVerifyMutationOptions = Apollo.BaseMutationOptions<ChangeEmailVerifyMutation, ChangeEmailVerifyMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    success
    accessToken
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CustomerEnableCardDocument = gql`
    mutation customerEnableCard {
  customerEnableCard {
    success
    cardId
  }
}
    `;
export type CustomerEnableCardMutationFn = Apollo.MutationFunction<CustomerEnableCardMutation, CustomerEnableCardMutationVariables>;

/**
 * __useCustomerEnableCardMutation__
 *
 * To run a mutation, you first call `useCustomerEnableCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerEnableCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerEnableCardMutation, { data, loading, error }] = useCustomerEnableCardMutation({
 *   variables: {
 *   },
 * });
 */
export function useCustomerEnableCardMutation(baseOptions?: Apollo.MutationHookOptions<CustomerEnableCardMutation, CustomerEnableCardMutationVariables>) {
        return Apollo.useMutation<CustomerEnableCardMutation, CustomerEnableCardMutationVariables>(CustomerEnableCardDocument, baseOptions);
      }
export type CustomerEnableCardMutationHookResult = ReturnType<typeof useCustomerEnableCardMutation>;
export type CustomerEnableCardMutationResult = Apollo.MutationResult<CustomerEnableCardMutation>;
export type CustomerEnableCardMutationOptions = Apollo.BaseMutationOptions<CustomerEnableCardMutation, CustomerEnableCardMutationVariables>;
export const CustomerAcceptNewPoliciesDocument = gql`
    mutation customerAcceptNewPolicies {
  customerAcceptNewPolicies {
    success
    customer {
      ...CustomerData
    }
  }
}
    ${CustomerDataFragmentDoc}`;
export type CustomerAcceptNewPoliciesMutationFn = Apollo.MutationFunction<CustomerAcceptNewPoliciesMutation, CustomerAcceptNewPoliciesMutationVariables>;

/**
 * __useCustomerAcceptNewPoliciesMutation__
 *
 * To run a mutation, you first call `useCustomerAcceptNewPoliciesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAcceptNewPoliciesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAcceptNewPoliciesMutation, { data, loading, error }] = useCustomerAcceptNewPoliciesMutation({
 *   variables: {
 *   },
 * });
 */
export function useCustomerAcceptNewPoliciesMutation(baseOptions?: Apollo.MutationHookOptions<CustomerAcceptNewPoliciesMutation, CustomerAcceptNewPoliciesMutationVariables>) {
        return Apollo.useMutation<CustomerAcceptNewPoliciesMutation, CustomerAcceptNewPoliciesMutationVariables>(CustomerAcceptNewPoliciesDocument, baseOptions);
      }
export type CustomerAcceptNewPoliciesMutationHookResult = ReturnType<typeof useCustomerAcceptNewPoliciesMutation>;
export type CustomerAcceptNewPoliciesMutationResult = Apollo.MutationResult<CustomerAcceptNewPoliciesMutation>;
export type CustomerAcceptNewPoliciesMutationOptions = Apollo.BaseMutationOptions<CustomerAcceptNewPoliciesMutation, CustomerAcceptNewPoliciesMutationVariables>;
export const UserFeedbackCreateDocument = gql`
    mutation userFeedbackCreate($input: UserFeedbackInput!) {
  userFeedbackCreate(input: $input) {
    success
  }
}
    `;
export type UserFeedbackCreateMutationFn = Apollo.MutationFunction<UserFeedbackCreateMutation, UserFeedbackCreateMutationVariables>;

/**
 * __useUserFeedbackCreateMutation__
 *
 * To run a mutation, you first call `useUserFeedbackCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserFeedbackCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userFeedbackCreateMutation, { data, loading, error }] = useUserFeedbackCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserFeedbackCreateMutation(baseOptions?: Apollo.MutationHookOptions<UserFeedbackCreateMutation, UserFeedbackCreateMutationVariables>) {
        return Apollo.useMutation<UserFeedbackCreateMutation, UserFeedbackCreateMutationVariables>(UserFeedbackCreateDocument, baseOptions);
      }
export type UserFeedbackCreateMutationHookResult = ReturnType<typeof useUserFeedbackCreateMutation>;
export type UserFeedbackCreateMutationResult = Apollo.MutationResult<UserFeedbackCreateMutation>;
export type UserFeedbackCreateMutationOptions = Apollo.BaseMutationOptions<UserFeedbackCreateMutation, UserFeedbackCreateMutationVariables>;
export const ConvertOldCustomerDocument = gql`
    mutation convertOldCustomer($input: ConvertOldCustomerInput!) {
  convertOldCustomer(input: $input) {
    success
    customer {
      ...CustomerData
    }
  }
}
    ${CustomerDataFragmentDoc}`;
export type ConvertOldCustomerMutationFn = Apollo.MutationFunction<ConvertOldCustomerMutation, ConvertOldCustomerMutationVariables>;

/**
 * __useConvertOldCustomerMutation__
 *
 * To run a mutation, you first call `useConvertOldCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertOldCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertOldCustomerMutation, { data, loading, error }] = useConvertOldCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConvertOldCustomerMutation(baseOptions?: Apollo.MutationHookOptions<ConvertOldCustomerMutation, ConvertOldCustomerMutationVariables>) {
        return Apollo.useMutation<ConvertOldCustomerMutation, ConvertOldCustomerMutationVariables>(ConvertOldCustomerDocument, baseOptions);
      }
export type ConvertOldCustomerMutationHookResult = ReturnType<typeof useConvertOldCustomerMutation>;
export type ConvertOldCustomerMutationResult = Apollo.MutationResult<ConvertOldCustomerMutation>;
export type ConvertOldCustomerMutationOptions = Apollo.BaseMutationOptions<ConvertOldCustomerMutation, ConvertOldCustomerMutationVariables>;
export const CheckStoreUserStatusDocument = gql`
    mutation checkStoreUserStatus($input: CheckStoreUserInput!) {
  checkStoreUserStatus(input: $input) {
    status
    contact {
      email
      firstName
      lastName
    }
  }
}
    `;
export type CheckStoreUserStatusMutationFn = Apollo.MutationFunction<CheckStoreUserStatusMutation, CheckStoreUserStatusMutationVariables>;

/**
 * __useCheckStoreUserStatusMutation__
 *
 * To run a mutation, you first call `useCheckStoreUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckStoreUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkStoreUserStatusMutation, { data, loading, error }] = useCheckStoreUserStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckStoreUserStatusMutation(baseOptions?: Apollo.MutationHookOptions<CheckStoreUserStatusMutation, CheckStoreUserStatusMutationVariables>) {
        return Apollo.useMutation<CheckStoreUserStatusMutation, CheckStoreUserStatusMutationVariables>(CheckStoreUserStatusDocument, baseOptions);
      }
export type CheckStoreUserStatusMutationHookResult = ReturnType<typeof useCheckStoreUserStatusMutation>;
export type CheckStoreUserStatusMutationResult = Apollo.MutationResult<CheckStoreUserStatusMutation>;
export type CheckStoreUserStatusMutationOptions = Apollo.BaseMutationOptions<CheckStoreUserStatusMutation, CheckStoreUserStatusMutationVariables>;
export const CompleteStoreUserRegistrationDocument = gql`
    mutation completeStoreUserRegistration($input: RegisterStoreUserInput!) {
  completeStoreUserRegistration(input: $input) {
    success
    accessToken
  }
}
    `;
export type CompleteStoreUserRegistrationMutationFn = Apollo.MutationFunction<CompleteStoreUserRegistrationMutation, CompleteStoreUserRegistrationMutationVariables>;

/**
 * __useCompleteStoreUserRegistrationMutation__
 *
 * To run a mutation, you first call `useCompleteStoreUserRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteStoreUserRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeStoreUserRegistrationMutation, { data, loading, error }] = useCompleteStoreUserRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteStoreUserRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<CompleteStoreUserRegistrationMutation, CompleteStoreUserRegistrationMutationVariables>) {
        return Apollo.useMutation<CompleteStoreUserRegistrationMutation, CompleteStoreUserRegistrationMutationVariables>(CompleteStoreUserRegistrationDocument, baseOptions);
      }
export type CompleteStoreUserRegistrationMutationHookResult = ReturnType<typeof useCompleteStoreUserRegistrationMutation>;
export type CompleteStoreUserRegistrationMutationResult = Apollo.MutationResult<CompleteStoreUserRegistrationMutation>;
export type CompleteStoreUserRegistrationMutationOptions = Apollo.BaseMutationOptions<CompleteStoreUserRegistrationMutation, CompleteStoreUserRegistrationMutationVariables>;
export const UserConsentsUnsubscribeDocument = gql`
    mutation userConsentsUnsubscribe($input: UserConsentsUnsubscribeInput!) {
  userConsentsUnsubscribe(input: $input) {
    success
  }
}
    `;
export type UserConsentsUnsubscribeMutationFn = Apollo.MutationFunction<UserConsentsUnsubscribeMutation, UserConsentsUnsubscribeMutationVariables>;

/**
 * __useUserConsentsUnsubscribeMutation__
 *
 * To run a mutation, you first call `useUserConsentsUnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserConsentsUnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userConsentsUnsubscribeMutation, { data, loading, error }] = useUserConsentsUnsubscribeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserConsentsUnsubscribeMutation(baseOptions?: Apollo.MutationHookOptions<UserConsentsUnsubscribeMutation, UserConsentsUnsubscribeMutationVariables>) {
        return Apollo.useMutation<UserConsentsUnsubscribeMutation, UserConsentsUnsubscribeMutationVariables>(UserConsentsUnsubscribeDocument, baseOptions);
      }
export type UserConsentsUnsubscribeMutationHookResult = ReturnType<typeof useUserConsentsUnsubscribeMutation>;
export type UserConsentsUnsubscribeMutationResult = Apollo.MutationResult<UserConsentsUnsubscribeMutation>;
export type UserConsentsUnsubscribeMutationOptions = Apollo.BaseMutationOptions<UserConsentsUnsubscribeMutation, UserConsentsUnsubscribeMutationVariables>;
export const UnsubscribeSmsDocument = gql`
    mutation unsubscribeSms($input: SmsUnsubscribeInput!) {
  unsubscribeSms(input: $input) {
    success
    error
  }
}
    `;
export type UnsubscribeSmsMutationFn = Apollo.MutationFunction<UnsubscribeSmsMutation, UnsubscribeSmsMutationVariables>;

/**
 * __useUnsubscribeSmsMutation__
 *
 * To run a mutation, you first call `useUnsubscribeSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeSmsMutation, { data, loading, error }] = useUnsubscribeSmsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnsubscribeSmsMutation(baseOptions?: Apollo.MutationHookOptions<UnsubscribeSmsMutation, UnsubscribeSmsMutationVariables>) {
        return Apollo.useMutation<UnsubscribeSmsMutation, UnsubscribeSmsMutationVariables>(UnsubscribeSmsDocument, baseOptions);
      }
export type UnsubscribeSmsMutationHookResult = ReturnType<typeof useUnsubscribeSmsMutation>;
export type UnsubscribeSmsMutationResult = Apollo.MutationResult<UnsubscribeSmsMutation>;
export type UnsubscribeSmsMutationOptions = Apollo.BaseMutationOptions<UnsubscribeSmsMutation, UnsubscribeSmsMutationVariables>;
export const GetCustomerDocument = gql`
    query getCustomer {
  getCustomer {
    ...CustomerData
  }
}
    ${CustomerDataFragmentDoc}`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, baseOptions);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, baseOptions);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const SubscribeOutOfStockProductDocument = gql`
    mutation subscribeOutOfStockProduct($input: OutOfStockNotificationInput!) {
  subscribeOutOfStockProduct(input: $input) {
    success
  }
}
    `;
export type SubscribeOutOfStockProductMutationFn = Apollo.MutationFunction<SubscribeOutOfStockProductMutation, SubscribeOutOfStockProductMutationVariables>;

/**
 * __useSubscribeOutOfStockProductMutation__
 *
 * To run a mutation, you first call `useSubscribeOutOfStockProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeOutOfStockProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeOutOfStockProductMutation, { data, loading, error }] = useSubscribeOutOfStockProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeOutOfStockProductMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeOutOfStockProductMutation, SubscribeOutOfStockProductMutationVariables>) {
        return Apollo.useMutation<SubscribeOutOfStockProductMutation, SubscribeOutOfStockProductMutationVariables>(SubscribeOutOfStockProductDocument, baseOptions);
      }
export type SubscribeOutOfStockProductMutationHookResult = ReturnType<typeof useSubscribeOutOfStockProductMutation>;
export type SubscribeOutOfStockProductMutationResult = Apollo.MutationResult<SubscribeOutOfStockProductMutation>;
export type SubscribeOutOfStockProductMutationOptions = Apollo.BaseMutationOptions<SubscribeOutOfStockProductMutation, SubscribeOutOfStockProductMutationVariables>;
export const EditOrderPaymentVerifyDocument = gql`
    mutation editOrderPaymentVerify($input: EditOrderPaymentVerifyInput!) {
  editOrderPaymentVerify(input: $input) {
    success
  }
}
    `;
export type EditOrderPaymentVerifyMutationFn = Apollo.MutationFunction<EditOrderPaymentVerifyMutation, EditOrderPaymentVerifyMutationVariables>;

/**
 * __useEditOrderPaymentVerifyMutation__
 *
 * To run a mutation, you first call `useEditOrderPaymentVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrderPaymentVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrderPaymentVerifyMutation, { data, loading, error }] = useEditOrderPaymentVerifyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrderPaymentVerifyMutation(baseOptions?: Apollo.MutationHookOptions<EditOrderPaymentVerifyMutation, EditOrderPaymentVerifyMutationVariables>) {
        return Apollo.useMutation<EditOrderPaymentVerifyMutation, EditOrderPaymentVerifyMutationVariables>(EditOrderPaymentVerifyDocument, baseOptions);
      }
export type EditOrderPaymentVerifyMutationHookResult = ReturnType<typeof useEditOrderPaymentVerifyMutation>;
export type EditOrderPaymentVerifyMutationResult = Apollo.MutationResult<EditOrderPaymentVerifyMutation>;
export type EditOrderPaymentVerifyMutationOptions = Apollo.BaseMutationOptions<EditOrderPaymentVerifyMutation, EditOrderPaymentVerifyMutationVariables>;
export const EditOrderPaymentInitializeDocument = gql`
    mutation editOrderPaymentInitialize($input: EditOrderPaymentSessionInitInput!) {
  editOrderPaymentInitialize(input: $input) {
    providerId
    paymentPayload
    paymentSessionId
    paymentAmount
    paymentSuccessUrl
    paymentCancelUrl
    transactionId
  }
}
    `;
export type EditOrderPaymentInitializeMutationFn = Apollo.MutationFunction<EditOrderPaymentInitializeMutation, EditOrderPaymentInitializeMutationVariables>;

/**
 * __useEditOrderPaymentInitializeMutation__
 *
 * To run a mutation, you first call `useEditOrderPaymentInitializeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrderPaymentInitializeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrderPaymentInitializeMutation, { data, loading, error }] = useEditOrderPaymentInitializeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrderPaymentInitializeMutation(baseOptions?: Apollo.MutationHookOptions<EditOrderPaymentInitializeMutation, EditOrderPaymentInitializeMutationVariables>) {
        return Apollo.useMutation<EditOrderPaymentInitializeMutation, EditOrderPaymentInitializeMutationVariables>(EditOrderPaymentInitializeDocument, baseOptions);
      }
export type EditOrderPaymentInitializeMutationHookResult = ReturnType<typeof useEditOrderPaymentInitializeMutation>;
export type EditOrderPaymentInitializeMutationResult = Apollo.MutationResult<EditOrderPaymentInitializeMutation>;
export type EditOrderPaymentInitializeMutationOptions = Apollo.BaseMutationOptions<EditOrderPaymentInitializeMutation, EditOrderPaymentInitializeMutationVariables>;
export const OrdersDocument = gql`
    query orders($tot: Int!, $cursor: String, $languageId: String!) {
  orders(tot: $tot, cursor: $cursor, languageId: $languageId) {
    orders {
      id
      uid
      type
      status
      createdAt
      totalPrice
      shippingAddress {
        firstName
        lastName
        country
        countryCode
        provinceCode
        city
        address
        address2
        zipCode
        phone
      }
    }
    hasNextPage
    cursor
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      tot: // value for 'tot'
 *      cursor: // value for 'cursor'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, baseOptions);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, baseOptions);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const OrderDocument = gql`
    query order($id: String!, $languageId: String!) {
  order(orderId: $id, languageId: $languageId) {
    ...OrderDetailsData
  }
}
    ${OrderDetailsDataFragmentDoc}`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, baseOptions);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, baseOptions);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const PaymentInitializeDocument = gql`
    mutation paymentInitialize($input: InitializePaymentSessionInputType!) {
  paymentInitialize(input: $input) {
    providerId
    paymentPayload
    transactionId
    paymentAmount
  }
}
    `;
export type PaymentInitializeMutationFn = Apollo.MutationFunction<PaymentInitializeMutation, PaymentInitializeMutationVariables>;

/**
 * __usePaymentInitializeMutation__
 *
 * To run a mutation, you first call `usePaymentInitializeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentInitializeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentInitializeMutation, { data, loading, error }] = usePaymentInitializeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentInitializeMutation(baseOptions?: Apollo.MutationHookOptions<PaymentInitializeMutation, PaymentInitializeMutationVariables>) {
        return Apollo.useMutation<PaymentInitializeMutation, PaymentInitializeMutationVariables>(PaymentInitializeDocument, baseOptions);
      }
export type PaymentInitializeMutationHookResult = ReturnType<typeof usePaymentInitializeMutation>;
export type PaymentInitializeMutationResult = Apollo.MutationResult<PaymentInitializeMutation>;
export type PaymentInitializeMutationOptions = Apollo.BaseMutationOptions<PaymentInitializeMutation, PaymentInitializeMutationVariables>;
export const PaymentVerifyDocument = gql`
    mutation paymentVerify($input: PaymentVerifyInput!) {
  paymentVerify(input: $input) {
    success
    data {
      ...CheckoutData
    }
  }
}
    ${CheckoutDataFragmentDoc}`;
export type PaymentVerifyMutationFn = Apollo.MutationFunction<PaymentVerifyMutation, PaymentVerifyMutationVariables>;

/**
 * __usePaymentVerifyMutation__
 *
 * To run a mutation, you first call `usePaymentVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentVerifyMutation, { data, loading, error }] = usePaymentVerifyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentVerifyMutation(baseOptions?: Apollo.MutationHookOptions<PaymentVerifyMutation, PaymentVerifyMutationVariables>) {
        return Apollo.useMutation<PaymentVerifyMutation, PaymentVerifyMutationVariables>(PaymentVerifyDocument, baseOptions);
      }
export type PaymentVerifyMutationHookResult = ReturnType<typeof usePaymentVerifyMutation>;
export type PaymentVerifyMutationResult = Apollo.MutationResult<PaymentVerifyMutation>;
export type PaymentVerifyMutationOptions = Apollo.BaseMutationOptions<PaymentVerifyMutation, PaymentVerifyMutationVariables>;
export const PaymentCheckDocument = gql`
    mutation paymentCheck($input: PaymentCheckInput!) {
  paymentCheck(input: $input) {
    fulfilled
  }
}
    `;
export type PaymentCheckMutationFn = Apollo.MutationFunction<PaymentCheckMutation, PaymentCheckMutationVariables>;

/**
 * __usePaymentCheckMutation__
 *
 * To run a mutation, you first call `usePaymentCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentCheckMutation, { data, loading, error }] = usePaymentCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentCheckMutation(baseOptions?: Apollo.MutationHookOptions<PaymentCheckMutation, PaymentCheckMutationVariables>) {
        return Apollo.useMutation<PaymentCheckMutation, PaymentCheckMutationVariables>(PaymentCheckDocument, baseOptions);
      }
export type PaymentCheckMutationHookResult = ReturnType<typeof usePaymentCheckMutation>;
export type PaymentCheckMutationResult = Apollo.MutationResult<PaymentCheckMutation>;
export type PaymentCheckMutationOptions = Apollo.BaseMutationOptions<PaymentCheckMutation, PaymentCheckMutationVariables>;
export const GetProductInventoryDataDocument = gql`
    query getProductInventoryData($input: GetCommerceProductInputType!) {
  getCommerceProduct(input: $input) {
    product {
      info {
        productId
        productSlug
        available
      }
    }
    variants {
      info {
        variantId
        default
        available
        archived
      }
      inventory {
        price
        compareAtPrice
        discountPercent
        maxSellQuantity
      }
    }
    defaultVariant {
      ...DefaultCatalogProductVariantSearchData
    }
    extraData {
      ...CatalogProductExtraDataInfo
    }
  }
}
    ${DefaultCatalogProductVariantSearchDataFragmentDoc}
${CatalogProductExtraDataInfoFragmentDoc}`;

/**
 * __useGetProductInventoryDataQuery__
 *
 * To run a query within a React component, call `useGetProductInventoryDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductInventoryDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductInventoryDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProductInventoryDataQuery(baseOptions: Apollo.QueryHookOptions<GetProductInventoryDataQuery, GetProductInventoryDataQueryVariables>) {
        return Apollo.useQuery<GetProductInventoryDataQuery, GetProductInventoryDataQueryVariables>(GetProductInventoryDataDocument, baseOptions);
      }
export function useGetProductInventoryDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductInventoryDataQuery, GetProductInventoryDataQueryVariables>) {
          return Apollo.useLazyQuery<GetProductInventoryDataQuery, GetProductInventoryDataQueryVariables>(GetProductInventoryDataDocument, baseOptions);
        }
export type GetProductInventoryDataQueryHookResult = ReturnType<typeof useGetProductInventoryDataQuery>;
export type GetProductInventoryDataLazyQueryHookResult = ReturnType<typeof useGetProductInventoryDataLazyQuery>;
export type GetProductInventoryDataQueryResult = Apollo.QueryResult<GetProductInventoryDataQuery, GetProductInventoryDataQueryVariables>;
export const GetCommerceProductDocument = gql`
    query getCommerceProduct($input: GetCommerceProductInputType!) {
  getCommerceProduct(input: $input) {
    product {
      ...CatalogProductSearchData
    }
    variants {
      ...CatalogProductVariantSearchData
    }
    defaultVariant {
      ...DefaultCatalogProductVariantSearchData
    }
    extraData {
      ...CatalogProductExtraDataInfo
    }
  }
}
    ${CatalogProductSearchDataFragmentDoc}
${CatalogProductVariantSearchDataFragmentDoc}
${DefaultCatalogProductVariantSearchDataFragmentDoc}
${CatalogProductExtraDataInfoFragmentDoc}`;

/**
 * __useGetCommerceProductQuery__
 *
 * To run a query within a React component, call `useGetCommerceProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommerceProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommerceProductQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCommerceProductQuery(baseOptions: Apollo.QueryHookOptions<GetCommerceProductQuery, GetCommerceProductQueryVariables>) {
        return Apollo.useQuery<GetCommerceProductQuery, GetCommerceProductQueryVariables>(GetCommerceProductDocument, baseOptions);
      }
export function useGetCommerceProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommerceProductQuery, GetCommerceProductQueryVariables>) {
          return Apollo.useLazyQuery<GetCommerceProductQuery, GetCommerceProductQueryVariables>(GetCommerceProductDocument, baseOptions);
        }
export type GetCommerceProductQueryHookResult = ReturnType<typeof useGetCommerceProductQuery>;
export type GetCommerceProductLazyQueryHookResult = ReturnType<typeof useGetCommerceProductLazyQuery>;
export type GetCommerceProductQueryResult = Apollo.QueryResult<GetCommerceProductQuery, GetCommerceProductQueryVariables>;
export const GetCommerceProductByVariantIdDocument = gql`
    query getCommerceProductByVariantId($input: GetCommerceProductByVariantIdInputType!) {
  getCommerceProductByVariantId(input: $input) {
    product {
      ...CatalogProductSearchData
    }
    variants {
      ...CatalogProductVariantSearchData
    }
    defaultVariant {
      ...DefaultCatalogProductVariantSearchData
    }
    extraData {
      ...CatalogProductExtraDataInfo
    }
  }
}
    ${CatalogProductSearchDataFragmentDoc}
${CatalogProductVariantSearchDataFragmentDoc}
${DefaultCatalogProductVariantSearchDataFragmentDoc}
${CatalogProductExtraDataInfoFragmentDoc}`;

/**
 * __useGetCommerceProductByVariantIdQuery__
 *
 * To run a query within a React component, call `useGetCommerceProductByVariantIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommerceProductByVariantIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommerceProductByVariantIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCommerceProductByVariantIdQuery(baseOptions: Apollo.QueryHookOptions<GetCommerceProductByVariantIdQuery, GetCommerceProductByVariantIdQueryVariables>) {
        return Apollo.useQuery<GetCommerceProductByVariantIdQuery, GetCommerceProductByVariantIdQueryVariables>(GetCommerceProductByVariantIdDocument, baseOptions);
      }
export function useGetCommerceProductByVariantIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommerceProductByVariantIdQuery, GetCommerceProductByVariantIdQueryVariables>) {
          return Apollo.useLazyQuery<GetCommerceProductByVariantIdQuery, GetCommerceProductByVariantIdQueryVariables>(GetCommerceProductByVariantIdDocument, baseOptions);
        }
export type GetCommerceProductByVariantIdQueryHookResult = ReturnType<typeof useGetCommerceProductByVariantIdQuery>;
export type GetCommerceProductByVariantIdLazyQueryHookResult = ReturnType<typeof useGetCommerceProductByVariantIdLazyQuery>;
export type GetCommerceProductByVariantIdQueryResult = Apollo.QueryResult<GetCommerceProductByVariantIdQuery, GetCommerceProductByVariantIdQueryVariables>;
export const GetCommerceProductsDocument = gql`
    query getCommerceProducts($input: GetCommerceProductsInputType!) {
  getCommerceProducts(input: $input) {
    product {
      ...CatalogProductSearchData
    }
    variants {
      ...CatalogProductVariantSearchData
    }
    defaultVariant {
      ...DefaultCatalogProductVariantSearchData
    }
    extraData {
      ...CatalogProductExtraDataInfo
    }
  }
}
    ${CatalogProductSearchDataFragmentDoc}
${CatalogProductVariantSearchDataFragmentDoc}
${DefaultCatalogProductVariantSearchDataFragmentDoc}
${CatalogProductExtraDataInfoFragmentDoc}`;

/**
 * __useGetCommerceProductsQuery__
 *
 * To run a query within a React component, call `useGetCommerceProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommerceProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommerceProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCommerceProductsQuery(baseOptions: Apollo.QueryHookOptions<GetCommerceProductsQuery, GetCommerceProductsQueryVariables>) {
        return Apollo.useQuery<GetCommerceProductsQuery, GetCommerceProductsQueryVariables>(GetCommerceProductsDocument, baseOptions);
      }
export function useGetCommerceProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommerceProductsQuery, GetCommerceProductsQueryVariables>) {
          return Apollo.useLazyQuery<GetCommerceProductsQuery, GetCommerceProductsQueryVariables>(GetCommerceProductsDocument, baseOptions);
        }
export type GetCommerceProductsQueryHookResult = ReturnType<typeof useGetCommerceProductsQuery>;
export type GetCommerceProductsLazyQueryHookResult = ReturnType<typeof useGetCommerceProductsLazyQuery>;
export type GetCommerceProductsQueryResult = Apollo.QueryResult<GetCommerceProductsQuery, GetCommerceProductsQueryVariables>;
export const GetCommerceVariantDocument = gql`
    query getCommerceVariant($input: GetCommerceVariantInputType!) {
  getCommerceVariant(input: $input) {
    ...CatalogProductVariantSearchData
  }
}
    ${CatalogProductVariantSearchDataFragmentDoc}`;

/**
 * __useGetCommerceVariantQuery__
 *
 * To run a query within a React component, call `useGetCommerceVariantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommerceVariantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommerceVariantQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCommerceVariantQuery(baseOptions: Apollo.QueryHookOptions<GetCommerceVariantQuery, GetCommerceVariantQueryVariables>) {
        return Apollo.useQuery<GetCommerceVariantQuery, GetCommerceVariantQueryVariables>(GetCommerceVariantDocument, baseOptions);
      }
export function useGetCommerceVariantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommerceVariantQuery, GetCommerceVariantQueryVariables>) {
          return Apollo.useLazyQuery<GetCommerceVariantQuery, GetCommerceVariantQueryVariables>(GetCommerceVariantDocument, baseOptions);
        }
export type GetCommerceVariantQueryHookResult = ReturnType<typeof useGetCommerceVariantQuery>;
export type GetCommerceVariantLazyQueryHookResult = ReturnType<typeof useGetCommerceVariantLazyQuery>;
export type GetCommerceVariantQueryResult = Apollo.QueryResult<GetCommerceVariantQuery, GetCommerceVariantQueryVariables>;
export const GetCommerceVariantsDocument = gql`
    query getCommerceVariants($input: GetCommerceVariantsInputType!) {
  getCommerceVariants(input: $input) {
    ...CatalogProductVariantSearchData
  }
}
    ${CatalogProductVariantSearchDataFragmentDoc}`;

/**
 * __useGetCommerceVariantsQuery__
 *
 * To run a query within a React component, call `useGetCommerceVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommerceVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommerceVariantsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCommerceVariantsQuery(baseOptions: Apollo.QueryHookOptions<GetCommerceVariantsQuery, GetCommerceVariantsQueryVariables>) {
        return Apollo.useQuery<GetCommerceVariantsQuery, GetCommerceVariantsQueryVariables>(GetCommerceVariantsDocument, baseOptions);
      }
export function useGetCommerceVariantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommerceVariantsQuery, GetCommerceVariantsQueryVariables>) {
          return Apollo.useLazyQuery<GetCommerceVariantsQuery, GetCommerceVariantsQueryVariables>(GetCommerceVariantsDocument, baseOptions);
        }
export type GetCommerceVariantsQueryHookResult = ReturnType<typeof useGetCommerceVariantsQuery>;
export type GetCommerceVariantsLazyQueryHookResult = ReturnType<typeof useGetCommerceVariantsLazyQuery>;
export type GetCommerceVariantsQueryResult = Apollo.QueryResult<GetCommerceVariantsQuery, GetCommerceVariantsQueryVariables>;
export const TimedVoucherActivateDocument = gql`
    mutation timedVoucherActivate($input: ActivateTimedVoucherInput!) {
  timedVoucherActivate(input: $input) {
    code
    expirationDate
    valid
  }
}
    `;
export type TimedVoucherActivateMutationFn = Apollo.MutationFunction<TimedVoucherActivateMutation, TimedVoucherActivateMutationVariables>;

/**
 * __useTimedVoucherActivateMutation__
 *
 * To run a mutation, you first call `useTimedVoucherActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimedVoucherActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timedVoucherActivateMutation, { data, loading, error }] = useTimedVoucherActivateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTimedVoucherActivateMutation(baseOptions?: Apollo.MutationHookOptions<TimedVoucherActivateMutation, TimedVoucherActivateMutationVariables>) {
        return Apollo.useMutation<TimedVoucherActivateMutation, TimedVoucherActivateMutationVariables>(TimedVoucherActivateDocument, baseOptions);
      }
export type TimedVoucherActivateMutationHookResult = ReturnType<typeof useTimedVoucherActivateMutation>;
export type TimedVoucherActivateMutationResult = Apollo.MutationResult<TimedVoucherActivateMutation>;
export type TimedVoucherActivateMutationOptions = Apollo.BaseMutationOptions<TimedVoucherActivateMutation, TimedVoucherActivateMutationVariables>;
export const TimedVoucherDocument = gql`
    query timedVoucher($code: String!) {
  timedVoucher(code: $code) {
    code
    expirationDate
    valid
  }
}
    `;

/**
 * __useTimedVoucherQuery__
 *
 * To run a query within a React component, call `useTimedVoucherQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimedVoucherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimedVoucherQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useTimedVoucherQuery(baseOptions: Apollo.QueryHookOptions<TimedVoucherQuery, TimedVoucherQueryVariables>) {
        return Apollo.useQuery<TimedVoucherQuery, TimedVoucherQueryVariables>(TimedVoucherDocument, baseOptions);
      }
export function useTimedVoucherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimedVoucherQuery, TimedVoucherQueryVariables>) {
          return Apollo.useLazyQuery<TimedVoucherQuery, TimedVoucherQueryVariables>(TimedVoucherDocument, baseOptions);
        }
export type TimedVoucherQueryHookResult = ReturnType<typeof useTimedVoucherQuery>;
export type TimedVoucherLazyQueryHookResult = ReturnType<typeof useTimedVoucherLazyQuery>;
export type TimedVoucherQueryResult = Apollo.QueryResult<TimedVoucherQuery, TimedVoucherQueryVariables>;
export const CreateReturnDocument = gql`
    mutation createReturn($input: ReturnInputType!) {
  createReturn(input: $input) {
    success
  }
}
    `;
export type CreateReturnMutationFn = Apollo.MutationFunction<CreateReturnMutation, CreateReturnMutationVariables>;

/**
 * __useCreateReturnMutation__
 *
 * To run a mutation, you first call `useCreateReturnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReturnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReturnMutation, { data, loading, error }] = useCreateReturnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReturnMutation(baseOptions?: Apollo.MutationHookOptions<CreateReturnMutation, CreateReturnMutationVariables>) {
        return Apollo.useMutation<CreateReturnMutation, CreateReturnMutationVariables>(CreateReturnDocument, baseOptions);
      }
export type CreateReturnMutationHookResult = ReturnType<typeof useCreateReturnMutation>;
export type CreateReturnMutationResult = Apollo.MutationResult<CreateReturnMutation>;
export type CreateReturnMutationOptions = Apollo.BaseMutationOptions<CreateReturnMutation, CreateReturnMutationVariables>;
export const FullTextSearchDocument = gql`
    query fullTextSearch($query: String!, $options: FullTextSearchOptions!) {
  fullTextSearch(query: $query, options: $options) {
    products {
      product {
        ...CatalogProductSearchData
      }
      variants {
        ...CatalogProductVariantSearchData
      }
      defaultVariant {
        ...DefaultCatalogProductVariantSearchData
      }
      extraData {
        ...CatalogProductExtraDataInfo
      }
    }
    brands {
      code
      name
      slug
    }
    categories {
      code
      name
      slug
    }
  }
}
    ${CatalogProductSearchDataFragmentDoc}
${CatalogProductVariantSearchDataFragmentDoc}
${DefaultCatalogProductVariantSearchDataFragmentDoc}
${CatalogProductExtraDataInfoFragmentDoc}`;

/**
 * __useFullTextSearchQuery__
 *
 * To run a query within a React component, call `useFullTextSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullTextSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullTextSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useFullTextSearchQuery(baseOptions: Apollo.QueryHookOptions<FullTextSearchQuery, FullTextSearchQueryVariables>) {
        return Apollo.useQuery<FullTextSearchQuery, FullTextSearchQueryVariables>(FullTextSearchDocument, baseOptions);
      }
export function useFullTextSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FullTextSearchQuery, FullTextSearchQueryVariables>) {
          return Apollo.useLazyQuery<FullTextSearchQuery, FullTextSearchQueryVariables>(FullTextSearchDocument, baseOptions);
        }
export type FullTextSearchQueryHookResult = ReturnType<typeof useFullTextSearchQuery>;
export type FullTextSearchLazyQueryHookResult = ReturnType<typeof useFullTextSearchLazyQuery>;
export type FullTextSearchQueryResult = Apollo.QueryResult<FullTextSearchQuery, FullTextSearchQueryVariables>;
export const StoresV2Document = gql`
    query storesV2($input: GetStoresInput!) {
  storesV2(input: $input) {
    id
    company
    name
    address {
      street
      cap
      city
      province
    }
    coordinates {
      latitude
      longitude
    }
    contacts {
      phone
    }
    businessHours {
      day
      hours
    }
    services {
      name
    }
    serviceReservationCalendar {
      url
    }
    storeVisitCalendar {
      url
    }
  }
}
    `;

/**
 * __useStoresV2Query__
 *
 * To run a query within a React component, call `useStoresV2Query` and pass it any options that fit your needs.
 * When your component renders, `useStoresV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoresV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStoresV2Query(baseOptions: Apollo.QueryHookOptions<StoresV2Query, StoresV2QueryVariables>) {
        return Apollo.useQuery<StoresV2Query, StoresV2QueryVariables>(StoresV2Document, baseOptions);
      }
export function useStoresV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoresV2Query, StoresV2QueryVariables>) {
          return Apollo.useLazyQuery<StoresV2Query, StoresV2QueryVariables>(StoresV2Document, baseOptions);
        }
export type StoresV2QueryHookResult = ReturnType<typeof useStoresV2Query>;
export type StoresV2LazyQueryHookResult = ReturnType<typeof useStoresV2LazyQuery>;
export type StoresV2QueryResult = Apollo.QueryResult<StoresV2Query, StoresV2QueryVariables>;
export const TrackEventDocument = gql`
    mutation trackEvent($input: TrackEventInput!) {
  trackEvent(input: $input) {
    success
  }
}
    `;
export type TrackEventMutationFn = Apollo.MutationFunction<TrackEventMutation, TrackEventMutationVariables>;

/**
 * __useTrackEventMutation__
 *
 * To run a mutation, you first call `useTrackEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackEventMutation, { data, loading, error }] = useTrackEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackEventMutation(baseOptions?: Apollo.MutationHookOptions<TrackEventMutation, TrackEventMutationVariables>) {
        return Apollo.useMutation<TrackEventMutation, TrackEventMutationVariables>(TrackEventDocument, baseOptions);
      }
export type TrackEventMutationHookResult = ReturnType<typeof useTrackEventMutation>;
export type TrackEventMutationResult = Apollo.MutationResult<TrackEventMutation>;
export type TrackEventMutationOptions = Apollo.BaseMutationOptions<TrackEventMutation, TrackEventMutationVariables>;
export const WishlistItemAddDocument = gql`
    mutation wishlistItemAdd($input: AddWishlistItemInput!) {
  wishlistItemAdd(input: $input) {
    ...WishlistData
  }
}
    ${WishlistDataFragmentDoc}`;
export type WishlistItemAddMutationFn = Apollo.MutationFunction<WishlistItemAddMutation, WishlistItemAddMutationVariables>;

/**
 * __useWishlistItemAddMutation__
 *
 * To run a mutation, you first call `useWishlistItemAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWishlistItemAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wishlistItemAddMutation, { data, loading, error }] = useWishlistItemAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWishlistItemAddMutation(baseOptions?: Apollo.MutationHookOptions<WishlistItemAddMutation, WishlistItemAddMutationVariables>) {
        return Apollo.useMutation<WishlistItemAddMutation, WishlistItemAddMutationVariables>(WishlistItemAddDocument, baseOptions);
      }
export type WishlistItemAddMutationHookResult = ReturnType<typeof useWishlistItemAddMutation>;
export type WishlistItemAddMutationResult = Apollo.MutationResult<WishlistItemAddMutation>;
export type WishlistItemAddMutationOptions = Apollo.BaseMutationOptions<WishlistItemAddMutation, WishlistItemAddMutationVariables>;
export const WishlistItemRemoveDocument = gql`
    mutation wishlistItemRemove($input: RemoveWishlistItemInput!) {
  wishlistItemRemove(input: $input) {
    ...WishlistData
  }
}
    ${WishlistDataFragmentDoc}`;
export type WishlistItemRemoveMutationFn = Apollo.MutationFunction<WishlistItemRemoveMutation, WishlistItemRemoveMutationVariables>;

/**
 * __useWishlistItemRemoveMutation__
 *
 * To run a mutation, you first call `useWishlistItemRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWishlistItemRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wishlistItemRemoveMutation, { data, loading, error }] = useWishlistItemRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWishlistItemRemoveMutation(baseOptions?: Apollo.MutationHookOptions<WishlistItemRemoveMutation, WishlistItemRemoveMutationVariables>) {
        return Apollo.useMutation<WishlistItemRemoveMutation, WishlistItemRemoveMutationVariables>(WishlistItemRemoveDocument, baseOptions);
      }
export type WishlistItemRemoveMutationHookResult = ReturnType<typeof useWishlistItemRemoveMutation>;
export type WishlistItemRemoveMutationResult = Apollo.MutationResult<WishlistItemRemoveMutation>;
export type WishlistItemRemoveMutationOptions = Apollo.BaseMutationOptions<WishlistItemRemoveMutation, WishlistItemRemoveMutationVariables>;
export const WishlistDocument = gql`
    query wishlist($id: String!) {
  wishlist(id: $id) {
    ...WishlistData
  }
}
    ${WishlistDataFragmentDoc}`;

/**
 * __useWishlistQuery__
 *
 * To run a query within a React component, call `useWishlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useWishlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWishlistQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWishlistQuery(baseOptions: Apollo.QueryHookOptions<WishlistQuery, WishlistQueryVariables>) {
        return Apollo.useQuery<WishlistQuery, WishlistQueryVariables>(WishlistDocument, baseOptions);
      }
export function useWishlistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WishlistQuery, WishlistQueryVariables>) {
          return Apollo.useLazyQuery<WishlistQuery, WishlistQueryVariables>(WishlistDocument, baseOptions);
        }
export type WishlistQueryHookResult = ReturnType<typeof useWishlistQuery>;
export type WishlistLazyQueryHookResult = ReturnType<typeof useWishlistLazyQuery>;
export type WishlistQueryResult = Apollo.QueryResult<WishlistQuery, WishlistQueryVariables>;
export const WishlistAvailabilityDocument = gql`
    query wishlistAvailability($input: WishlistAvailabilityInput!) {
  wishlistAvailability(input: $input) {
    id
    languageId
    items {
      variantId
      item {
        product {
          ...CatalogProductSearchData
        }
        variants {
          ...CatalogProductVariantSearchData
        }
        defaultVariant {
          ...DefaultCatalogProductVariantSearchData
        }
        extraData {
          ...CatalogProductExtraDataInfo
        }
      }
    }
  }
}
    ${CatalogProductSearchDataFragmentDoc}
${CatalogProductVariantSearchDataFragmentDoc}
${DefaultCatalogProductVariantSearchDataFragmentDoc}
${CatalogProductExtraDataInfoFragmentDoc}`;

/**
 * __useWishlistAvailabilityQuery__
 *
 * To run a query within a React component, call `useWishlistAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useWishlistAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWishlistAvailabilityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWishlistAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<WishlistAvailabilityQuery, WishlistAvailabilityQueryVariables>) {
        return Apollo.useQuery<WishlistAvailabilityQuery, WishlistAvailabilityQueryVariables>(WishlistAvailabilityDocument, baseOptions);
      }
export function useWishlistAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WishlistAvailabilityQuery, WishlistAvailabilityQueryVariables>) {
          return Apollo.useLazyQuery<WishlistAvailabilityQuery, WishlistAvailabilityQueryVariables>(WishlistAvailabilityDocument, baseOptions);
        }
export type WishlistAvailabilityQueryHookResult = ReturnType<typeof useWishlistAvailabilityQuery>;
export type WishlistAvailabilityLazyQueryHookResult = ReturnType<typeof useWishlistAvailabilityLazyQuery>;
export type WishlistAvailabilityQueryResult = Apollo.QueryResult<WishlistAvailabilityQuery, WishlistAvailabilityQueryVariables>;