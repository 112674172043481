import { urlFor } from "../../../cms/services/images"
import { ProductVariant } from "../../../types/product"
import { shippingPagePath, productPagePath } from "../../../root/routing/urls"
import { BasketItem } from "../../checkout/state/checkoutTypes"
import {
  IdentifyInput,
  TrackAddToCartInput,
  Tracker,
  TrackInitiateCheckoutInput,
  TrackPopupInput,
  TrackViewProductInput,
  TrackReservationsInput,
} from "../types"

const learnq = () => {
  if (!(window as any)._learnq) {
    ;(window as any)._learnq = []
  }
  return (window as any)._learnq
}

const track = (input: any[]) => {
  try {
    learnq()?.push(input)
  } catch (e) {
    console.error(
      `Klaviyo tracker -> error tracking event ${JSON.stringify(input)}`,
      e
    )
  }
}

const variantUrl = (variant: ProductVariant) =>
  `${window.location.hash}${productPagePath(variant.info.slug as string)}`

const variantImageUrl = (variant: ProductVariant) =>
  urlFor(variant.media.productImages[0]?.asset)

const variantCategories = (variant: ProductVariant) =>
  variant.metadata.categoryPath?.map((x) => x.name)

const lineItemData = (item: BasketItem) => ({
  ProductName: item.variant?.contents?.name,
  ProductID: item.variant?.info.variantId,
  SKU: item.variant?.info.variantId,
  ProductURL: variantUrl(item.variant as ProductVariant),
  ImageURL: variantImageUrl(item.variant as ProductVariant),
  ProductCategories: variantImageUrl(item.variant as ProductVariant),
  Quantity: item.order.quantity,
  ItemPrice: item.variant?.inventory?.price,
  RowTotal: item.order.total,
})

export const klaviyoTracker: Tracker = {
  name: "Klaviyo",
  enabled: function (): boolean {
    return true
  },
  initialize: function (): void {},
  identifyUser: function (input: IdentifyInput): void {
    track([
      "identify",
      {
        $email: input.email,
        $first_name: input.firstName,
        $last_name: input.lastName,
      },
    ])
  },
  trackPageView: function (): void {},
  trackAddToCart: function (input: TrackAddToCartInput): void {
    track([
      "track",
      "Added to Cart",
      {
        $value: input.item.productVariant.inventory?.price,
        AddedItemProductName: input.item.productVariant.contents?.name,
        AddedItemProductID: input.item.productVariant.info.variantId,
        AddedItemSKU: input.item.productVariant.info.variantId,
        AddedItemCategories: variantCategories(input.item.productVariant),
        AddedItemImageURL: variantImageUrl(input.item.productVariant),
        AddedItemURL: variantUrl(input.item.productVariant),
        AddedItemPrice: input.item.productVariant.inventory?.price,
        AddedItemQuantity: input.item.quantity,
        ItemNames: [input.item.productVariant.contents?.name],
        CheckoutURL: `${window.location.host}${shippingPagePath()}`,
        // Items: input.basket.items.map((x) => lineItemData(x)),
      },
    ])
  },
  trackRemoveFromCart: function (): void {},
  trackAddToWishlist: function (): void {},
  trackCompleteRegistration: function (): void {},
  trackContact: function (): void {},
  trackLead: function (): void {},
  trackViewProduct: function (input: TrackViewProductInput): void {
    track([
      "track",
      "Viewed Product",
      {
        ProductName: input.variant.contents?.name,
        ProductID: input.variant.info.variantId,
        SKU: input.variant.info.variantId,
        Categories: variantCategories(input.variant),
        ImageURL: variantImageUrl(input.variant),
        URL: variantUrl(input.variant),
        Brand: input.variant.metadata.brand?.name,
        Price: input.variant.inventory?.price,
        CompareAtPrice: input.variant.inventory?.compareAtPrice,
      },
    ])
  },
  trackInitiateCheckout: function (input: TrackInitiateCheckoutInput): void {
    track([
      "track",
      "Started Checkout",
      {
        $event_id: `${input.basket.id}_${new Date().getTime()}`,
        $value:
          input.basket.recap.total?.price ?? input.basket.recap.subtotal?.price,
        ItemNames: input.basket.items.map((x) => x.variant?.contents?.name),
        CheckoutURL: `${window.location.host}${shippingPagePath()}`,
        Categories: input.basket.items.map(
          (x) => x.variant?.metadata.categoryPath?.[0].name
        ),
        Items: input.basket.items.map((x) => lineItemData(x)),
        basketId: input.basket.id,
        totalPrice:
          input.basket.recap.total?.price ?? input.basket.recap.subtotal?.price,
        totArticles: input.basket.items.length,
      },
    ])
  },
  trackAddPromoCode: function (): void {},
  trackAddShippingInfo: function (): void {},
  trackAddPaymentInfo: function (): void {},
  trackPurchase: function (): void {},
  trackSearch: function (): void {},
  trackClickedProduct: function (): void {},
  trackPopup: (input: TrackPopupInput) => {
    track(["track", "Viewed Popup", input])
  },
  trackSelectProductGift: function (): void {},
  trackReservations: function (input: TrackReservationsInput): void {
    track(["track", "Reservations", input])
  },
}
