import Link from "next/link"
import React from "react"
import { SanityExternalLinkMarkDef } from "./types"

interface Props {
  mark: SanityExternalLinkMarkDef
  children: any
}

export const ExternalLinkMark = ({ mark, children }: Props) => {
  const target = mark.openInNewWindow ? "_blank" : undefined
  const href = mark.url ?? mark.href

  const isInternal = href?.startsWith("/") ?? false
  if (href && isInternal && !mark.openInNewWindow) {
    return (
      <Link href={href} passHref prefetch={false}>
        <a>{children}</a>
      </Link>
    )
  }

  return (
    <a href={href} target={target} rel={target ? "noopener" : undefined}>
      {children}
    </a>
  )
}
