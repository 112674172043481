import { readObject, removeObject, writeObject } from "@punks/core"
import { Basket } from "../state/checkoutTypes"

export const BASKET_STORAGE_KEY = "__checkout:basket_v2"

export const saveBasket = (basket: Basket) =>
  writeObject(BASKET_STORAGE_KEY, basket)

export const readBasket = () => readObject<Basket>(BASKET_STORAGE_KEY)
export const clearBasket = () => removeObject(BASKET_STORAGE_KEY)
