import { createSlice } from "@reduxjs/toolkit"

import { ForgotPasswordState } from "./forgotPasswordTypes"

const createInitialState = () =>
  ({
    isLoading: false,
    error: false,
    isMailSent: false,
  } as ForgotPasswordState)

const { actions, reducer } = createSlice({
  name: "forgotPassword",
  initialState: createInitialState(),
  reducers: {
    forgotPasswordStart: () => ({
      ...createInitialState(),
      isLoading: true,
    }),
    forgotPasswordSuccessful: () => ({
      isLoading: false,
      error: false,
      isMailSent: true,
    }),
    forgotPasswordError: () => ({
      isLoading: false,
      error: true,
      isMailSent: false,
    }),
  },
})

export const {
  forgotPasswordStart,
  forgotPasswordSuccessful,
  forgotPasswordError,
} = actions

export default reducer
