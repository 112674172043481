import { readObject, writeObject } from "../../../root/punks/utils/storage"
import { newUuid } from "../../../utils"

const SESSION_ID_KEY = "_internal_session_id"
const ANON_USER_ID_KEY = "_internal_anon_user_id"

export const getSessionId = (): string => readObject(SESSION_ID_KEY) as string
export const getAnonUserId = (): string =>
  readObject(ANON_USER_ID_KEY) as string

export const ensureSessionId = (): string => {
  const sessionId = getSessionId()
  if (sessionId) {
    return sessionId
  }

  const newSessionId = newUuid()
  writeObject(SESSION_ID_KEY, newSessionId)
  return newSessionId
}

export const ensureAnonUserId = (): string => {
  const anonUserId = getAnonUserId()
  if (anonUserId) {
    return anonUserId
  }

  const newAnonUserId = newUuid()
  writeObject(ANON_USER_ID_KEY, newAnonUserId)
  return newAnonUserId
}
