import React from "react"
import { Placeholders, TextProps, TextRenderer } from "./types"

export interface RichTextContextData {
  placeholders?: Placeholders
  typographyProps?: TextProps
  linkResolver: (pageId: string) => string | undefined
  textRenderer: TextRenderer
}

export const RichTextContext = React.createContext<RichTextContextData>(
  {} as any
)

export const useRichTextContext = () => React.useContext(RichTextContext)
