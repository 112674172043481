import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faFacebookF,
  faGoogle,
  faTwitter,
  faYoutube,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons"

import { faEnvelope, faLink } from "@fortawesome/free-solid-svg-icons"

export const importFaIcons = () => {
  library.add(
    faFacebookF,
    faYoutube,
    faInstagram,
    faGoogle,
    faTwitter,
    faWhatsapp,
    faEnvelope,
    faLink
  )
}
