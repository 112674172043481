import React from "react"
import NProgress from "nprogress"
import { WrapBody } from "../root/index"
import { SiteMeta } from "../features/seo/components/SiteMeta"
import "../assets/scss/styles.scss"
import Router from "next/router"
import "tailwindcss/tailwind.css"
import { trackPageView } from "../features/tracking"

const progressStart = () => NProgress.start()

const progressComplete = () => {
  NProgress.done()
  trackPageView()
}

const CustomApp = ({ Component, pageProps, router }: any) => {
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side")
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles)
    }

    Router.events.on("routeChangeStart", progressStart)
    Router.events.on("routeChangeComplete", progressComplete)
    Router.events.on("routeChangeError", progressComplete)

    return () => {
      Router.events.off("routeChangeStart", progressStart)
      Router.events.off("routeChangeComplete", progressComplete)
      Router.events.off("routeChangeError", progressComplete)
    }
  }, [])

  return (
    <>
      <WrapBody>
        <Component {...pageProps} key={router.route} />
      </WrapBody>
      <SiteMeta />
    </>
  )
}

export default CustomApp
