import React from "react"
import { MUIThemeProvider } from "./MUIThemeProvider"

interface Props {
  children: any
  themeId?: string
  themeOverrides?: any
}

export const MuiProviders = ({ children, themeId, themeOverrides }: Props) => {
  return (
    <MUIThemeProvider themeId={themeId} overrides={themeOverrides} withBaseline>
      {children}
    </MUIThemeProvider>
  )
}
