import React from "react"
import { useSettingsInitialized } from "../../../modules/settings/state/hooks"
import { useAppDispatch } from "../../../state/store"
import { useAppUser } from "../../user/state/userHooks"
import { loadUserWishlist } from "../state/actions/loadUserWishlist"

interface Props {
  children: any
}

export const WishlistInitializer = ({ children }: Props) => {
  const dispatch = useAppDispatch()
  const initialized = useSettingsInitialized()
  const user = useAppUser()

  React.useEffect(() => {
    if (!initialized || !user.initialized) {
      return
    }
    dispatch(loadUserWishlist())
  }, [user.account?.email, initialized])

  return <>{children}</>
}
