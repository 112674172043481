export interface OperationState {
  called: boolean
  running: boolean
  success: boolean | undefined
  error: boolean | undefined
  errorDetails: any
}

export const ready = (): OperationState => ({
  called: false,
  running: false,
  success: undefined,
  error: undefined,
  errorDetails: undefined,
})

export const running = (): OperationState => ({
  called: true,
  running: true,
  success: undefined,
  error: undefined,
  errorDetails: undefined,
})

export const success = (): OperationState => ({
  called: true,
  running: false,
  success: true,
  error: false,
  errorDetails: undefined,
})

export const error = (errorDetails?: any): OperationState => ({
  called: true,
  running: false,
  success: false,
  error: true,
  errorDetails,
})
