import { ThemeColor } from "@punks/core"
import React from "react"
import { getThemeColor } from "../../../themes/utils"

interface SpinProps {
  innerWidth: number
  borderWidth: number
  margin: number
  className?: string
  color: ThemeColor
}

const Spin = ({
  className,
  innerWidth,
  borderWidth,
  margin,
  color,
}: SpinProps) => {
  return (
    <div
      className={`lds-ring ${className ?? ""}`}
      style={{
        width: innerWidth + margin * 2,
        height: innerWidth + margin * 2,
      }}
    >
      {[0, 1, 2, 3].map((x) => (
        <div
          key={x}
          style={{
            width: innerWidth,
            height: innerWidth,
            margin,
            borderWidth,
            borderColor: `${getThemeColor(
              color
            )} transparent transparent transparent`,
          }}
        ></div>
      ))}
    </div>
  )
}

Spin.defaultProps = {
  innerWidth: 44,
  borderWidth: 4,
  margin: 12,
  color: "primary" as ThemeColor,
}

export default Spin
