import React from "react"
import { SanityInternalLinkMarkDef } from "./types"
import { RichTextContext } from "../../context"
import { useRouter } from "next/router"

interface Props {
  mark: SanityInternalLinkMarkDef
  children: any
}

export const InternalLinkMark = ({ mark, children }: Props) => {
  const router = useRouter()
  const { linkResolver } = React.useContext(RichTextContext)
  if (!mark.reference) {
    console.error(`Missing reference for link marker in doc ${mark._key}`)
    return <>{children}</>
  }

  const link = linkResolver(mark.reference._id)
  if (!link) {
    console.error(
      `Broken reference ${mark.reference._id} for link marker in doc ${mark._key}`
    )
    return <>{children}</>
  }

  return <a onClick={() => router.push(link)}>{children}</a>
}
