import React from "react"
import { useSettingsInitialized } from "../../../../modules/settings/state/hooks"
import { useAppDispatch } from "../../../../state/store"
import {
  loadAnonymousProfile,
  loadAuthenticatedProfile,
} from "../../../user/actions"
import { readAuthToken } from "../../services/authService"

interface Props {
  children: React.ReactNode
}

const AuthInitializer = ({ children }: Props) => {
  const dispatch = useAppDispatch()
  const initialized = useSettingsInitialized()

  React.useEffect(() => {
    if (initialized) {
      const token = readAuthToken()
      if (!token) {
        dispatch(loadAnonymousProfile())
      } else {
        dispatch(loadAuthenticatedProfile())
      }
    }
  }, [initialized])

  return <>{children}</>
}

export default AuthInitializer
