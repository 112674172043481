import React from "react"
import { WebsiteSettings } from "../../contents/options/types"
import { isActiveDate } from "../triggers/timer"
import { RootContentsContext } from "./context"
import { ExtraContentMessage } from "./types"
import { hasValidDate } from "./utils"

export const useRootContents = () => React.useContext(RootContentsContext)
export const useProfileMenuData = () => useRootContents().profileMenu

export const useLayoutData = () => useRootContents()?.layout?.data
export const useExtraContents = () => useLayoutData()?.extraContents
export const useSettings = (): WebsiteSettings =>
  useRootContents()?.websiteSettings as WebsiteSettings

export const useExtraContentBlock = (id: string) =>
  useExtraContents()?.blocks?.find((x: any) => x.id === id)?.html

export const useExtraContentMessage = (
  id: string
): ExtraContentMessage | undefined =>
  useExtraContents()?.messages?.find(
    (x: ExtraContentMessage) =>
      x.id === id &&
      x.disabled !== true &&
      hasValidDate(
        {
          validFrom: x.validFrom,
          validTo: x.validTo,
        },
        new Date()
      )
  )

export const useActivePromo = () => {
  const { promotions } = useRootContents()

  return promotions?.filter((x) =>
    isActiveDate(x.data?.info?.validFrom, x.data?.info?.validTo)
  )?.[0]
}
