import React from "react"
import LezdaButton from "./LezdaButton"
import classNames from "classnames"
import { ThemeColor } from "@punks/core"
import classes from "./CustomButton.module.css"
import Ripple from "material-ripple-effects"
import Spin from "../Spin"

export type ButtonVariant = "text" | "outlined" | "contained"
export type ButtonSize = "small" | "medium" | "large"
export type ButtonLabelAlignment = "left" | "right" | "center"

export interface CustomButtonClasses {
  button?: string
  inner?: string
}

export interface CustomButtonProps {
  classes?: CustomButtonClasses
  children: any
  size?: ButtonSize
  disabled?: boolean
  fullWidth?: boolean
  mobileFullWidth?: boolean
  variant?: ButtonVariant
  alignContent?: ButtonLabelAlignment
  className?: string
  color?: ThemeColor
  onClick?: () => void
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>
  invariantCase?: boolean
  hoverInvariant?: boolean
  noMinWidth?: boolean
  preventDefault?: boolean
  noGutters?: boolean
  underline?: boolean
  loading?: boolean
  vCenter?: boolean
  style?: React.CSSProperties
  innerStyle?: React.CSSProperties
  type?: "submit" | "reset" | "button"
  ripple?: "light" | "dark"
}

const CustomButton = ({
  classes: userClasses,
  children,
  size,
  disabled,
  fullWidth,
  variant,
  className,
  color,
  onClick,
  onMouseDown,
  invariantCase,
  hoverInvariant,
  noMinWidth,
  preventDefault,
  noGutters,
  underline,
  loading,
  innerStyle,
  type,
  vCenter,
  ripple,
  style,
}: CustomButtonProps) => {
  const buttonClasses = classNames(
    classes.root,
    {
      [classes.invariantCase]: invariantCase,
      [classes.noMinWidth]: noMinWidth,
      [classes.underline]: underline,
    },
    userClasses?.button,
    className
  )

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (loading || disabled) {
      return
    }

    onClick?.()
    if (e && preventDefault) {
      e.preventDefault()
    }
  }

  const Inner = () => (
    <>
      {loading === true && (
        <div className={classes.loaderContainer}>
          <Spin
            innerWidth={27}
            margin={0}
            borderWidth={3}
            color={variant === "contained" ? "white" : "primary"}
            // className={classNames(
            //   {
            //     [classes.loaderOutlined]: variant === "outlined",
            //     [classes.loaderContained]: variant === "contained",
            //   },
            //   classes.loader
            // )}
          />
        </div>
      )}
      <span
        className={classNames(
          { "opacity-0": loading === true },
          { "flex items-center": vCenter },
          userClasses?.inner
        )}
        style={innerStyle}
      >
        {children}
      </span>
    </>
  )

  if (variant === "contained" || variant === "outlined") {
    return (
      <LezdaButton
        type={type}
        fullWidth={fullWidth}
        disabled={disabled}
        size={size}
        className={buttonClasses}
        onClick={handleClick}
        onMouseDown={onMouseDown}
        color={color}
        variant={variant}
        noGutters={noGutters}
        invariantCase={invariantCase}
        hoverInvariant={hoverInvariant}
      >
        <Inner />
      </LezdaButton>
    )
  }
  const rippleEffect = new Ripple()
  return (
    <button
      className={classNames(
        "border-0",
        fullWidth && "w-full",
        invariantCase !== true && classes.textUppercase,
        disabled !== true && classes.text,
        size === "small" && classes.textSmall,
        size === "medium" && classes.textMedium,
        size === "large" && classes.textLarge,
        noGutters && classes.noGutters,
        buttonClasses
      )}
      onClick={handleClick}
      disabled={disabled}
      onMouseUp={(e) => {
        disabled !== true && ripple === "dark" && rippleEffect.create(e, "dark")
        disabled !== true &&
          ripple === "light" &&
          rippleEffect.create(e, "light")
      }}
      onMouseDown={onMouseDown}
      style={style}
    >
      <Inner />
    </button>
  )
}

CustomButton.defaultProps = {
  size: "medium",
  variant: "contained",
  ripple: "dark",
}

export default CustomButton
