const replacePlaceholder = (
  value: string,
  placeHolderName: string,
  placeholderValue: string
) => {
  return value.replace("${" + placeHolderName + "}", placeholderValue)
}

export const replacePlaceholders = (value: string, placeholders: any) => {
  for (const placeholder of Object.keys(placeholders)) {
    value = replacePlaceholder(value, placeholder, placeholders[placeholder])
  }
  return value
}
