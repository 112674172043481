import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PopupState, TriggeredPopup } from "./popupTypes"

const popup = createSlice({
  name: "popup",
  initialState: {
    opened: false,
  } as PopupState,
  reducers: {
    setPopupOpen(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        opened: action.payload,
      }
    },
    triggerPopup(state, action: PayloadAction<TriggeredPopup>) {
      return {
        ...state,
        opened: true,
        triggeredPopup: action.payload,
      }
    },
  },
})

export const { setPopupOpen, triggerPopup } = popup.actions

export default popup.reducer
