import React from "react"
import { SanityImageNode } from "./types"

interface Props {
  node: SanityImageNode
}

export const ImageSerializer = ({ node }: Props) => {
  return (
    <img
      src={node.image.asset.url}
      alt={node.alt}
      style={{
        width: node.size ?? "100%",
      }}
    />
  )
}
