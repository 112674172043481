import React from "react"
import SanityPunksProviders from "./sanity"
import CoreProviders from "./core"
import { AppThemesContext } from "@punks/core"
import { defaultAppTheme } from "../../themes/default"
import RichTextProviders from "./concrete/RichTextProviders"
import { MuiProviders } from "./mui/MuiProviders"

interface Props {
  children: any
}

export const PunksProviders = ({ children }: Props) => {
  return (
    <CoreProviders>
      <SanityPunksProviders>
        <AppThemesContext.Provider value={[defaultAppTheme]}>
          <RichTextProviders>
            <MuiProviders>{children}</MuiProviders>
          </RichTextProviders>
        </AppThemesContext.Provider>
      </SanityPunksProviders>
    </CoreProviders>
  )
}
