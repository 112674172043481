import React from "react"
import { toMap } from "../../../../utils/array"
import { SanityMarkDef } from "./types"

export type MarkSerializer = (
  marker: SanityMarkDef,
  children: any
) => React.ReactElement

export const MarkSerializerContext = React.createContext<MarkSerializer>(() => {
  throw new Error("Missing mark serializer context")
})

export interface MarkSerializerDefinition {
  type: string
  component: React.ElementType
}

interface Props {
  definitions: MarkSerializerDefinition[]
  children: any
}

export const MarksSerializationProvider = ({
  definitions,
  children,
}: Props) => {
  const definitionsMap = toMap(definitions, (x) => x.type)

  const serializeMark = (mark: SanityMarkDef, children: any) => {
    const definition = definitionsMap.get(mark._type)
    if (!definition) {
      console.error(`Undefined mark type ${mark._type} (key: ${mark._key})`)
    }

    const Serializer = definition?.component as any
    return <Serializer mark={mark}>{children}</Serializer>
  }

  return (
    <MarkSerializerContext.Provider value={serializeMark}>
      {children}
    </MarkSerializerContext.Provider>
  )
}

interface MarkSerializerProps {
  mark: SanityMarkDef
  children: any
}

export const MarkSerializer = ({ mark, children }: MarkSerializerProps) => {
  const serializer = React.useContext(MarkSerializerContext)
  return serializer(mark, children)
}

interface MarkWrapperProps {
  marks: SanityMarkDef[]
  children: any
}

export const MarksWrapper = ({ marks, children }: MarkWrapperProps) => {
  if (marks.length === 0) {
    return <>{children}</>
  }

  return (
    <MarkSerializer mark={marks[0]}>
      {marks.length > 1 ? (
        <MarksWrapper marks={marks.slice(1)}>{children}</MarksWrapper>
      ) : (
        <>{children}</>
      )}
    </MarkSerializer>
  )
}
