import React from "react"
import { RootProviders } from "@punks/core"
import { buildPagePath } from "../../routing/urls"

interface Props {
  children: any
}

const CoreProviders = ({ children }: Props) => {
  return (
    <RootProviders
      urlBuilder={buildPagePath}
      metadata={{
        description:
          "Entra nel mondo Modus: scopri le nostre offerte su profumi, make-up e tanto altro.",
        title: "Modus Profumerie",
      }}
    >
      {children}
    </RootProviders>
  )
}

export default CoreProviders
