import React from "react"
import { JsonLd } from "react-schemaorg"
import { Organization } from "schema-dts"
import { COVER_IMAGE } from "../data/meta"

const OrganizationJsonLD = () => {
  return (
    <JsonLd<Organization>
      item={{
        "@context": "https://schema.org/" as any,
        "@type": "Organization",
        name: "Modus Profumerie",
        legalName: "Vallesi S.p.A.",
        image: COVER_IMAGE,
        address: {
          "@type": "PostalAddress",
          streetAddress: "Via Pietro Massia 13",
          addressLocality: "Savigliano",
          addressRegion: "Cuneo",
          postalCode: "12038",
        },
        telephone: "+39 017296269",
      }}
    />
  )
}

export default OrganizationJsonLD
