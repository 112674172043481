import React from "react"
import { serializeNode } from "./node"
import { renderNodes } from "./nodesProcessor"
import { listItemProcessor } from "./nodesProcessor/listItems"
import { NodesProcessor } from "./nodesProcessor/types"
import { NodeSerializerDefinition, Placeholders, SanityNode } from "./types"

export const serializeNodes = (
  definitions: Map<string, NodeSerializerDefinition>,
  nodes: SanityNode[],
  data: { placeholders?: Placeholders }
) => {
  if (!Array.isArray(nodes)) {
    console.error("Invalid nodes type, expecting array and got this", nodes)
    return <></>
  }

  const defaultProcessor: NodesProcessor = (nodes) => {
    return (
      <>
        {nodes?.map((node) => (
          <React.Fragment key={node._key}>
            {serializeNode(definitions, node, data)}
          </React.Fragment>
        ))}
      </>
    )
  }
  const processors = [listItemProcessor, defaultProcessor]

  return <>{renderNodes(nodes, processors)}</>
}
