import classNames from "classnames"
import React from "react"
import styles from "./index.module.css"
import {
  FontWeight,
  TextProps,
  ThemeColor,
} from "../../../root/punks/sanity/richText/types"

export interface CustomTypographyProps extends Omit<TextProps, "component"> {
  children: React.ReactNode
  className?: string
  onClick?: any
  style?: React.CSSProperties
  component?: keyof JSX.IntrinsicElements
  fontFamily?: string
  lineTrough?: boolean
  noWrap?: boolean
}

interface CompProps {
  tag: keyof JSX.IntrinsicElements
}

const HtmlTag: React.FunctionComponent<
  CompProps & React.HTMLAttributes<HTMLOrSVGElement>
> = ({ tag: Wrapper = "p", children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>
}

const variantProps: { [key: string]: keyof JSX.IntrinsicElements } = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  body1: "p",
  body2: "p",
  inherit: "span",
  caption: "span",
  button: "span",
  overline: "span",
  subtitle1: "h6",
  subtitle2: "h6",
}
const fontWeight = (weight: FontWeight) => {
  switch (weight) {
    // react mui -> tw map
    case "bold":
      return "font-normal"
    case "boldest":
      return "font-semibold"
    case "light":
      return "font-extralight"
    case "lightest":
      return "font-thin"
    case "normal":
      return "font-light"
    default:
      return null
  }
}

const getColor = (color: ThemeColor) => {
  switch (color) {
    case "disabled":
      return "text-disabled"
    case "neutral":
      return "text-lightestGray"
    case "transparent":
      return "text.transparent"
    case "black":
      return "text-black"
    case "white":
      return "text-white"
    case "error":
      return "text-error"
    case "primary":
      return "text-primary"
    case "secondary":
      return "text-secondary"
    case "success":
      return "text-successText"
    case "warning":
      return "text-warningText"
    case "info":
      return "text-infoText"
    default:
      return null
  }
}

const Typography = ({
  children,
  className,
  style,
  onClick,
  fontFamily,
  ...props
}: CustomTypographyProps) => {
  return (
    <HtmlTag
      onClick={onClick}
      tag={
        props.component ??
        (props.variant && variantProps[props.variant]) ??
        "span"
      }
      className={classNames(
        "tw-typography",
        !props.fillLine && styles.noFillLine,
        props.fillLine && styles.fillLine,
        props.variant && styles[props.variant],
        props.capitalize && "capitalize",
        props.ellipsis && "truncate",
        props.lowercase && "lowercase",
        props.uppercase && "uppercase",
        props.textJustify && "text-justify",
        props.textLeft && "text-left",
        props.textRight && "text-right",
        props.textCenter && "text-center",
        props.underline && "underline",
        props.lineTrough && "line-through",
        props.noWrap && "whitespace-nowrap",
        props.weight && props.weightValue == null && fontWeight(props.weight),
        props.color && props.colorValue == null && getColor(props.color),
        props.weightValue != null && styles.fontWeight,
        props.colorValue != null && styles.color,
        props.lineHeight &&
          props.lineHeightValue == null &&
          styles[`${props.lineHeight}LineHeight`],
        props.fontSize &&
          props.fontSizeValue == null &&
          styles[`${props.fontSize}FontSize`],
        props.textClassName,
        className
      )}
      style={{
        ["--ty-fw" as any]: props.weightValue ?? undefined,
        ["--ty-lh" as any]: props.lineHeightValue ?? undefined,
        ["--ty-fs" as any]: props.fontSizeValue ?? undefined,
        ["--ty-col" as any]: props.colorValue ?? undefined,
        fontFamily,
        ...style,
      }}
    >
      {children}
    </HtmlTag>
  )
}

Typography.defaultProps = {
  variant: "inherit",
}

export default Typography
