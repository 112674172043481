import { addDays } from "date-fns"
import React from "react"
import { useRootContents } from "../../aggregators/rootData"
import { initTracking } from "../../features/tracking"
import {
  ensureAnonUserId,
  ensureSessionId,
} from "../../features/tracking/internal/storage"
import { initUserSession } from "../../features/user/actions"
import { useSettingsInitialized } from "../../modules/settings/state/hooks"
import { useAppDispatch } from "../../state/store"
import { getQueryParam } from "../../utils/url"
import { writeCookie } from "../punks/utils/cookies"

const AWIN_CAMPAIGN_EXP_DAYS = 30
const getAwinAwc = () => getQueryParam(window.location, "awc")

const CAMPAIGN_PROMO_EXP_DAYS = 30
const getCampaignPromo = () => getQueryParam(window.location, "campaignPromo")

const InternalTracking = () => {
  const dispatch = useAppDispatch()
  const initialized = useSettingsInitialized()
  const { websiteSettings } = useRootContents()

  React.useEffect(() => {
    if (!initialized) {
      return
    }

    const sessionId = ensureSessionId()
    const userToken = ensureAnonUserId()
    dispatch(
      initUserSession({
        userToken,
        sessionId,
      })
    )

    initTracking()

    if (websiteSettings?.internal?.trackingEnabled) {
      ;(window as any).internalTracking = {
        enabled: websiteSettings?.internal?.trackingEnabled,
      }
    }

    const awc = getAwinAwc()
    if (awc) {
      writeCookie("awinCks", awc, addDays(new Date(), AWIN_CAMPAIGN_EXP_DAYS))
    }

    const campaignPromo = getCampaignPromo()
    if (campaignPromo) {
      writeCookie(
        "campaignPromo",
        campaignPromo,
        addDays(new Date(), CAMPAIGN_PROMO_EXP_DAYS)
      )
    }
  }, [initialized])

  return <></>
}

export default InternalTracking
