import {
  CatalogProductVariant,
  CheckoutBasketDiscount,
  CheckoutDataFragment,
  CheckoutLineItem,
  CheckoutShippingAddress,
  ShippingPreference,
} from "../../../../api/backend/backend-api"
import { toProduct, toVariant } from "../../../../converters/catalog"
import { ProductVariant } from "../../../../types/product"
import { addDays } from "../../../../utils/dates"
import { moneyAmount } from "../../../../utils/money"
import {
  Basket,
  BasketItem,
  BasketPromo,
  ShippingAddress,
} from "../checkoutTypes"

export const toBasketPromo = (item: CheckoutBasketDiscount): BasketPromo => ({
  code: item.code,
  name: item.name,
  id: item.id,
  totalDiscount: item.totalDiscount ?? undefined,
  logoUrl: item.item?.contents.logoUrl ?? undefined,
})

const toLineItem = (
  checkout: CheckoutDataFragment,
  lineItem: CheckoutLineItem,
  itemIndex: number
): BasketItem => {
  const product = lineItem.product
    ? toProduct(lineItem.product.document)
    : undefined
  const variant = product?.variants.find(
    (x) => x.info.variantId === lineItem.product?.variant.info.variantId
  ) as ProductVariant
  return {
    index: itemIndex,
    type: lineItem.assignedGiftCardCode ? "giftcard" : "product",
    giftcardCode: lineItem.assignedGiftCardCode ?? undefined,
    product,
    variant,
    source: lineItem.source ?? undefined,
    order: {
      gift: lineItem.isGift,
      maxQuantity: variant?.inventory?.maxSellQuantity ?? (0 as number),
      quantity: lineItem.quantity,
      total: {
        originalPrice: lineItem.originalTotal,
        discountedPrice: lineItem.discountedTotal ?? undefined,
        compareAtPrice: lineItem.originalTotalCompareAtPrice ?? undefined,
        currency: checkout.price.currency,
        discountPercent: lineItem.discountPercent ?? undefined,
        extraDiscountPercent: lineItem.extraDiscountPercent ?? undefined,
      },
      promoCodes: lineItem.promoCodes ?? [],
    },
  }
}

const toShippingAddress = (
  address?: CheckoutShippingAddress
): ShippingAddress => ({
  address: address?.address ?? "",
  address2: address?.address2 ?? "",
  city: address?.city ?? "",
  country: "",
  countryCode: address?.countryCode ?? "",
  firstName: address?.firstName ?? "",
  lastName: address?.lastName ?? "",
  phone: address?.phone ?? "",
  province: "",
  provinceCode: address?.provinceCode ?? "",
  zip: address?.zipCode ?? "",
  addressId: address?.customerAddressId ?? undefined,
})

export const toBasket = (checkout: CheckoutDataFragment): Basket => {
  // nascondo gli articoli che sono diventati out of stock
  const lineItems = checkout.lineItems.filter((x) => x.quantity > 0)
  return {
    id: checkout.id,
    customer: {
      email: checkout.customer.email ?? undefined,
      acceptsMarketing: checkout.customer.acceptsMarketing ?? false,
    },
    selectedPayment: checkout.selectedPayment
      ? {
          captureType: checkout.selectedPayment.captureType,
          providerId: checkout.selectedPayment.providerId,
        }
      : undefined,
    promoState: {
      acceptedNoLoggedPromo: checkout.promoState.acceptedNoLoggedPromo,
      hasLoggedPromoInBasket: checkout.promoState.hasLoggedPromoInBasket,
      authenticatedBasket: checkout.promoState.authenticatedBasket,
    },
    empty: lineItems.length === 0,
    items: lineItems.map((x, index) =>
      toLineItem(checkout, x as CheckoutLineItem, index)
    ),
    giftItems:
      checkout.giftLineItems?.map((x, index) =>
        toLineItem(checkout, x as CheckoutLineItem, index)
      ) ?? [],
    unavailableItems: checkout.unavailableLineItems?.map((x) => ({
      variant: toVariant(x.variant as CatalogProductVariant),
    })),
    recap: {
      total: checkout.price.total
        ? {
            currency: checkout.price.currency,
            price: checkout.price.total,
          }
        : undefined,
      totalToPay:
        checkout.price.totalToPay !== undefined &&
        checkout.price.totalToPay !== null
          ? {
              currency: checkout.price.currency,
              price: checkout.price.totalToPay as number,
            }
          : undefined,
      totalToPayLater:
        checkout.price.totalToPayLater !== undefined &&
        checkout.price.totalToPayLater !== null
          ? {
              currency: checkout.price.currency,
              price: checkout.price.totalToPayLater as number,
            }
          : undefined,
      currierPaymentPrice: checkout.price.currierPaymentPrice
        ? {
            currency: checkout.price.currency,
            price: checkout.price.currierPaymentPrice as number,
          }
        : undefined,
      giftcards: checkout.price.appliedGiftcards ?? [],
      basketDiscounts: checkout.basketDiscounts.map((x) => toBasketPromo(x)),
      articlesNumber: lineItems.length,
      shipping:
        checkout.price.shippingPrice !== null
          ? {
              currency: checkout.price.currency,
              price: checkout.price.shippingPrice as number,
              compareAtPrice: checkout.price.shippingDiscount ?? undefined,
            }
          : undefined,
      subtotal: {
        currency: checkout.price.currency,
        price: checkout.price.subtotal,
      },
      discountTotal: checkout.price.discountTotal
        ? {
            currency: checkout.price.currency,
            price: checkout.price.discountTotal,
          }
        : undefined,
    },
    shipping: {
      requestInvoice: false,
      free:
        checkout.price.subtotal >= checkout.price.defaultFreeShippingThreshold,
      freeThreshold: checkout.price.defaultFreeShippingThreshold,
      freeShippingAmountLeft: moneyAmount(
        Math.max(
          0,
          checkout.price.defaultFreeShippingThreshold - checkout.price.subtotal
        )
      ),
      hasFreeShippingPromo: checkout.price.hasFreeShippingPromo ?? false,
      // freeShippingPromoCode: checkout.price.freeShippingPromoCode ?? undefined,
      shippingPrice: checkout.price.defaultShippingPrice,
      minDate: addDays(new Date(), 1).getTime(),
      maxDate: addDays(new Date(), 1).getTime(),
      shippingAddress: toShippingAddress(
        checkout.shipping?.shippingAddress as CheckoutShippingAddress
      ),
      billingAddress: toShippingAddress(
        checkout.shipping?.billingAddress as CheckoutShippingAddress
      ),
      shippingMethod:
        checkout.shipping?.preference === ShippingPreference.ClickAndCollect
          ? "store"
          : "courier",
      store: checkout.shipping?.store
        ? {
            id: checkout.shipping.store.id,
            address: checkout.shipping.store.address.street,
            city: checkout.shipping.store.address.city,
            cap: checkout.shipping.store.address.cap,
            province: checkout.shipping.store.address.province,
            company: checkout.shipping.store.company,
            name: checkout.shipping.store.name,
          }
        : undefined,
    },
    promotions: checkout.promotions.map((x) => ({
      id: x.id,
      code: x.code,
      discount: x.amount,
      trigger: x.trigger,
    })),
    samples: checkout.samples ?? {
      samples: [],
    },
    shopify: {
      id: checkout.id,
    },
    order: checkout.order
      ? {
          ...checkout.order,
          id: checkout.order.id ?? undefined,
          alias: checkout.order.uid ?? undefined,
          orderDate: checkout.order.orderDate ?? undefined,
        }
      : undefined,
    settings: {
      clickAndCollectEnabled:
        checkout.settings?.clickAndCollectEnabled ?? false,
      giftcardEnabled: checkout.settings?.giftcardEnabled ?? false,
      giftBoxEnabled: checkout.settings?.giftBoxEnabled ?? false,
      enabledPaymentMethods: checkout.settings?.enabledPaymentMethods ?? [],
    },
    customerDiscounts: {
      birthdayDiscount: checkout.customerDiscounts?.birthdayDiscount ?? false,
      passagesDiscount: checkout.customerDiscounts?.passagesDiscount ?? false,
      promoCode: checkout.customerDiscounts?.promoCode ?? false,
      points: checkout.customerDiscounts?.pointDiscounts ?? [],
    },
    lock: {
      locked: checkout.lock?.locked ?? false,
      lockExpiration: checkout.lock?.lockExpiration ?? undefined,
      lockTime: checkout.lock?.lockTime ?? undefined,
    },
    promotionsExtra: {
      availableFreeProducts: checkout.promotionsExtra?.availableFreeProducts,
      freeProductsThresholds:
        checkout.promotionsExtra?.freeProductPromoThresholds,
      availableProductPromoDiscounts:
        checkout.promotionsExtra?.availableProductPromoDiscounts,
    },
  }
}
