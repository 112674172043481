import { TrackEventType } from "../../../api/backend/backend-api"
import { trackEvent } from "../../../api/backend/tracking/api"
import { toPurchaseData } from "../google/converter"
import {
  TrackAddToCartInput,
  TrackClickedProductInput,
  Tracker,
  TrackPurchaseInput,
  TrackViewProductInput,
} from "../types"
import { getAnonUserId, getSessionId } from "./storage"

export const internalTracker: Tracker = {
  name: "internal",
  enabled: function (): boolean {
    return (window as any).internalTracking?.enabled ?? false
  },
  initialize: function (): void {},
  identifyUser: function (): void {},
  trackPageView: function (): void {},
  trackViewProduct: function (input: TrackViewProductInput): void {
    trackEvent({
      input: {
        event: TrackEventType.ViewedProduct,
        sessionId: getSessionId() as string,
        userId: getAnonUserId() as string,
        payload: {
          eanCode: input.variant.info.variantId,
        },
      },
    })
  },
  trackAddToCart: function (input: TrackAddToCartInput): void {
    trackEvent({
      input: {
        event: TrackEventType.ClickedProduct,
        sessionId: getSessionId() as string,
        userId: getAnonUserId() as string,
        payload: {
          eanCode: input.item.productVariant.info.variantId,
        },
      },
    })
  },
  trackRemoveFromCart: function (): void {},
  trackAddToWishlist: function (): void {},
  trackAddPromoCode: function (): void {},
  trackCompleteRegistration: function (): void {},
  trackContact: function (): void {},
  trackInitiateCheckout: function (): void {},
  trackAddShippingInfo: function (): void {},
  trackAddPaymentInfo: function (): void {},
  trackLead: function (): void {},
  trackPurchase: function (input: TrackPurchaseInput): void {
    trackEvent({
      input: {
        event: TrackEventType.OrderCompleted,
        sessionId: getSessionId() as string,
        userId: getAnonUserId() as string,
        payload: {
          google: toPurchaseData(input),
        },
      },
    })

    for (const lineItem of input.basket.items) {
      trackEvent({
        input: {
          event: TrackEventType.PurchasedProduct,
          sessionId: getSessionId() as string,
          userId: getAnonUserId() as string,
          payload: {
            eanCode: lineItem.variant?.info.variantId,
          },
        },
      })
    }
  },
  trackSearch: function (): void {},
  trackClickedProduct: function (input: TrackClickedProductInput): void {
    trackEvent({
      input: {
        event: TrackEventType.ClickedProduct,
        sessionId: getSessionId() as string,
        userId: getAnonUserId() as string,
        payload: {
          eanCode: input.variant.info.variantId,
          searchId: input.searchId,
          position: input.position,
        },
      },
    })
  },
  trackPopup: () => {},
  trackSelectProductGift: () => {},
  trackReservations: () => {},
}
