import { ThemeColor, ThemeColorVariant } from "@punks/core"
import { defaultAppTheme, paletteContrast } from "./default"
import { lightBlack } from "./variables"

export const getColorMain = (color: ThemeColor) => {
  switch (color) {
    case "black":
      return lightBlack
    case "disabled":
      return defaultAppTheme.palette?.action?.disabled as string
    case "error":
      return defaultAppTheme.palette?.error?.main as string
    case "info":
      return defaultAppTheme.palette?.info?.main as string
    case "inherit":
      return "inherit"
    case "neutral":
      return defaultAppTheme.palette?.action?.active as string
    case "primary":
      return defaultAppTheme.palette?.primary?.main as string
    case "secondary":
      return defaultAppTheme.palette?.secondary?.main as string
    case "success":
      return defaultAppTheme.palette?.success?.main as string
    case "transparent":
      return "transparent"
    case "warning":
      return defaultAppTheme.palette?.warn?.main as string
    case "white":
      return "#fff"
  }
}

export const getColorLight = (color: ThemeColor) => {
  switch (color) {
    case "black":
      return lightBlack
    case "disabled":
      return defaultAppTheme.palette?.action?.disabled as string
    case "error":
      return defaultAppTheme.palette?.error?.light as string
    case "info":
      return defaultAppTheme.palette?.info?.light as string
    case "inherit":
      return "inherit"
    case "neutral":
      return defaultAppTheme.palette?.action?.active as string
    case "primary":
      return defaultAppTheme.palette?.primary?.light as string
    case "secondary":
      return defaultAppTheme.palette?.secondary?.light as string
    case "success":
      return defaultAppTheme.palette?.success?.light as string
    case "transparent":
      return "transparent"
    case "warning":
      return defaultAppTheme.palette?.warn?.light as string
    case "white":
      return "#fff"
  }
}

export const getColorDark = (color: ThemeColor) => {
  switch (color) {
    case "black":
      return lightBlack
    case "disabled":
      return defaultAppTheme.palette?.action?.disabled as string
    case "error":
      return defaultAppTheme.palette?.error?.dark as string
    case "info":
      return defaultAppTheme.palette?.info?.dark as string
    case "inherit":
      return "inherit"
    case "neutral":
      return defaultAppTheme.palette?.action?.active as string
    case "primary":
      return defaultAppTheme.palette?.primary?.dark as string
    case "secondary":
      return defaultAppTheme.palette?.secondary?.dark as string
    case "success":
      return defaultAppTheme.palette?.success?.dark as string
    case "transparent":
      return "transparent"
    case "warning":
      return defaultAppTheme.palette?.warn?.dark as string
    case "white":
      return "#fff"
  }
}

export const getThemeColor = (
  color: ThemeColor,
  colorVariant?: ThemeColorVariant
) => {
  switch (colorVariant) {
    case "light":
      return getColorLight(color)
    case "dark":
      return getColorDark(color)
    case "main":
    default:
      return getColorMain(color)
  }
}

export const getThemeColorContrast = (color: ThemeColor) => {
  switch (color) {
    case "black":
      return paletteContrast.black
    case "disabled":
      return paletteContrast.disabled
    case "error":
      return paletteContrast.error
    case "info":
      return paletteContrast.info
    case "inherit":
      return "inherit"
    case "neutral":
      return paletteContrast.neutral
    case "primary":
      return paletteContrast.primary
    case "secondary":
      return paletteContrast.secondary
    case "success":
      return paletteContrast.success
    case "transparent":
      return paletteContrast.transparent
    case "warning":
      return paletteContrast.warning
    case "white":
      return paletteContrast.white
  }
}
