import { createSlice } from "@reduxjs/toolkit"
import { LoginState } from "./loginTypes"

const createInitialState = () =>
  ({
    isLoading: false,
    error: false,
    wrongCredentials: false,
    oldUser: false,
    loginSuccess: false,
  } as LoginState)

const { actions, reducer } = createSlice({
  name: "login",
  initialState: createInitialState(),
  reducers: {
    userLoginStart: () => ({
      ...createInitialState(),
      isLoading: true,
      error: false,
      wrongCredentials: false,
      oldUser: false,
      loginSuccess: false,
    }),
    userLoginSuccessful: (state) => ({
      ...state,
      isLoading: false,
      error: false,
      wrongCredentials: false,
      oldUser: false,
      loginSuccess: true,
    }),
    userLoginFailure: (state) => ({
      ...state,
      isLoading: false,
      error: true,
      wrongCredentials: false,
      oldUser: false,
      loginSuccess: false,
    }),
    userLoginWrongCredentials: (state) => ({
      ...state,
      isLoading: false,
      error: false,
      wrongCredentials: true,
      oldUser: false,
      loginSuccess: false,
    }),
    userLoginOldUser: (state) => ({
      ...state,
      isLoading: false,
      error: false,
      wrongCredentials: false,
      oldUser: true,
      loginSuccess: false,
    }),
  },
})

export const {
  userLoginStart,
  userLoginSuccessful,
  userLoginFailure,
  userLoginWrongCredentials,
  userLoginOldUser,
} = actions

export default reducer
