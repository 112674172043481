// const compareFields = <T>(a: T, b: T, selector: (value: T) => any): number => {
//   if (selector(a) < selector(b)) {
//     return -1
//   }
//   if (selector(a) > selector(b)) {
//     return 1
//   }
//   return 0
// }

import { orderBy } from "lodash"

export const groupBy = <TValue, TKey>(
  array: TValue[],
  key: (element: TValue) => TKey
): Map<TKey, TValue[]> =>
  array.reduce((objectsByKeyValue: Map<any, TValue[]>, obj) => {
    const keyValue = key(obj)
    objectsByKeyValue.set(
      keyValue,
      (objectsByKeyValue.get(keyValue) ?? []).concat(obj)
    )
    return objectsByKeyValue
  }, new Map<TKey, TValue[]>())

export const chunkArray = <T>(arr: T[], len: number) => {
  const chunks = []
  let i = 0
  const n = arr.length

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)))
  }
  return chunks
}

export const range = (start: number, end: number): number[] => {
  return new Array(end - start + 1).fill(undefined).map((x, i) => i + start)
}

export const indexes = (startIndex: number, count: number) =>
  range(startIndex, startIndex + count - 1)

// export const sort = <T>(array: T[], property: (element: T) => number) =>
//   array.sort((a, b) => property(a) - property(b))

// export const sortDesc = <T>(array: T[], property: (element: T) => number) =>
//   sort(array, (x) => -property(x))

// export const byField = <T>(
//   selector: (value: T) => any
// ): ((a: T, b: T) => number) => {
//   return function (a: T, b: T) {
//     return compareFields(a, b, selector)
//   }
// }

// export const byFieldDesc = <T>(
//   selector: (value: T) => any
// ): ((a: T, b: T) => number) => {
//   return function (a: T, b: T) {
//     return -compareFields(a, b, selector)
//   }
// }

export interface Dict<TVal> {
  [key: string]: TVal
}

export const toDict = <TVal>(
  array: TVal[],
  selector: (value: TVal) => string
): Dict<TVal> => {
  const data: Dict<TVal> = {}
  for (const item of array) {
    data[selector(item)] = item
  }
  return data
}

export const toMap = <TKey, TVal>(
  array: TVal[],
  key: (val: TVal) => TKey
): Map<TKey, TVal> => {
  const map = new Map<TKey, TVal>()
  array.forEach((x) => map.set(key(x), x))
  return map
}

export const sumValues = (array: number[]) => array.reduce((a, b) => a + b, 0)

export const randomIndex = <T>(array: T[]) =>
  Math.floor(Math.random() * array.length)

export const pickRandomElement = <T>(array: T[]) => array[randomIndex(array)]

export const last = <T>(array: T[]) =>
  array.length > 0 ? array[array.length - 1] : undefined

export const isLast = (index: number, array: any[]) =>
  index === array.length - 1

export const sortStrings = (array: string[]) => orderBy(array, (x) => x)

export const elementsEquals = (array1: string[], array2: string[]) => {
  if (array1.length !== array2.length) {
    return false
  }
  const sortedArray2 = sortStrings(array2)
  return sortStrings(array1).every((x, i) => x === sortedArray2[i])
}

export const dictValues = <T>(dict: Dict<T>): T[] => {
  return Object.keys(dict).map((x) => dict[x])
}
