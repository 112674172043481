import React from "react"
import Typography from "../../../../ui/atoms/Typography"
import classNames from "classnames"
import classes from "./renderer.module.css"
import { TextNode, TextProps } from "../../sanity/richText/types"

interface Props {
  text: TextNode
  props: TextProps
  innerKey?: any
}

const TwTextRenderer = ({ text, props }: Props) => {
  const { component, textClassName, ...other } = props
  return (
    <Typography
      className={classNames(textClassName, classes.root)}
      component={component as any}
      {...other}
    >
      {text}
    </Typography>
  )
}

export default TwTextRenderer
