import { TextProps } from "../../types"
import { SanityBlockNode } from "./types"

export const getVariant = (node: SanityBlockNode) => {
  switch (node.style) {
    case "h1":
      return "h1"
    case "h2":
      return "h2"
    case "h3":
      return "h3"
    case "h4":
      return "h4"
    case "h5":
      return "h5"
    case "h6":
      return "h6"
    case "subtitle1":
      return "subtitle1"
    case "subtitle2":
      return "subtitle2"
    case "overline":
      return "overline"
    case "button":
      return "button"
    case "caption":
      return "caption"
    case "normal":
      return "body1"
    case "body2":
      return "body2"
    default:
      console.error(`Invalid style ${node.style} for node ${node._key}`)
      return undefined
  }
}

export const getComponent = (
  node: SanityBlockNode,
  typographyProps: TextProps | undefined,
  root: boolean
) => {
  if (node.listItem && ["bullet", "number"].includes(node.listItem)) {
    return "li"
  }
  switch (typographyProps?.variant ?? node.style) {
    case "h1":
      return "h1"
    case "h2":
      return "h2"
    case "h3":
      return "h3"
    case "h4":
      return "h4"
    case "h5":
      return "h5"
    case "h6":
      return "h6"
    default:
      return root ? "p" : "span"
  }
}
