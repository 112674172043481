import { readObject, removeObject, writeObject } from "@punks/core"
import { MarketingProfile, UserAccount, UserProfile } from "../state/userTypes"

const ACCOUNT_PROFILE_KEY = "profile:anon_customer_account"
const MARKETING_PROFILE_KEY = "profile:anon_marketing_profile"
const USER_PROFILE_KEY = "profile:anon_user_profile"

const createEmptyMarketingProfile = (): MarketingProfile => ({
  profilingConsent: false,
  marketingConsent: false,
  surveyConsent: false,
  subscribedToNewsletter: false,
})

export const saveAnonymousUserAccount = (profile: UserAccount) =>
  writeObject(ACCOUNT_PROFILE_KEY, profile)

const createEmptyUserAccount = (): UserAccount => ({
  email: "",
  emailVerified: undefined,
})

export const readAnonymousUserAccount = (): UserAccount =>
  readObject(ACCOUNT_PROFILE_KEY) ?? createEmptyUserAccount()

export const deleteAnonymousUserAccount = () =>
  removeObject(ACCOUNT_PROFILE_KEY)

export const saveAnonymousMarketingProfile = (profile: MarketingProfile) =>
  writeObject(MARKETING_PROFILE_KEY, profile)

export const readAnonymousMarketingProfile = (): MarketingProfile =>
  readObject(MARKETING_PROFILE_KEY) ?? createEmptyMarketingProfile()

export const deleteAnonymousMarketingProfile = () =>
  removeObject(MARKETING_PROFILE_KEY)

const createEmptyUserProfile = (): UserProfile => ({
  firstName: "",
  lastName: "",
  birthday: "",
  gender: undefined,
  taxCode: undefined,
  phone: undefined,
  country: undefined,
  state: undefined,
  city: undefined,
  address: undefined,
  zipCode: undefined,
})

export const saveAnonymousUserProfile = (profile: UserProfile) =>
  writeObject(USER_PROFILE_KEY, profile)

export const readAnonymousUserProfile = (): UserProfile =>
  readObject(USER_PROFILE_KEY) ?? createEmptyUserProfile()

export const deleteAnonymousUserProfile = () => removeObject(USER_PROFILE_KEY)
