import { ProductVariant } from "../../../types/product"
import { mul, sub } from "../../../utils/math"
import {
  Basket,
  BasketCustomer,
  BasketItem,
} from "../../checkout/state/checkoutTypes"
import { LineItemInput } from "../../checkout/types"
import { TrackPurchaseInput } from "../types"

const variantCommonProperties = (variant: ProductVariant) => ({
  id: variant.info.variantId,
  name: variant.contents?.name,
  brand: variant.metadata.brand?.name,
  category: variant.metadata?.categoryPath?.[0]?.name,
  category2: variant.metadata?.categoryPath?.[1]?.name,
  category3: variant.metadata?.categoryPath?.[2]?.name,
  variant: variant.info.color?.name ?? variant.info.quantity,
  google_business_vertical: "retail",
})

// const variantCommonProperties = (variant: ProductVariant) => ({
//   currency: "EUR",
//   item_id: variant.info.variantId,
//   item_name: variant.contents?.name,
//   item_brand: variant.metadata.brand?.name,
//   item_category: variant.metadata?.categoryPath?.[0]?.name,
//   item_category2: variant.metadata?.categoryPath?.[1]?.name,
//   item_category3: variant.metadata?.categoryPath?.[2]?.name,
//   item_variant: variant.info.color?.name ?? variant.info.quantity,
//   location_id: "default",
// })

const calculateDiscount = (variant: ProductVariant) =>
  variant.inventory?.compareAtPrice &&
  variant.inventory?.price !== variant.inventory?.compareAtPrice
    ? sub(variant.inventory.compareAtPrice, variant.inventory.price)
    : 0

export const toPurchaseItem = (item: BasketItem) => {
  const compareAtPrice =
    item.order.total.compareAtPrice ?? item.order.total.originalPrice
  const price =
    item.order.total.discountedPrice ?? item.order.total.originalPrice
  return {
    ...(item.variant ? variantCommonProperties(item.variant) : {}),
    //       affiliation: "Google Store",
    //       coupon: "SUMMER_FUN",
    discount: (compareAtPrice !== price
      ? sub(compareAtPrice, price)
      : 0
    ).toString(),
    index: item.index,
    //       item_list_id: "related_products",
    //       item_list_name: "Related Products",
    price: price.toString(),
    quantity: item.order.quantity,
  }
}

export const toAddToCartItem = (item: LineItemInput) => ({
  ...(item.productVariant ? variantCommonProperties(item.productVariant) : {}),
  // affiliation: "Google Store",
  // coupon: "SUMMER_FUN",
  discount: calculateDiscount(item.productVariant),
  index: 0,
  // item_list_id: "related_products",
  // item_list_name: "Related Products",
  price: mul(item.productVariant.inventory?.price ?? 0, item.quantity),
  quantity: item.quantity,
})

export const toCommonProductItem = (variant: ProductVariant) => ({
  ...variantCommonProperties(variant),
  // affiliation: "Google Store",
  // coupon: "SUMMER_FUN",
  discount: calculateDiscount(variant),
  index: 0,
  // item_list_id: "related_products",
  // item_list_name: "Related Products",
  price: variant.inventory?.price,
  quantity: 1,
})

export const toUserData = (customer: BasketCustomer) => ({
  email: customer.email,
  acceptsMarketing: customer.acceptsMarketing,
})

export const toPurchaseData = (input: TrackPurchaseInput) => ({
  currency: "EUR",
  transaction_id: input.orderId,
  value: input.price.price,
  coupon: input.basket.promotions[0]?.code,
  shipping: input.basket.recap.shipping?.price,
  items: input.basket.items.map((x) => toPurchaseItem(x)),
})

// export const toConversionData = (input: TrackPurchaseInput) => ({
//   currency: "EUR",
//   send_to: process.env.GOOGLE_ADS_SEND_TO,
//   value: input.price.price,
//   transaction_id: input.orderId,
// })

export const toBasketInfo = (basket: Basket) => ({
  price: {
    total: basket.recap.total?.price,
    subtotal: basket.recap.subtotal?.price,
    discountTotal: basket.recap.discountTotal?.price ?? 0,
    shipping: basket.recap.shipping?.price ?? 0,
    articlesNumber: basket.recap.articlesNumber,
  },
  promo: {
    codes: basket.promotions.map((x) => x.code),
    codeValues: basket.promotions.map((x) => x.code).join("|"),
  },
})
