import { AppThunk } from "../../../state/store"
import {
  setBasketDrawerOpen,
  setWishlistDrawerOpen,
  setLoginDialogOpen,
  setMobileMenuOpen,
  setSearchDialogOpen,
  setWelcomePanelOpen,
  setNewPoliciesPanelOpen,
  setPromoRouletteModalOpen,
  setPromoTimedVoucherOpen,
} from "./uiSlice"

export const updateBasketDrawerState = (open: boolean): AppThunk => async (
  dispatch
) => {
  dispatch(setBasketDrawerOpen(open))
}

export const updateWishlistDrawerState = (open: boolean): AppThunk => async (
  dispatch
) => {
  dispatch(setWishlistDrawerOpen(open))
}

export const updateLoginDialogState = (open: boolean): AppThunk => async (
  dispatch
) => {
  dispatch(setLoginDialogOpen(open))
}

export const updateMobileMenuState = (open: boolean): AppThunk => async (
  dispatch
) => {
  dispatch(setMobileMenuOpen(open))
}

export const updateSearchDialogState = (open: boolean): AppThunk => async (
  dispatch
) => {
  dispatch(setSearchDialogOpen(open))
}

export const updateWelcomePanelOpenState = (open: boolean): AppThunk => async (
  dispatch
) => {
  dispatch(setWelcomePanelOpen(open))
}

export const updateNewPoliciesPanelState = (open: boolean): AppThunk => async (
  dispatch
) => {
  dispatch(setNewPoliciesPanelOpen(open))
}

export const updatePromoRouletteModalOpenState = (
  open: boolean
): AppThunk => async (dispatch) => {
  dispatch(setPromoRouletteModalOpen(open))
}

export const updatePromoTimedVoucherOpen = (open: boolean): AppThunk => async (
  dispatch
) => {
  dispatch(setPromoTimedVoucherOpen(open))
}
