import React from "react"
import CustomButton from "../../../../ui/atoms/Button"

export interface PrivateModeLoginPageProps {
  onTokenSubmit: (token: string) => void
  loginError?: boolean
}

const PrivateModeLoginPage = ({
  onTokenSubmit,
  loginError,
}: PrivateModeLoginPageProps) => {
  const [pwd, setPwd] = React.useState("")
  //   const image = !!logo && (
  //     <img
  //       width="130"
  //       height="auto"
  //       src={logo}
  //       alt="Logo"
  //       style={{ marginBottom: "40px" }}
  //     />
  //   )

  return (
    <div
      style={{
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "100vw",
        minHeight: "100vh",
        width: "100%",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          maxWidth: "100%",
          display: "flex",
          flex: 1,
          width: "100%",
          padding: "32px 16px",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <style
          dangerouslySetInnerHTML={{
            __html: `
              html {
                font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
              }

              body {
                margin: 0;
              }

              * {
                box-sizing: border-box;
              }

              .link {
                font-style: normal;
                text-decoration: none;
                color: #666;
                transition: color 0.2s ease-out;
              }

              .link:hover, .link:focus {
                color: #111;
              }

              #password-form {
                padding: 40px;
              }

              @media (max-width: 767px) {
                #password-form {
                  padding: 32px 16px;
                }
              }

              #password-form .invalid {
                outline: auto 1px;
                outline-color: #DD4A4A;
                animation: shake .4s linear;
              }

              #password-form .error {
                color: #DD4A4A;
                margin-top: 12px;
                font-size: 18px;
              }

              @keyframes shake {
                8%, 41% {
                  transform: translateX(-10px);
                }
                25%, 58% {
                  transform: translateX(10px);
                }
                75% {
                  transform: translateX(-5px);
                }
                92% {
                  transform: translateX(5px);
                }
                0%, 100% {
                  transform: translateX(0);
                }
              }
            `,
          }}
        />

        <div
          id="password-form"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            boxShadow: "0px 15px 40px rgba(26, 30, 43, 0.13)",
            borderRadius: "8px",
            width: "420px",
            maxWidth: "100%",
            marginBottom: "48px",
          }}
        >
          <div style={{ margin: "0 auto", textAlign: "center" }}>
            <img
              width="200"
              height="auto"
              src={
                "https://cdn.sanity.io/images/srnt2ltl/production/f47000ce402d5806f4a7837aaee225a89eaa1c3c-1130x328.svg?w=250"
              }
              alt="Logo"
              style={{ marginBottom: "40px" }}
            />
            <h1 style={{ margin: "0 0 24px", color: "#111" }}>AMBIENTE TEST</h1>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <label
              htmlFor="password"
              style={{
                color: "#525252",
                fontSize: "18px",
                marginBottom: "8px",
              }}
            >
              Password
            </label>
            <input
              className={loginError ? "invalid" : ""}
              name="password"
              type="password"
              id="password"
              placeholder="Enter password..."
              required
              onChange={(e) => setPwd(e.target.value)}
              style={{
                background: "#F5F5F5",
                borderRadius: "4px",
                padding: "0 16px",
                fontSize: "18px",
                color: "#525252",
                border: "none",
                height: "48px",
              }}
            />
            {!!loginError && (
              <div className="error" data-testid="error">
                Invalid password
              </div>
            )}

            <CustomButton onClick={() => onTokenSubmit(pwd)} className="mt-4">
              Login
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivateModeLoginPage
