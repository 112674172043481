import React from "react"
import TwTextRenderer from "./renderer"
import { TextRenderers } from "../../core/fields/RichTextField/renderer"

interface Props {
  children: any
}

export const TwRichTextRenderingProvider = ({ children }: Props) => {
  return (
    <TextRenderers.Provider
      value={{
        default: (text, props, key) => (
          <TwTextRenderer text={text} props={props} key={key} />
        ),
      }}
    >
      {children}
    </TextRenderers.Provider>
  )
}
