import { createSlice } from "@reduxjs/toolkit"
import { ChangeEmailVerificationState } from "./changeEmailVerifyTypes"

const initialStateProvider = () =>
  ({
    isLoading: false,
    error: undefined,
    result: undefined,
  } as ChangeEmailVerificationState)

const initialState = initialStateProvider()

const { actions, reducer } = createSlice({
  name: "changeEmailVerify",
  initialState,
  reducers: {
    changeEmailVerifyStart: () => ({
      ...initialState,
      isLoading: true,
    }),
    changeEmailVerifySuccessful: () => ({
      isLoading: false,
      error: false,
      result: "ok",
    }),
    changeEmailVerifyError: () => ({
      ...initialState,
      error: true,
    }),
  },
})

export const {
  changeEmailVerifyStart,
  changeEmailVerifySuccessful,
  changeEmailVerifyError,
} = actions

export default reducer
