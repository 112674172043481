import { createSlice } from "@reduxjs/toolkit"
import { ChangeEmailState } from "./changeEmailTypes"

const createInitialState = () =>
  ({
    isLoading: false,
    error: false,
    changeEmailSuccess: false,
    emailInUseError: false,
  } as ChangeEmailState)

const { actions, reducer } = createSlice({
  name: "changeEmail",
  initialState: createInitialState(),
  reducers: {
    changeEmailStart: () => ({
      ...createInitialState(),
      isLoading: true,
    }),
    changeEmailSuccessful: () => ({
      isLoading: false,
      error: false,
      changeEmailSuccess: true,
      emailInUseError: false,
    }),
    changeEmailError: () => ({
      isLoading: false,
      error: true,
      changeEmailSuccess: false,
      emailInUseError: false,
    }),
    changeEmailInUseError: () => ({
      isLoading: false,
      error: true,
      changeEmailSuccess: false,
      emailInUseError: true,
    }),
  },
})

export const {
  changeEmailStart,
  changeEmailSuccessful,
  changeEmailError,
  changeEmailInUseError,
} = actions

export default reducer
