import React from "react"

interface Props {
  analyticsId: string
}

export const GaHead = ({ analyticsId }: Props) => {
  if (!analyticsId) {
    return <></>
  }
  return (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${analyticsId}`}
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${analyticsId}');`,
        }}
      ></script>
    </>
  )
}
