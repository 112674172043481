const today = () => {
  const d = new Date().toLocaleDateString("it-it", { timeZone: "Europe/Rome" })
  const p = d.split("/")
  return `${p[2]}-${p[1].padStart(2, "0")}-${p[0].padStart(2, "0")}`
}

export const isActiveDate = (minDate?: string, maxDate?: string) => {
  const t = today()
  return (!minDate || t >= minDate) && (!maxDate || t <= maxDate)
}
