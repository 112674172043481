import { Tracker, TrackPurchaseInput } from "../types"

const getPixelId = () => process.env.FACEBOOK_PIXEL_ID

const fbq = () => (window as any).fbq
const track = (event: string, data?: any) => {
  try {
    fbq()?.("track", event, data)
  } catch (e) {
    console.error(`FB tracker -> error tracking event ${event}`, e)
  }
}

export const facebookTracker: Tracker = {
  name: "Facebook",
  enabled: () => !!getPixelId(),
  identifyUser: function (): void {},
  initialize: () => {
    console.log("fb pixel init")
    const loadScript = require("./script").loadScript // eslint-disable-line
    loadScript()
    fbq()?.("init", getPixelId())
    track("PageView")
  },
  trackViewProduct: () => track("ViewContent"),
  trackAddPromoCode: () => {},
  trackAddToCart: () => track("AddToCart"),
  trackRemoveFromCart: () => {},
  trackAddToWishlist: () => track("AddToWishlist"),
  trackCompleteRegistration: () => track("CompleteRegistration"),
  trackContact: () => track("Contact"),
  trackInitiateCheckout: () => track("InitiateCheckout"),
  trackLead: () => track("Lead"),
  trackPageView: () => track("PageView"),
  trackPurchase: (input: TrackPurchaseInput) =>
    track("Purchase", {
      value: input.price.price,
      currency: "EUR",
    }),
  trackAddShippingInfo: () => {},
  trackAddPaymentInfo: () => {},
  trackSearch: () => track("Search"),
  trackClickedProduct: () => {},
  trackPopup: () => {},
  trackSelectProductGift: () => {},
  trackReservations: () => {},
}
