import * as queryString from "query-string"

export const parseQuery = (value: string) => queryString.parse(value)

export const serializeQuery = (value: any) => queryString.stringify(value)

export const buildUrl = (url: string, params?: any) =>
  params ? `${url}?${serializeQuery(params)}` : url

export const getQueryParam = (
  location: Location | undefined,
  name: string
): string =>
  location ? (queryString.parse(location.search)[name] as string) : ""
