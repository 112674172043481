import { MarkSerializerDefinition } from "./marks"
import { ExternalLinkMark } from "./marks/ExternalLink"
import { InternalLinkMark } from "./marks/InternalLink"
import { BlockSerializer } from "./serializers/Block"
import { ImageSerializer } from "./serializers/MainImage"
import { NodeSerializerDefinition } from "./types"

export const serializerDefinitions: () => NodeSerializerDefinition[] = () => [
  {
    type: "block",
    component: BlockSerializer,
  },
  {
    type: "richTextImage",
    component: ImageSerializer,
  },
]

export const markDefinitions: () => MarkSerializerDefinition[] = () => [
  {
    type: "internalLink",
    component: InternalLinkMark,
  },
  {
    type: "link",
    component: ExternalLinkMark,
  },
]
