import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { InitSettingsInput, SettingsState } from "./types"

const settings = createSlice({
  name: "settings",
  initialState: {
    initialized: false,
  } as SettingsState,
  reducers: {
    initSettings(state, action: PayloadAction<InitSettingsInput>) {
      return {
        ...action.payload,
        initialized: true,
      }
    },
  },
})

export const { initSettings } = settings.actions

export default settings.reducer
