export const hasValidDate = (
  input: {
    validFrom?: string
    validTo?: string
  },
  refDate: Date
) => {
  return (
    (!input.validFrom || refDate >= new Date(input.validFrom)) &&
    (!input.validTo || refDate <= new Date(input.validTo))
  )
}
