import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Wishlist } from "../../../api/backend/backend-api"
import { WishlistState } from "./wishlistTypes"

const wishlist = createSlice({
  name: "wishlist",
  initialState: {
    wishlist: undefined,
    updating: false,
    initialized: false,
  } as WishlistState,
  reducers: {
    updateWishlistStarted(state) {
      return {
        ...state,
        updating: true,
        error: undefined,
      }
    },
    updateWishlistCompleted(
      state,
      action: PayloadAction<Wishlist | undefined>
    ) {
      return {
        ...state,
        wishlist: action.payload,
        updating: false,
        initialized: true,
        error: undefined,
      }
    },
    updateWishlistError(state, action: PayloadAction<any>) {
      return {
        ...state,
        updating: false,
        initialized: true,
        error: action.payload,
      }
    },
  },
})

export const {
  updateWishlistStarted,
  updateWishlistCompleted,
  updateWishlistError,
} = wishlist.actions

export default wishlist.reducer
