import React from "react"
import { RawHtmlReact } from "../../ui/utilities/Html"

interface Props {
  rootData: any
}

const CookiesBar = ({ rootData }: Props) => {
  return (
    <>
      {rootData?.layout?.data?.customScripts?.cookiesHead && (
        <RawHtmlReact value={rootData.layout.data.customScripts.cookiesHead} />
      )}
    </>
  )
}

export default CookiesBar
