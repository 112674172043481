import { facebookTracker } from "./facebook"
import { googleTracker } from "./google"
import { internalTracker } from "./internal"
import { klaviyoTracker } from "./klaviyo"
import { getLastTrackedOrder, saveLastTrackedOrder } from "./store"
import {
  IdentifyInput,
  TrackAddPaymentInfoInput,
  TrackAddPromoCodeInput,
  TrackAddShippingInfoInput,
  TrackAddToCartInput,
  TrackAddToWishlistInput,
  TrackClickedProductInput,
  Tracker,
  TrackInitiateCheckoutInput,
  TrackPopupInput,
  TrackPurchaseInput,
  TrackSelectProductGiftInput,
  TrackViewProductInput,
  TrackReservationsInput,
} from "./types"

const trackers: Tracker[] = [
  facebookTracker,
  googleTracker,
  klaviyoTracker,
  internalTracker,
]

const enabledTrackers = () =>
  trackers.filter((x) => {
    try {
      return x.enabled()
    } catch (e) {
      console.error(`TRACKER | ${x.name} | Error retrieving enabled state`, e)
      return false
    }
  })

export const initTracking = () => {
  enabledTrackers().forEach((x) => {
    try {
      x.initialize()
    } catch (e) {
      console.error(`TRACKER | ${x.name} | Error initializing tracking`, e)
    }
  })
}

export const identifyUser = (input: IdentifyInput) => {
  enabledTrackers().forEach((x) => {
    try {
      x.identifyUser(input)
    } catch (e) {
      console.error(`TRACKER | ${x.name} | Error invoking identiy`, e)
    }
  })
}

export const trackViewProduct = (input: TrackViewProductInput) => {
  enabledTrackers().forEach((x) => {
    try {
      x.trackViewProduct(input)
    } catch (e) {
      console.error(`TRACKER | ${x.name} | Error tracking view product`, e)
    }
  })
}

export const trackAddToCart = (input: TrackAddToCartInput) => {
  enabledTrackers().forEach((x) => {
    try {
      x.trackAddToCart(input)
    } catch (e) {
      console.error(`TRACKER | ${x.name} | Error tracking add to cart`, e)
    }
  })
}

export const trackClickedProduct = (input: TrackClickedProductInput) => {
  enabledTrackers().forEach((x) => {
    try {
      x.trackClickedProduct(input)
    } catch (e) {
      console.error(`TRACKER | ${x.name} | Error tracking clicked product`, e)
    }
  })
}

export const trackAddToWishlist = (input: TrackAddToWishlistInput) => {
  enabledTrackers().forEach((x) => {
    try {
      x.trackAddToWishlist(input)
    } catch (e) {
      console.error(`TRACKER | ${x.name} | Error tracking add to wishlist`, e)
    }
  })
}

export const trackCompleteRegistration = () => {
  enabledTrackers().forEach((x) => {
    try {
      x.trackCompleteRegistration()
    } catch (e) {
      console.error(
        `TRACKER | ${x.name} | Error tracking complete registration`,
        e
      )
    }
  })
}

export const trackContact = () => {
  enabledTrackers().forEach((x) => {
    try {
      x.trackContact()
    } catch (e) {
      console.error(`TRACKER | ${x.name} | Error tracking contact`, e)
    }
  })
}

export const trackInitiateCheckout = (input: TrackInitiateCheckoutInput) => {
  enabledTrackers().forEach((x) => {
    try {
      x.trackInitiateCheckout(input)
    } catch (e) {
      console.error(`TRACKER | ${x.name} | Error tracking checkout init`, e)
    }
  })
}

export const trackAddPromoCode = (input: TrackAddPromoCodeInput) => {
  enabledTrackers().forEach((x) => {
    try {
      x.trackAddPromoCode(input)
    } catch (e) {
      console.error(`TRACKER | ${x.name} | Error tracking add promo code`, e)
    }
  })
}

export const trackSelectProductGift = (input: TrackSelectProductGiftInput) => {
  enabledTrackers().forEach((x) => {
    try {
      x.trackSelectProductGift(input)
    } catch (e) {
      console.error(
        `TRACKER | ${x.name} | Error tracking select product gift`,
        e
      )
    }
  })
}

export const trackLead = () => {
  enabledTrackers().forEach((x) => {
    try {
      x.trackLead()
    } catch (e) {
      console.error(`TRACKER | ${x.name} | Error tracking lead`, e)
    }
  })
}

export const trackPageView = () => {
  enabledTrackers().forEach((x) => {
    try {
      x.trackPageView()
    } catch (e) {
      console.error(`TRACKER | ${x.name} | Error tracking page view`, e)
    }
  })
}

export const trackAddShippingInfo = (input: TrackAddShippingInfoInput) => {
  enabledTrackers().forEach((x) => {
    try {
      x.trackAddShippingInfo(input)
    } catch (e) {
      console.error(`TRACKER | ${x.name} | Error tracking add shipping info`, e)
    }
  })
}

export const trackAddPaymentInfo = (input: TrackAddPaymentInfoInput) => {
  enabledTrackers().forEach((x) => {
    try {
      x.trackAddPaymentInfo(input)
    } catch (e) {
      console.error(`TRACKER | ${x.name} | Error tracking add payment info`, e)
    }
  })
}

export const trackPurchase = (input: TrackPurchaseInput) => {
  try {
    if (getLastTrackedOrder() === input.orderId) {
      console.log(
        `TRACKER | Skipping order ${input.orderId} -> already tracked`
      )
      return
    }
  } catch (e) {
    console.error("TRACKER | Error reading last tracked order", e)
  }

  enabledTrackers().forEach((x) => {
    try {
      x.trackPurchase(input)
    } catch (e) {
      console.error(`TRACKER | ${x.name} | Error tracking purchase`, e)
    }
  })

  try {
    saveLastTrackedOrder(input.orderId)
  } catch (e) {
    console.error("TRACKER | Error saving last tracked order", e)
  }
}

export const trackSearch = () => {
  enabledTrackers().forEach((x) => {
    try {
      x.trackSearch()
    } catch (e) {
      console.error(`TRACKER | ${x.name} | Error tracking lead`, e)
    }
  })
}

export const trackPopup = (input: TrackPopupInput) => {
  enabledTrackers().forEach((x) => {
    try {
      x.trackPopup(input)
    } catch (e) {
      console.error(`TRACKER | ${x.name} | Error tracking popup`, e)
    }
  })
}

export const trackReservations = (input: TrackReservationsInput) => {
  enabledTrackers().forEach((x) => {
    try {
      x.trackReservations(input)
    } catch (e) {
      console.error(`TRACKER | ${x.name} | Error tracking reservations`, e)
    }
  })
}
