import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { getGraphqlEndpoint } from "../../aggregators/rootData/settings"
import { readAuthToken } from "../../features/authentication/services/authService"

const httpLink = createHttpLink({
  uri: () => getGraphqlEndpoint(),
})

const authLink = setContext((x, { headers }) => {
  const token = typeof window !== "undefined" ? readAuthToken() : undefined
  return {
    headers: {
      ...headers,
      authorization: token ? `bearer ${token}` : "",
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV === "development",
})

export default client
