import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  AnonymousCustomerData,
  Basket,
  CheckoutError,
  CheckoutState,
} from "./checkoutTypes"
import { CheckoutAvailableProductPromoDiscount } from "../../../api/backend/backend-api"

const checkout = createSlice({
  name: "checkout",
  initialState: {
    initialized: false,
    loading: false,
    basket: undefined,
    error: undefined,
  } as CheckoutState,
  reducers: {
    showProductDiscountPopup: (
      state: CheckoutState,
      action: PayloadAction<CheckoutAvailableProductPromoDiscount[]>
    ) => ({
      ...state,
      productDiscountPopup: {
        show: true,
        discounts: action.payload,
      },
    }),
    hideProductDiscountPopup: (state: CheckoutState) => ({
      ...state,
      productDiscountPopup: undefined,
    }),
    updateBasketStarted: (state: CheckoutState) => ({
      ...state,
      loading: true,
      error: undefined,
    }),
    updateBasketCompleted: (
      state: CheckoutState,
      action: PayloadAction<Basket>
    ) => ({
      ...state,
      basket: action.payload,
      loading: false,
      error: undefined,
      initialized: true,
      anonymousCustomer: {
        email: action.payload.customer.email ?? "",
        acceptsMarketing: action.payload.customer.acceptsMarketing ?? false,
      },
    }),
    updateBasketError: (
      state: CheckoutState,
      action: PayloadAction<CheckoutError>
    ) => ({
      ...state,
      loading: false,
      initialized: true,
      error: action.payload,
    }),
    basketReset: () => ({
      initialized: true,
      loading: false,
      basket: undefined,
      error: undefined,
    }),
    updateAnonymousCustomer: (
      state: CheckoutState,
      action: PayloadAction<AnonymousCustomerData>
    ) => ({
      ...state,
      anonymousCustomer: action.payload,
    }),
  },
})

export const {
  updateBasketCompleted,
  updateBasketError,
  updateBasketStarted,
  basketReset,
  updateAnonymousCustomer,
  showProductDiscountPopup,
  hideProductDiscountPopup,
} = checkout.actions

export default checkout.reducer
