import React from "react"
import classNames from "classnames"
import { ThemeColor } from "@punks/core"
import classes from "./LezdaButton.module.css"

export type ButtonVariant = "outlined" | "contained"
export type ButtonSize = "small" | "medium" | "large" | "xl"

export interface LezdaButtonProps {
  children: any
  disabled?: boolean
  size?: ButtonSize
  variant?: ButtonVariant
  fullWidth?: boolean
  className?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>
  color?: ThemeColor
  noGutters?: boolean
  invariantCase?: boolean
  hoverInvariant?: boolean
  type?: "submit" | "reset" | "button"
}

const LezdaButton = ({
  children,
  size,
  fullWidth,
  className,
  onClick,
  onMouseDown,
  variant,
  noGutters,
  type,
  color,
  invariantCase,
  hoverInvariant,
  disabled,
}: LezdaButtonProps) => {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      onMouseDown={onMouseDown}
      className={classNames(
        "LezdaButton",
        classes.root,
        {
          [classes.small]: size === "small",
          [classes.smallNoGutters]: size === "small" && noGutters,
          [classes.medium]: size === "medium",
          [classes.mediumNoGutters]: size === "medium" && noGutters,
          [classes.large]: size === "large",
          [classes.largeNoGutters]: size === "large" && noGutters,
          [classes.xl]: size === "xl",
          [classes.xlNoGutters]: size === "xl" && noGutters,
          [classes.fullWidth]: fullWidth === true,
          [classes.outlined]: variant === "outlined",
          [classes.contained]: variant === "contained",
          [classes.containedPrimary]:
            variant === "contained" && color === "primary",
          [classes.containedPrimaryInvariant]:
            variant === "contained" && color === "primary" && hoverInvariant,
          [classes.containedSecondary]:
            variant === "contained" && color === "secondary",
          [classes.containedSecondaryInvariant]:
            variant === "contained" && color === "secondary" && hoverInvariant,
          [classes.containedWhite]:
            variant === "contained" && color === "white",
          [classes.outlinedPrimary]:
            variant === "outlined" && color === "primary",
          [classes.outlinedPrimaryInvariant]:
            variant === "outlined" && color === "primary" && hoverInvariant,
          [classes.invariantCase]: invariantCase,
        },
        className
      )}
    >
      {children}
    </button>
  )
}

LezdaButton.defaultProps = {
  size: "medium",
  color: "black",
  variant: "contained",
  ripple: "light",
}

export default LezdaButton
