export type CurrencyPosition = "after" | "before"

export interface MoneyInput {
  amount: number | undefined
  currency: string | undefined
  currencyPosition?: CurrencyPosition
}

export interface CurrencyMap {
  [details: string]: string
}

const currencyMap: CurrencyMap = {
  eur: "€",
}

const getCurrencySymbol = (currencyId: string) =>
  currencyMap[currencyId.toLowerCase()] ?? currencyId

const formatMoneyAmount = (amount: number) =>
  amount.toFixed(2).replace(".", ",")

export const moneyAmount = (amount: number) => parseFloat(amount.toFixed(2))

export const formatMoney = (input: MoneyInput) => {
  try {
    return input.amount !== undefined && input.amount !== null && input.currency
      ? [
          ...(!input.currencyPosition || input.currencyPosition === "before"
            ? [getCurrencySymbol(input.currency)]
            : []),
          formatMoneyAmount(input.amount),
          ...(input.currencyPosition === "after"
            ? [getCurrencySymbol(input.currency)]
            : []),
        ].join(" ")
      : undefined
  } catch (err) {
    console.error("Cannot format price", input, err)
  }
}
