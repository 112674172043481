import React from "react"
import { ListItemType, SanityBlockNode } from "../../serializers/Block"
import { SanityNode } from "../../types"
import { NodesProcessor } from "../types"

const splitLists = (nodes: SanityNode[]) => {
  const lists: SanityNode[][] = []
  let sublist: SanityNode[] = []
  let sublistType: ListItemType | undefined

  nodes.forEach((node) => {
    const listItemType = (node as SanityBlockNode).listItem
    if (sublistType === listItemType) {
      sublist.push(node)
    } else {
      if (sublist.length > 0) {
        lists.push(sublist)
      }
      sublistType = listItemType
      sublist = [node]
    }
  })

  if (sublist.length > 0) {
    lists.push(sublist)
  }

  return lists
}

const renderSublist = (
  nodes: SanityNode[],
  content: React.ReactNode,
  key: number
) => {
  switch ((nodes[0] as SanityBlockNode).listItem) {
    case "bullet":
      return <ul key={key}>{content}</ul>
    case "number":
      return <ol key={key}>{content}</ol>
    default:
      return <React.Fragment key={key}>{content}</React.Fragment>
  }
}

export const listItemProcessor: NodesProcessor = (nodes, next) => {
  return splitLists(nodes).map((sublist, index) => {
    return renderSublist(sublist, next?.[0](sublist, next?.slice(1)), index)
  })
}
