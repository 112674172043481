import { createSlice } from "@reduxjs/toolkit"
import { NavigationState } from "./navigationTypes"

const navigation = createSlice({
  name: "navigation",
  initialState: {
    isFirstPage: true,
  } as NavigationState,
  reducers: {
    setNotFirstPage(state) {
      return {
        ...state,
        isFirstPage: false,
      }
    },
  },
})

export const { setNotFirstPage } = navigation.actions

export default navigation.reducer
