import React from "react"
import { NodeSerializerDefinition, Placeholders, SanityNode } from "./types"

export const serializeNode = (
  definitions: Map<string, NodeSerializerDefinition>,
  node: SanityNode,
  data: { placeholders?: Placeholders }
) => {
  const definition = definitions.get(node._type)
  if (!definition) {
    console.error(`Undefined node type ${node._type} (key: ${node._key})`)
    return <></>
  }

  const Serializer = definition?.component as any
  return <Serializer node={node} placeholders={data?.placeholders} />
}
