import { cleanString, isEmpty, isNullOrEmpty, notUndefined } from "@punks/core"
import React from "react"
import { RichTextContext } from "../../context"
import { MarksWrapper } from "../../marks"
import {
  Placeholders,
  SanityMarkDef,
  TextProps,
  TextRenderer,
} from "../../types"
import { getComponent, getVariant } from "./converters"
import { SanityBlockNode, SanitySpanNode } from "./types"
import { useDefaultTextRenderer } from "../../../../core/fields/RichTextField/renderer"
import { replacePlaceholders } from "../../../../../../features/contents/utils/placeholders"

interface BlockProps {
  node: SanityBlockNode
  children: any
  root?: boolean
}

const Block = ({ node, root, children }: BlockProps) => {
  const renderer = useDefaultTextRenderer()
  const { typographyProps } = React.useContext(RichTextContext)
  return (
    <>
      {renderer(children, {
        variant: getVariant(node),
        component: getComponent(node, typographyProps, root ?? false),
        ...(typographyProps as any), // todo: remove after punks is removed
      })}
    </>
  )
}

interface SpanProps {
  node: SanitySpanNode
  markDefs: SanityMarkDef[]
  placeholders?: Placeholders
}

const createSpanComponentTree = (node: SanitySpanNode) => {
  const tree = []

  if (node.marks?.includes("strong")) {
    tree.push("strong")
  }

  if (node.marks?.includes("em")) {
    tree.push("em")
  }

  return isEmpty(tree) ? ["span"] : tree
}

interface SpanRenderingProps {
  tree: string[]
  textProps?: TextProps
  text: string | undefined
}

const renderSpanComponentTree = (
  renderer: TextRenderer,
  props: SpanRenderingProps,
  placeholders?: Placeholders
): React.ReactNode => {
  return renderer(
    props.tree.length > 1
      ? renderSpanComponentTree(
          renderer,
          {
            text:
              props.text && placeholders
                ? replacePlaceholders(props.text, placeholders)
                : props.text,
            tree: props.tree.slice(1),
          },
          placeholders
        )
      : props.text,
    {
      component: props.tree[0],
      ...props.textProps,
    }
  )
}

const getColor = (marks: string[]) => {
  if (marks?.includes("colorPrimary")) {
    return "primary"
  }
  if (marks?.includes("colorSecondary")) {
    return "secondary"
  }
  if (marks?.includes("colorLight")) {
    return "white"
  }
  if (marks?.includes("colorDark")) {
    return "black"
  }
  return undefined
}

const getCustomMark = (mark: string, markDefs: SanityMarkDef[]) =>
  markDefs.find((x) => x._key === mark)

const getCustomMarks = (node: SanitySpanNode, markDefs: SanityMarkDef[]) =>
  node.marks?.map((x) => getCustomMark(x, markDefs)).filter(notUndefined)

const InnerSpan = ({
  node,
  placeholders,
}: {
  node: SanitySpanNode
  placeholders?: Placeholders
}) => {
  const renderer = useDefaultTextRenderer()

  if (isNullOrEmpty(node.marks)) {
    return <>{node.text ? cleanString(node.text) : undefined}</>
  }

  const tree = createSpanComponentTree(node)
  return (
    <>
      {renderSpanComponentTree(
        renderer,
        {
          tree,
          text:
            node.text && placeholders
              ? replacePlaceholders(node.text, placeholders)
              : node.text,
          textProps: {
            textCenter: node.marks?.includes("textCenter"),
            textLeft: node.marks?.includes("textLeft"),
            textRight: node.marks?.includes("textRight"),
            textJustify: node.marks?.includes("textJustify"),
            underline: node.marks?.includes("underline"),
            color: node.marks ? getColor(node.marks) : undefined,
          },
        },
        placeholders
      )}
    </>
  )
}

const Span = ({ node, markDefs, placeholders }: SpanProps) => {
  return (
    <MarksWrapper marks={getCustomMarks(node, markDefs) ?? []}>
      <InnerSpan node={node} placeholders={placeholders} />
    </MarksWrapper>
  )
}

interface Props {
  node: SanityBlockNode
  markDefs?: SanityMarkDef[]
  parent?: SanityBlockNode
  placeholders?: Placeholders
}

export const BlockSerializer = ({
  node,
  markDefs,
  parent,
  placeholders,
}: Props) => {
  if (node._type === "block") {
    return (
      <Block node={node} root={parent === undefined}>
        {node.children?.map((x) => (
          <BlockSerializer
            node={x}
            key={x._key}
            markDefs={markDefs ?? node.markDefs}
            parent={node}
            placeholders={placeholders}
          />
        ))}
      </Block>
    )
  }

  if (node._type === "span") {
    return (
      <Span
        node={node}
        markDefs={markDefs ?? node.markDefs ?? []}
        placeholders={placeholders}
      />
    )
  }

  console.error(`Invalid node type ${node._type} for element ${node._key}`)
  return <></>
}
