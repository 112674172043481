import React from "react"
import { RawContent } from "./renderer"
import { RichTextProcessor, RichTextRenderingProps } from "./types"

export const SanityRichTextProcessor = ({
  value,
  placeholders,
  className,
  style,
  rootComponent,
  ...typographyProps
}: RichTextRenderingProps) => {
  return (
    <RawContent
      value={value?.raw ?? value}
      className={className}
      placeholders={placeholders}
      typographyProps={typographyProps}
      rootComponent={rootComponent}
      style={style}
    />
  )
}

export interface RichTextProcessorsContext {
  default: RichTextProcessor
}

export const RichTextProcessors = React.createContext<RichTextProcessorsContext>(
  {
    default: () => {
      console.error("No rich text processor configured")
      return <></>
    },
  }
)

interface Props {
  children: any
}

export const SanityRichTextProvider = ({ children }: Props) => {
  return (
    <RichTextProcessors.Provider
      value={{
        default: (props) => <SanityRichTextProcessor {...props} />,
      }}
    >
      {children}
    </RichTextProcessors.Provider>
  )
}
