import { CheckoutStatus } from "../../../../api/backend/backend-api"
import {
  getCheckout,
  getCustomerCheckout,
} from "../../../../api/backend/checkout/api"
import { AppThunk } from "../../../../state/store"
import { ProfileState } from "../../../user/state/userTypes"
import { clearBasket, readBasket } from "../../services/persist"
import {
  basketReset,
  updateBasketCompleted,
  updateBasketError,
} from "../checkoutSlice"
import { toBasket } from "../converters/toBasket"

const fetchCheckout = async (user: ProfileState, languageId: string) => {
  const checkoutId = readBasket()?.id
  if (checkoutId) {
    const checkout = await getCheckout({
      id: checkoutId,
      languageId,
      email: user.account?.email,
    })

    if (checkout.data.checkout) {
      return checkout.data.checkout
    }
  }

  if (user.isAuthenticated) {
    const checkout = await getCustomerCheckout({
      languageId,
    })
    if (checkout.data.customerCheckout) {
      return checkout.data.customerCheckout
    }
  }

  return undefined
}

export const loadCheckout = (): AppThunk => async (dispatch, getState) => {
  const languageId = getState().localization.languageId
  const user = getState().user

  console.log("Checkout fetch", user)
  try {
    const checkout = await fetchCheckout(user, languageId)
    if (!checkout) {
      clearBasket()
      dispatch(basketReset())
      return
    }

    if (checkout.status === CheckoutStatus.Completed) {
      clearBasket()
      dispatch(basketReset())
      return
    }

    dispatch(updateBasketCompleted(toBasket(checkout)))
  } catch (err) {
    dispatch(updateBasketError(err.toString()))
    console.error("Error loading basket", err)
  }
}
