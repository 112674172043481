import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SessionState } from "./sessionTypes"

const session = createSlice({
  name: "session",
  initialState: {
    startTimestamp: new Date().getTime(),
  } as SessionState,
  reducers: {
    resetSession(state, action: PayloadAction<Date>) {
      return {
        ...state,
        startTimestamp: action.payload.getTime(),
      }
    },
  },
})

export const { resetSession } = session.actions

export default session.reducer
