import {
  AppTheme,
  FontWeight,
  getColor,
  isEmpty,
  isEmptyObject,
  TextNode,
  TextProps,
  TextRenderer,
  TextVariant,
  useDefaultAppTheme,
} from "@punks/core"
import React, { CSSProperties } from "react"

const getTextAlign = (props: TextProps) => {
  if (props.textLeft) {
    return "left"
  }
  if (props.textCenter) {
    return "center"
  }
  if (props.textRight) {
    return "right"
  }
  if (props.textJustify) {
    return "justify"
  }

  return undefined
}

const getTextTransform = (props: TextProps) => {
  if (props.capitalize) {
    return "capitalize"
  }

  if (props.lowercase) {
    return "lowercase"
  }

  if (props.uppercase) {
    return "uppercase"
  }

  return undefined
}

const getTextColor = (props: TextProps, theme: AppTheme) => {
  if (props.colorValue) {
    return props.colorValue
  }

  if (props.color) {
    return getColor(theme, props.color, props.colorVariant)
  }

  return undefined
}

const getStyles = (props: TextProps, theme: AppTheme): CSSProperties => ({
  ...(getTextAlign(props) ? { textAlign: getTextAlign(props) } : undefined),
  ...(getTextTransform(props)
    ? { textTransform: getTextTransform(props) }
    : undefined),
  ...(getTextColor(props, theme)
    ? { color: getTextColor(props, theme) }
    : undefined),
  ...(props.ellipsis ? { textOverflow: "ellipsis" } : undefined),
  ...(props.underline ? { textDecoration: "underline" } : undefined),
  ...(props.fontSizeValue ? { fontSize: props.fontSizeValue } : undefined),
  ...(props.lineHeightValue
    ? { lineHeight: props.lineHeightValue }
    : undefined),
})

const getFontWeight = (weight: FontWeight) => `text--w-${weight}`

const getVariantClass = (variant: TextVariant) => `text--v-${variant}`

const getFontClass = (variant: TextVariant) => `text--f-${variant}`

const getLineHeightClass = (variant: TextVariant) => `text--lh-${variant}`

const getClasses = (props: TextProps) => {
  const classes = []

  if (props.textClassName) {
    classes.push(props.textClassName)
  }
  if (props.fontSize) {
    classes.push(getFontClass(props.fontSize))
  }
  if (props.variant) {
    classes.push(getVariantClass(props.variant))
  }
  if (props.weight) {
    classes.push(getFontWeight(props.weight))
  }
  if (props.lineHeight) {
    classes.push(getLineHeightClass(props.lineHeight))
  }

  return classes
}

const innerRenderer = (text: TextNode, props: TextProps, theme: AppTheme) => {
  const styles = getStyles(props, theme)
  const classes = getClasses(props)

  const elementProps = {
    style: !isEmptyObject(styles) ? styles : undefined,
    className: !isEmpty(classes) ? classes.join(" ") : undefined,
  }

  if (props.component) {
    return React.createElement(props.component, elementProps, text)
  }

  switch (props.variant) {
    case "h1":
      return <h1 {...elementProps}>{text}</h1>
    case "h2":
      return <h2 {...elementProps}>{text}</h2>
    case "h3":
      return <h3 {...elementProps}>{text}</h3>
    case "h4":
      return <h4 {...elementProps}>{text}</h4>
    case "h5":
      return <h5 {...elementProps}>{text}</h5>
    case "h6":
      return <h6 {...elementProps}>{text}</h6>
    default:
      return <span {...elementProps}>{text}</span>
  }
}

export const defaultRenderer: TextRenderer = (
  text: TextNode,
  props: TextProps,
  key?: any
) => {
  const theme = useDefaultAppTheme() // eslint-disable-line
  return (
    <React.Fragment key={key}>
      {innerRenderer(text, props, theme)}
    </React.Fragment>
  )
}
