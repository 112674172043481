import { BigFloat } from "bigfloat.js"

const toNumber = (value: BigFloat) => parseFloat(value.toString())

export const mul = (value1: number, value2: number): number =>
  toNumber(new BigFloat(value1).mul(new BigFloat(value2)))

export const div = (value1: number, value2: number): number =>
  toNumber(new BigFloat(value1).div(new BigFloat(value2)))

export const add = (value1: number, value2: number): number =>
  toNumber(new BigFloat(value1).add(new BigFloat(value2)))

export const sub = (value1: number, value2: number): number =>
  toNumber(new BigFloat(value1).sub(new BigFloat(value2)))
